/* @flow */

import { memo, useState } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Modal from 'src/shared/app/base/modal/component/Modal';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import messages from 'src/shared/app/topic/new/l10n/newTopicNotificationL10n';

import styles from './NewTopicNotificationModal.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  notification?: Notification<>,
  title: string,
  subtitle: string,
  content: string | Node,
  image: Node,
  acceptCTA?: string,
  closeCTA?: string,
  onAccept?: () => void,
  onDismiss?: () => void,
};

const NewTopicNotificationModal = ({
  notification,
  title,
  subtitle,
  content,
  image,
  acceptCTA,
  closeCTA,
  onAccept,
  onDismiss,
}: Props): Node => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);

  const classes = useStyles();

  const handleAccept = () => {
    if (onAccept) {
      onAccept();
    }
    if (notification) {
      dispatch(dismissNotification(notification));
    }
    setIsOpen(false);
  };

  const handleClose = () => {
    if (onDismiss) {
      window.setTimeout(() => {
        onDismiss();
      }, 500);
    }
    if (notification) {
      dispatch(dismissNotification(notification));
    }
    setIsOpen(false);
  };

  return (
    <Modal
      title={title}
      withLargeTitle
      content={
        <>
          <div>{image}</div>
          <p className={classes.subtitle}>{subtitle}</p>
          {typeof content === 'string' ? <p>{content}</p> : content}
        </>
      }
      width={450}
      acceptCTA={acceptCTA || intl.formatMessage(messages.addTopic)}
      closeCTA={closeCTA || intl.formatMessage(messages.browse)}
      handleAccept={handleAccept}
      handleClose={handleClose}
      isOpen={isOpen}
      isAnimated
    />
  );
};

export default memo<Props>(NewTopicNotificationModal);
