/* @flow */

import type { BraindateEventSponsor } from '@braindate/domain/lib/event/type';
import {
  getEventNavbarSponsors,
  getEventPlugins,
  getEventSponsorPluginLevelIsEnabled,
} from '@braindate/domain/lib/event/util';
import { getMembershipPhoneNumber } from '@braindate/domain/lib/membership/util';

import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import {
  mainNavMenuLevel,
  sponsorshipPlugin as sponsorshipPluginKey,
} from 'src/shared/app/feature/settings/featureSettings';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import useMembership from 'src/shared/domain/membership/hook/useMembership';

const useSidebar = (): {
  hasSidebar: boolean,
  showSurvey: boolean,
  eventSponsors: BraindateEventSponsor[],
  showPhoneReminder: boolean,
} => {
  const membership = useMembership();
  const { isProvideFeedbackSectionEnabled, isSmsPhoneReminderWidgetEnabled } =
    useFeatureDecisions();

  // Survey
  const showSurvey = isProvideFeedbackSectionEnabled;

  // Sponsors
  const event = useEvent();
  const sponsorshipPlugin = getEventPlugins(event)[sponsorshipPluginKey];
  const isSponsorPluginEnabledForSidebar =
    sponsorshipPlugin &&
    getEventSponsorPluginLevelIsEnabled(sponsorshipPlugin, mainNavMenuLevel);

  const eventSponsors = isSponsorPluginEnabledForSidebar
    ? getEventNavbarSponsors(event)
    : [];

  // SMS Phone Reminder Widget
  const showPhoneReminder =
    isSmsPhoneReminderWidgetEnabled &&
    (!membership ? false : !getMembershipPhoneNumber(membership));

  // https://e180.atlassian.net/browse/PAX-5015
  // const hasSidebar =
  //   (!!sponsorshipPlugin &&
  //     !!isSponsorPluginEnabledForSidebar &&
  //     !!eventSponsors.length) ||
  //   showSurvey;

  const hasSidebar = true;

  return {
    hasSidebar,
    showSurvey,
    eventSponsors,
    showPhoneReminder,
  };
};

export default useSidebar;
