/* @flow */

export const SUPPORT_LOGIN_LINK_REQUEST = 'SUPPORT_LOGIN_LINK_REQUEST';
export const SUPPORT_LOGIN_LINK_FAILURE = 'SUPPORT_LOGIN_LINK_FAILURE';
export const SUPPORT_LOGIN_LINK_SUCCESS = 'SUPPORT_LOGIN_LINK_SUCCESS';

export const RESET_SUPPORT_LOGIN_LINK = 'RESET_SUPPORT_LOGIN_LINK';

export const SUPPORT_RESEND_LOGIN_LINK_REQUEST =
  'SUPPORT_RESEND_LOGIN_LINK_REQUEST';
export const SUPPORT_RESEND_LOGIN_LINK_FAILURE =
  'SUPPORT_RESEND_LOGIN_LINK_FAILURE';
export const SUPPORT_RESEND_LOGIN_LINK_SUCCESS =
  'SUPPORT_RESEND_LOGIN_LINK_SUCCESS';
