/* @flow */

export const SHOW_MANUAL_AVAILABILITY_SECTION =
  'SHOW_MANUAL_AVAILABILITY_SECTION';
export const RESET_AVAILABILITY_SECTION = 'RESET_AVAILABILITY_SECTION';

export const SHOW_MANUAL_AVAILABILITY_TAKEOVER =
  'SHOW_MANUAL_AVAILABILITY_TAKEOVER';
export const RESET_AVAILABILITY_TAKEOVER = 'RESET_AVAILABILITY_TAKEOVER';

export const SHOW_SYNC_CONFIRMATION_MODAL = 'SHOW_SYNC_CONFIRMATION_MODAL';
export const RESET_SYNC_CONFIRMATION_MODAL = 'RESET_SYNC_CONFIRMATION_MODAL';
