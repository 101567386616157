(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./membershipAssertions"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./membershipAssertions"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.membershipAssertions);
    global.membershipGetters = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _membershipAssertions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.areMembershipEmailBundleNotificationsEnabled = areMembershipEmailBundleNotificationsEnabled;
  _exports.areMembershipEmailRecapNotificationsEnabled = areMembershipEmailRecapNotificationsEnabled;
  _exports.areMembershipEmailUrgentNotificationsEnabled = areMembershipEmailUrgentNotificationsEnabled;
  _exports.areMembershipSMSUrgentNotificationsEnabled = areMembershipSMSUrgentNotificationsEnabled;
  _exports.areMembershipSlackNotificationsEnabled = areMembershipSlackNotificationsEnabled;
  _exports.getMembershipEmailAddress = getMembershipEmailAddress;
  _exports.getMembershipFlags = getMembershipFlags;
  _exports.getMembershipGuidanceURL = getMembershipGuidanceURL;
  _exports.getMembershipId = getMembershipId;
  _exports.getMembershipPhoneNumber = getMembershipPhoneNumber;
  _exports.getMembershipPreferences = getMembershipPreferences;
  _exports.getMembershipTimeFormat = getMembershipTimeFormat;
  _exports.getMembershipTimezone = getMembershipTimezone;
  _exports.getMembershipURL = getMembershipURL;
  _exports.getMembershipURLs = getMembershipURLs;
  _exports.isMembershipFavoriteTutorialCompleted = isMembershipFavoriteTutorialCompleted;
  _exports.isMembershipOnboardingCompleted = isMembershipOnboardingCompleted;
  _exports.isMembershipOptInCompleted = isMembershipOptInCompleted;
  _exports.isMembershipProfileCompleted = isMembershipProfileCompleted;
  _exports.isMembershipProfileOptIn = isMembershipProfileOptIn;
  _exports.isMembershipSlackOptInCompleted = isMembershipSlackOptInCompleted;
  /*
   |------------------------------------------------------------------------------
   | Base getters
   |------------------------------------------------------------------------------
   */
  /**
   * Get the id of a membership
   * @param   {Membership} membership - Membership to get the id from
   * @returns {number} Id
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getMembershipId(membership) {
    (0, _membershipAssertions.assertMembership)(membership);
    return membership.id;
  }

  /**
   * Get the URL of a membership
   * @param   {Membership} membership - Membership to get the URL from
   * @returns {string} URL
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getMembershipURL(membership) {
    (0, _membershipAssertions.assertMembership)(membership);
    return membership.url;
  }

  /**
   * Get the flags of a membership
   * @param   {Membership} membership - Membership to get the flags from
   * @returns {MembershipFlags} Flags
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getMembershipFlags(membership) {
    (0, _membershipAssertions.assertMembership)(membership);
    var flags = membership.flags;
    (0, _membershipAssertions.assertMembershipFlags)(flags);
    return flags;
  }

  /**
   * Get the preferences of a membership
   * @param   {Membership} membership - Membership to get the preferences from
   * @returns {MembershipPreferences} Preferences
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getMembershipPreferences(membership) {
    (0, _membershipAssertions.assertMembership)(membership);
    var preferences = membership.preferences;
    (0, _membershipAssertions.assertMembershipPreferences)(preferences);
    return preferences;
  }

  /**
   * Get the URLs of a membership
   * @param   {Membership} membership - Membership to get the URLs from
   * @returns {MembershipURLs} URLs
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getMembershipURLs(membership) {
    (0, _membershipAssertions.assertMembership)(membership);
    var urls = membership.urls;
    (0, _membershipAssertions.assertMembershipURLs)(urls);
    return urls;
  }

  /*
   |------------------------------------------------------------------------------
   | Advanced getters
   |------------------------------------------------------------------------------
   */

  /**
   * Get the email address associated to a membership
   * @param   {Membership} membership - Membership to get the email address from
   * @returns {string} Email address
   */
  function getMembershipEmailAddress(membership) {
    return getMembershipPreferences(membership).email_address;
  }

  /**
   * Get the phone number associated to a membership
   * @param   {Membership} membership - Membership to get the phone number from
   * @returns {string} Phone number
   */
  function getMembershipPhoneNumber(membership) {
    return getMembershipPreferences(membership).phone_number;
  }

  /**
   * Get the time format associated to a membership
   * @param   {Membership} membership - Membership to get the time format from
   * @returns {string} Time format
   */
  function getMembershipTimeFormat(membership) {
    return getMembershipPreferences(membership).time_format;
  }

  /**
   * Get the timezone associated to a membership
   * @param   {Membership} membership - Membership to get the timezone from
   * @returns {string} Timezone
   */
  function getMembershipTimezone(membership) {
    return getMembershipPreferences(membership).timezone;
  }

  /**
   * Check if email urgent notifications are enabled
   * @param   {Membership} membership - Membership to check
   * @returns {boolean} True if notifications are enabled
   */
  function areMembershipEmailUrgentNotificationsEnabled(membership) {
    return getMembershipPreferences(membership).email_urgent_optin;
  }

  /**
   * Check if sms urgent notifications are enabled
   * @param   {Membership} membership - Membership to check
   * @returns {boolean} True if notifications are enabled
   */
  function areMembershipSMSUrgentNotificationsEnabled(membership) {
    return getMembershipPreferences(membership).sms_urgent_optin;
  }

  /**
   * Check if email bundle notifications are enabled
   * @param   {Membership} membership - Membership to check
   * @returns {boolean} True if notifications are enabled
   */
  function areMembershipEmailBundleNotificationsEnabled(membership) {
    return getMembershipPreferences(membership).email_bundle_optin;
  }

  /**
   * Check if email recap notifications are enabled
   * @param   {Membership} membership - Membership to check
   * @returns {boolean} True if notifications are enabled
   */
  function areMembershipEmailRecapNotificationsEnabled(membership) {
    return getMembershipPreferences(membership).email_recap_optin;
  }

  /**
   * Check if Slack notifications are enabled
   * @param   {Membership} membership - Membership to check
   * @returns {boolean} True if notifications are enabled
   */
  function areMembershipSlackNotificationsEnabled(membership) {
    return getMembershipPreferences(membership).slack_optin;
  }

  /**
   * Check if the onboarding step is completed
   * @param   {Membership} membership - Membership to check
   * @returns {boolean} True if it is
   */
  function isMembershipOnboardingCompleted(membership) {
    var _getMembershipFlags = getMembershipFlags(membership),
      isCompleted = _getMembershipFlags.onboarding_completed;

    // If not defined, we assume it isn't completed
    return typeof isCompleted === "undefined" ? false : isCompleted;
  }

  /**
   * Check if the opt-in step is completed
   * @param   {Membership} membership - Membership to check
   * @returns {boolean} True if it is
   */
  function isMembershipOptInCompleted(membership) {
    var _getMembershipFlags2 = getMembershipFlags(membership),
      isCompleted = _getMembershipFlags2.optin_completed;

    // If not defined, we assume it isn't completed
    return typeof isCompleted === "undefined" ? false : isCompleted;
  }

  /**
   * Check if the Slack opt-in step is completed
   * @param   {Membership} membership - Membership to check
   * @returns {boolean} True if it is
   */
  function isMembershipSlackOptInCompleted(membership) {
    var _getMembershipFlags3 = getMembershipFlags(membership),
      isCompleted = _getMembershipFlags3.slack_optin_completed;

    // If not defined, we assume it isn't completed
    return typeof isCompleted === "undefined" ? false : isCompleted;
  }

  /**
   * Check if the favorite tutorial is completed
   * @param   {Membership} membership - Membership to check
   * @returns {boolean} True if it is
   */
  function isMembershipFavoriteTutorialCompleted(membership) {
    var _getMembershipFlags4 = getMembershipFlags(membership),
      isCompleted = _getMembershipFlags4.favorite_tutorial_completed;

    // If not defined, we assume it isn't completed
    return typeof isCompleted === "undefined" ? false : isCompleted;
  }

  /**
   * Check if the opt-in profile is true
   * @param   {Membership} membership - Membership to check
   * @returns {boolean} True if it is
   */
  function isMembershipProfileOptIn(membership) {
    var _getMembershipFlags5 = getMembershipFlags(membership),
      peopleTabOptin = _getMembershipFlags5.people_tab_optin;

    // If not defined, we assume it isn't completed
    return typeof peopleTabOptin === "undefined" ? false : peopleTabOptin;
  }

  /**
   * Check if the profile is completed
   * @param   {Membership} membership - Membership to check
   * @returns {boolean} True if it is
   */
  function isMembershipProfileCompleted(membership) {
    var _getMembershipFlags6 = getMembershipFlags(membership),
      isCompleted = _getMembershipFlags6.profile_completed;
    return !!isCompleted;
  }

  /**
   * Get the guidance endpoint URL of the membership
   * @param   {Membership} membership - Membership to get the URL from
   * @returns {string} URL
   */
  function getMembershipGuidanceURL(membership) {
    return getMembershipURLs(membership).guidance;
  }
});