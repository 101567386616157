(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "luxon", "@braindate/util/lib/assert", "./locationGetters", "../setting", "../../braindateRange/util/braindateRangeGetters"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("luxon"), require("@braindate/util/lib/assert"), require("./locationGetters"), require("../setting"), require("../../braindateRange/util/braindateRangeGetters"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.luxon, global.assert, global.locationGetters, global.setting, global.braindateRangeGetters);
    global.locationCheckers = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _luxon, _assert, _locationGetters, _setting, _braindateRangeGetters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isHybridLocations = isHybridLocations;
  _exports.isLocationBraindatingUnavailable = isLocationBraindatingUnavailable;
  _exports.isLocationOver = isLocationOver;
  _exports.isLocationsBraindatingUnavailable = isLocationsBraindatingUnavailable;
  _exports.isLocationsCompatible = isLocationsCompatible;
  _exports.isLocationsOver = isLocationsOver;
  _exports.isOnsiteLocation = isOnsiteLocation;
  _exports.isOnsiteLocations = isOnsiteLocations;
  _exports.isVirtualLocation = isVirtualLocation;
  _exports.isVirtualLocations = isVirtualLocations;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  /**
   * Check if location is a virtual location
   * @param   {Location} location - Location to check
   * @returns {boolean} True if it is
   */
  function isVirtualLocation(location) {
    (0, _assert.assertObject)(location, "location");
    return (0, _locationGetters.getLocationContext)(location) === _setting.locationContextVirtual;
  }

  /**
   * Check if location is an onsite location
   * @param   {Location} location - Location to check
   * @returns {boolean} True if it is
   */
  function isOnsiteLocation(location) {
    (0, _assert.assertObject)(location, "location");
    return (0, _locationGetters.getLocationContext)(location) === _setting.locationContextOnsite;
  }

  /**
   * Check ifa locations list represent Hybrid
   * @param   {Array<Location>} locations - Locations to check
   * @returns {boolean} True if it is
   */
  function isHybridLocations(locations) {
    (0, _assert.assertArray)(locations, "locations");
    return locations.some(function (location) {
      return isVirtualLocation(location);
    }) && locations.some(function (location) {
      return !isVirtualLocation(location);
    });
  }

  /**
   * Check if a locations list represent only onsite
   * @param   {Array<Location>} locations - Locations to check
   * @returns {boolean} True if it is
   */
  function isOnsiteLocations(locations) {
    (0, _assert.assertArray)(locations, "locations");
    return locations.every(function (location) {
      return isOnsiteLocation(location);
    });
  }

  /**
   * Check if a locations list represent only virtual
   * @param   {Array<Location>} locations - Locations to check
   * @returns {boolean} True if it is
   */
  function isVirtualLocations(locations) {
    (0, _assert.assertArray)(locations, "locations");
    return locations.every(function (location) {
      return isVirtualLocation(location);
    });
  }

  /**
   * Check if two locations are compatibles
   * @param   {Array<Location>} locationsA - Locations to check
   * @param   {Array<Location>} locationsB - Locations to check
   * @returns {boolean} True if a same location is found
   */
  function isLocationsCompatible(locationsA, locationsB) {
    (0, _assert.assertArray)(locationsA, "locations");
    (0, _assert.assertArray)(locationsB, "locations");
    return (0, _locationGetters.getLocationsCompatible)(locationsA, locationsB).length > 0;
  }

  /**
   * Check if a location is over
   * @param   {Location} location - Location to check
   * @returns {boolean} True if it is
   */
  function isLocationOver(location) {
    var ranges = _toConsumableArray((0, _locationGetters.getLocationBraindateRanges)(location));
    // @TODO Add Fishbowl and Groups
    var count = ranges.length;
    if (count > 0) {
      ranges.sort(function (slot1, slot2) {
        var end1 = (0, _braindateRangeGetters.getBraindateRangeEndTime)(slot1);
        var end2 = (0, _braindateRangeGetters.getBraindateRangeEndTime)(slot2);

        // $FlowIssue: FIXME
        return _luxon.DateTime.fromISO(end1)
        // $FlowIssue: FIXME
        .diff(_luxon.DateTime.fromISO(end2)).valueOf() >= 0 ? 1 : -1;
      });
      return (
        // $FlowIssue: FIXME
        _luxon.DateTime.fromISO((0, _braindateRangeGetters.getBraindateRangeEndTime)(ranges[count - 1]))
        // $FlowIssue: FIXME
        .diffNow().valueOf() <= 0
      );
    }
    return true;
  }

  /**
   * Check if any location is over
   * @param   {Array<Location>} locations - Location to check
   * @returns {boolean} True if it is
   */
  function isLocationsOver(locations) {
    return locations.every(isLocationOver);
  }

  /**
   * Check if braindating is unavailable
   * @param   {Location} location - Location to check
   * @returns {boolean} True if it is
   */
  function isLocationBraindatingUnavailable(location) {
    return (0, _locationGetters.getLocationBraindateRanges)(location).length === 0;
  }

  /**
   * Check if braindating is unavailable
   * @param   {Array<Location>} locations - List of locations to check
   * @returns {boolean} True if it is
   */
  function isLocationsBraindatingUnavailable(locations) {
    return locations.every(isLocationBraindatingUnavailable);
  }
});