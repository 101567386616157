/* @flow */

export const xsmall = 'XSMALL';
export const small = 'SMALL';
export const medium = 'MEDIUM';
export const large = 'LARGE';
export const xlarge = 'XLARGE';
export const xxlarge = 'XXLARGE';
export const mobilePeopleCardSize = 'MOBILE_PEOPLE_CARD_SIZE';
export const peopleCardSize = 'PEOPLE_CARD_SIZE';
