(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./conversationAssertions"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./conversationAssertions"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.conversationAssertions);
    global.conversationGetters = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _conversationAssertions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getConversationActions = getConversationActions;
  _exports.getConversationActionsURL = getConversationActionsURL;
  _exports.getConversationFishbowlSocketURL = getConversationFishbowlSocketURL;
  _exports.getConversationId = getConversationId;
  _exports.getConversationLastReadTimestamp = getConversationLastReadTimestamp;
  _exports.getConversationLastUpdateTimestamp = getConversationLastUpdateTimestamp;
  _exports.getConversationMarkAsReadURL = getConversationMarkAsReadURL;
  _exports.getConversationMessages = getConversationMessages;
  _exports.getConversationParticipantParticipationId = getConversationParticipantParticipationId;
  _exports.getConversationParticipants = getConversationParticipants;
  _exports.getConversationPath = getConversationPath;
  _exports.getConversationReplyURL = getConversationReplyURL;
  _exports.getConversationSendContactCardUrl = getConversationSendContactCardUrl;
  _exports.getConversationSocketURL = getConversationSocketURL;
  _exports.getConversationTopic = getConversationTopic;
  _exports.getConversationURL = getConversationURL;
  _exports.getConversationURLs = getConversationURLs;
  _exports.getConversationUnreadNotificationsCount = getConversationUnreadNotificationsCount;
  _exports.getConversationVirtualSocketURL = getConversationVirtualSocketURL;
  /**
   * Get the id of a conversation
   * @param   {Conversation} conversation - Conversation to get the id from
   * @returns {number} Id
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getConversationId(conversation) {
    (0, _conversationAssertions.assertConversation)(conversation);
    return conversation.id;
  }

  /**
   * Get the URL of a conversation
   * @param   {Conversation} conversation - Conversation to get the URL from
   * @returns {string} URL
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getConversationURL(conversation) {
    (0, _conversationAssertions.assertConversation)(conversation);
    return conversation.url;
  }

  /**
   * Get the path of a conversation
   * @param   {Conversation} conversation - Conversation to get the path from
   * @returns {string} Path
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getConversationPath(conversation) {
    (0, _conversationAssertions.assertConversation)(conversation);
    return conversation.path;
  }

  /**
   * Get the topic of a conversation
   * @param   {Conversation} conversation - Conversation to get the topic from
   * @returns {Topic} Topic
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getConversationTopic(conversation) {
    (0, _conversationAssertions.assertConversation)(conversation);
    var topic = conversation.topic;
    (0, _conversationAssertions.assertConversationTopic)(topic);

    // $FlowIssue: both OneOnOneTopic or GroupTopic can be returned
    return topic;
  }

  /**
   * Get the participants of a conversation
   * @param   {Conversation} conversation - Conversation to get the participants from
   * @returns {Array<User>} Participants
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an array
   */
  function getConversationParticipants(conversation) {
    (0, _conversationAssertions.assertConversation)(conversation);
    var participants = conversation.participants;
    (0, _conversationAssertions.assertConversationParticipants)(participants);
    return participants;
  }

  /**
   * Get the URLs of a conversation
   * @param   {Conversation} conversation - Conversation to get the URLs from
   * @returns {ConversationURLs} URLs
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getConversationURLs(conversation) {
    (0, _conversationAssertions.assertConversation)(conversation);
    var urls = conversation.urls;
    (0, _conversationAssertions.assertConversationURLs)(urls);
    return urls;
  }

  /**
   * Get the last update timestamp of a conversation
   * @param   {Conversation} conversation - Conversation to get the timestamp from
   * @returns {string} Timestamp
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getConversationLastUpdateTimestamp(conversation) {
    (0, _conversationAssertions.assertConversation)(conversation);
    return conversation.last_update;
  }

  /**
   * Get the actions of a conversation
   * @param   {Conversation} conversation - Conversation to get the actions from
   * @returns {Array<ConversationAction>} Actions
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is defined but is not an array
   */
  function getConversationActions(conversation) {
    (0, _conversationAssertions.assertConversation)(conversation);
    var actions = conversation.actions;
    if (actions) {
      (0, _conversationAssertions.assertConversationActions)(actions);
      return actions;
    }
  }

  /**
   * Get the messages of a conversation
   * @param   {Conversation} conversation - Conversation to get the messages from
   * @returns {Array<ConversationMessage>} Messages
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is defined but is not an array
   */
  function getConversationMessages(conversation) {
    (0, _conversationAssertions.assertConversation)(conversation);
    var messages = conversation.messages;
    if (messages) {
      (0, _conversationAssertions.assertConversationMessages)(messages);
      return messages;
    }
  }

  /**
   * Get the last read timestamp of a conversation
   * @param   {Conversation} conversation - Conversation to get the timestamp from
   * @returns {string} Timestamp
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getConversationLastReadTimestamp(conversation) {
    (0, _conversationAssertions.assertConversation)(conversation);
    return conversation.last_read || "";
  }

  /**
   * Get the count of unread notifications of a conversation
   * @param   {Conversation} conversation - Conversation to get the count from
   * @returns {number} Count
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getConversationUnreadNotificationsCount(conversation) {
    (0, _conversationAssertions.assertConversation)(conversation);
    return conversation.unread_count;
  }

  /*
   |------------------------------------------------------------------------------
   | Advanced getters
   |------------------------------------------------------------------------------
   */

  /**
   * Get the fischbowl socket URL of a conversation
   * @param   {Conversation} conversation - Conversation to get the URL from
   * @returns {string} URL
   */
  function getConversationFishbowlSocketURL(conversation) {
    return getConversationURLs(conversation).fishbowl_socket_url;
  }

  /**
   * Get the virtual socket URL of a conversation
   * @param   {Conversation} conversation - Conversation to get the URL from
   * @returns {string} URL
   */
  function getConversationVirtualSocketURL(conversation) {
    return getConversationURLs(conversation).virtual_socket_url;
  }

  /**
   * Get the socket URL of a conversation
   * @param   {Conversation} conversation - Conversation to get the URL from
   * @returns {string} URL
   */
  function getConversationSocketURL(conversation) {
    return getConversationURLs(conversation).socket_url;
  }

  /**
   * Get the mark as read endpoint URL of a conversation
   * @param   {Conversation} conversation - Conversation to get the URL from
   * @returns {string} URL
   */
  function getConversationMarkAsReadURL(conversation) {
    return getConversationURLs(conversation).mark_as_read;
  }

  /**
   * Get the send contact url of a conversation
   * @param   {Conversation} conversation - Conversation to get the URL from
   * @returns {string} URL
   */
  function getConversationSendContactCardUrl(conversation) {
    return getConversationURLs(conversation).send_contact_card;
  }

  /**
   * Get the action endpoint URL of a conversation
   * @param   {Conversation} conversation - Conversation to get the URL from
   * @returns {string} URL
   */
  function getConversationActionsURL(conversation) {
    return getConversationURLs(conversation).actions;
  }

  /**
   * Get the reply endpoint URL of a conversation
   * @param   {Conversation} conversation - Conversation to get the URL from
   * @returns {string} URL
   */
  function getConversationReplyURL(conversation) {
    return getConversationURLs(conversation).reply;
  }

  /**
   * Gets the participation id of a conversation participant
   * @param {ConversationParticipant} participant Participant
   * @returns {string} participation id
   */
  function getConversationParticipantParticipationId(participant) {
    return participant.participation_id;
  }
});