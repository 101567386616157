/* @flow */

import type { Location } from '@braindate/domain/lib/location/type';
import { getUserLocations } from '@braindate/domain/lib/user/util';

import useSelf from 'src/shared/domain/user/hook/useSelf';

export default function useSelfLocations(): Array<Location> {
  const self = useSelf();
  if (!self) return [];
  return getUserLocations(self);
}
