/* @flow */

import { combineReducers } from 'redux';

import publicEventReducer from './publicEventReducer';

// $FlowIssue
const publicReducer = combineReducers({
  events: publicEventReducer,
});

export default publicReducer;
