/* @flow */

import button from 'src/shared/app/base/style/element/form/button';
import primaryButton from 'src/shared/ui/component/button/PrimaryButton.style';
import secondaryButton from 'src/shared/ui/component/button/SecondaryButton.style';

export default {
  root: {
    ...button.button,
    textDecoration: 'none',
    display: 'inline-flex',
    fontWeight: 900,
    lineHeight: 'normal',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  primary: {
    ...primaryButton.root,
  },
  secondary: {
    ...secondaryButton.root,
  },
};
