/* @flow */

import { useSelector } from 'react-redux';

import { isTouchDevice } from 'src/shared/app/base/selector/appDeviceSelectors';

const useIsTouchDevice = () => {
  return useSelector(isTouchDevice);
};

export default useIsTouchDevice;
