/* @flow */

import {
  darken,
  lighten,
  meetsContrastGuidelines,
  mix,
  parseToHsl,
  readableColor,
  setHue,
  setLightness,
} from 'polished';

import { textColor } from 'plugin/light/style/color';

export function getColorShade(color: string): string {
  return darken(0.12, color);
}

export function getColorTint(color: string): string {
  return lighten(0.2, color);
}

export function getAlternateShade(color: string): string {
  if (readableColor(color) === '#fff') {
    return getColorShade(color);
  }

  return getColorTint(color);
}

export function getColorLightTint(color: string): string {
  return setLightness(0.925, color);
}

export function getAlternateReadbleColor(color: string): string {
  return readableColor(getAlternateShade(color), textColor);
}

export function getReadableColor(color: string): string {
  const offset = 0.05;

  let mixWeight = 0.75;
  let darkColor = mix(mixWeight, textColor, color);
  let prevColor;

  while (
    !meetsContrastGuidelines(darkColor, color).AA &&
    prevColor !== darkColor &&
    mixWeight < 1
  ) {
    prevColor = darkColor;

    mixWeight += offset;
    mixWeight = Math.min(mixWeight, 1);

    darkColor = mix(mixWeight, textColor, color);
  }

  return readableColor(color, darkColor);
}

export function getAACompliantColor(color: string, bgColor: string): string {
  return getCompliantColor(color, bgColor, 'AA');
}

export function getAALargeCompliantColor(
  color: string,
  bgColor: string,
): string {
  return getCompliantColor(color, bgColor, 'AALarge');
}

export function getCompliantColor(
  color: string,
  bgColor: string,
  criteria: string,
): string {
  const weight = 16;
  const hueOffset = 9 / weight; // 49 - 40
  const lightnessOffset = 12 / weight; // 50 - 38
  const { hue, lightness } = parseToHsl(color);

  let newColor = color;
  let prevColor;
  let counter = 1;

  while (
    !meetsContrastGuidelines(newColor, bgColor)[criteria] &&
    prevColor !== newColor
  ) {
    prevColor = newColor;

    newColor = setHue(hue - hueOffset * counter, newColor);
    newColor = setLightness(
      lightness - (lightnessOffset / 100) * counter,
      newColor,
    );

    counter += 1;
  }

  return newColor;
}
