/* @flow */

import { rem } from 'polished';

import { pressDuration } from '@braindate/ui/lib/token/animation';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { outlineWidth } from 'src/shared/app/base/style/token/box';
import { disabledOpacity } from 'src/shared/app/base/style/token/opacity';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  button: {
    // Show the overflow in Edge
    overflow: 'visible',

    // Remove the margin in Firefox and Safari
    margin: 0,
    padding: [rem(9, baseFontSize), rem(21, baseFontSize)],
    fontFamily: ({ theme }: ThemeFunction<{}>) => theme.secondaryFontFamily,

    border: 'none',
    borderRadius: ({ theme }: ThemeFunction<{}>) => theme.buttonBorderRadius,
    appearance: 'none',
    // Hide highlight occuring when a button is being tapped on WebKit
    WebkitTapHighlightColor: 'transparent',

    // Remove the inheritance of text transform in Firefox
    textTransform: 'none',

    cursor: 'pointer',
    // Prevent text from being selected. Especially useful on touch devices if
    // the user taps the button for too long.
    userSelect: 'none',

    transformOrigin: 'center center',

    transition: `transform ${pressDuration}`,
    minHeight: '3em',
    minWidth: '3em',

    '&:focus-visible': {
      outlineOffset: 2,
      // Style and width need to be explicitly set for Firefox
      outlineStyle: 'solid',
      outlineWidth,
    },
    '&:active': {
      transform: 'scale(0.95, 0.95)',
    },
    '&:disabled': {
      opacity: disabledOpacity,

      pointerEvents: 'none',
    },
  },
};
