/* @flow */

import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  environment: '',
  devMode: false,
  event: null,
  apiURL: '',
  platformBaseDomain: '',
  staticURL: '',
  trackingId: '',
  isTrackingEnabled: true,
  isErrorReportingEnabled: true,
  isServiceWorkerEnabled: false,
  version: '',
};

export default createReducer(initialState, {});
