/* @flow */

import { createSelector } from '@reduxjs/toolkit';

const _getLocale = (state) => state.app.language;

export const getLocale: (state: Object) => string = createSelector(
  [_getLocale],
  (locale: string): string => locale,
);
