/* @flow */

import type { Node } from 'react';

import { Box, Divider } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import withForwardRef from '@braindate/util/lib/react/hoc/withForwardRef';
import { hasURLProtocol } from '@braindate/util/lib/url';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './MenuPopoverItem.style';

export type MenuPopoverItemProps = {
  icon?: Node,
  title: string,
  subtitle?: string,
  isActive?: boolean,
  isCheckbox?: boolean,
  isDisabled?: boolean,
  'aria-label'?: string,
  to?: string,
  hasDivider?: boolean,
  onClick?: () => void | Promise<void>,
  onMouseOver?: (() => void) | (() => Promise<void>),
};

type Props = {
  forwardedRef: { current: HTMLElement | null },
};

const useStyles = createUseThemeStyles(styles);

const MenuPopoverItem = ({
  icon,
  title,
  subtitle,
  isActive,
  'aria-label': ariaLabel,
  isCheckbox,
  isDisabled,
  to,
  hasDivider,
  onClick,
  onMouseOver,
  forwardedRef,
  ...props
}: Props & MenuPopoverItemProps): Node => {
  const classes = useStyles();

  const isExternalLink = to && hasURLProtocol(to);

  const linkProps = to
    ? { component: isExternalLink ? 'a' : Link, to, href: to }
    : {};

  return (
    <MenuItem
      ref={forwardedRef}
      onClick={onClick}
      onMouseOver={onMouseOver}
      disabled={isDisabled}
      classes={{
        root: classNames(
          classes.menuItem,
          isActive && classes.menuItemActive,
          'ignore-focus',
        ),
      }}
      aria-label={ariaLabel}
      {...(isCheckbox ? isActive : {})}
      {...linkProps}
      {...props}
    >
      <Box
        className={classNames(classes.menuContent, {
          [classes.withDivider]: hasDivider,
        })}
      >
        {icon ? (
          <ListItemIcon>
            <div className={classNames(classes.iconContainer)}>{icon}</div>
          </ListItemIcon>
        ) : null}

        <ListItemText disableTypography className={classes.title}>
          <Typography classes={{ root: classes.text }}>{title}</Typography>

          {subtitle ? (
            <Typography variant="caption">{subtitle}</Typography>
          ) : null}
        </ListItemText>
      </Box>

      {hasDivider ? <Divider className={classes.divider} /> : null}
    </MenuItem>
  );
};

export default withForwardRef(MenuPopoverItem);
