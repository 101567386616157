/* @flow */

import { createSelector } from '@reduxjs/toolkit';

const _isAppEmbedded = (state) => state.app.integration.isAppEmbedded;

export const isAppEmbedded: (state: Object) => boolean = createSelector(
  [_isAppEmbedded],
  (x: boolean): boolean => x,
);
