/* @flow */

import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

export default {
  root: {},
  text: {
    width: '100%',
    fontSize: rem(18, baseFontSize),
  },
};
