/* @flow */

import type { Braindate } from '@braindate/domain/lib/braindate/type';

import { convertBraindateReminderToNotification } from 'src/shared/app/check-in/util/checkInUtil';
import { queueNotification } from 'src/shared/app/notification/action/uiNotificationActions';

export function showCheckInReminder(
  braindate: Braindate,
  isSelfCheckInEnabled: boolean,
) {
  return queueNotification(
    convertBraindateReminderToNotification(braindate, isSelfCheckInEnabled),
  );
}
