/* @flow */

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
    width: '100%',
    '& p': {
      marginBottom: 10,
      fontWeight: 600,
      textAlign: 'left',
      color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    },
  },
  sponsorsList: {
    display: 'flex',
    gap: '14px',
  },
  logo: {
    maxWidth: 150,
    maxHeight: 38,
  },
};
