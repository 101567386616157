/* @flow */

import { assertArray, assertObject } from '@braindate/util/lib/assert';

import type { RouteData } from 'src/shared/app/base/route/type/routeTypes';

const routeRegistry = new Set();

export function registerRoute(route: RouteData) {
  assertObject(route, 'route');

  routeRegistry.add(route);
}

export function registerRoutes(routes: Array<RouteData>) {
  assertArray(routes, 'routes');

  routes.forEach(registerRoute);
}

export function getRoutes(): Array<RouteData> {
  return Array.from(routeRegistry);
}
