/* @flow */
import type { Picture } from '@braindate/domain/lib/base/type';
import type {
  Conversation,
  ConversationMessage,
  ConversationParticipant,
} from '@braindate/domain/lib/conversation/type';
import {
  getConversationMessages,
  getConversationParticipants,
  getConversationTopic,
} from '@braindate/domain/lib/conversation/util';
import {
  getTopicAuthor,
  getTopicTitle,
  isUserTopicAuthor,
} from '@braindate/domain/lib/topic/util';
import type { User } from '@braindate/domain/lib/user/type';
import {
  getUserAvatar,
  getUserFirstName,
  getUserFullName,
  getUserId,
} from '@braindate/domain/lib/user/util';
import { assertArray, assertObject } from '@braindate/util/lib/assert';

import { isMessageRead } from './messageUtils';

/*
|------------------------------------------------------------------------------
| GETTERS
|------------------------------------------------------------------------------
*/

/**
 * Get the participants of the conversation, sorted by the topic author first
 * and the other participants alphabetically by first names.
 * @param  {Conversation} conversation - Conversation to get the participants from
 * @return {Array<User>} Sorted participants by host first then by firstnames
 *
 * @throws Will throw an exception if parameter `conversation` is not an object
 */
export function getConversationParticipantsByTopicAuthorFirst(
  conversation: Conversation,
): Array<ConversationParticipant> {
  assertObject(conversation, 'conversation');

  const conversationParticipants = getConversationParticipants(conversation);

  const authorId = getConversationTopicAuthorId(conversation);
  const host = conversationParticipants.find(
    (participant) => getUserId(participant) === authorId,
  );
  const participants = conversationParticipants.filter(
    (participant) => getUserId(participant) !== authorId,
  );

  // Sort all participants but the host
  const sortedParticipants = sortParticipantsByFirstName(participants);

  if (host) {
    sortedParticipants.unshift(host);
  } else if (sortedParticipants.length) {
    throw new Error('No host was found in conversation');
  }

  return sortedParticipants;
}

/**
 * Sprt participants by their first names
 * @param  {Array<User>} participants - Participants
 * @return {Array<User>} Sorted participants by firstname
 *
 * @throws Will throw an exception if parameter `participants` is not an array
 */
export function sortParticipantsByFirstName<T = User | ConversationParticipant>(
  participants: Array<T>,
): T[] {
  assertArray(participants, 'participants');

  // $FlowIssue
  return participants.sort((user: User, user2: User) =>
    getUserFirstName(user).localeCompare(getUserFirstName(user2)),
  );
}

export function getConversationUnreadMessages(
  conversation: Conversation,
): Array<ConversationMessage> {
  assertObject(conversation, 'conversation');

  return (getConversationMessages(conversation) || []).filter(
    (message) => !isMessageRead(message, conversation),
  );
}

export function getConversationReadMessages(
  conversation: Conversation,
): Array<ConversationMessage> {
  assertObject(conversation, 'conversation');

  return (getConversationMessages(conversation) || []).filter((message) =>
    isMessageRead(message, conversation),
  );
}

export function getConversationLastReadMessage(
  conversation: Conversation,
): ?ConversationMessage {
  assertObject(conversation, 'conversation');

  const messages = (getConversationMessages(conversation) || []).filter(
    (message) => isMessageRead(message, conversation),
  );

  const { length: messageCount } = messages;

  return messageCount ? messages[messageCount - 1] : undefined;
}

export function getIsConversationRead(conversation: Conversation): boolean {
  return (getConversationMessages(conversation) || []).every((message) =>
    isMessageRead(message, conversation),
  );
}

/*
|------------------------------------------------------------------------------
| TOPIC GETTERS
|------------------------------------------------------------------------------
*/

/**
 * Get the title of the conversation topic
 * @param  {Conversation} conversation - Conversation to get the title from
 * @param  {string} [locale] - Locale in which to get the title
 * @return {string} Title
 */
export function getConversationTopicTitle(
  conversation: Conversation,
  locale?: string,
): string {
  return getTopicTitle(getConversationTopic(conversation), locale);
}

/**
 * Get the author of the conversation topic
 * @param  {Conversation} conversation - Conversation to get the author from
 * @return {User} Author
 */
export function getConversationTopicAuthor(conversation: Conversation): User {
  return getTopicAuthor(getConversationTopic(conversation));
}

/**
 * Get the id of the conversation topic author
 * @param  {Conversation} conversation - Conversation to get the id from
 * @return {number} Id
 */
export function getConversationTopicAuthorId(
  conversation: Conversation,
): number {
  return getUserId(getTopicAuthor(getConversationTopic(conversation)));
}

export function isUserConversationHost(
  conversation: Conversation,
  user: User,
): boolean {
  assertObject(conversation, 'conversation');
  assertObject(user, 'user');

  return getConversationTopicAuthorId(conversation) === getUserId(user);
}

export function isUserConversationGuest(
  conversation: Conversation,
  user: User,
): boolean {
  return !isUserConversationHost(conversation, user);
}

/*
|------------------------------------------------------------------------------
| FILTERS
|------------------------------------------------------------------------------
*/

export function getConversationOtherParticipants(
  conversation: Conversation,
  user: User,
): Array<User> {
  const participants = getConversationParticipants(conversation);

  // $FlowIssue
  return participants.filter((p) => getUserId(p) !== getUserId(user));
}

export function getConversationOtherParticipant(
  conversation: Conversation,
  user: User,
): User {
  const participants = getConversationParticipants(conversation);
  const others = participants.filter((p) => getUserId(p) !== getUserId(user));

  return others[0];
}

/*
|------------------------------------------------------------------------------
| PARTICIPANTS MAPPINGS
|------------------------------------------------------------------------------
*/

/**
 * Get the first names of the conversation participants
 * @param  {Conversation} conversation - Conversation to get the first names from
 * @param  {boolean} [byTopicAuthorFirst] - If true, sort by topic author first
 * @return {Array<string>} First names
 */
export function getConversationParticipantsFirstNames(
  conversation: Conversation,
  byTopicAuthorFirst?: boolean = false,
): Array<string> {
  let participants;

  if (byTopicAuthorFirst) {
    participants = getConversationParticipantsByTopicAuthorFirst(conversation);
  } else {
    participants = getConversationParticipants(conversation);
  }

  return participants.map((user) => getUserFirstName(user));
}

/**
 * Get the first names of the conversation participants, as a string
 * @param  {Conversation} conversation - Conversation to get the first names from
 * @param  {boolean} [byTopicAuthorFirst] - If true, sort by topic author first
 * @param  {string} [separator] - Separator between names
 * @return {string} First names
 */
export function getConversationParticipantsFirstNamesAsString(
  conversation: Conversation,
  byTopicAuthorFirst?: boolean = false,
  separator?: string = ', ',
): string {
  return getConversationParticipantsFirstNames(
    conversation,
    byTopicAuthorFirst,
  ).join(separator);
}

/**
 * Get the avatars of the conversation participants
 * @param  {Conversation} conversation - Conversation to get the avatars from
 * @param  {boolean} byTopicAuthorFirst - If true, sort by topic author first
 * @return {Array<Picture>} Avatars
 */
export function getConversationParticipantsAvatars(
  conversation: Conversation,
  byTopicAuthorFirst: boolean = false,
): Array<Picture> {
  let participants;

  if (byTopicAuthorFirst) {
    participants = getConversationParticipantsByTopicAuthorFirst(conversation);
  } else {
    participants = getConversationParticipants(conversation);
  }

  return participants.map((user) => getUserAvatar(user) || {});
}

/*
|------------------------------------------------------------------------------
| OTHER PARTICIPANTS MAPPINGS
|------------------------------------------------------------------------------
*/

export function getConversationOtherFirstNames(
  conversation: Conversation,
  user: User,
): Array<string> {
  return getConversationOtherParticipants(conversation, user).map(
    (conversationUser) => getUserFirstName(conversationUser),
  );
}

/**
 * Get the full names of the conversation other participants
 * @param  {Conversation} conversation - Conversation to get the full names from
 * @param  {User} user - Authenticated user
 * @return {Array<string>} Full names
 */
export function getConversationOtherFullNames(
  conversation: Conversation,
  user: User,
): Array<string> {
  return getConversationOtherParticipants(conversation, user).map(
    (conversationUser) => getUserFullName(conversationUser),
  );
}

/*
|------------------------------------------------------------------------------
| CHECKERS
|------------------------------------------------------------------------------
*/

/**
 * Check if the conversation topic author is the connected user
 * @param  {Conversation} conversation - Conversation to check
 * @param  {User} user - Authenticated user
 * @return {boolean} True if the conversation topic author is
 */
export function isConversationAboutOwnTopic(
  conversation: Conversation,
  user: User,
): boolean {
  return isUserTopicAuthor(user, getConversationTopic(conversation));
}

export function hasConversationMessages(conversation: Conversation): boolean {
  // TODO: assert conversation

  return 'messages' in conversation;
}

export function hasConversationActions(conversation: Conversation): boolean {
  // TODO: assert conversation

  return 'actions' in conversation;
}
