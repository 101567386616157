(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "ramda", "../assert"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("ramda"), require("../assert"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.ramda, global.assert);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _ramda, _assert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getOriginalPicture = getOriginalPicture;
  _exports.getPictureNormalAndRetinaSrc = getPictureNormalAndRetinaSrc;
  _exports.getPictureSizeMap = getPictureSizeMap;
  _exports.getPictureSrcSets = getPictureSrcSets;
  _exports.isOriginalPictureVectorial = isOriginalPictureVectorial;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /*
   |------------------------------------------------------------------------------
   | Config
   |------------------------------------------------------------------------------
   */

  var vectorialPattern = /\.svg$/;
  var dimensionsPattern = /^([0-9]+)x([0-9]+)$/;

  /*
   |------------------------------------------------------------------------------
   | Exports
   |------------------------------------------------------------------------------
   */

  /**
   * Get the original source of a picture
   * @param   {Picture} picture - Picture to get the original source from
   * @returns {string} Original source
   *
   * @throws Will throw an exception if parameters are not valid
   */
  function getOriginalPicture(picture) {
    (0, _assert.assertObject)(picture, "picture");
    return picture.original;
  }

  /**
   * Check if the original source of a picture is a vectorial file
   * @param   {Picture} picture - Picture to check
   * @returns {boolean} True if it is, undefined if picture is an empty object
   *
   * @throws Will throw an exception if parameters are not valid
   */
  function isOriginalPictureVectorial(picture) {
    (0, _assert.assertObject)(picture, "picture");
    if (!(0, _ramda.isEmpty)(picture)) {
      return !!getOriginalPicture(picture).match(vectorialPattern);
    }
  }

  /**
   * For each entry of the picture object that is not original, extract its width
   * and height
   * @param   {Picture} picture - Picture to parse
   * @returns {Array<Object>} Array of objects containing the key, width and
   * height of each entry
   *
   * @throws Will throw an exception if parameters are not valid
   */
  function getPictureSizeMap(picture) {
    (0, _assert.assertObject)(picture, "picture");
    var result = [];
    for (var key in picture) {
      var match = key.match(dimensionsPattern);
      if (match) {
        var _match = _slicedToArray(match, 3),
          width = _match[1],
          height = _match[2];
        result.push({
          key: key,
          width: Number(width),
          height: Number(height)
        });
      }
    }
    return result;
  }

  /**
   * Given a picture object and the width it's supposed to get displayed at,
   * define the src and srcset attributes. It is assumed the image can be
   * displayed on devices with a 1x, a.5x and 2x pixel density.
   * @param   {Picture} picture - Picture to display
   * @param   {number} displayWidth - Width to display the image at
   * @returns {Object} src and srcset attributes
   *
   * @throws Will throw an exception if parameters are not valid
   */
  function getPictureSrcSets(picture, displayWidth) {
    (0, _assert.assertObject)(picture, "picture");
    (0, _assert.assertNumber)(displayWidth, "displayWidth");
    var ratios = [1, 1.5, 2];
    var resMap = {}; // { ratio: source }
    var sizeMap = getPictureSizeMap(picture).sort(function (a, b) {
      return a.width - b.width;
    });
    for (var _i = 0, _ratios = ratios; _i < _ratios.length; _i++) {
      var ratio = _ratios[_i];
      if (resMap[ratio]) {
        break;
      }
      var _iterator = _createForOfIteratorHelper(sizeMap),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = _step.value,
            key = _step$value.key,
            width = _step$value.width;
          if (width >= displayWidth * ratio) {
            resMap[ratio] = picture[key];
            break;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
    var firstRatio = ratios.shift();
    return {
      src: resMap[firstRatio] || getOriginalPicture(picture),
      srcSet: ratios.map(function (ratio) {
        return {
          ratio: ratio,
          src: resMap[ratio]
        };
      }).map(function (_ref) {
        var ratio = _ref.ratio,
          src = _ref.src;
        return src ? "".concat(src, " ").concat(String(ratio), "x") : null;
      }).filter(Boolean).join(", ") || null
    };
  }
  function getPictureNormalAndRetinaSrc(picture, displayWidth) {
    (0, _assert.assertObject)(picture, "picture");
    (0, _assert.assertNumber)(displayWidth, "displayWidth");
    var ratios = [1, 1.5];
    var result = [];
    var sizeMap = getPictureSizeMap(picture).sort(function (a, b) {
      return a.width - b.width;
    });
    for (var _i2 = 0, _ratios2 = ratios; _i2 < _ratios2.length; _i2++) {
      var ratio = _ratios2[_i2];
      var _iterator2 = _createForOfIteratorHelper(sizeMap),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _step2$value = _step2.value,
            key = _step2$value.key,
            width = _step2$value.width;
          if (width >= displayWidth * ratio) {
            result.push(picture[key]);
            break;
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
    return result;
  }
});