/* @flow */

import type { BraindateEventFeatures } from '@braindate/domain/lib/event/type';
import { getEventFeatures } from '@braindate/domain/lib/event/util';

import useEvent from './useEvent';

const useEventFeatures = (): BraindateEventFeatures => {
  const event = useEvent();

  return getEventFeatures(event);
};

export default useEventFeatures;
