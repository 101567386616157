/* @flow */

import { createReducer } from 'src/shared/core/util/reducerUtils';
import { FETCH_LOCATIONS_SUCCESS } from 'src/shared/domain/location/action/locationActionTypes';

const initialState = [];

const _fetchSuccess = (state: Object, { result }: Object) => result;

export default createReducer(initialState, {
  [FETCH_LOCATIONS_SUCCESS]: _fetchSuccess,
});
