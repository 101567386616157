/* @flow */

import { useDispatch } from 'react-redux';

import { clearModal } from 'src/shared/app/base/modal/slice/modalSlice';

export default function useClearModalDispatch() {
  const dispatch = useDispatch();

  return () => dispatch(clearModal());
}
