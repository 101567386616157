/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './CallToActionCard.style';

type Props = {
  children: Node,
};

const useStyles = createUseThemeStyles(styles);

const CallToActionCard = ({ children }: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */

  const classes = useStyles();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  return <div className={classes.root}>{children}</div>;
};

export default memo<Props>(CallToActionCard);
