/* @flow */

import { useEffect, useRef, useState } from 'react';

import useResizeObserver from '@react-hook/resize-observer';

import { isClient } from 'src/shared/core/util/ssrUtil';
import useHideOnScroll from 'src/shared/ui/context/HideOnScrollContext/hook/useHideOnScroll';

import useMounted from './useMounted';

export default function useFixed() {
  const ref = useRef<HTMLElement | null>(null);

  const isScrolled = useHideOnScroll();

  const isMounted = useMounted();
  const [innerStyles, setInnerStyles] = useState({
    left: 'auto',
    width: 'auto',
  });

  const evaluateLayout = () => {
    if (ref.current && isClient) {
      const parent = ref.current.parentElement;

      if (parent instanceof HTMLElement) {
        // $FlowIssue
        const posTop =
          parent.getBoundingClientRect().top +
          (document.documentElement?.scrollTop || 0) -
          (isScrolled ? 88 : 0);
        const width = parent.offsetWidth;

        setInnerStyles({
          width,
          top: posTop,
          position: 'fixed',
          transition: '0.2s ease-out',
        });
      }
    }
  };
  useResizeObserver(ref.current?.parentElement, () => evaluateLayout());

  useEffect(() => {
    evaluateLayout();
  }, [isMounted, isScrolled]);

  return {
    innerProps: { ref, style: innerStyles },
  };
}
