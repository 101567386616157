/* @flow */
import { getEventId } from '@braindate/domain/lib/event/util';

import * as authenticationTypes from 'src/shared/app/authentication/action/base/authenticationActionTypes';
import {
  createReducer,
  patchEntities,
} from 'src/shared/core/util/reducerUtils';
import * as entititesTypes from 'src/shared/domain/base/action/entityActionTypes';
import * as types from 'src/shared/domain/event/action/eventActionTypes';

const initialState = {};

const _patch = (state: Object, action: Object) =>
  patchEntities(state, action, 'events');

const _clean = (state: Object, action: Object) => {
  const { result: id } = action;

  return {
    ...state,
    [id]: null,
  };
};

const handleAuthSuccess = (state: Object, { event }: Object) => ({
  ...state,
  [getEventId(event)]: event,
});

export default createReducer(initialState, {
  [entititesTypes.PATCH_ENTITIES]: _patch,
  [types.FETCH_PUBLIC_EVENT_SUCCESS]: _clean,
  [authenticationTypes.LOGIN_SUCCESS]: handleAuthSuccess,
  [authenticationTypes.CREATE_PASSWORD_SUCCESS]: handleAuthSuccess,
});
