/* @flow */

import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    margin: [16, 0],

    fontFamily: ({ theme }: ThemeFunction<{}>) => theme.primaryFontFamily,
    fontSize: rem(36, baseFontSize),
    fontWeight: 700,
    letterSpacing: -1,
    lineHeight: 40 / 36,
    WebkitFontSmoothing: 'antialiased',
  },
  'root-small': {
    margin: [12, 0],

    fontSize: rem(26, baseFontSize),
    lineHeight: 1,
  },
};
