/* @flow */
import { isEmpty } from 'ramda';

import type { Picture } from '@braindate/domain/lib/base/type';
import { assertNumber, assertObject } from '@braindate/util/lib/assert';

/*
 |------------------------------------------------------------------------------
 | Config
 |------------------------------------------------------------------------------
 */

const vectorialPattern = /\.svg$/;
const dimensionsPattern = /^([0-9]+)x([0-9]+)$/;

/*
 |------------------------------------------------------------------------------
 | Exports
 |------------------------------------------------------------------------------
 */

/**
 * Get the original source of a picture
 * @param   {Picture} picture - Picture to get the original source from
 * @returns {string} Original source
 *
 * @throws Will throw an exception if parameters are not valid
 */
export function getOriginalPicture(picture: Picture): string {
  assertObject(picture, 'picture');

  return picture.original;
}

/**
 * Check if the original source of a picture is a vectorial file
 * @param   {Picture} picture - Picture to check
 * @returns {boolean} True if it is, undefined if picture is an empty object
 *
 * @throws Will throw an exception if parameters are not valid
 */
export function isOriginalPictureVectorial(picture: Picture): ?boolean {
  assertObject(picture, 'picture');

  if (!isEmpty(picture)) {
    return !!getOriginalPicture(picture).match(vectorialPattern);
  }
}

/**
 * For each entry of the picture object that is not original, extract its width
 * and height
 * @param   {Picture} picture - Picture to parse
 * @returns {Array<Object>} Array of objects containing the key, width and
 * height of each entry
 *
 * @throws Will throw an exception if parameters are not valid
 */
export function getPictureSizeMap(
  picture: Picture,
): Array<{ key: string, width: number, height: number }> {
  assertObject(picture, 'picture');

  const result = [];

  for (const key in picture) {
    const match = key.match(dimensionsPattern);

    if (match) {
      const [, width, height] = match;

      result.push({ key, width: Number(width), height: Number(height) });
    }
  }

  return result;
}

/**
 * Given a picture object and the width it's supposed to get displayed at,
 * define the src and srcset attributes. It is assumed the image can be
 * displayed on devices with a 1x, a.5x and 2x pixel density.
 * @param   {Picture} picture - Picture to display
 * @param   {number} displayWidth - Width to display the image at
 * @returns {Object} src and srcset attributes
 *
 * @throws Will throw an exception if parameters are not valid
 */
export function getPictureSrcSets(
  picture: Picture,
  displayWidth: number,
): { src: string, srcSet: ?string } {
  assertObject(picture, 'picture');
  assertNumber(displayWidth, 'displayWidth');

  const ratios = [1, 1.5, 2];
  const resMap = {}; // { ratio: source }
  const sizeMap = getPictureSizeMap(picture).sort((a, b) => a.width - b.width);

  for (const ratio of ratios) {
    if (resMap[ratio]) {
      break;
    }

    for (const { key, width } of sizeMap) {
      if (width >= displayWidth * ratio) {
        resMap[ratio] = picture[key];

        break;
      }
    }
  }

  const firstRatio = ratios.shift();

  return {
    src: resMap[firstRatio] || getOriginalPicture(picture),
    srcSet:
      ratios
        .map((ratio) => ({ ratio, src: resMap[ratio] }))
        .map(({ ratio, src }) => (src ? `${src} ${String(ratio)}x` : null))
        .filter(Boolean)
        .join(', ') || null,
  };
}

export function getPictureNormalAndRetinaSrc(
  picture: Picture,
  displayWidth: number,
): Array<string> {
  assertObject(picture, 'picture');
  assertNumber(displayWidth, 'displayWidth');

  const ratios = [1, 1.5];
  const result = [];
  const sizeMap = getPictureSizeMap(picture).sort((a, b) => a.width - b.width);

  for (const ratio of ratios) {
    for (const { key, width } of sizeMap) {
      if (width >= displayWidth * ratio) {
        result.push(picture[key]);

        break;
      }
    }
  }

  return result;
}
