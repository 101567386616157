(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "../../user/util/userAssertions", "../../braindate/util/braindateAssertions", "@braindate/util/lib/assert", "./cheerAssertions"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("../../user/util/userAssertions"), require("../../braindate/util/braindateAssertions"), require("@braindate/util/lib/assert"), require("./cheerAssertions"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.userAssertions, global.braindateAssertions, global.assert, global.cheerAssertions);
    global.cheerGetters = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _userAssertions, _braindateAssertions, _assert, _cheerAssertions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCheerUnwrapitUrl = _exports.getCheerSender = _exports.getCheerSendGift = _exports.getCheerRecipient = _exports.getCheerPath = _exports.getCheerMessage = _exports.getCheerMarkAsReadUrl = _exports.getCheerId = _exports.getCheerDateSent = _exports.getCheerDateRead = _exports.getCheerCategory = _exports.getCheerBraindate = void 0;
  /**
   * Gets the cheer object id
   * @param {Cheer} cheer - Cheer
   * @returns {number} id
   */
  var getCheerId = _exports.getCheerId = function getCheerId(cheer) {
    (0, _cheerAssertions.assertCheer)(cheer);
    var id = cheer.id;
    (0, _assert.assertNumber)(id);
    return id;
  };

  /**
   * Gets the cheer sender
   * @param {Cheer} cheer - Cheer
   * @returns {User} sender
   */
  var getCheerSender = _exports.getCheerSender = function getCheerSender(cheer) {
    (0, _cheerAssertions.assertCheer)(cheer);
    var sender = cheer.sender;
    (0, _userAssertions.assertUser)(sender);
    return sender;
  };

  /**
   * Gets the cheer recipient
   * @param {Cheer} cheer - Cheer
   * @returns {User} recipient
   */
  var getCheerRecipient = _exports.getCheerRecipient = function getCheerRecipient(cheer) {
    (0, _cheerAssertions.assertCheer)(cheer);
    var recipient = cheer.recipient;
    (0, _userAssertions.assertUser)(recipient);
    return recipient;
  };

  /**
   * Gets the cheer braindate
   * @param {Cheer} cheer - Cheer
   * @returns {Braindate} braindate
   */
  var getCheerBraindate = _exports.getCheerBraindate = function getCheerBraindate(cheer) {
    (0, _cheerAssertions.assertCheer)(cheer);
    var braindate = cheer.braindate;
    (0, _braindateAssertions.assertBraindate)(braindate);
    return braindate;
  };

  /**
   * Gets the cheer category
   * @param {Cheer} cheer - Cheer
   * @returns {string} category
   */
  var getCheerCategory = _exports.getCheerCategory = function getCheerCategory(cheer) {
    (0, _cheerAssertions.assertCheer)(cheer);
    var category = cheer.category;
    (0, _assert.assertString)(category);
    return category;
  };

  /**
   * Gets the cheer message
   * @param {Cheer} cheer - Cheer
   * @returns {string} message
   */
  var getCheerMessage = _exports.getCheerMessage = function getCheerMessage(cheer) {
    (0, _cheerAssertions.assertCheer)(cheer);
    var message = cheer.message;
    (0, _assert.assertString)(message);
    return message;
  };

  /**
   * Gets the cheer date sent
   * @param {Cheer} cheer - Cheer
   * @returns {string} date
   */
  var getCheerDateSent = _exports.getCheerDateSent = function getCheerDateSent(cheer) {
    (0, _cheerAssertions.assertCheer)(cheer);
    var dateSent = cheer.date_sent;
    (0, _assert.assertString)(dateSent);
    return dateSent;
  };

  /**
   * Gets the cheer date read
   * @param {Cheer} cheer - Cheer
   * @returns {string | undefined} date
   */
  var getCheerDateRead = _exports.getCheerDateRead = function getCheerDateRead(cheer) {
    (0, _cheerAssertions.assertCheer)(cheer);
    var dateRead = cheer.date_read;
    if (!dateRead) return null;
    (0, _assert.assertString)(dateRead);
    return dateRead;
  };

  /**
   * Gets the cheer unwrapit url
   * @param {Cheer} cheer - Cheer
   * @returns {string | undefined} url
   */
  var getCheerUnwrapitUrl = _exports.getCheerUnwrapitUrl = function getCheerUnwrapitUrl(cheer) {
    (0, _cheerAssertions.assertCheer)(cheer);
    var unwrapitUrl = cheer.unwrapit_url;
    if (!unwrapitUrl) return undefined;
    (0, _assert.assertString)(unwrapitUrl);
    return unwrapitUrl;
  };

  /**
   * Gets the cheer mark as read url
   * @param {Cheer} cheer - Cheer
   * @returns {string | undefined} url
   */
  var getCheerMarkAsReadUrl = _exports.getCheerMarkAsReadUrl = function getCheerMarkAsReadUrl(cheer) {
    (0, _cheerAssertions.assertCheer)(cheer);
    var url = cheer.mark_as_read;
    if (!url) return undefined;
    (0, _assert.assertString)(url);
    return url;
  };

  /**
   * Gets the cheer send gift
   * @param {Cheer} cheer - Cheer
   * @returns {boolean} boolean
   */
  var getCheerSendGift = _exports.getCheerSendGift = function getCheerSendGift(cheer) {
    (0, _cheerAssertions.assertCheer)(cheer);
    var sendGift = cheer.send_gift;
    (0, _assert.assertBoolean)(sendGift);
    return sendGift;
  };

  /**
   * Gets the cheer path
   * @param {Cheer} cheer - Cheer
   * @returns {string} url
   */
  var getCheerPath = _exports.getCheerPath = function getCheerPath(cheer) {
    (0, _cheerAssertions.assertCheer)(cheer);
    var path = cheer.path;
    (0, _assert.assertString)(path);
    return path;
  };
});