(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.complaintTypeTopic = _exports.complaintTypeInvitation = _exports.complaintTypeComment = void 0;
  var complaintTypeTopic = _exports.complaintTypeTopic = "topic";
  var complaintTypeInvitation = _exports.complaintTypeInvitation = "invitation";
  var complaintTypeComment = _exports.complaintTypeComment = "comment";
});