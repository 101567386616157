/* @flow */
import { createSelector } from '@reduxjs/toolkit';

import { getEventColor } from '@braindate/domain/lib/event/util';
import { getMembershipEmailAddress } from '@braindate/domain/lib/membership/util';
import {
  getUserAvatar,
  getUserFullName,
  getUserId,
} from '@braindate/domain/lib/user/util';

import { getMembership } from 'src/shared/app/base/api/endpoint/membershipEndpoint';
import { getUser } from 'src/shared/app/base/api/endpoint/usersEndpoint';
import { getLiveChatEnvOptions } from 'src/shared/app/base/selector/appEnvSelectors';
import { getLocale } from 'src/shared/app/locale/selector/localeSelectors';
import type { State } from 'src/shared/core/type/reduxTypes';
import { getEventSafely } from 'src/shared/domain/event/selector/eventSelectors';

import {
  updateOpenedStatus,
  updateStatus,
} from 'plugin/live-chat/action/liveChatAction';
import {
  LIVE_CHAT_PROVIDER_HUBSPOT,
  LIVE_CHAT_PROVIDER_INTERCOM,
  LIVE_CHAT_PROVIDER_TAWK,
} from 'plugin/live-chat/constant/liveChatConstant';
import { hubspotWidgetActions } from 'plugin/live-chat/providers/liveChatHubspot';
import { intercomWidgetActions } from 'plugin/live-chat/providers/liveChatIntercom';
import { tawkWidgetActions } from 'plugin/live-chat/providers/liveChatTawk';
import {
  getLiveChatOptions,
  getLiveChatProvider,
} from 'plugin/live-chat/util/liveChatUtils';

import type {
  InternalLiveChatActions,
  WidgetLoadParams,
} from 'plugin/live-chat/type/liveChatType';

/*
 |------------------------------------------------------------------------------
 | Base selectors
 |------------------------------------------------------------------------------
 */

const getState = (state) => state.app.plugins.liveChat;

/*
 |------------------------------------------------------------------------------
 | Exports
 |------------------------------------------------------------------------------
 */

export const getStatus = (state: State): string => getState(state).status;
export const getOpenedStatus = (state: State): string =>
  getState(state).openedStatus;

export const getLiveChatLoadParams: (
  state: Object,
  dispatch: Function,
) => WidgetLoadParams = createSelector(
  [
    (state) => state,
    getEventSafely,
    getLiveChatEnvOptions,
    (state) => getMembership.select()(state).data,
    (state) => getUser.select('me')(state).data,
    getOpenedStatus,
    getLocale,
    (_state, dispatch) => dispatch,
  ],
  (
    state,
    event,
    liveChatEnvOptions,
    membership,
    selfUser,
    openedStatus,
    locale,
    dispatch,
  ): WidgetLoadParams => ({
    onStatusChange: (newStatus) => {
      dispatch(updateStatus(newStatus));
    },
    getState: () => state,
    sdkOptions: getLiveChatOptions(event),
    onOpenedStatusChange: (newStatus) => {
      dispatch(updateOpenedStatus(newStatus));
    },
    envSdkOptions: liveChatEnvOptions,
    name: selfUser ? getUserFullName(selfUser) : null,
    email: membership ? getMembershipEmailAddress(membership) : null,
    userId: selfUser ? getUserId(selfUser) : null,
    avatar: selfUser ? getUserAvatar(selfUser) : null,
    locale,
    accentColor: getEventColor(event),
  }),
);

const mapSdkActions = {
  [LIVE_CHAT_PROVIDER_INTERCOM]: intercomWidgetActions,
  [LIVE_CHAT_PROVIDER_TAWK]: tawkWidgetActions,
  [LIVE_CHAT_PROVIDER_HUBSPOT]: hubspotWidgetActions,
};

export const getLiveChatActions: (state: Object) => Object = createSelector(
  [getEventSafely],
  (event): InternalLiveChatActions => {
    const sdkOptions = getLiveChatOptions(event);
    const provider = sdkOptions && getLiveChatProvider(sdkOptions);

    const sdkActions = provider && mapSdkActions[provider];
    return (
      sdkActions || {
        load: () => {},
        kill: () => {},
        toggle: () => {},
        identify: () => {},
        update: () => {},
      }
    );
  },
);
