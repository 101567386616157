/* @flow */

import { createUseStyles, useTheme } from 'react-jss';

export default function createUseThemeStyles<T>(styles: T): (
  props?: Object,
) => {
  [key: $Keys<T>]: string,
} {
  const useStyles = createUseStyles(styles);

  return (props) => {
    const theme = useTheme();
    // $FlowIssue
    return useStyles({ theme, ...props });
  };
}
