/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { Skeleton } from '@mui/material';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './NotificationCard.style';

const useStyles = createUseThemeStyles(styles);

const NotificationCardSkeleton = (): Node => {
  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Skeleton variant="text" width="50%" height={30} />
      </div>

      <div className={classes.link}>
        <Skeleton variant="circular" width={50} height={50} />
        <span className={classes.text} style={{}}>
          <Skeleton variant="text" width="80%" height={20} />
          <Skeleton variant="text" width="30%" height={20} />
        </span>
      </div>
    </div>
  );
};

export default memo<{}>(NotificationCardSkeleton);
