/* @flow */

import { combineReducers } from 'redux';

import entitiesEventsReducer from 'src/shared/domain/event/reducer/entitiesEventsReducer';
import entitiesMembershipsReducer from 'src/shared/domain/membership/reducer/entitiesMembershipsReducer';

// $FlowIssue
const entitiesReducer = combineReducers({
  events: entitiesEventsReducer,
  memberships: entitiesMembershipsReducer,
});

export default entitiesReducer;
