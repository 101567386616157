/* @flow */

import { invalidateTags } from 'src/shared/app/base/api/actions/apiActions';
import { ENTITIES_TYPE } from 'src/shared/app/base/api/apiConstant';
import { getQueryTag } from 'src/shared/app/base/api/utils/endpointUtils';

import * as types from './entityActionTypes';

export type EntityIdType = number | 'LIST' | 'me';

export function getAllTagfromEntities(
  entities: Object,
): { type: string, id?: EntityIdType }[] {
  const entitiesNames = Object.keys(entities);
  const tagsList = entitiesNames
    .flatMap<{ type: string, id?: EntityIdType }>(
      (entity) =>
        ENTITIES_TYPE[entity] &&
        Object.keys(entities[entity]).flatMap((id) =>
          getQueryTag(ENTITIES_TYPE[entity], parseInt(id, 10)),
        ),
    )
    .filter(Boolean);
  return tagsList;
}

export function patchEntities(entities: Object) {
  return (dispatch: any) => {
    dispatch(invalidateTags(getAllTagfromEntities(entities)));
    dispatch({
      type: types.PATCH_ENTITIES,
      entities,
    });
  };
}
