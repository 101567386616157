import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import { useGetTogethernessQuery } from 'src/shared/app/base/api/endpoint/togethernessEndpoints';
import { isEmpty } from 'ramda';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetMembershipQuery } from 'src/shared/app/base/api/endpoint/membershipEndpoint';

export default function useTogethernessRedirection() {
    const { data: membership, isFetching: isMembershipFetching } =
    useGetMembershipQuery();
    const {
        isTogethernessEnabled,
      } = useFeatureDecisions();
    const skipTogetherness =
        isMembershipFetching ||
        !isTogethernessEnabled ||
        membership?.flags.togetherness_seen;
    const event = useEvent();
    const { data: togetherness } = useGetTogethernessQuery(
        !skipTogetherness ? { eventId: event.id } : skipToken,
    );
    const shouldRedirectToTogetherness =
        !skipTogetherness && togetherness !== undefined && !isEmpty(togetherness);
  
    return {
        shouldRedirectToTogetherness
    };
  }