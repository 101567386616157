/* @flow */
import { memo } from 'react';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateLocation,
  getBraindateStartTime,
} from '@braindate/domain/lib/braindate/util';

import { convertToDateTime } from 'src/shared/app/base/date/util/dateUtils';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import LocationsInfo from 'src/shared/components/domain/location/LocationsInfo/LocationsInfo';

import styles from './UpcomingBraindateCardDate.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  braindate: Braindate,
};

const UpcomingBraindateCardDate = ({ braindate }: Props) => {
  const classes = useStyles();

  const location = getBraindateLocation(braindate);
  const braindateStartTime = getBraindateStartTime(braindate);
  if (!braindateStartTime) return null;

  const startTime = convertToDateTime(braindateStartTime);
  const date = startTime.toFormat('ccc, dd LLL');
  const time = startTime.toFormat('hh:mm a');

  return (
    <div className={classes.root}>
      <p className={classes.date}>{date}</p>
      <p className={classes.time}>{time}</p>

      <LocationsInfo
        location={location}
        className={classes.location}
        showMeetingPoint
      />
    </div>
  );
};

export default memo<Props>(UpcomingBraindateCardDate);
