/* @flow */

import { getTheme } from 'src/shared/app/base/settings/themeSettings';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import useEventAccentColor from 'src/shared/domain/event/hook/useEventAccentColor';
import useEventBrandingCustomization from 'src/shared/domain/event/hook/useEventBrandingCustomization';
import useEventDefaultTheme from 'src/shared/domain/event/hook/useEventDefaultTheme';

export default function useCreateBaseStyleProps() {
  const [accentColor] = useEventAccentColor();
  const { useLightTheme } = useFeatureDecisions();

  const brandingCustomization = useEventBrandingCustomization();
  const themeName = useEventDefaultTheme();

  const theme = getTheme(
    themeName,
    brandingCustomization?.primary_color || accentColor,
    brandingCustomization,
  );

  return {
    theme,
    withLightTheme: useLightTheme,
  };
}
