/* @flow */

import * as types from 'src/shared/app/braindate/action/appBraindateActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  name: '',
  data: [],
  isLoading: false,
  braindateId: 0,
};

const _setQuickActionName = (state: Object, { name }) => ({
  ...state,
  name,
});

const _unsetQuickActionName = (state: Object) => ({
  ...state,
  name: '',
});

const _setQuickActionData = (state: Object, { data }) => ({
  ...state,
  data,
});

const _unsetQuickActionData = (state: Object) => ({
  ...state,
  data: [],
});

const _setQuickActionBraindateId = (state: Object, { braindateId }) => ({
  ...state,
  braindateId: braindateId || 0,
});

const _unsetQuickActionBraindateId = (state: Object) => ({
  ...state,
  braindateId: 0,
});

const _setQuickActionLoading = (state: Object) => ({
  ...state,
  isLoading: true,
});

const _resetQuickActionLoading = (state: Object) => ({
  ...state,
  isLoading: false,
});

export default createReducer(initialState, {
  [types.SET_QUICK_ACTION_NAME]: _setQuickActionName,
  [types.UNSET_QUICK_ACTION_NAME]: _unsetQuickActionName,
  [types.SET_QUICK_ACTION_DATA]: _setQuickActionData,
  [types.UNSET_QUICK_ACTION_DATA]: _unsetQuickActionData,
  [types.SET_QUICK_ACTION_BRAINDATE_ID]: _setQuickActionBraindateId,
  [types.UNSET_QUICK_ACTION_BRAINDATE_ID]: _unsetQuickActionBraindateId,
  [types.SET_QUICK_ACTION_LOADING]: _setQuickActionLoading,
  [types.RESET_QUICK_ACTION_LOADING]: _resetQuickActionLoading,
});
