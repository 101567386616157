/* @flow */

import type { State } from 'src/shared/core/type/reduxTypes';

const slice = (state: State) => state.ui.modal;

export function selectModalId(state: State): string {
  return slice(state).id;
}

export function selectModalData(state: State): ?Object {
  return slice(state).data;
}
