/* @flow */
import { useContext } from 'react';

import ModalStatusContext from 'src/shared/app/base/modal/context/ModalStatusContext';
import type { ModalStatusContextProps } from 'src/shared/app/base/modal/context/ModalStatusContext';

export default function useModalStatusContext(): ModalStatusContextProps {
  const context = useContext(ModalStatusContext);
  return context;
}
