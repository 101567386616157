/* @flow */
import {
  large,
  medium,
  mobilePeopleCardSize,
  peopleCardSize,
  small,
  xlarge,
  xsmall,
  xxlarge,
} from 'src/shared/ui/component/image/setting/avatarVariants';

export default {
  [xsmall]: 26,
  [small]: 35,
  [medium]: 50,
  [mobilePeopleCardSize]: 64,
  [large]: 70,
  [peopleCardSize]: 100,
  [xlarge]: 108,
  [xxlarge]: 190,
};
