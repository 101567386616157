/* @flow */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const portalRegistration = createSlice({
  name: 'portalRegistration',
  initialState,
  reducers: {
    registerPortal(state, { payload: { portalKey, id } }) {
      state[id] = { portalKey, id };
    },
    unRegisterPortal(state, { payload }) {
      delete state[payload];
    },
  },
});

export const { registerPortal, unRegisterPortal } = portalRegistration.actions;

export default portalRegistration.reducer;
