/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

type Props = {
  children: Node,
};

const BaseNotification = ({ children }: Props): Node => children;

export default memo<Props>(BaseNotification);
