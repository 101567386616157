/* @flow */
import { useState } from 'react';
import type { Node } from 'react';

import { useId } from 'react-aria';
import { useIntl } from 'react-intl';

import { usePatchMembershipMutation } from 'src/shared/app/base/api/endpoint/membershipEndpoint';
import { triggerErrorModal } from 'src/shared/app/base/modal/action/modalActions';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/components/domain/user/modals/l10n/phoneReminderL10n';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import PhoneInput from 'src/shared/ui/component/form/PhoneInput';

import { errorModalId } from 'plugin/virtual-braindate/constant/virtualBraindateConstants';

import styles from './SMSReminderModule.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  content?: Node,
  onSubmit?: () => void,
};

const SMSReminderPhoneInput = ({ content, onSubmit }: Props) => {
  const intl = useIntl();
  const classes = useStyles();

  const id = useId();
  const [phone, setPhone] = useState('');
  const [patchMembership, { isLoading, error }] = usePatchMembershipMutation();

  const { preferences } = error || { preferences: null };
  const { phone_number: phoneNumberError } = preferences || {
    phone_number: null,
  };

  const handleSubmit = async () => {
    const data: any = {
      preferences: {
        sms_urgent_optin: true,
        phone_number: phone,
      },
    };

    try {
      await patchMembership(data);
      if (onSubmit) onSubmit();
    } catch (e) {
      triggerErrorModal(errorModalId, e);
    }
  };

  const onChange = (value) => {
    setPhone(value);
  };

  return (
    <div className={classes.root}>
      {content}

      <div className={classes.inputContainer}>
        <PhoneInput
          value={phone}
          id={id}
          placeholder={intl.formatMessage(messages.placeholder)}
          onChange={onChange}
          error={phoneNumberError}
        />
      </div>

      <div className={classes.actions}>
        <PrimaryButton
          onClick={handleSubmit}
          isDisabled={!phone}
          isFetching={isLoading}
        >
          {intl.formatMessage(messages.turnOn)}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default SMSReminderPhoneInput;
