/* @flow */

import { combineReducers } from 'redux';

import appJoinedBraindateReducer from './appJoinedBraindateReducer';

// $FlowIssue
const appBraindatesReducer = combineReducers({
  joined: appJoinedBraindateReducer,
});

export default appBraindatesReducer;
