(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "@braindate/util/lib/assert"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("@braindate/util/lib/assert"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.assert);
    global.conversationAssertions = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _assert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertConversation = assertConversation;
  _exports.assertConversationActions = assertConversationActions;
  _exports.assertConversationMessages = assertConversationMessages;
  _exports.assertConversationParticipants = assertConversationParticipants;
  _exports.assertConversationTopic = assertConversationTopic;
  _exports.assertConversationURLs = assertConversationURLs;
  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertConversation(value) {
    (0, _assert.assertObject)(value, "conversation");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertConversationTopic(value) {
    (0, _assert.assertObject)(value, "conversation.topic");
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertConversationParticipants(value) {
    (0, _assert.assertArray)(value, "conversation.participants");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertConversationURLs(value) {
    (0, _assert.assertObject)(value, "conversation.urls");
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertConversationActions(value) {
    (0, _assert.assertArray)(value, "conversation.actions");
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertConversationMessages(value) {
    (0, _assert.assertArray)(value, "conversation.messages");
  }
});