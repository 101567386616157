/* @flow */
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,

    WebkitFontSmoothing: 'antialiased',
  },
};
