/* @flow */
import { engagement } from 'src/shared/app/base/constant/socketConstants';
import { NOTIFICATION_COMPONENT_KEYS } from 'src/shared/app/notification/constant/notificationComponentsMap';
import {
  notificationInfoLevel,
  notificationModalType,
} from 'src/shared/app/notification/constant/notificationConstants';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import { NEW_TOPICS_MODAL } from 'src/shared/app/topic/new/constant/newTopicConstants';

export const gearsPortalId = 'gears-portal';

export const maxTopicTitleLength = 95;

export function convertEngagementTriggerToNotification(
  type: string,
): null | Notification<> {
  switch (type) {
    case NEW_TOPICS_MODAL.TOPIC_ENGAGEMENT:
      return {
        id: 'topic-engagement-ongoing-events',
        level: notificationInfoLevel,
        type: notificationModalType,
        componentKey:
          NOTIFICATION_COMPONENT_KEYS.TOPIC_ENGAGEMENT_ONGOING_EVENTS,
        data: { type },
      };
    case NEW_TOPICS_MODAL.AFTER_3_DAYS:
      return {
        id: 'new-topic-returning',
        level: notificationInfoLevel,
        type: notificationModalType,
        componentKey: NOTIFICATION_COMPONENT_KEYS.NEW_TOPIC_RETURNING,
        data: { type },
      };
    case engagement:
      return {
        id: 'new-topic-returning',
        level: notificationInfoLevel,
        type: notificationModalType,
        componentKey: NOTIFICATION_COMPONENT_KEYS.NEW_TOPIC_RETURNING,
        data: { type },
      };
    default:
      return null;
  }
}
