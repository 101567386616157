/* @flow */

import { memo, useMemo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateId,
  getBraindateLocation,
  getBraindatePath,
} from '@braindate/domain/lib/braindate/util';
import { isVirtualLocation } from '@braindate/domain/lib/location/util';
import { getTopicTitle, isGroupTopic } from '@braindate/domain/lib/topic/util';
import { getUserFirstName } from '@braindate/domain/lib/user/util';

import {
  useBraindateCheckInMutation,
  useGetBraindateQuery,
} from 'src/shared/app/base/api/endpoint/braindatesEndpoint';
import { getStaticAsset } from 'src/shared/app/base/util/assetUtils';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import SittingInChairsImg from 'src/shared/app/booking_response/assets/sitting_in_chairs.png';
import BackgroundConfettis from 'src/shared/app/check-in/component/assets/confettis.gif';
import DancingImg from 'src/shared/app/check-in/component/assets/dancing.png';
import CheckInActionButton from 'src/shared/app/check-in/component/notification/CheckInActionButton';
import OtherUserCheckInCard from 'src/shared/app/check-in/component/OtherUserCheckInCard';
import messages from 'src/shared/app/check-in/l10n/checkInTakeOverL10n';
import { hasUserCheckedIn } from 'src/shared/app/check-in/util/checkInUtil';
import {
  getBraindateOtherParticipant,
  getBraindateTopic,
} from 'src/shared/domain/braindate/util/braindateUtil';
import {
  getBraindateMeetingPoint,
  getBraindatePodName,
} from 'src/shared/domain/braindate/util/meetingPointUtils';
import useSelf from 'src/shared/domain/user/hook/useSelf';
import SecondaryButton from 'src/shared/ui/component/button/SecondaryButton';
import LinkButton from 'src/shared/ui/component/link/LinkButton';
import Subheading from 'src/shared/ui/component/text/Subheading';

import useVirtualBraindateOverlayShown from 'plugin/virtual-braindate/hook/useVirtualBraindateOverlayShown';

import styles from './CheckInModal.style';

type Props = {
  braindate: Braindate,
  handleDismiss: () => void,
};

const useStyles = createUseThemeStyles(styles);

const CheckInModalDumb = ({
  handleDismiss,
  braindate: braindateProps,
}: Props): Node => {
  const intl = useIntl();
  const self = useSelf();
  const { pathname, search } = useLocation();

  const { data: queriedBraindate } = useGetBraindateQuery(
    getBraindateId(braindateProps),
  );
  const braindate = queriedBraindate || braindateProps;

  const otherParticipant = self
    ? getBraindateOtherParticipant(braindate, self)
    : null;
  const topic = getBraindateTopic(braindate);
  const isGroup = isGroupTopic(topic);
  const location = getBraindateLocation(braindate);
  const loungeName = getBraindateMeetingPoint(braindate, intl.locale);
  const isVirtual = !!location && isVirtualLocation(location);
  const braindatePath = getBraindatePath(braindate);

  const [, { isLoading: isCheckingIn }] = useBraindateCheckInMutation();

  const hasCheckedIn =
    !isVirtual && !!self && hasUserCheckedIn(braindate, self);

  const isConversationOpened = () => {
    const params = new URLSearchParams(search);
    const section = params.get('section');

    return (
      `${pathname}${section ? `?section=${section}` : ''}` === braindatePath
    );
  };

  const SecondaryAction = isConversationOpened() ? SecondaryButton : LinkButton;

  const virtualBraindateShown = useVirtualBraindateOverlayShown();

  const title = useMemo(() => {
    if (hasCheckedIn) {
      const podName = getBraindatePodName(braindate, intl.locale);

      if (isGroup) {
        return intl.formatMessage(messages.modalTitleCheckedInGroup, {
          podName,
          loungeName,
        });
      }

      return intl.formatMessage(messages.modalTitleCheckedIn, {
        podName,
        loungeName,
      });
    }

    if (isGroup) {
      return intl.formatMessage(messages.modalTitleGroup);
    }

    return intl.formatMessage(messages.modalTitle, {
      // $FlowIssue
      name: getUserFirstName(otherParticipant),
    });
  }, [braindate]);

  const subtitle = useMemo(() => {
    if (hasCheckedIn) {
      if (isGroup) {
        return intl.formatMessage(messages.modalSubtitleCheckedInGroup);
      }

      return intl.formatMessage(messages.modalSubtitleCheckedIn, {
        // $FlowIssue
        name: getUserFirstName(otherParticipant),
      });
    }

    if (isCheckingIn) {
      if (isGroup) {
        return intl.formatMessage(messages.modalSubtitleCheckingInGroup);
      }

      return intl.formatMessage(messages.modalSubtitleCheckingIn);
    }

    return intl.formatMessage(messages.modalSubtitle, { loungeName });
  }, [hasCheckedIn, isCheckingIn]);

  const classes = useStyles({
    backgroundGif: hasCheckedIn ? getStaticAsset(BackgroundConfettis) : '',
  });

  if (virtualBraindateShown) return null;

  return (
    <div className={classes.root}>
      <img
        className={classes.img}
        src={getStaticAsset(hasCheckedIn ? DancingImg : SittingInChairsImg)}
        alt=""
      />

      <Subheading layoutClass={classes.heading}>{title}</Subheading>

      {!hasCheckedIn ? (
        <p className={classes.title}>&apos;{getTopicTitle(topic)}&apos;</p>
      ) : null}

      <p className={classes.subtitle}>{subtitle}</p>

      {hasCheckedIn ? <OtherUserCheckInCard braindate={braindate} /> : null}

      <div className={classes.actions}>
        <SecondaryAction
          to={braindatePath}
          variant="secondary"
          onClick={handleDismiss}
        >
          {intl.formatMessage(messages.message)}
        </SecondaryAction>

        {!hasCheckedIn ? <CheckInActionButton braindate={braindate} /> : null}
      </div>
    </div>
  );
};

export default memo<Props>(CheckInModalDumb);
