/* @flow */

import * as types from 'src/shared/app/authentication/action/support/supportContactActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

/*
|------------------------------------------------------------------------------
| INITIAL STATE
|------------------------------------------------------------------------------
*/

const initialState = {
  isFetching: false,
  failure: false,
  success: false,
};

/*
|------------------------------------------------------------------------------
| REDUCERS
|------------------------------------------------------------------------------
*/

const _supportContactRequest = (state: Object): Object => ({
  ...state,
  isFetching: true,
  failure: false,
  success: false,
});

const _supportContactFailure = (state: Object): Object => ({
  ...state,
  isFetching: false,
  failure: true,
  success: false,
});

const _supportContactSuccess = (state: Object): Object => ({
  ...state,
  isFetching: false,
  failure: false,
  success: true,
});

const _resetContactSupport = (state: Object): Object => ({
  ...state,
  isFetching: false,
  failure: false,
  success: false,
});

/*
|------------------------------------------------------------------------------
| REDUCER
|------------------------------------------------------------------------------
*/

export default createReducer(initialState, {
  [types.CONTACT_SUPPORT_REQUEST]: _supportContactRequest,
  [types.CONTACT_SUPPORT_FAILURE]: _supportContactFailure,
  [types.CONTACT_SUPPORT_SUCCESS]: _supportContactSuccess,
  [types.RESET_CONTACT_SUPPORT]: _resetContactSupport,
});
