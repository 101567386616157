/* @flow */

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  button: {
    padding: 0,
    background: 'none',
    minHeight: 0,
  },
  link: {
    '&:focus-visible': {
      color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    },
  },
};
