/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';
import { useIntl } from 'react-intl';

import messages from 'src/shared/app/base/l10n/uiL10n';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import CircledCrossIcon from 'src/shared/ui/component/icon/CircledCrossIcon';
import CrossIcon from 'src/shared/ui/component/icon/CrossIcon';

import styles from './DismissButton.style';

type Props = {
  layoutClass?: string,
  onClick: () => void,
  isLarge?: boolean,
  isCircled?: boolean,
  isReverseColor?: boolean,
  tabIndex?: number,
  ariaLabel?: string,
};

const useStyles = createUseThemeStyles(styles);

const DismissButton = ({
  layoutClass,
  isLarge,
  isCircled = true,
  onClick,
  isReverseColor,
  ariaLabel,
  tabIndex,
}: Props): Node => {
  const intl = useIntl();

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();

    onClick();
  };

  const classes = useStyles({ isReverseColor });

  const elt = isCircled ? (
    <CircledCrossIcon large={isLarge} />
  ) : (
    <CrossIcon large={isLarge} />
  );

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  return (
    <a
      className={classNames(classes.root, layoutClass)}
      href="#"
      onClick={handleClick}
      role="button"
      tabIndex={tabIndex}
      aria-label={ariaLabel || intl.formatMessage(messages.dismiss)}
    >
      {elt}
    </a>
  );
};

export default memo<Props>(DismissButton);
