/* @flow */

import type { BraindateEvent } from '@braindate/domain/lib/event/type';
import {
  getEventPlugins,
  getEventSplashSponsors,
  getEventSponsorPluginLevelIsEnabled,
} from '@braindate/domain/lib/event/util';
import {
  isMembershipOnboardingCompleted,
  isMembershipOptInCompleted,
} from '@braindate/domain/lib/membership/util';

import {
  getAuthenticationToken,
  hasLoggedInFromBos,
} from 'src/shared/app/authentication/selector/base/appAuthenticationBaseSelectors';
import { getMembership } from 'src/shared/app/base/api/endpoint/membershipEndpoint';
import {
  loginSplashLevel as loginSplashLevelKey,
  sponsorshipPlugin as sponsorshipPluginKey,
} from 'src/shared/app/feature/settings/featureSettings';
import {
  isOnboardingCompletedOnDevice,
  isSplashSeen,
} from 'src/shared/app/onboarding/selector/uiOnboardingSelectors';
import { isOptinDone } from 'src/shared/app/permission/util/permissionUtils';
import type { State } from 'src/shared/core/type/reduxTypes';
import { isMembershipTermsAccepted } from 'src/shared/domain/membership/util/membershipUtil';

// @TODO - This should be a redux selector
export function isSplashScreenShown(
  state: State,
  event: BraindateEvent,
): boolean {
  if (hasLoggedInFromBos(state)) return false;

  const splashSponsors = getEventSplashSponsors(event);
  const sponsorshipPlugin = getEventPlugins(event)[sponsorshipPluginKey];
  const sponsorSplashIsEnabled =
    sponsorshipPlugin &&
    getEventSponsorPluginLevelIsEnabled(sponsorshipPlugin, loginSplashLevelKey);
  return (
    !!sponsorSplashIsEnabled &&
    !getAuthenticationToken(state) &&
    !isSplashSeen(state) &&
    !!splashSponsors &&
    splashSponsors.length > 0
  );
}

// @TODO - This should be a redux selector
export function isPermissionScreenShown(state: State): boolean {
  const membership = getMembership.select()(state).data;

  return !!membership && !isOptinDone(membership);
}

export function isMarketShown(state: State): boolean {
  const membership = getMembership.select()(state).data;

  let isOnboardingCompleted = isOnboardingCompletedOnDevice(state);
  let isOptinCompleted;
  let areTermsAccepted;

  if (membership) {
    isOnboardingCompleted = isMembershipOnboardingCompleted(membership);
    isOptinCompleted = isMembershipOptInCompleted(membership);
    areTermsAccepted = isMembershipTermsAccepted(membership);
  }

  return !!(
    getAuthenticationToken(state) &&
    isOnboardingCompleted &&
    isOptinCompleted &&
    areTermsAccepted
  );
}
