/* @flow */

import { createContext } from 'react';

export type ModalStatusContextProps = {
  modals: Set<symbol>,
  register: (symbol) => void,
  unregister: (symbol) => void,
};

export default createContext<ModalStatusContextProps>({
  modals: new Set(),
  register: () => {},
  unregister: () => {},
});
