/* @flow */
import { memo } from 'react';

import classNames from 'classnames';
import { useIntl } from 'react-intl';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  isFishbowlTopic,
  isGroupTopic,
  isSuperGroupTopic,
} from '@braindate/domain/lib/topic/util';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import LiveTag from 'src/shared/app/braindate/component/LiveTag';
import TopicFormatTag from 'src/shared/components/domain/topic/feed/TopicFormatTag';
import {
  getBraindateTopic,
  isBraindateLive,
} from 'src/shared/domain/braindate/util/braindateUtil';
import messages from 'src/shared/domain/topic/l10n/topicL10n';
import SecondaryTag from 'src/shared/ui/component/data-display/SecondaryTag';

import styles from './BraindateCardTags.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  braindate: Braindate,
  className?: string,
};

const BraindateCardTags = ({ braindate, className }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const topic = getBraindateTopic(braindate);
  const isGroupBraindate = isGroupTopic(topic);

  const isLive = isBraindateLive(braindate);

  const getTopicType = () => {
    if (isGroupBraindate) {
      if (isFishbowlTopic(topic)) return intl.formatMessage(messages.fishbowl);
      if (isSuperGroupTopic(topic)) {
        return intl.formatMessage(messages.super_group);
      }
      return intl.formatMessage(messages.group);
    }

    return intl.formatMessage(messages.oneOnOne);
  };

  return (
    <div className={classNames(classes.root, className)}>
      {isLive && <LiveTag />}
      <SecondaryTag>{getTopicType()}</SecondaryTag>
      <TopicFormatTag topic={topic} isConversationFormatEnabled />
    </div>
  );
};

export default memo<Props>(BraindateCardTags);
