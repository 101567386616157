/* @flow */

export default {
  root: {
    fill: 'inherit',
    stroke: 'inherit',

    verticalAlign: 'middle',
  },
};
