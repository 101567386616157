/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import withForwardRef from '@braindate/util/lib/react/hoc/withForwardRef';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './LinkButton.style';

type Props = {
  to: string,
  state?: Object,
  layoutClass?: string,
  variant?: string,
  children: Node,
  onClick?: (Event) => void,
  isExternal?: boolean,
  onMouseOver?: () => void,
  title?: string,
  'aria-label'?: string,
  'data-tip'?: string,
  withSuccess?: boolean,
  id?: string,
  forwardedRef?: Object,
  tabIndex?: number,
};

const useStyles = createUseThemeStyles(styles);

const LinkButton = ({
  to,
  state,
  children,
  layoutClass,
  onClick,
  variant,
  isExternal,
  onMouseOver,
  withSuccess,
  forwardedRef,
  title,
  'aria-label': ariaLabel,
  'data-tip': dataTip,
  id,
  tabIndex,
}: Props): Node => {
  const classes = useStyles({ withSuccess });
  const rootClasses = classNames(classes.root, layoutClass, {
    [classes.primary]: variant !== 'secondary',
    [classes.secondary]: variant === 'secondary',
  });

  const commonProps = {
    onClick,
    className: rootClasses,
    onMouseOver,
    title,
    'aria-label': ariaLabel,
    'data-tip': dataTip,
    id,
    tabIndex,
    ref: forwardedRef,
  };

  if (isExternal) {
    return (
      <a {...commonProps} target="_blank" href={to} rel="noreferrer">
        {children}
      </a>
    );
  }

  return (
    <Link {...commonProps} state={state} to={to}>
      {children}
    </Link>
  );
};

export default withForwardRef(memo<Props>(LinkButton));
