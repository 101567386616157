/* @flow */

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  icon: {
    fill: ({ theme }: ThemeFunction<{}>) => theme.textColor,
    stroke: ({ theme }: ThemeFunction<{}>) => theme.textColor,
  },
};
