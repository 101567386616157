(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "luxon", "./braindateRangeAssertions"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("luxon"), require("./braindateRangeAssertions"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.luxon, global.braindateRangeAssertions);
    global.braindateRangeGetters = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _luxon, _braindateRangeAssertions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getBraindateRangeComputedRanges = getBraindateRangeComputedRanges;
  _exports.getBraindateRangeEndTime = getBraindateRangeEndTime;
  _exports.getBraindateRangeKind = getBraindateRangeKind;
  _exports.getBraindateRangeLocation = getBraindateRangeLocation;
  _exports.getBraindateRangeURL = getBraindateRangeURL;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  /**
   * Get the url of a braindateRange
   * @param   {BraindateRange} braindateRange - BraindateRange to get the url from
   * @returns {string} URL
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getBraindateRangeURL(braindateRange) {
    (0, _braindateRangeAssertions.assertBraindateRange)(braindateRange);
    return braindateRange.url;
  }

  /**
   * Get the location of a braindateRange
   * @param   {BraindateRange} braindateRange - BraindateRange to get the location from
   * @returns {Location} location
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getBraindateRangeLocation(braindateRange) {
    (0, _braindateRangeAssertions.assertBraindateRange)(braindateRange);
    return braindateRange.location;
  }

  /**
   * Get the kind of a braindateRange
   * @param   {BraindateRange} braindateRange - BraindateRange to get the date from
   * @returns {string} kind
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getBraindateRangeKind(braindateRange) {
    (0, _braindateRangeAssertions.assertBraindateRange)(braindateRange);
    return braindateRange.kind;
  }

  /**
   * Get the computed ranges of a braindateRange
   * @param   {BraindateRange} braindateRange - BraindateRange to get the date from
   * @returns {TimeSlot[] | null} computed ranges
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getBraindateRangeComputedRanges(braindateRange) {
    (0, _braindateRangeAssertions.assertBraindateRange)(braindateRange);
    return braindateRange.computed_ranges;
  }
  function getBraindateRangeEndTime(braindateRange) {
    var endTime = braindateRange.end_time;
    var computedRanges = getBraindateRangeComputedRanges(braindateRange);
    if (!computedRanges || !computedRanges.length) return endTime;
    var allEndTimes = [endTime].concat(_toConsumableArray(computedRanges.map(function (computedRange) {
      return computedRange.end_time;
    })));
    allEndTimes.sort(function (end1, end2) {
      return (
        // $FlowIssue
        _luxon.DateTime.fromISO(end1)
        // $FlowIssue: FIXME
        .diff(_luxon.DateTime.fromISO(end2)).valueOf() >= 0 ? 1 : -1
      );
    });
    return allEndTimes[allEndTimes.length - 1];
  }
});