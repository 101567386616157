/* @flow */

import enMessages from 'src/shared/app/locale/message/en';
import frMessages from 'src/shared/app/locale/message/fr';
import jaMessages from 'src/shared/app/locale/message/ja';
import koMessages from 'src/shared/app/locale/message/ko';
import {
  getShortLocale,
  matchSupportedLocale,
} from 'src/shared/app/locale/util/localeUtils';

export const timeFormat12h = '12h';
export const timeFormat24h = '24h';
export const defaultLocale = 'en';
export const supportedLocales = [
  defaultLocale,
  // Commenting out `en-US` prevent React-intl warning messages. It's not
  // required anyway since it's similar as `en`.
  // "en-US",
  'en-gb',
  'en-au',
  'fr',
  'fr-fr',
  'fr-ca',
  'ja',
  'ja-jp',
  'ko',
  'ko-kr',
];

const _localeSettings = {
  en: {
    label: 'English',
    capitalizeTitleWords: true,
    messages: enMessages,
  },
  fr: {
    label: 'Français',
    messages: frMessages,
  },
  ja: {
    label: 'Japanese',
    messages: jaMessages,
  },
  ko: {
    label: 'Korean',
    messages: koMessages,
  },
  // WARNING: when adding a new locale, make sure to update intlPolyfill.js
  // in consequence
};

export function getLocaleLabel(locale: string): any {
  const matchedLocale = matchSupportedLocale(
    defaultLocale,
    supportedLocales,
    locale,
  );
  const settings = _localeSettings[getShortLocale(matchedLocale)];

  if (!settings) {
    throw new Error(`${matchedLocale} has no settings`);
  }

  const { label } = settings;

  if (!label) {
    throw new Error(`${matchedLocale} has no label`);
  }

  return label;
}

export function getLocaleMessages(locale: string): any {
  const matchedLocale = matchSupportedLocale(
    defaultLocale,
    supportedLocales,
    locale,
  );

  const settings = _localeSettings[getShortLocale(matchedLocale)];

  if (!settings) {
    throw new Error(`${matchedLocale} has no settings`);
  }

  const { messages } = settings;

  if (getShortLocale(locale) !== defaultLocale && !messages) {
    throw new Error(`${matchedLocale} has no messages`);
  }

  return messages;
}

export function areTitleWordsCapitalized(locale: string): boolean {
  const matchedLocale = matchSupportedLocale(
    defaultLocale,
    supportedLocales,
    locale,
  );

  const settings = _localeSettings[getShortLocale(matchedLocale)];

  return !!settings.capitalizeTitleWords;
}
