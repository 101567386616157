/* @flow */
import { em } from 'polished';

export default {
  root: {
    width: em(16),
    height: em(14),
    fontSize: em(16),
  },
};
