/* @flow */

import type {
  BraindateEventCustomizations,
  BrandingCustomizationType,
} from '@braindate/domain/lib/event/type';
import { getEventCustomizations } from '@braindate/domain/lib/event/util';

import useEvent from 'src/shared/domain/event/hook/useEvent';

const useEventBrandingCustomization = (): BrandingCustomizationType | null => {
  const event = useEvent();

  const eventCustomization: BraindateEventCustomizations =
    getEventCustomizations(event);

  const eventBrandingCustomization = eventCustomization?.branding_customization;
  if (!eventBrandingCustomization) return null;

  return eventBrandingCustomization;
};

export default useEventBrandingCustomization;
