/* @flow */

import { createElement, memo, useMemo, useState } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';
import { ErrorMessage as FormikErrorMessage } from 'formik';

import FormikSelect from 'src/shared/app/base/component/data-entry/form/FormikSelect';
import LengthCounter from 'src/shared/app/base/component/data-entry/length-counter/LengthCounter';
import useIsTouchDevice from 'src/shared/app/base/hook/useIsTouchDevice';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import ErrorMessage from 'src/shared/ui/component/form/ErrorMessage';
import Label from 'src/shared/ui/component/form/Label';

import styles from './FormGroup.style';
import FormikDatalist from './FormikDatalist';
import FormikInput from './FormikInput';
import FormikTextArea from './FormikTextArea';

const useStyles = createUseThemeStyles(styles);

type Props = {
  id: string,
  componentOpts?: Object,
  field: Object,
  form: Object,
  maxLength?: number,
  required?: boolean,
  onFocus?: (e?: Event) => void,
  onBlur?: (e?: Event) => void,
  placeholder?: string,
  error?: string,
  'data-testid'?: string,
} & Object;

const FormikFormGroup = (props: Props): Node => {
  const {
    id,
    componentOpts,
    field,
    form,
    maxLength,
    required,
    onFocus = () => null,
    onBlur,
    placeholder,
    error: componentError,
    'data-testid': testId,
  } = props;

  const isTouchDevice = useIsTouchDevice();

  const { name, value } = field;
  const {
    useTextarea,
    hasLengthCounter,
    hideError,
    label,
    labelHelper,
    labelHidden,
    labelClassName,
    helper,
    list,
    inputComp,
    inputOpts,
    className: rootClassName,
    ariaHidden,
  } = componentOpts || {};
  const isLengthCounterShowm = !!(hasLengthCounter && maxLength);
  const error = form.errors[name] || componentError;
  const touched = form.touched[name];
  const safeProps = { ...props, 'data-testid': testId };

  delete safeProps.componentOpts;
  delete safeProps.field;
  delete safeProps.form;
  delete safeProps.innerRef;

  safeProps.autoFocus = !isTouchDevice && safeProps.autoFocus;
  // eslint-disable-next-line react/destructuring-assignment
  safeProps.ref = props.innerRef;

  const errorId = `${id}-error`;
  if (!hideError && error) {
    safeProps['aria-describedby'] = errorId;
  }
  if (!label && placeholder) {
    safeProps['aria-label'] = placeholder;
  }

  const helpId = `${id}-help`;

  const [isFocused, setFocused] = useState(false);

  const handleOnFocus = () => {
    onFocus();
    setFocused(true);
  };

  const handleBlur = () => setFocused(false);

  const classes = useStyles();

  const inputClasses = classNames(classes.input, {
    [`${classes['input-isInvalid']}`]: error && touched,
  });

  const labelId = label ? `${id}-label` : null;
  const hideLabelForID = inputComp === FormikSelect;
  const labelElt = label && (
    <Label
      className={labelClassName}
      htmlFor={!hideLabelForID ? id : null}
      id={labelId}
      hidden={labelHidden}
    >
      {label} {required ? <span className={classes.asterisk}>*</span> : ''}
      {labelHelper}
    </Label>
  );

  const errorElt = useMemo(() => {
    if (!hideError && error) {
      if (componentError) {
        return <ErrorMessage id={errorId}>{componentError}</ErrorMessage>;
      }
      return (
        <FormikErrorMessage name={name}>
          {(e) => <ErrorMessage id={errorId}>{e}</ErrorMessage>}
        </FormikErrorMessage>
      );
    }
    return null;
  }, [hideError, error]);

  const helperElt = helper && !error && (
    <span className={classes.helper} id={helpId}>
      {helper}
    </span>
  );
  if (helperElt) {
    safeProps['aria-describedby'] = helpId;
  }
  let inputElt;

  if (useTextarea) {
    inputElt = (
      <FormikTextArea
        className={inputClasses}
        field={field}
        inputProps={safeProps}
        hasError={!!error}
        hasLengthCounter={hasLengthCounter}
        handleFocus={handleOnFocus}
        handleBlur={handleBlur}
      />
    );
  } else if (list) {
    inputElt = (
      <FormikDatalist
        className={inputClasses}
        field={field}
        inputProps={safeProps}
        list={list}
        handleFocus={handleOnFocus}
        handleBlur={handleBlur}
      />
    );
  } else if (inputComp) {
    let inputProps = {
      field,
      form,
      labelId,
      id,
      ...safeProps,
    };

    if (inputOpts) {
      inputProps = {
        ...props,
        ...inputOpts,
      };
    }

    if (labelElt) {
      inputProps['aria-labelledby'] = labelId;
      inputProps.labelId = labelId;
    }

    inputElt = createElement(inputComp, inputProps);
  } else {
    inputElt = (
      <FormikInput
        className={inputClasses}
        field={field}
        inputProps={safeProps}
        handleFocus={handleOnFocus}
        handleBlur={(e) => {
          handleBlur();
          if (onBlur) {
            onBlur(e);
          }
        }}
      />
    );
  }

  let maxLengthElt;

  if (isLengthCounterShowm) {
    const length = value ? value.length : 0;
    const isOverLimit = length > Number(maxLength);

    if (isFocused || isOverLimit) {
      maxLengthElt = (
        <span className={classes.maxLengthCtn} tabIndex="-1" role="status">
          <LengthCounter count={length} maxLength={parseInt(maxLength, 10)} />
        </span>
      );
    }
  }

  return (
    <div
      className={classNames([classes.root, rootClassName])}
      aria-hidden={ariaHidden}
    >
      {labelElt}
      {inputElt}
      {errorElt}
      {maxLengthElt}
      {helperElt}
    </div>
  );
};

export default memo<Props>(FormikFormGroup);
