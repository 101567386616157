/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/nav/l10n/navigationL10n';
import { mainContentId } from 'src/shared/app/nav/settings/navSettings';

import styles from './SkipNavLink.style';

const useStyles = createUseThemeStyles(styles);

const SkipNavLink = (): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */

  const intl = useIntl();

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */

  const classes = useStyles();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  return (
    <a className={classes.root} href={`#${mainContentId}`}>
      {intl.formatMessage(messages.skip)}
    </a>
  );
};

export default memo<{}>(SkipNavLink);
