/* @flow */

export default {
  close: {
    position: 'absolute',
    top: 10,
    right: 20,
  },
  container: {
    overflowX: 'hidden',
  },
};
