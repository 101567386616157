/* @flow */
import { tablet } from '@braindate/ui/lib/token/mediaQuery';

export default {
  root: {
    minWidth: 160,

    [tablet]: {
      marginLeft: 19,
    },
  },
};
