/* @flow */

import * as types from 'src/shared/app/base/action/deviceActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  isTouchDevice: null,
};

const _markAsTouchDevice = (state: Object) => ({
  ...state,
  isTouchDevice: true,
});

export default createReducer(initialState, {
  [types.MARK_AS_TOUCH_DEVICE]: _markAsTouchDevice,
});
