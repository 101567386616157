/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateLocation,
  getBraindatePath,
} from '@braindate/domain/lib/braindate/util';
import { isVirtualLocation } from '@braindate/domain/lib/location/util';
import {
  getTopicPath,
  isFishbowlTopic,
  isGroupTopic,
} from '@braindate/domain/lib/topic/util';
import { getUserFirstName, getUserId } from '@braindate/domain/lib/user/util';

import Notification from 'src/shared/app/notification/component/Notification';
import {
  getBraindateParticipants,
  getBraindateTopic,
} from 'src/shared/domain/braindate/util/braindateUtil';
import useSelf from 'src/shared/domain/user/hook/useSelf';

import messages from 'plugin/virtual-braindate/l10n/virtualBraindateL10n';

type Props = {
  braindate: Braindate,
  handleDismiss: () => void,
};

const VirtualBraindateEarlyReminderDumb = ({
  braindate,
  handleDismiss,
}: Props): Node => {
  const self = useSelf();
  const topic = getBraindateTopic(braindate);
  const isGroupBraindate = isGroupTopic(topic);
  const isFishbowl = isFishbowlTopic(topic);

  const location = getBraindateLocation(braindate);
  const isVirtual = location && isVirtualLocation(location);

  const href = isFishbowl ? getTopicPath(topic) : getBraindatePath(braindate);

  const participants = getBraindateParticipants(braindate);
  const otherParticipants = participants.filter(
    (p) => self && getUserId(p) !== getUserId(self),
  );

  const intl = useIntl();

  const messageTxt = isFishbowl
    ? intl.formatMessage(messages.earlyFishbowlReminder)
    : intl.formatMessage(messages.earlyReminder, {
        isVirtual,
        isGroupBraindate,
        firstName: otherParticipants.map(getUserFirstName).join(', '),
      });

  return (
    <Notification href={href} handleDismiss={handleDismiss}>
      {messageTxt}
    </Notification>
  );
};

export default memo<Props>(VirtualBraindateEarlyReminderDumb);
