/* @flow */

import { em } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
  },
  banner: {
    width: '100%',
    background: ({ gradient }: ThemeFunction<{ gradient: string }>) => gradient,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    color: ({ theme }: ThemeFunction<{}>) => theme.textColorReverse,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: 25,
    marginBottom: 20,

    '& svg': {
      width: 52,
      height: 56,
    },
  },
  category: {
    position: 'absolute',
    top: 12,
    left: 12,
  },
  messageWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  message: {
    maxWidth: 410,
    padding: 0,
    margin: 0,
    fontSize: em(22, baseFontSize),
    fontStyle: 'normal',
    fontWeight: 600,
    minHeight: 62,
    textAlign: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 560,
    padding: 20,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  from: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '5px',
    marginBottom: 12,
  },
  sender: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  senderName: {
    fontWeight: 'bold',
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
  },
  text: {
    width: '100%',
    textAlign: 'left',
  },
  button: {
    width: 'max-content',
    gap: '5px',
  },
};
