(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.topicSuperGroupKind = _exports.topicOneOnOneKind = _exports.topicModerationVerifiedStatus = _exports.topicModerationRejectedStatus = _exports.topicModerationPendingStatus = _exports.topicGroupKind = _exports.topicFishbowlKind = _exports.topicEntityKey = void 0;
  /**
   * Entity key used by the topic schema
   * @type {string}
   */
  var topicEntityKey = _exports.topicEntityKey = "topics";

  /**
   * Value of `kind` for a one-on-one topic
   * @type {TopicKindOneOnOne}
   */
  var topicOneOnOneKind = _exports.topicOneOnOneKind = "offer";

  /**
   * Value of `kind` for a group topic
   * @type {TopicKindGroup}
   */
  var topicGroupKind = _exports.topicGroupKind = "group";

  /**
   * Value of `kind` for a fishbowl topic
   * @type {TopicKindFishbowl}
   */
  var topicFishbowlKind = _exports.topicFishbowlKind = "fishbowl";

  /**
   * Value of `kind` for a fishbowl topic
   * @type {TopicKindSuperGroup}
   */
  var topicSuperGroupKind = _exports.topicSuperGroupKind = "super_group";

  /**
   * Value of `moderation` for a pending topic
   * @type {string}
   */
  var topicModerationPendingStatus = _exports.topicModerationPendingStatus = "pending";

  /**
   * Value of `moderation` for a rejected topic
   * @type {string}
   */
  var topicModerationRejectedStatus = _exports.topicModerationRejectedStatus = "rejected";

  /**
   * Value of `moderation` for a verified topic
   * @type {string}
   */
  var topicModerationVerifiedStatus = _exports.topicModerationVerifiedStatus = "verified";
});