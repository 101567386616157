/* @flow */

import * as types from 'src/shared/app/signup/action/signupActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  email: null,
  code: null,
  error: null,
  isPasswordSet: false,
};

const setEmail = (state: Object, { email, isPasswordSet }: Object): Object => ({
  ...state,
  email,
  isPasswordSet,
});

const resetEmail = (state: Object): Object => ({
  ...state,
  email: null,
  isPasswordSet: false,
});

const setCode = (state: Object, { code }: Object): Object => ({
  ...state,
  code,
});

const resetCode = (state: Object): Object => ({
  ...state,
  code: null,
});

export default createReducer(initialState, {
  [types.VALIDATE_SIGNUP_EMAIL_REQUEST]: resetEmail,
  [types.VALIDATE_SIGNUP_EMAIL_FAILURE]: resetEmail,
  [types.VALIDATE_SIGNUP_EMAIL_SUCCESS]: setEmail,
  [types.VALIDATE_SIGNUP_ACTIVATION_CODE_REQUEST]: resetCode,
  [types.VALIDATE_SIGNUP_ACTIVATION_CODE_FAILURE]: resetCode,
  [types.VALIDATE_SIGNUP_ACTIVATION_CODE_SUCCESS]: setCode,
});
