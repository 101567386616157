(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "@braindate/util/lib/type", "@braindate/util/lib/assert", "./eventAssertions", "../../base/l10n/util"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("@braindate/util/lib/type"), require("@braindate/util/lib/assert"), require("./eventAssertions"), require("../../base/l10n/util"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.type, global.assert, global.eventAssertions, global.util);
    global.eventGetters = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _type, _assert, _eventAssertions, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getEventAuthenticationConfig = getEventAuthenticationConfig;
  _exports.getEventBraindateDuration = getEventBraindateDuration;
  _exports.getEventBraindateNames = getEventBraindateNames;
  _exports.getEventBraindateStep = getEventBraindateStep;
  _exports.getEventBraindatesURL = getEventBraindatesURL;
  _exports.getEventCheerModalSponsors = getEventCheerModalSponsors;
  _exports.getEventColor = getEventColor;
  _exports.getEventContext = getEventContext;
  _exports.getEventCustomCopy = getEventCustomCopy;
  _exports.getEventCustomId = getEventCustomId;
  _exports.getEventCustomizations = getEventCustomizations;
  _exports.getEventDarkColor = getEventDarkColor;
  _exports.getEventDates = getEventDates;
  _exports.getEventDefaultMarketOrdering = getEventDefaultMarketOrdering;
  _exports.getEventDefaultTheme = getEventDefaultTheme;
  _exports.getEventEmailSponsors = getEventEmailSponsors;
  _exports.getEventEndTime = getEventEndTime;
  _exports.getEventExplicitTabOptin = getEventExplicitTabOptin;
  _exports.getEventFAQURL = getEventFAQURL;
  _exports.getEventFeatures = getEventFeatures;
  _exports.getEventFiltersURL = getEventFiltersURL;
  _exports.getEventFishbowlCapacity = getEventFishbowlCapacity;
  _exports.getEventFishbowlDuration = getEventFishbowlDuration;
  _exports.getEventFishbowlPlugin = getEventFishbowlPlugin;
  _exports.getEventFishbowlSpeakerCapacity = getEventFishbowlSpeakerCapacity;
  _exports.getEventFishbowlStep = getEventFishbowlStep;
  _exports.getEventFishbowlTransitionDelay = getEventFishbowlTransitionDelay;
  _exports.getEventForgotPasswordURL = getEventForgotPasswordURL;
  _exports.getEventGroupBraindateCapacity = getEventGroupBraindateCapacity;
  _exports.getEventGroupBraindateDuration = getEventGroupBraindateDuration;
  _exports.getEventGroupBraindateStep = getEventGroupBraindateStep;
  _exports.getEventId = getEventId;
  _exports.getEventLanguages = getEventLanguages;
  _exports.getEventLoginPasswordLabel = getEventLoginPasswordLabel;
  _exports.getEventLoginPasswordLegend = getEventLoginPasswordLegend;
  _exports.getEventLoginPasswordPlaceholder = getEventLoginPasswordPlaceholder;
  _exports.getEventLoginPasswordText = getEventLoginPasswordText;
  _exports.getEventLoginURL = getEventLoginURL;
  _exports.getEventLoginUsernameLabel = getEventLoginUsernameLabel;
  _exports.getEventLoginUsernameLegend = getEventLoginUsernameLegend;
  _exports.getEventLoginUsernamePlaceholder = getEventLoginUsernamePlaceholder;
  _exports.getEventLoginUsernameText = getEventLoginUsernameText;
  _exports.getEventLogo = getEventLogo;
  _exports.getEventLogoAltText = getEventLogoAltText;
  _exports.getEventMarketplaceCardSponsors = getEventMarketplaceCardSponsors;
  _exports.getEventMarketplaceFooterSponsors = getEventMarketplaceFooterSponsors;
  _exports.getEventMarketplaceHeaderSponsors = getEventMarketplaceHeaderSponsors;
  _exports.getEventName = getEventName;
  _exports.getEventNavbarSponsors = getEventNavbarSponsors;
  _exports.getEventOccurrence = getEventOccurrence;
  _exports.getEventOpenGraphDescription = getEventOpenGraphDescription;
  _exports.getEventOpenGraphPicture = getEventOpenGraphPicture;
  _exports.getEventOpenGraphTitle = getEventOpenGraphTitle;
  _exports.getEventPasswordFieldType = getEventPasswordFieldType;
  _exports.getEventPlatformURL = getEventPlatformURL;
  _exports.getEventPlugins = getEventPlugins;
  _exports.getEventPreferencesURL = getEventPreferencesURL;
  _exports.getEventPreselectedKeywordsPlugin = getEventPreselectedKeywordsPlugin;
  _exports.getEventProfileCompanyLabel = getEventProfileCompanyLabel;
  _exports.getEventProfileCompanyPlaceholder = getEventProfileCompanyPlaceholder;
  _exports.getEventProfileFieldDefinitionChoices = getEventProfileFieldDefinitionChoices;
  _exports.getEventProfileFieldDefinitionDescription = getEventProfileFieldDefinitionDescription;
  _exports.getEventProfileFieldDefinitionId = getEventProfileFieldDefinitionId;
  _exports.getEventProfileFieldDefinitionRequired = getEventProfileFieldDefinitionRequired;
  _exports.getEventProfileFieldDefinitionTitle = getEventProfileFieldDefinitionTitle;
  _exports.getEventProfileFieldDefinitions = getEventProfileFieldDefinitions;
  _exports.getEventProfilePositionLabel = getEventProfilePositionLabel;
  _exports.getEventProfilePositionPlaceholder = getEventProfilePositionPlaceholder;
  _exports.getEventProfileRequiredFields = getEventProfileRequiredFields;
  _exports.getEventRetrievalExtraFieldLabel = getEventRetrievalExtraFieldLabel;
  _exports.getEventRetrievalExtraFieldLegend = getEventRetrievalExtraFieldLegend;
  _exports.getEventRetrievalExtraFieldPlaceholder = getEventRetrievalExtraFieldPlaceholder;
  _exports.getEventSignupCodeLabel = getEventSignupCodeLabel;
  _exports.getEventSignupCodeLegend = getEventSignupCodeLegend;
  _exports.getEventSignupCodePlaceholder = getEventSignupCodePlaceholder;
  _exports.getEventSignupCodeText = getEventSignupCodeText;
  _exports.getEventSocketURL = getEventSocketURL;
  _exports.getEventSplashSponsors = getEventSplashSponsors;
  _exports.getEventSponsors = getEventSponsors;
  _exports.getEventStartTime = getEventStartTime;
  _exports.getEventSuperGroupCapacity = getEventSuperGroupCapacity;
  _exports.getEventSuperGroupDuration = getEventSuperGroupDuration;
  _exports.getEventSuperGroupPlugin = getEventSuperGroupPlugin;
  _exports.getEventSupportEmail = getEventSupportEmail;
  _exports.getEventTimezone = getEventTimezone;
  _exports.getEventTopicContextPlaceholder = getEventTopicContextPlaceholder;
  _exports.getEventTopicContextTitle = getEventTopicContextTitle;
  _exports.getEventTopicCreationGroupLabel = getEventTopicCreationGroupLabel;
  _exports.getEventTopicGroupTitlePlaceholder = getEventTopicGroupTitlePlaceholder;
  _exports.getEventTopicGuidanceDescription = getEventTopicGuidanceDescription;
  _exports.getEventTopicGuidanceTitle = getEventTopicGuidanceTitle;
  _exports.getEventTopicTitlePlaceholder = getEventTopicTitlePlaceholder;
  _exports.getEventTopicsURL = getEventTopicsURL;
  _exports.getEventURLs = getEventURLs;
  _exports.getEventUsernameFieldType = getEventUsernameFieldType;
  _exports.getEventUsersURL = getEventUsersURL;
  _exports.getEventVideoCallSponsors = getEventVideoCallSponsors;
  _exports.getMarketplaceFilterAllowedCategories = getMarketplaceFilterAllowedCategories;
  _exports.getPreselectedKeywords = getPreselectedKeywords;
  _exports.isEventUsernameRetrievalAllowed = isEventUsernameRetrievalAllowed;
  /*
   |------------------------------------------------------------------------------
   | Base getters - Core
   |------------------------------------------------------------------------------
   */
  /**
   * Get the id of an event
   * @param   {BraindateEvent} event - Event to get the id from
   * @returns {number} Id
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventId(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.id;
  }

  /**
   * Get the color of an event
   * @param   {BraindateEvent} event - Event to get the color from
   * @returns {string} Color
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventColor(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.color;
  }

  /**
   * Get the dark color of an event
   * @param   {BraindateEvent} event - Event to get the dark color from
   * @returns {string} Dark color
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventDarkColor(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.color_dark;
  }

  /**
   * Get the custom id of an event
   * @param   {BraindateEvent} event - Event to get the id from
   * @returns {string} Custom id
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventCustomId(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.custom_id;
  }

  /**
   * Get the platform URL of an event
   * @param   {BraindateEvent} event - Event to get the URL from
   * @returns {string} URL
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventPlatformURL(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.platform_url;
  }

  /**
   * Get the login URL of an event
   * @param   {BraindateEvent} event - Event to get the URL from
   * @returns {string} URL
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventLoginURL(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.login_url;
  }

  /**
   * Get the forgot password URL of an event
   * @param   {BraindateEvent} event - Event to get the URL from
   * @returns {string} URL
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventForgotPasswordURL(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.forgot_password_url;
  }

  /**
   * Get the preferences URL of an event
   * @param   {BraindateEvent} event - Event to get the URL from
   * @returns {string} URL
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventPreferencesURL(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.preferences_url;
  }

  /**
   * Get the timezone of an event
   * @param   {BraindateEvent} event - Event to get the timezone from
   * @returns {string} Timezone
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventTimezone(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.timezone;
  }

  /**
   * Get the languages of an event
   * @param   {BraindateEvent} event - Event to get the languages from
   * @returns {Array<string>} Languages
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an array
   */
  function getEventLanguages(event) {
    (0, _eventAssertions.assertEvent)(event);
    var languages = event.languages;
    (0, _eventAssertions.assertEventLanguages)(languages);
    return languages;
  }

  /**
   * Get the support email of an event
   * @param   {BraindateEvent} event - Event to get the email from
   * @returns {string} Email
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventSupportEmail(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.support_email;
  }

  /**
   * Get the plugins of an event
   * @param   {BraindateEvent} event - Event to get the plugins from
   * @returns {BraindateEventPlugins} Plugins
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getEventPlugins(event) {
    (0, _eventAssertions.assertEvent)(event);
    var plugins = event.plugins;
    (0, _eventAssertions.assertEventPlugins)(plugins);
    return plugins;
  }

  /**
   * Get the fishbowl plugin of an event
   * @param   {BraindateEvent} event - Event to get the fishbowl plugin from
   * @returns {BraindateEventFishbowlPlugin} Fishbowl Plugin
   *
   * @throws Will throw an exception if parameter is not an object, if attribute
   * is not an object or if the fishbowl plugins is not enabled
   */
  function getEventFishbowlPlugin(event) {
    (0, _eventAssertions.assertEvent)(event);
    var plugins = event.plugins;
    (0, _eventAssertions.assertEventPlugins)(plugins);
    var fishbowl = plugins.fishbowl;
    if (!fishbowl) return null;
    return fishbowl;
  }

  /**
   * Get the super group plugin of an event
   * @param   {BraindateEvent} event - Event to get the super group plugin from
   * @returns {BraindateEventSuperGroupPlugin} SuperGroup Plugin
   *
   * @throws Will throw an exception if parameter is not an object, if attribute is not an object
   */
  function getEventSuperGroupPlugin(event) {
    (0, _eventAssertions.assertEvent)(event);
    var plugins = event.plugins;
    (0, _eventAssertions.assertEventPlugins)(plugins);
    var superGroup = plugins.super_group;
    if (!superGroup) return null;
    return superGroup;
  }

  /**
   * Get the preselected keywords plugin of an event
   * @param   {BraindateEvent} event - Event to get the preselected keywords plugin from
   * @returns {PreselectedKeywordsPlugin} Preselected Keywords Plugin
   *
   * @throws Will throw an exception if parameter is not an object, if attribute is not an object
   */
  function getEventPreselectedKeywordsPlugin(event) {
    (0, _eventAssertions.assertEvent)(event);
    var plugins = event.plugins;
    (0, _eventAssertions.assertEventPlugins)(plugins);
    var customKeywordsPlugin = plugins.custom_keywords;
    if (!customKeywordsPlugin) return null;
    return customKeywordsPlugin;
  }
  function getPreselectedKeywords(plugin, locale) {
    var _getLocalizedData = (0, _util.getLocalizedData)(plugin, locale),
      keywords = _getLocalizedData.keywords;
    return keywords;
  }

  /**
   * Get the features of an event
   * @param   {BraindateEvent} event - Event to get the features from
   * @returns {BraindateEventFeatures} Features
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getEventFeatures(event) {
    (0, _eventAssertions.assertEvent)(event);
    var features = event.features;
    (0, _eventAssertions.assertEventFeatures)(features);
    return features;
  }

  /**
   * Get the sponsors of an event
   * @param   {BraindateEvent} event - Event to get the sponsors from
   * @returns {BraindateEventSponsors} Sponsors
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getEventSponsors(event) {
    (0, _eventAssertions.assertEvent)(event);
    var sponsors = event.sponsors_v2;
    if (sponsors) {
      (0, _eventAssertions.assertEventSponsors)(sponsors);
      return sponsors;
    }
  }

  /**
   * Get the logo of an event
   * @param   {BraindateEvent} event - Event to get the logo from
   * @param   {string} locale - Current locale
   * @returns {Picture | null} Logo
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getEventLogo(event, locale) {
    (0, _eventAssertions.assertEvent)(event);
    var _getLocalizedData2 = (0, _util.getLocalizedData)(event, locale),
      logo = _getLocalizedData2.logo;
    if (!logo || Object.keys(logo).length === 0) {
      var body = event.body;
      // Find the first possible logo
      for (var key in body) {
        var _logo = body[key].logo;
        if (_logo && Object.keys(_logo).length > 0) {
          (0, _eventAssertions.assertEventLogo)(_logo);
          return _logo;
        }
      }
      return undefined;
    }
    (0, _eventAssertions.assertEventLogo)(logo);
    return logo;
  }

  /**
   * Get the logo alt text of an event
   * @param   {BraindateEvent} event - Event to get the logo from
   * @param   {string} [locale] - Locale in which to get the name
   * @returns {string} Logo alt text
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getEventLogoAltText(event, locale) {
    (0, _eventAssertions.assertEvent)(event);
    var _getLocalizedData3 = (0, _util.getLocalizedData)(event, locale),
      logoAltText = _getLocalizedData3.logo_alt_text;
    if (logoAltText) {
      (0, _assert.assertString)(logoAltText);
      return logoAltText;
    }
    return getEventName(event, locale);
  }

  /**
   * Get the Opengraph picture of an event
   * @param   {BraindateEvent} event - Event to get the picture from
   * @returns {string} Picture
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventOpenGraphPicture(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.opengraph_picture;
  }

  /**
   * Get the onboarding picture of an event
   * @param   {BraindateEvent} event - Event to get the picture from
   * @returns {BraindateEventContext} Picture
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getEventContext(event) {
    (0, _eventAssertions.assertEvent)(event);
    var eventContext = event.context;
    (0, _assert.assertString)(eventContext);
    return eventContext;
  }

  /**
   * Get the occurrence of the event
   * @param   {BraindateEvent} event - Event to get the occurrence
   * @returns {BraindateEventOccurence} Occurence
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not a string
   */
  function getEventOccurrence(event) {
    (0, _eventAssertions.assertEvent)(event);
    var occurrence = event.occurrence;
    (0, _assert.assertString)(occurrence);
    return occurrence;
  }

  /**
   * Get the event customizations
   * @param   {BraindateEvent} event - Event to get the customizations from
   * @returns {EventCustomizations} Customizations Object
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getEventCustomizations(event) {
    (0, _eventAssertions.assertEvent)(event);
    var customizations = event.customizations;
    (0, _eventAssertions.assertEventCustomizations)(customizations);
    return customizations;
  }

  /**
   * Get the event profile field definitions
   * @param   {BraindateEvent} event - Event to get the field definitions from
   * @returns {BraindateEventProfileFieldDefinition[]} Field definitions Object
   */
  function getEventProfileFieldDefinitions(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.profile_field_definitions;
  }

  /**
   * Get the id of the field definition
   * @param   {BraindateEventProfileFieldDefinition} field - Definitions to get the id from
   * @returns {number} Id
   */
  function getEventProfileFieldDefinitionId(field) {
    var id = field.id;
    return id;
  }

  /**
   * Get the title of the field definition
   * @param   {BraindateEventProfileFieldDefinition} field - Definitions to get the title from
   * @returns {string} Title
   */
  function getEventProfileFieldDefinitionTitle(field) {
    return (0, _util.getLocalizedData)(field).title;
  }

  /**
   * Get the description of the field definition
   * @param   {BraindateEventProfileFieldDefinition} field - Definitions to get the description from
   * @returns {string} Description
   */
  function getEventProfileFieldDefinitionDescription(field) {
    return (0, _util.getLocalizedData)(field).description;
  }

  /**
   * Gets if the field is required
   * @param   {BraindateEventProfileFieldDefinition} field - Definitions to get the required from
   * @returns {boolean} Required
   */
  function getEventProfileFieldDefinitionRequired(field) {
    var required = field.required;
    return !!required;
  }

  /**
   * Get the choices of the field definition
   * @param   {BraindateEventProfileFieldDefinition} field - Definitions to get the choices from
   * @returns {string[]} Choices
   */
  function getEventProfileFieldDefinitionChoices(field) {
    return (0, _util.getLocalizedData)(field).choices;
  }

  /**
   * Get the event customizations
   * @param   {BraindateEvent} event - Event to get the customizations from
   * @returns {EventCustomizations} Customizations Object
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getEventProfileRequiredFields(event) {
    (0, _eventAssertions.assertEvent)(event);
    var requiredUserProfileFields = event.required_user_profile_fields;
    if (requiredUserProfileFields) {
      (0, _eventAssertions.assertEventProfileRequireFields)(requiredUserProfileFields);
      return requiredUserProfileFields;
    }
  }
  /*
   |------------------------------------------------------------------------------
   | Base getters - Public data
   |------------------------------------------------------------------------------
   */

  /**
   * Get the authentication config of an event
   * @param   {BraindateEventPublicData} event - Event to get the config from
   * @returns {BraindateEventAuthenticationConfig} Authentication config
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getEventAuthenticationConfig(event) {
    (0, _eventAssertions.assertEvent)(event);
    var authentication = event.authentication;
    if (authentication) {
      (0, _eventAssertions.assertEventAuthenticationConfig)(authentication);
      return authentication;
    }
  }

  /*
   |------------------------------------------------------------------------------
   | Base getters - Private data
   |------------------------------------------------------------------------------
   */

  /**
   * Get the URLs of an event
   * @param   {BraindateEvent} event - Event to get the URLs from
   * @returns {BraindateEventURLs} URLs
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getEventURLs(event) {
    (0, _eventAssertions.assertEvent)(event);
    var urls = event.urls;
    (0, _eventAssertions.assertEventURLs)(urls);
    return urls;
  }

  /**
   * Get the dates of an event
   * @param   {BraindateEvent} event - Event to get the dates from
   * @returns {Array<string>} Dates
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an array
   */
  function getEventDates(event) {
    (0, _eventAssertions.assertEvent)(event);
    var dates = event.dates;
    (0, _eventAssertions.assertEventDates)(dates);
    return dates;
  }

  /**
   * Get the start date of an event
   * @param   {BraindateEvent} event - Event to get the date from
   * @returns {string} Date
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not a string
   */
  function getEventStartTime(event) {
    (0, _eventAssertions.assertEvent)(event);
    var startTime = event.start_time;
    (0, _assert.assertString)(startTime);
    return startTime;
  }

  /**
   * Get the end date of an event
   * @param   {BraindateEvent} event - Event to get the date from
   * @returns {string} Date
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not a string
   */
  function getEventEndTime(event) {
    (0, _eventAssertions.assertEvent)(event);
    var endTime = event.end_time;
    (0, _assert.assertString)(endTime);
    return endTime;
  }

  /**
   * Get the braindate duration of an event
   * @param   {BraindateEvent} event - Event to get the duration from
   * @returns {number} Duration, in minutes
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventBraindateDuration(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.braindate_length;
  }

  /**
   * Get the braindate step of an event
   * @param   {BraindateEvent} event - Event to get the step from
   * @returns {number} Step, in minutes
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventBraindateStep(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.braindate_interval;
  }

  /**
   * Get the group braindate duration of an event
   * @param   {BraindateEvent} event - Event to get the duration from
   * @returns {number} Duration, in minutes
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventGroupBraindateDuration(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.group_braindate_length;
  }

  /**
   * Get the group braindate step of an event
   * @param   {BraindateEvent} event - Event to get the step from
   * @returns {number} Step, in minutes
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventGroupBraindateStep(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.group_braindate_interval;
  }

  /**
   * Get the group braindate capacity of an event
   * @param   {BraindateEvent} event - Event to get the capacity from
   * @returns {number} Capacity
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventGroupBraindateCapacity(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.group_braindate_capacity;
  }

  /**
   * Get the URLs of an event
   * @param   {BraindateEvent} event - Event to get the URLs from
   * @returns {string} Default
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getEventDefaultTheme(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.default_theme;
  }

  /**
   * Get the
   * @param   {BraindateEvent} event - Event
   * @returns {boolean} Is explicit
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getEventExplicitTabOptin(event) {
    (0, _eventAssertions.assertEvent)(event);
    return !!event.explicit_people_tab_optin;
  }

  /**
   * Get the fishbowl capacity
   * @param   {BraindateEvent} event - Event to get the capacity from
   * @returns {number | null} Capacity
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventFishbowlCapacity(event) {
    (0, _eventAssertions.assertEvent)(event);
    var fishbowl = getEventFishbowlPlugin(event);
    if (!fishbowl) return null;
    var maxCapacity = fishbowl.max_capacity;
    (0, _assert.assertNumber)(maxCapacity);
    return maxCapacity;
  }

  /**
   * Get the fishbowl speakers capacity
   * @param   {BraindateEvent} event - Event to get the capacity from
   * @returns {number} Capacity
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventFishbowlSpeakerCapacity(event) {
    (0, _eventAssertions.assertEvent)(event);
    var fishbowl = getEventFishbowlPlugin(event);
    if (!fishbowl) return null;
    var speakersNumber = fishbowl.speakers_number;
    (0, _assert.assertNumber)(speakersNumber);
    return speakersNumber;
  }

  /**
   * Get the fishbowl braindate duration of an event
   * @param   {BraindateEvent} event - Event to get the duration from
   * @returns {number} Duration, in minutes
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventFishbowlDuration(event) {
    (0, _eventAssertions.assertEvent)(event);
    var fishbowl = getEventFishbowlPlugin(event);
    if (!fishbowl) return null;
    var braindateLength = fishbowl.braindate_length;
    (0, _assert.assertNumber)(braindateLength);
    return braindateLength;
  }

  /**
   * Get the fishbowl braindate interval of an event
   * @param   {BraindateEvent} event - Event to get the duration from
   * @returns {number} Interval, in minutes
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventFishbowlStep(event) {
    (0, _eventAssertions.assertEvent)(event);
    var fishbowl = getEventFishbowlPlugin(event);
    if (!fishbowl) return null;
    var braindateInterval = fishbowl.braindate_interval;
    (0, _assert.assertNumber)(braindateInterval);
    return braindateInterval;
  }

  /**
   * Get the fishbowl braindate transition delay
   * @param   {BraindateEvent} event - Event to get the delay
   * @returns {number} Delay, in seconds
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventFishbowlTransitionDelay(event) {
    (0, _eventAssertions.assertEvent)(event);
    var fishbowl = getEventFishbowlPlugin(event);
    if (!fishbowl) return null;
    var transitionDelay = fishbowl.transition_delay;
    (0, _assert.assertNumber)(transitionDelay);
    return transitionDelay;
  }

  /**
   * Get the supergroup capacity
   * @param   {BraindateEvent} event - Event to get the capacity from
   * @returns {number} Capacity
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventSuperGroupCapacity(event) {
    (0, _eventAssertions.assertEvent)(event);
    var superGroup = getEventSuperGroupPlugin(event);
    if (!superGroup) return null;
    var capacity = superGroup.max_count;
    (0, _assert.assertNumber)(capacity);
    return capacity;
  }

  /**
   * Get the super group braindate duration of an event
   * @param   {BraindateEvent} event - Event to get the duration from
   * @returns {number | null} Duration, in minutes
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventSuperGroupDuration(event) {
    (0, _eventAssertions.assertEvent)(event);
    var superGroup = getEventSuperGroupPlugin(event);
    if (!superGroup) return null;
    var braindateLength = superGroup.braindate_length;
    (0, _assert.assertNumber)(braindateLength);
    return braindateLength;
  }

  /**
   * Get the fishbowl available slots of an event
   * @param   {BraindateEvent} event - Event to get the slots from
   * @returns {string[]} Slots
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an array
   */
  function getMarketplaceFilterAllowedCategories(event) {
    (0, _eventAssertions.assertEvent)(event);
    return event.marketplace_filter_allowed_categories;
  }

  /*
   |------------------------------------------------------------------------------
   | Advanced getters - Body
   |------------------------------------------------------------------------------
   */

  /**
   * Get the name of an event
   * @param   {BraindateEvent} event - Event to get the name from
   * @param   {string} [locale] - Locale in which to get the name
   * @returns {string} Name
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventName(event, locale) {
    (0, _eventAssertions.assertEvent)(event);
    return (0, _util.getLocalizedData)(event, locale).title || "";
  }

  /**
   * Get the custom copy of an event
   * @param   {BraindateEvent} event - Event to get the custom copy from
   * @param   {string} [locale] - Locale in which to get the custom copy
   * @returns {Object} Custom copy
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventCustomCopy(event, locale) {
    (0, _eventAssertions.assertEvent)(event);
    return (0, _util.getLocalizedData)(event, locale).custom_copy;
  }

  /**
   * Get the singular and plural names of "braindate"
   * @param   {BraindateEvent} event - Event to get the names from
   * @param   {string} [locale] - Locale in which to get the names
   * @returns {Object} Names
   */
  function getEventBraindateNames(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).braindate;
  }

  /**
   * Get the Opengraph title of an event
   * @param   {BraindateEvent} event - Event to get the title from
   * @param   {string} [locale] - Locale in which to get the title
   * @returns {string} Title
   */
  function getEventOpenGraphTitle(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).opengraph_title || "";
  }

  /**
   * Get the Opengraph description of an event
   * @param   {BraindateEvent} event - Event to get the description from
   * @param   {string} [locale] - Locale in which to get the description
   * @returns {string} Description
   */
  function getEventOpenGraphDescription(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).opengraph_description || "";
  }

  /**
   * Get the login username label of an event
   * @param   {BraindateEvent} event - Event to get the label from
   * @param   {string} [locale] - Locale in which to get the label
   * @returns {string} Label
   */
  function getEventLoginUsernameLabel(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).login_username_label || "";
  }

  /**
   * Get the login username legend of an event
   * @param   {BraindateEvent} event - Event to get the legend from
   * @param   {string} [locale] - Locale in which to get the legend
   * @returns {string} Legend
   */
  function getEventLoginUsernameLegend(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).login_username_legend || "";
  }

  /**
   * Get the login username placeholder of an event
   * @param   {BraindateEvent} event - Event to get the placeholder from
   * @param   {string} [locale] - Locale in which to get the placeholder
   * @returns {string} Placeholder
   */
  function getEventLoginUsernamePlaceholder(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).login_username_placeholder || "";
  }

  /**
   * Get the login username text of an event
   * @param   {BraindateEvent} event - Event to get the text from
   * @param   {string} [locale] - Locale in which to get the text
   * @returns {string} Text
   */
  function getEventLoginUsernameText(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).login_username_text || "";
  }

  /**
   * Get the login password label of an event
   * @param   {BraindateEvent} event - Event to get the label from
   * @param   {string} [locale] - Locale in which to get the label
   * @returns {string} Label
   */
  function getEventLoginPasswordLabel(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).login_password_label || "";
  }

  /**
   * Get the login password legend of an event
   * @param   {BraindateEvent} event - Event to get the legend from
   * @param   {string} [locale] - Locale in which to get the legend
   * @returns {string} Legend
   */
  function getEventLoginPasswordLegend(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).login_password_legend || "";
  }

  /**
   * Get the login password placeholder of an event
   * @param   {BraindateEvent} event - Event to get the placeholder from
   * @param   {string} [locale] - Locale in which to get the placeholder
   * @returns {string} Placeholder
   */
  function getEventLoginPasswordPlaceholder(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).login_password_placeholder || "";
  }

  /**
   * Get the login password text of an event
   * @param   {BraindateEvent} event - Event to get the text from
   * @param   {string} [locale] - Locale in which to get the text
   * @returns {string} Text
   */
  function getEventLoginPasswordText(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).login_password_text || "";
  }

  /**
   * Get the signup code label of an event
   * @param   {BraindateEvent} event - Event to get the label from
   * @param   {string} [locale] - Locale in which to get the label
   * @returns {string} Label
   */
  function getEventSignupCodeLabel(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).signup_code_label || "";
  }

  /**
   * Get the signup code legend of an event
   * @param   {BraindateEvent} event - Event to get the legend from
   * @param   {string} [locale] - Locale in which to get the legend
   * @returns {string} Legend
   */
  function getEventSignupCodeLegend(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).signup_code_legend || "";
  }

  /**
   * Get the signup code placeholder of an event
   * @param   {BraindateEvent} event - Event to get the placeholder from
   * @param   {string} [locale] - Locale in which to get the placeholder
   * @returns {string} Placeholder
   */
  function getEventSignupCodePlaceholder(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).signup_code_placeholder || "";
  }

  /**
   * Get the signup code text of an event
   * @param   {BraindateEvent} event - Event to get the text from
   * @param   {string} [locale] - Locale in which to get the text
   * @returns {string} Text
   */
  function getEventSignupCodeText(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).signup_code_text || "";
  }

  /**
   * Get the profile position label of an event
   * @param   {BraindateEvent} event - Event to get the label from
   * @param   {string} [locale] - Locale in which to get the label
   * @returns {string} Label
   */
  function getEventProfilePositionLabel(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).profile_position_label || "";
  }

  /**
   * Get the profile position placeholder of an event
   * @param   {BraindateEvent} event - Event to get the placeholder from
   * @param   {string} [locale] - Locale in which to get the placeholder
   * @returns {string} Placeholder
   */
  function getEventProfilePositionPlaceholder(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).profile_position_placeholder || "";
  }

  /**
   * Get the profile company label of an event
   * @param   {BraindateEvent} event - Event to get the label from
   * @param   {string} [locale] - Locale in which to get the label
   * @returns {string} Label
   */
  function getEventProfileCompanyLabel(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).profile_company_label || "";
  }

  /**
   * Get the profile company placeholder of an event
   * @param   {BraindateEvent} event - Event to get the placeholder from
   * @param   {string} [locale] - Locale in which to get the placeholder
   * @returns {string} Placeholder
   */
  function getEventProfileCompanyPlaceholder(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).profile_company_placeholder || "";
  }

  /**
   * Get the retrieval extra field label of an event
   * @param   {BraindateEvent} event - Event to get the label from
   * @param   {string} [locale] - Locale in which to get the label
   * @returns {string} Label
   */
  function getEventRetrievalExtraFieldLabel(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).retrieval_extra_field_label || "";
  }

  /**
   * Get the retrieval extra field legend of an event
   * @param   {BraindateEvent} event - Event to get the legend from
   * @param   {string} [locale] - Locale in which to get the legend
   * @returns {string} Legend
   */
  function getEventRetrievalExtraFieldLegend(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).retrieval_extra_field_legend || "";
  }

  /**
   * Get the retrieval extra field placeholder of an event
   * @param   {BraindateEvent} event - Event to get the placeholder from
   * @param   {string} [locale] - Locale in which to get the placeholder
   * @returns {string} Placeholder
   */
  function getEventRetrievalExtraFieldPlaceholder(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).retrieval_extra_field_placeholder || "";
  }

  /**
   * Get the topic creation group label of an event
   * @param   {BraindateEvent} event - Event to get the label from
   * @param   {string} [locale] - Locale in which to get the label
   * @returns {string} Label
   */
  function getEventTopicCreationGroupLabel(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).topic_creation_group_label || "";
  }

  /**
   * Get the topic title placeholder of an event
   * @param   {BraindateEvent} event - Event to get the placeholder from
   * @param   {string} [locale] - Locale in which to get the placeholder
   * @returns {string} Placeholder
   */
  function getEventTopicTitlePlaceholder(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).topic_title_placeholder || "";
  }

  /**
   * Get the topic group title placeholder of an event
   * @param   {BraindateEvent} event - Event to get the placeholder from
   * @param   {string} [locale] - Locale in which to get the placeholder
   * @returns {string} Placeholder
   */
  function getEventTopicGroupTitlePlaceholder(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).topic_group_title_placeholder || "";
  }

  /**
   * Get the topic guidance title of an event
   * @param   {BraindateEvent} event - Event to get the title from
   * @param   {string} [locale] - Locale in which to get the title
   * @returns {string} Title
   */
  function getEventTopicGuidanceTitle(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).topic_guidance_title || "";
  }

  /**
   * Get the topic guidance description of an event
   * @param   {BraindateEvent} event - Event to get the description from
   * @param   {string} [locale] - Locale in which to get the description
   * @returns {string} Description
   */
  function getEventTopicGuidanceDescription(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).topic_guidance_description || "";
  }

  /**
   * Get the topic context title of an event
   * @param   {BraindateEvent} event - Event to get the title from
   * @param   {string} [locale] - Locale in which to get the title
   * @returns {string} Title
   */
  function getEventTopicContextTitle(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).topic_how_title || "";
  }

  /**
   * Get the topic context placeholder of an event
   * @param   {BraindateEvent} event - Event to get the placeholder from
   * @param   {string} [locale] - Locale in which to get the placeholder
   * @returns {string} Placeholder
   */
  function getEventTopicContextPlaceholder(event, locale) {
    return (getEventCustomCopy(event, locale) || {}).topic_how_placeholder || "";
  }

  /*
   |------------------------------------------------------------------------------
   | Advanced getters - Authentication config
   |------------------------------------------------------------------------------
   */

  /**
   * Get the username field type of an event
   * @param   {BraindateEventPublicData} event - Event to get the type from
   * @returns {string} Type
   */
  function getEventUsernameFieldType(event) {
    var _ref = getEventAuthenticationConfig(event) || {},
      username = _ref.username;
    if ((0, _type.isObject)(username)) {
      // $FlowIssue: `username` is an object
      return username.type;
    }
  }

  /**
   * Get the password field type of an event
   * @param   {BraindateEventPublicData} event - Event to get the type from
   * @returns {string} Type
   */
  function getEventPasswordFieldType(event) {
    var _ref2 = getEventAuthenticationConfig(event) || {},
      password = _ref2.password;
    if ((0, _type.isObject)(password)) {
      // $FlowIssue: `password` is an object
      return password.type;
    }
  }

  /**
   * Check if the username retrieval is allowed for an event
   * @param   {BraindateEventPublicData} event - Event to check
   * @returns {boolean} True if it is
   */
  function isEventUsernameRetrievalAllowed(event) {
    return !!(getEventAuthenticationConfig(event) || {}).retrieval_allowed;
  }

  /*
   |------------------------------------------------------------------------------
   | Advanced getters - URLs
   |------------------------------------------------------------------------------
   */

  /**
   * Get the URL to fetch the users of an event
   * @param   {BraindateEvent} event - Event to get the URL from
   * @returns {string} URL
   */
  function getEventUsersURL(event) {
    return getEventURLs(event).users;
  }

  /**
   * Get the URL to fetch the authenticated user's braindates of an event
   * @param   {BraindateEvent} event - Event to get the URL from
   * @returns {string} URL
   */
  function getEventBraindatesURL(event) {
    return getEventURLs(event).braindates;
  }

  /**
   * Get the URL to fetch the topics of an event
   * @param   {BraindateEvent} event - Event to get the URL from
   * @returns {string} URL
   */
  function getEventTopicsURL(event) {
    return getEventURLs(event).topics;
  }

  /**
   * Get the URL to fetch the filters of an event
   * @param   {BraindateEvent} event - Event to get the URL from
   * @returns {string} URL
   */
  function getEventFiltersURL(event) {
    return getEventURLs(event).filters;
  }

  /**
   * Get the URL to fetch the FAQ data of an event
   * @param   {BraindateEvent} event - Event to get the URL from
   * @returns {string} URL
   */
  function getEventFAQURL(event) {
    return getEventURLs(event).faq;
  }

  /**
   * Get the socket URL an event
   * @param   {BraindateEvent} event - Event to get the URL from
   * @returns {string} URL
   */
  function getEventSocketURL(event) {
    return getEventURLs(event).socket;
  }

  /*
   |------------------------------------------------------------------------------
   | Advanced getters - Sponsors
   |------------------------------------------------------------------------------
   */

  /**
   * Get the splash sponsors of an event
   * @param   {BraindateEvent} event - Event to get the sponsors from
   * @returns {Array<BraindateEventSponsor>} Sponsors
   */
  function getEventSplashSponsors(event) {
    return (getEventSponsors(event) || {}).splash || [];
  }

  /**
   * Get the navbar sponsors of an event
   * @param   {BraindateEvent} event - Event to get the sponsors from
   * @returns {Array<BraindateEventSponsor>} Sponsors
   */
  function getEventNavbarSponsors(event) {
    return (getEventSponsors(event) || {}).navbar || [];
  }

  /**
   * Get the marketplace header sponsors of an event
   * @param   {BraindateEvent} event - Event to get the sponsors from
   * @returns {Array<BraindateEventSponsor>} Sponsors
   */
  function getEventMarketplaceHeaderSponsors(event) {
    return (getEventSponsors(event) || {})["marketplace-header"] || [];
  }

  /**
   * Get the marketplace card sponsors of an event
   * @param   {BraindateEvent} event - Event to get the sponsors from
   * @returns {Array<BraindateEventSponsor>} Sponsors
   */
  function getEventMarketplaceCardSponsors(event) {
    return (getEventSponsors(event) || {})["marketplace-card"] || [];
  }

  /**
   * Get the marketplace footer sponsors of an event
   * @param   {BraindateEvent} event - Event to get the sponsors from
   * @returns {Array<BraindateEventSponsor>} Sponsors
   */
  function getEventMarketplaceFooterSponsors(event) {
    return (getEventSponsors(event) || {})["marketplace-footer"] || [];
  }

  /**
   * Get the video-call sponsors of an event
   * @param   {BraindateEvent} event - Event to get the sponsors from
   * @returns {Array<BraindateEventSponsor>} Sponsors
   */
  function getEventVideoCallSponsors(event) {
    return (getEventSponsors(event) || {})["video-call"] || [];
  }

  /**
   * Get the email sponsors of an event
   * @param   {BraindateEvent} event - Event to get the sponsors from
   * @returns {Array<BraindateEventSponsor>} Sponsors
   */
  function getEventEmailSponsors(event) {
    return (getEventSponsors(event) || {}).email || [];
  }

  /**
   * Get the cheers sponsors of an event
   * @param   {BraindateEvent} event - Event to get the sponsors from
   * @returns {Array<BraindateEventSponsor>} Sponsors
   */
  function getEventCheerModalSponsors(event) {
    return (getEventSponsors(event) || {})["cheer-modal"] || [];
  }

  /**
   * Get the default event market default sort filter
   * @param   {BraindateEvent} event - Event to get the default sort filter from
   * @returns {string} Default sort filter
   */
  function getEventDefaultMarketOrdering(event) {
    var defaultMarketplaceSort = event.default_marketplace_sort;
    if (defaultMarketplaceSort) {
      (0, _assert.assertString)(defaultMarketplaceSort);
      return defaultMarketplaceSort;
    }
    return null;
  }
});