/* @flow */

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    position: 'relative',

    // IE11: prevents the Avatar from shrinking without using problematic
    // `flex:1`
    flexShrink: 0,
    border: ({ isHost, theme }: ThemeFunction<{ isHost: boolean }>) =>
      isHost && `solid 1px ${theme.accentColor}`,
    borderRadius: '50%',
    textAlign: 'center',
  },
  badge: {
    position: 'absolute',
  },
  userBadge: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  'badge-xsmall': {
    top: -4,
    right: -2,
  },
  'badge-small': {
    top: 0,
    right: 2,
  },
  'badge-medium': {
    top: 0,
    right: 0,
  },
  'badge-large': {
    top: 3,
    right: 3,
  },
  'badge-xlarge': {
    top: 3,
    right: 3,
  },
  'badge-xxlarge': {
    top: 3,
    right: 3,
  },
  'badge-mobilePeopleCardSize': {
    top: 3,
    right: 3,
  },
  'badge-peopleCardSize': {
    top: 3,
    right: 3,
  },
  // User badge classes
  'user-badge-xsmall': {
    bottom: -4,
    left: -2,
  },
  'user-badge-small': {
    bottom: -2,
    left: -2,
  },
  'user-badge-medium': {
    bottom: 0,
    left: 0,
  },
  'user-badge-large': {
    bottom: 3,
    left: 3,
  },
  'user-badge-xlarge': {
    bottom: 3,
    left: 3,
  },
  'user-badge-xxlarge': {
    bottom: 3,
    left: 3,
  },
  'user-badge-mobilePeopleCardSize': {
    bottom: 3,
    left: 3,
  },
  'user-badge-peopleCardSize': {
    bottom: 3,
    left: 3,
  },
};
