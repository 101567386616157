/* @flow */

import { useMediaQuery } from '@mui/material';

import { tablet } from '@braindate/ui/lib/token/mediaQuery';

import useReduxState from 'src/shared/app/base/hook/useReduxState';
import useRouteMatch from 'src/shared/app/base/route/hook/useRouteMatch';
import { getRouteBackgroundImage } from 'src/shared/app/base/route/util/routeUtils';
import useEventBrandingCustomization from 'src/shared/domain/event/hook/useEventBrandingCustomization';

const useRouteBackgroundImage = (): string | null => {
  const route = useRouteMatch();
  const state = useReduxState();
  const brandingCustomization = useEventBrandingCustomization();

  const isPhablet = useMediaQuery(tablet);
  if (!isPhablet) return null;

  const backgroundImage = getRouteBackgroundImage(
    route,
    state,
    brandingCustomization,
  );
  return backgroundImage;
};

export default useRouteBackgroundImage;
