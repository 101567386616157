/* @flow */

import { getBraindateURL } from '@braindate/domain/lib/braindate/util';
import type { Cheer } from '@braindate/domain/lib/cheer/type';
import {
  getCheerBraindate,
  getCheerCategory,
  getCheerId,
  getCheerMarkAsReadUrl,
  getCheerMessage,
  getCheerRecipient,
  getCheerSendGift,
} from '@braindate/domain/lib/cheer/util/cheerGetters';
import type { User } from '@braindate/domain/lib/user/type';
import { getUserURL } from '@braindate/domain/lib/user/util';

import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';
import { apiRoot } from 'src/shared/app/base/api/apiRoot';
import { defaultLimitPages } from 'src/shared/app/base/api/constant/apiConstant';
import type {
  ApiListResult,
  EndpointSelector,
  UseLazyQuery,
  UseMutation,
  UseQuery,
} from 'src/shared/app/base/api/type/apiQueryType';
import { infiniteLoadingSettings } from 'src/shared/app/base/api/utils/endpointUtils';
import {
  cheersEndpoint,
  sendCheerEndpoint,
} from 'src/shared/app/base/settings/endpointSettings';

type Api = {
  endpoints: {
    getReceivedCheers: EndpointSelector<Object, ApiListResult<Cheer>>,
    getSentCheers: EndpointSelector<Object, ApiListResult<Cheer>>,
  },

  useGetReceivedCheersQuery: UseQuery<ApiListResult<Cheer>>,
  useGetSentCheersQuery: UseQuery<ApiListResult<Cheer>>,
  useLazyGetReceivedCheersQuery: UseLazyQuery<ApiListResult<Cheer>, Object>,
  useLazyGetSentCheersQuery: UseLazyQuery<ApiListResult<Cheer>, Object>,

  useGetPossibleCheerUsersQuery: UseQuery<ApiListResult<User>>,
  useSendCheerMutation: UseMutation<Cheer, Cheer>,
  useCheerMarkAsReadMutation: UseMutation<Cheer, Cheer>,
};

const cheersInfiniteLoadingSettings = infiniteLoadingSettings<Cheer>({
  tagType: TAG_TYPE.CHEER,
  idGetter: getCheerId,
});

type CheerEndpointParams = {
  page?: number,
  limit?: number,
  query?: string,
};

const extendedApi: Api = apiRoot.injectEndpoints({
  endpoints: (builder) => ({
    getReceivedCheers: builder.query({
      query: ({
        page = 1,
        limit = defaultLimitPages,
        query,
      }: CheerEndpointParams = {}) => ({
        url: cheersEndpoint(),
        params: {
          received: true,
          limit,
          offset: (page - 1) * limit,
          ...(query ? { q: query } : {}),
        },
      }),
      ...cheersInfiniteLoadingSettings,
    }),
    getSentCheers: builder.query({
      query: ({
        page = 1,
        limit = defaultLimitPages,
        query,
      }: CheerEndpointParams = {}) => ({
        url: cheersEndpoint(),
        params: {
          sent: true,
          limit,
          offset: (page - 1) * limit,
          ...(query ? { q: query } : {}),
        },
      }),
      ...cheersInfiniteLoadingSettings,
    }),

    sendCheer: builder.mutation({
      query: (cheer: Cheer) => {
        const category = getCheerCategory(cheer);
        const message = getCheerMessage(cheer);
        const forBraindate = getCheerBraindate(cheer);
        const recipient = getCheerRecipient(cheer);
        const sendGift = getCheerSendGift(cheer);

        return {
          url: sendCheerEndpoint(),
          method: 'POST',
          params: {
            category,
            message,
            braindate: getBraindateURL(forBraindate),
            recipient: getUserURL(recipient),
            send_gift: !!sendGift,
          },
        };
      },
      invalidatesTags: [{ type: TAG_TYPE.CHEER, id: 'LIST' }],
    }),
    cheerMarkAsRead: builder.mutation({
      query: (cheer: Cheer) => ({
        url: getCheerMarkAsReadUrl(cheer),
        method: 'POST',
        params: {
          id: getCheerId(cheer),
        },
      }),
      invalidatesTags: (result, _, { id }) => [{ type: TAG_TYPE.CHEER, id }],
    }),
  }),
});

export const {
  useGetReceivedCheersQuery,
  useGetSentCheersQuery,
  useLazyGetReceivedCheersQuery,
  useLazyGetSentCheersQuery,

  useGetPossibleCheerUsersQuery,
  useSendCheerMutation,
  useCheerMarkAsReadMutation,

  endpoints,
} = extendedApi;

export const { getReceivedCheers, getSentCheers } = endpoints;
