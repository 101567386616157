(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./eventGetters", "../setting", "@braindate/util/lib/assert"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./eventGetters"), require("../setting"), require("@braindate/util/lib/assert"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.eventGetters, global.setting, global.assert);
    global.eventCheckers = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _eventGetters, _setting, _assert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEventContextHybrid = isEventContextHybrid;
  _exports.isEventContextOnSite = isEventContextOnSite;
  _exports.isEventContextVirtual = isEventContextVirtual;
  _exports.isEventOccurenceLimited = isEventOccurenceLimited;
  _exports.isEventOccurrenceOngoing = isEventOccurrenceOngoing;
  _exports.isEventUserGuidanceEnabled = isEventUserGuidanceEnabled;
  _exports.isEventUserProfileFieldIsRequired = isEventUserProfileFieldIsRequired;
  _exports.isGroupMessageModalEnabled = isGroupMessageModalEnabled;
  _exports.isManualAvailabilityEnabled = isManualAvailabilityEnabled;
  _exports.isSelfCheckinEnabled = isSelfCheckinEnabled;
  /**
   * Check if an event is a hybrid context
   * @param   {BraindateEvent} event - Event to check
   * @returns {boolean} True if it is
   */
  function isEventContextHybrid(event) {
    var context = (0, _eventGetters.getEventContext)(event);
    return context === _setting.eventContextHybrid;
  }

  /**
   * Check if an event is a on-site context
   * @param   {BraindateEvent} event - Event to check
   * @returns {boolean} True if it is
   */
  function isEventContextOnSite(event) {
    var context = (0, _eventGetters.getEventContext)(event);
    return context === _setting.eventContextOnSite;
  }

  /**
   * Check if an event is a virtual context
   * @param   {BraindateEvent} event - Event to check
   * @returns {boolean} True if it is
   */
  function isEventContextVirtual(event) {
    var context = (0, _eventGetters.getEventContext)(event);
    return context === _setting.eventContextVirtual;
  }

  /**
   * Check if an event is a limited event
   * @param   {BraindateEvent} event - Event to check
   * @returns {boolean} True if it is
   */
  function isEventOccurenceLimited(event) {
    var occurrence = (0, _eventGetters.getEventOccurrence)(event);
    return occurrence === _setting.eventOccurenceLimited;
  }

  /**
   * Check if an event is an ongoing event
   * @param   {BraindateEvent} event - Event to check
   * @returns {boolean} True if it is
   */
  function isEventOccurrenceOngoing(event) {
    var occurrence = (0, _eventGetters.getEventOccurrence)(event);
    return occurrence === _setting.eventOccurenceOngoing;
  }

  /**
   * Check if a user profile field is required
   * @param   {BraindateEvent} event - Event to check
   * @param   {BraindateEventProfileRequiredField} field - Field to check
   * @returns {boolean} True if it is
   */
  function isEventUserProfileFieldIsRequired(event, field) {
    var requiredFields = (0, _eventGetters.getEventProfileRequiredFields)(event);
    (0, _assert.assertString)(field);
    if (!requiredFields) return false;
    return requiredFields.includes(field);
  }

  /**
   * Check if the event's guidance is enabled
   * @param   {BraindateEvent} event - Event to check
   * @returns {boolean} True if it is
   */
  function isEventUserGuidanceEnabled(event) {
    var userGuidance = event.user_guidance;
    return !!userGuidance;
  }
  function isGroupMessageModalEnabled(event) {
    var groupMessageModalEnabled = event.group_message_modal;
    return !!groupMessageModalEnabled;
  }
  function isManualAvailabilityEnabled(event) {
    var manualAvailability = event.manual_availability;
    return !!manualAvailability;
  }
  function isSelfCheckinEnabled(event) {
    var selfCheckin = event.self_checkin;
    return !!selfCheckin;
  }
});