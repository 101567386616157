/* @flow */

import { createReducer } from 'src/shared/core/util/reducerUtils';
import * as types from 'src/shared/domain/event/action/eventActionTypes';

const initialState = {};

const _patch = (state: Object, action: Object) => {
  const { result: id, entities: data } = action;

  const event = state[id];

  if (event) {
    return {
      ...state,
      [id]: {
        ...event,
        ...data,
      },
    };
  }
  return {
    ...state,
    [id]: data,
  };
};

const _clean = (state: Object, action: Object) => {
  const { result: id } = action;

  return {
    ...state,
    [id]: null,
  };
};

export default createReducer(initialState, {
  [types.FETCH_PUBLIC_EVENT_SUCCESS]: _patch,
  [types.FETCH_EVENT_SUCCESS]: _clean,
});
