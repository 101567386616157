(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "@braindate/util/lib/assert", "./topicCheckers"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("@braindate/util/lib/assert"), require("./topicCheckers"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.assert, global.topicCheckers);
    global.topicAssertions = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _assert, _topicCheckers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertGroupTopic = assertGroupTopic;
  _exports.assertGroupTopicDate = assertGroupTopicDate;
  _exports.assertGroupTopicParticipants = assertGroupTopicParticipants;
  _exports.assertInspirationTopic = assertInspirationTopic;
  _exports.assertOneOnOneTopic = assertOneOnOneTopic;
  _exports.assertTopic = assertTopic;
  _exports.assertTopicAuthor = assertTopicAuthor;
  _exports.assertTopicCohosts = assertTopicCohosts;
  _exports.assertTopicCustomIcon = assertTopicCustomIcon;
  _exports.assertTopicCustomizationData = assertTopicCustomizationData;
  _exports.assertTopicLocations = assertTopicLocations;
  _exports.assertTopicStatistics = assertTopicStatistics;
  _exports.assertTopicURLs = assertTopicURLs;
  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertTopic(value) {
    (0, _assert.assertObject)(value, "topic");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertInspirationTopic(value) {
    (0, _assert.assertObject)(value, "inspirationTopic");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertGroupTopic(value) {
    (0, _assert.baseAssert)(_topicCheckers.isGroupTopic, "must be a group topic", value, "topic");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertOneOnOneTopic(value) {
    (0, _assert.baseAssert)(_topicCheckers.isOneOnOneTopic, "must be a one-on-one topic", value, "topic.kind");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertTopicAuthor(value) {
    (0, _assert.assertObject)(value, "topic.author");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertTopicURLs(value) {
    (0, _assert.assertObject)(value, "topic.urls");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertTopicStatistics(value) {
    (0, _assert.assertObject)(value, "topic.statistics");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertTopicCustomizationData(value) {
    (0, _assert.assertObject)(value, "topic.customization");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertTopicCustomIcon(value) {
    (0, _assert.assertObject)(value, "topic.customization.picture");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertGroupTopicDate(value) {
    (0, _assert.assertObject)(value, "topic.date");
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertGroupTopicParticipants(value) {
    (0, _assert.assertArray)(value, "topic.participants");
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertTopicLocations(value) {
    (0, _assert.assertArray)(value, "topic.locations");
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertTopicCohosts(value) {
    (0, _assert.assertArray)(value, "topic.cohosts");
  }
});