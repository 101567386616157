/* @flow */

import { DateTime } from 'luxon';

import type { GroupTopic, Topic } from '@braindate/domain/lib/topic/type';
import {
  getGroupTopic,
  getGroupTopicEndTime,
  getGroupTopicParticipants,
  getGroupTopicStartTime,
  getTopicAuthor,
  isGroupTopic,
} from '@braindate/domain/lib/topic/util';
import type { User } from '@braindate/domain/lib/user/type';
import { getUserId } from '@braindate/domain/lib/user/util';

import { isDateInInterval } from 'src/shared/app/base/date/util/dateUtils';
import { NOTIFICATION_COMPONENT_KEYS } from 'src/shared/app/notification/constant/notificationComponentsMap';
import {
  notificationErrorLevel,
  notificationTopType,
} from 'src/shared/app/notification/constant/notificationConstants';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import { sortParticipantsByFirstName } from 'src/shared/domain/conversation/util/conversationUtil';

export function topicHasDate(topic: Topic) {
  if (!isGroupTopic(topic)) return false;

  // 'date' can be null if group braindate was cancelled
  return 'date' in topic && getGroupTopic(topic).date !== null;
}

/**
 * Check if the topic is past
 * @param  {Topic} topic - Topic to check
 * @return {boolean} True if the topic is in the past
 */
export function isTopicPast(topic: Topic): boolean {
  const hasDate = topicHasDate(topic);
  if (!hasDate) return false;

  const time = getGroupTopicEndTime(getGroupTopic(topic));
  const dateTime = DateTime.fromISO(time);

  return DateTime.local().ts > dateTime.ts;
}

/**
 * Check if the topic is live
 * @param  {Topic} topic - Topic to check
 * @return {boolean} True if the topic is live
 */
export function isTopicLive(topic: Topic): boolean {
  const hasDate = topicHasDate(topic);
  if (!hasDate) return false;

  const now = DateTime.now();

  const start: string = getGroupTopicStartTime(getGroupTopic(topic));
  const end: string = getGroupTopicEndTime(getGroupTopic(topic));

  return isDateInInterval(now, { start_time: start, end_time: end });
}

export function createTopicNotJoinableNotification(
  topic: Topic,
  disabledReason: string,
): Notification<any> {
  return {
    id: 'topic-joining-error',
    level: notificationErrorLevel,
    type: notificationTopType,
    componentKey: NOTIFICATION_COMPONENT_KEYS.TOPIC_NOT_JOINABLE_NOTIFICATION,
    data: { disabledReason, topic },
  };
}

/**
 * Get the participants of the topic, sorted by the topic author first
 * and the other participants alphabetically by first names.
 * @param  {GroupTopic} topic - Topic to get the participants from
 * @return {Array<User>} Sorted participants by host first then by firstnames
 *
 * @throws Will throw an exception if parameter `conversation` is not an object
 */
export function getGroupTopicParticipantsByTopicAuthorFirst(
  topic: GroupTopic,
): Array<User> {
  if (!isGroupTopic) throw new Error('Only a group topic has participants');
  const host = getTopicAuthor(topic);
  const allParticipants = getGroupTopicParticipants(topic) || [];
  const participants = allParticipants.filter(
    (user) => getUserId(user) !== getUserId(host),
  );

  const sortedParticipants = sortParticipantsByFirstName(participants);
  return [host, ...sortedParticipants];
}
