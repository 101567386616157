/* @flow */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasBottomBar: false,
  withMobileNav: false,
};

const navSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    registerBottomBar(state) {
      state.hasBottomBar = true;
    },
    unregisterBottomBar(state) {
      state.hasBottomBar = false;
    },
    registerMobileNav(state) {
      state.withMobileNav = true;
    },
    unRegisterMobileNav(state) {
      state.withMobileNav = true;
    },
  },
});

export const {
  registerBottomBar,
  unregisterBottomBar,
  registerMobileNav,
  unRegisterMobileNav,
} = navSlice.actions;

export default navSlice.reducer;
