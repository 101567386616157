/* @flow */

import { aaDarkGrey, blueGrey, white } from '@braindate/ui/lib/token/palette';
import { highlightedInputFontFamily } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
  },
  input: {
    WebkitFontSmoothing: 'antialiased',
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,

    '&:focus': {
      borderColor: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    },

    '&::placeholder': {
      color: aaDarkGrey,

      WebkitFontSmoothing: 'antialiased',
    },
  },
  'input-isHighlighted': {
    padding: '0.5em',

    backgroundColor: white,
    borderRadius: 4,
    border: 'none',
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,

    fontFamily: highlightedInputFontFamily,
    fontSize: 22,
    lineHeight: 1.1,

    '&::placeholder': {
      color: '#747476',

      fontStyle: 'normal',
      WebkitFontSmoothing: 'antialiased',
    },
  },
  'input-isInvalid': {
    borderColor: ({ theme }: ThemeFunction<{}>) =>
      `${theme.errorMessageColor} !important`,
  },
  'input-noBorder': {
    borderColor: 'rgb(0, 0, 0, 0)',
  },
  'input-isDarkText': {
    color: `${blueGrey} !important`,
  },
  maxLengthCtn: {
    position: 'absolute',
    top: '100%',
    right: 0,
  },
  asterisk: {
    color: ({ theme }: ThemeFunction<{}>) => theme.errorMessageColor,
  },
  helper: {
    display: 'block',

    marginTop: 4,

    color: ({ theme }: ThemeFunction<{}>) => theme.helperMessageColor,

    fontSize: 12,
    fontStyle: 'italic',
    textAlign: 'right',
    WebkitFontSmoothing: 'antialiased',
  },
};
