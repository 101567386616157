/* @flow */

export default {
  root: {
    width: 92,
    height: 50,
    fill: ({ color }: Object) => color,
  },
  'root-isSmall': {
    height: 30,
  },
  'root-isExtraSmall': {
    width: 30,
    height: 16,
  },
};
