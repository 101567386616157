/* @flow */

import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  '@keyframes enter': {
    from: {
      opacity: 0,
      transform: 'translateY(-20px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
  icon: {
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    fontSize: `${rem(50, baseFontSize)} !important`,
    animation: '$enter 450ms ease',
    animationFillMode: 'both',
    animationDelay: '600ms',
  },
};
