/* @flow */

import { MoreHoriz } from '@mui/icons-material';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import withForwardRef from '@braindate/util/lib/react/hoc/withForwardRef';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/ui/component/menu/l10n/menuL10n';

import styles from './OptionsMenuButton.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  id?: string,
  forwardedRef?: any,
  className?: string,
  onClick: (e: Event) => void,
};

const OptionsMenuButton = ({ id, forwardedRef, className, onClick }: Props) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <button
      id={id}
      ref={forwardedRef}
      onClick={onClick}
      aria-label={intl.formatMessage(messages.moreOptions)}
      className={classNames(classes.button, className)}
      type="button"
      data-testid="options-menu-button"
    >
      <MoreHoriz />
    </button>
  );
};

export default withForwardRef(OptionsMenuButton);
