/* @flow */

import { em } from 'polished';

import { lap } from '@braindate/ui/lib/token/mediaQuery';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

import { getReadableColor } from 'plugin/light/util/lightUtils';

export default {
  root: {
    width: '100%',
    height: 96,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    color: ({ theme }: ThemeFunction<{}>) =>
      getReadableColor(theme.accentColor),

    [lap]: {
      minWidth: 216,
      width: 216,
      height: 'unset',
      minHeight: '100%',
    },
  },
  date: {
    fontWeight: 900,
    fontSize: em(30),
    margin: 0,
    color: 'currentColor',
  },
  time: {
    fontWeight: 600,
    fontSize: em(20),
    margin: [0, 0, 5, 0],
    color: 'currentColor',
  },
  location: {
    justifyContent: 'center',
  },
};
