(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./userGetters", "./userUtils"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./userGetters"), require("./userUtils"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.userGetters, global.userUtils);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _userGetters, _userUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_userGetters).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _userGetters[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _userGetters[key];
      }
    });
  });
  Object.keys(_userUtils).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _userUtils[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _userUtils[key];
      }
    });
  });
});