/* @flow */

import type { DrawerMenuItemType } from 'src/shared/app/nav/component/drawermenu/DrawerMenu';
import useIsPathActive from 'src/shared/core/hooks/useIsPathActive';
import MenuPopoverItem from 'src/shared/ui/component/menu/MenuPopoverItem';

type Props = {
  menuItem: DrawerMenuItemType,
};

const DesktopDrawerMenuItem = ({ menuItem }: Props) => {
  const isActive = useIsPathActive(menuItem.to || '');

  return (
    <MenuPopoverItem
      {...menuItem}
      to={menuItem.to ? menuItem.to : undefined}
      isActive={isActive}
    />
  );
};

export default DesktopDrawerMenuItem;
