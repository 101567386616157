/* @flow */

import { tablet } from '@braindate/ui/lib/token/mediaQuery';

export default {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '12px',
    marginTop: '15px',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    gap: '9px',
    minWidth: 0,
  },
  right: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    [tablet]: {
      justifyContent: 'flex-end',
    },
  },
  avatarQueue: {
    display: 'none',

    [tablet]: {
      display: 'flex',
    },
  },
};
