/* @flow */

export const virtualBraindateFeature = 'video-call';
export const virtualBraindateConnectSrc =
  'https://ecs.us1.twilio.com wss://global.vss.twilio.com wss://sdkgw.us1.twilio.com';

export const virtualBraindateBeforeDuration = 5 * 60; // in seconds
export const virtualBraindateExtendedDuration = 10 * 60; // in seconds
export const takeOverMinutesToBraindate = 3;

export const feedbackKeys = {
  insight_check: 'useful_insights',
  valuable_check: 'feeling_experience_knowledge_valuable',
  profession_check: 'professionnel_connection',
  professional_personnal_check: 'professional_personal_connection',
  heard_and_supported_check: 'heard_and_supported',
  nobenefits_check: 'no_benefits',
  nobenefits_more: 'no_benefits_message',
  other_check: 'other',
  other_more: 'other_message',
  more: 'more',
};
