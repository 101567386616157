/* @flow */

import { createElement } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import withForwardRef from '@braindate/util/lib/react/hoc/withForwardRef';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './Heading.style';

type Props = {
  children: Node,
  level?: number,
  layoutClass?: string,
  component?: string,
  forwardedRef: { current: HTMLElement | null },
};

const useStyles = createUseThemeStyles(styles);

const Heading = ({
  children,
  level: propLevel,
  layoutClass,
  component,
  forwardedRef,
  ...props
}: Props): Node => {
  const defaultLevel = 1;

  let level = propLevel ? parseInt(propLevel, 10) : defaultLevel;

  if (level < 1 || level > 6) {
    level = defaultLevel;
  }

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */

  const classes = useStyles({ level });

  const rootClasses = classNames(classes.root, layoutClass);

  /*
   |----------------------------------------------------------------------------
   | Elements
   |---------------------------------------------------------------------------
   */

  return createElement(
    component || `h${level}`,
    { ...props, className: rootClasses, ref: forwardedRef },
    children,
  );
};

export default withForwardRef(Heading);
