/* @flow */

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import type { Cheer } from '@braindate/domain/lib/cheer/type';
import { getEventPlugins } from '@braindate/domain/lib/event/util';
import { isGroupTopic } from '@braindate/domain/lib/topic/util/';
import type { User } from '@braindate/domain/lib/user/type';

import { getUser } from 'src/shared/app/base/api/endpoint/usersEndpoint';
import { getCheckedInOtherParticipants } from 'src/shared/app/check-in/util/checkInUtil';
import {
  buildCheerRecipientNotification,
  buildNewCheerModalNotification,
  buildPostBraindateCheerModalNotification,
} from 'src/shared/app/cheer/notification/cheerNotification';
import { cheerPlugin as cheerPluginKey } from 'src/shared/app/feature/settings/featureSettings';
import { queueNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import type { GetState } from 'src/shared/core/type/reduxTypes';
import { getBraindateTopic } from 'src/shared/domain/braindate/util/braindateUtil';
import { getEventSafely } from 'src/shared/domain/event/selector/eventSelectors';

export function showNewCheerModal(user: User, braindate?: Braindate) {
  return (dispatch: any, getState: GetState) => {
    const event = getEventSafely(getState());

    const cheerPlugin = getEventPlugins(event)[cheerPluginKey];
    if (!cheerPlugin) return null;

    return dispatch(
      queueNotification(buildNewCheerModalNotification(user, braindate)),
    );
  };
}

export function showPostBraindateCheerModal(braindate: Braindate) {
  return (dispatch: any, getState: GetState) => {
    const event = getEventSafely(getState());
    const self = getUser.select('me')(getState()).data;

    const cheerPlugin = getEventPlugins(event)[cheerPluginKey];
    if (!cheerPlugin) return null;

    const topic = getBraindateTopic(braindate);
    if (
      isGroupTopic(topic) &&
      getCheckedInOtherParticipants(braindate, self).length === 0
    ) {
      return null;
    }

    return dispatch(
      queueNotification(buildPostBraindateCheerModalNotification(braindate)),
    );
  };
}

export function showPostBraindateCheerModalBundle(
  braindates: Array<Braindate>,
) {
  return (dispatch: any) =>
    braindates.map<Braindate>((braindate) =>
      dispatch(showPostBraindateCheerModal(braindate)),
    );
}

export function showCheerRecipientModal(cheers: Cheer[]) {
  return queueNotification(buildCheerRecipientNotification(cheers));
}

export function parseEligibleParticipants(
  eligibleParticipants: Array<User>,
  braindateParticipants: Array<User>,
): Array<User> {
  const indexedEligible = new Set();

  eligibleParticipants.forEach((p) => {
    indexedEligible.add(p.id);
  });

  return braindateParticipants.filter((participant) =>
    indexedEligible.has(participant.id),
  );
}
