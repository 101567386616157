/* @flow */

import { memo, useEffect } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import LazyOwnTopicsMain from 'src/shared/app/account/base/component/LazyOwnTopicsMain';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import { ownTopicsRejectedRoute } from 'src/shared/app/base/route/setting/routeSettings';
import messages from 'src/shared/app/moderation/l10n/moderationL10n';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';

type Props = {
  notification: NotificationType<>,
};

const ModerationRejectedNotificationDumb = ({ notification }: Props): Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [ownTopicsRejectedPath] = useRoutes(ownTopicsRejectedRoute);

  useEffect(() => {
    LazyOwnTopicsMain.preload();
  }, []);

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  return (
    <Notification
      withWarning
      href={ownTopicsRejectedPath}
      handleDismiss={handleDismiss}
      timeout={8000}
    >
      {intl.formatMessage(messages.moderationRejectedNotification)}
    </Notification>
  );
};

export default memo<Props>(ModerationRejectedNotificationDumb);
