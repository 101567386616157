/* @flow */

import { generatePath as routerGeneratePath } from 'react-router-dom';

import type { BrandingCustomizationType } from '@braindate/domain/lib/event/type';
import {
  assertNumber,
  assertObject,
  assertString,
} from '@braindate/util/lib/assert';
import { isFunction } from '@braindate/util/lib/type';

import type { RouteData } from 'src/shared/app/base/route/type/routeTypes';
import type { ReactElement } from 'src/shared/core/type/reactTypes';
import type { State } from 'src/shared/core/type/reduxTypes';

import type { Location, Params } from 'react-router';

export function getRoutePath({ path }: RouteData): string {
  return path;
}

export function getRouteTemplate(
  { template }: RouteData,
  state?: State,
  config?: Object,
): ReactElement {
  if (isFunction(template) && state) {
    return template(state, config);
  }

  return template;
}

export function getRouteMain(
  { main }: RouteData,
  state?: State,
  config?: Object,
): ReactElement {
  if (isFunction(main) && state) {
    return main(state, config);
  }

  return main;
}

export function getRouteHelp({ help }: RouteData, state?: State): boolean {
  if (help && isFunction(help) && state) {
    return help(state);
  }

  return !!help;
}

export function getRouteBackgroundImage(
  { backgroundImage }: RouteData,
  state: State,
  brandingCustomization: BrandingCustomizationType | null,
): string | null {
  if (!backgroundImage) return null;

  if (typeof backgroundImage === 'string') {
    return backgroundImage;
  }

  return backgroundImage(state, brandingCustomization);
}

export function getRouteRedirect(
  { redirect }: RouteData,
  state: State,
  config: Object,
  location: Location,
): ?string {
  if (redirect) {
    return redirect(state, config, location);
  }
}

export function getRouteAllowAbsoluteURLRedirect({
  allowAbsoluteURLRedirect,
}: RouteData): boolean {
  return !!allowAbsoluteURLRedirect;
}

export function getRouteScrollToTop({ scrollToTop }: RouteData): boolean {
  return !!scrollToTop;
}

export function getPathId(
  params: Params<string>,
  name: string | null = 'id',
): number {
  assertString(name, 'name');
  return parseInt(params[name], 10);
}

export function getPathValue(params: Params<string>, name: string): string {
  assertString(name, 'name');
  return params[name];
}

/**
 * Add an HTTP status code to the context passed in `props`
 * Cf. https://reacttraining.com/react-router/web/guides/server-rendering/404-401-or-any-other-status
 * @param {Object} props - Object that contains the context
 * @param {number} status - HTTP status code
 * @throws {TypeError} `props` must be an object
 * @throws {TypeError} `status` must be a number
 * @return {undefined}
 */
export function setHttpStatusCode(props: Object, status: number): void {
  assertObject(props, 'props');
  assertNumber(status, 'status');

  const { staticContext } = props;

  if (staticContext && typeof staticContext === 'object') {
    staticContext.status = status;
  }
}

export function generatePath(route: RouteData, params?: Object): string {
  return routerGeneratePath(getRoutePath(route), params);
}
