(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "polished", "../../token/typography", "../../token/animation", "../../token/color"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("polished"), require("../../token/typography"), require("../../token/animation"), require("../../token/color"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.polished, global.typography, global.animation, global.color);
    global.BaseButtonStyle = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _polished, _typography, _animation, _color) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var spinnerWidth = 14; /* small spinner width */
  var spinnerWidthWithPadding = "calc(".concat(spinnerWidth, "px + 1em)"); /* small spinner width w/ padding */
  var _default = _exports.default = {
    root: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      transition: _animation.fadeDuration,
      transformOrigin: "center center",
      fontSize: (0, _polished.rem)(14, _typography.baseFontSize),
      minHeight: (0, _polished.rem)(34, _typography.baseFontSize),
      textTransform: "uppercase",
      fontWeight: 800,
      padding: function padding(_ref) {
        var withEvenSides = _ref.withEvenSides;
        return withEvenSides ? (0, _polished.rem)(8, _typography.baseFontSize) : [(0, _polished.rem)(8, _typography.baseFontSize), (0, _polished.rem)(21, _typography.baseFontSize)];
      },
      "&:active": {
        transform: "scale(0.95, 0.95)"
      },
      "&[disabled]": {
        opacity: 0.4
      },
      "& a": {
        textDecoration: "none",
        "&:hover": {
          color: "inherit"
        }
      }
    },
    "root-large": {
      minWidth: 216
    },
    "root-fitText": {
      minWidth: 0
    },
    "root-isSubmitting": {
      paddingRight: spinnerWidthWithPadding,
      paddingLeft: spinnerWidthWithPadding,
      pointerEvents: "none"
    },
    "root-isFetching": {},
    "root-withSuccess": {
      /* FIXME: overwrite accent color background-color */
      backgroundColor: "".concat(_color.successColor, " !important"),
      "&:hover": {
        backgroundColor: "".concat(_color.darkSuccessColor, " !important")
      }
    },
    spinner: {
      // position: "absolute",
      // top: 0,
      // right: "0.5em",
      // bottom: 0,

      display: "inline-flex",
      // alignItems: "center"
      marginRight: "0.6em"
    },
    "spinner-isFetching": {
      // left: 0,
      // right: 0,
      // margin: "0 auto",
      // width: spinnerWidth
    }
  };
});