/* @flow */

import { memo, useState } from 'react';
import type { Node } from 'react';

import { Field, Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import type { Topic } from '@braindate/domain/lib/topic/type';
import { getTopicURL } from '@braindate/domain/lib/topic/util';
import type { User } from '@braindate/domain/lib/user/type';
import {
  getUserFullName,
  getUserId,
  getUserURL,
} from '@braindate/domain/lib/user/util';

import FormikFormGroup from 'src/shared/app/base/component/data-entry/form/FormikFormGroup';
import Modal from 'src/shared/app/base/modal/component/Modal';
import useErrorModal from 'src/shared/app/base/modal/hook/useErrorModal';
import { clearModal } from 'src/shared/app/base/modal/slice/modalSlice';
import useRouteMatch from 'src/shared/app/base/route/hook/useRouteMatch';
import { topicRoute } from 'src/shared/app/base/route/setting/routeSettings';
import { getRoutePath } from 'src/shared/app/base/route/util/routeUtils';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import { marketErrorModalId } from 'src/shared/app/market/component/modal/MarketErrorModal';
import styles from 'src/shared/components/domain/user/form/RecommendationModal.style';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import {
  userRecommendTopic,
  userRecommendUser,
} from 'src/shared/domain/user/action/userActions';
import messages from 'src/shared/domain/user/l10n/userL10n';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import RecommendIcon from 'src/shared/ui/component/icon/RecommendIcon';

import FieldUserAutocomplete from './common/FieldUserAutocomplete';

export const recommendationModalId = 'recommendationModalId';

type Props = {
  title: string,
  topic?: Topic,
  defaultUser?: User,
  onAfterClose?: () => void,
};

const useStyles = createUseThemeStyles(styles);

const RecommendationModal = ({
  title,
  topic,
  defaultUser,
  onAfterClose,
}: Props): Node => {
  const intl = useIntl();
  const event = useEvent();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpened, setIsOpened] = useState<boolean>(true);
  const triggerErrorModal = useErrorModal();

  const [step, setStep] = useState(1);
  const [selectedUser, setSelectedUser] = useState();

  const match = useRouteMatch();
  const matchPath = getRoutePath(match);
  const topicRoutePath = getRoutePath(topicRoute);
  const isInTopicPage = matchPath === topicRoutePath;

  const errorMessage = intl.formatMessage(messages.recommendUserErrorMessage);
  const textErrorMessage = intl.formatMessage(messages.recommendErrorMessage);

  const handleModalClose = () => {
    dispatch(clearModal());
    setIsOpened(false);
    if (onAfterClose) {
      onAfterClose();
    }
  };
  const handleSubmit = async (
    values,
    { setSubmitting, setStatus, setErrors },
  ) => {
    if (!values.user || !values.message) {
      setErrors({
        user: !values.user ? errorMessage : null,
        message: !values.message ? textErrorMessage : null,
      });
      setSubmitting(false);
    } else {
      try {
        // @TODO PAX-4722 - Move recommendations to RTK Query
        if (topic) {
          await dispatch(
            userRecommendTopic(
              getUserId(values.user),
              getTopicURL(topic),
              values.message,
            ),
          );
        } else {
          if (!defaultUser) {
            throw new Error('No default user');
          }

          await dispatch(
            userRecommendUser(
              getUserId(values.user),
              getUserURL(defaultUser),
              // $FlowIssue
              event.url,
              values.message,
            ),
          );
        }

        setSelectedUser(values.user);
        setStep(2);
      } catch (e) {
        setSubmitting(false);
        setStatus(e);

        if (e.status === 400 && !!topic) {
          dispatch(clearModal());

          triggerErrorModal(marketErrorModalId, () => {
            if (isInTopicPage) {
              navigate(-1);
            }
            dispatch(clearModal());
          });
        }
      }
    }
  };

  const getDefaultUserValue = () => {
    if (!defaultUser) return;
    if (topic) return defaultUser;
    return null;
  };

  const step1 = (
    <Formik
      initialValues={{
        message: '',
        user: getDefaultUserValue(),
      }}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting }) => (
        <Form className={classes.form} noValidate>
          <FieldUserAutocomplete
            label={intl.formatMessage(
              !defaultUser ? messages.recommendTo : messages.recommendLabel,
            )}
            suppParams={{ people_tab_optin: true }}
            disabled={!!getDefaultUserValue()}
          />
          <Field
            id="message"
            name="message"
            rows="5"
            required
            component={FormikFormGroup}
            componentOpts={{
              useTextarea: true,
              label: intl.formatMessage(messages.recommendPrivate),
            }}
            placeholder={intl.formatMessage(
              topic
                ? messages.recommendPlaceholder
                : messages.recommendPersonPlaceholder,
            )}
            maxLength="250"
          />
          <div className={classes.submit}>
            <PrimaryButton
              aria-disabled={!values.user || !values.message}
              isSubmitting={isSubmitting}
              type="submit"
            >
              {title}
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );

  const step2 = selectedUser && (
    <div className={classes.messageConfirmation}>
      {intl.formatMessage(messages.recommendSentMessage, {
        fullName: getUserFullName(selectedUser),
      })}
    </div>
  );

  return (
    <Modal
      isOpen={isOpened}
      handleClose={handleModalClose}
      onAfterClose={handleModalClose}
      hideActions
      width="40em"
      title={title}
      icon={<RecommendIcon />}
      content={
        <div>
          <div className={classes.body}>
            {step === 1 && step1}
            {step === 2 && step2}
          </div>
        </div>
      }
    />
  );
};

export default memo<Props>(RecommendationModal);
