/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { useButton } from '@mui/base';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import { capitalize } from 'src/shared/core/util/stringUtil';
import ConditionalWrapper from 'src/shared/ui/component/misc/ConditionalWrapper';

import styles from './SecondaryTag.style';

type Props = {
  children: Node,
  postfix?: string,
  handleClick?: (string) => void,
  tooltip?: string,
  layoutClass?: string,
};

const useStyles = createUseThemeStyles(styles);

const SecondaryTag = ({
  children,
  handleClick,
  postfix,
  tooltip,
  layoutClass,
}: Props): Node => {
  const text = typeof children === 'string' ? capitalize(children) : children;

  const { buttonProps } = useButton({
    component: 'span',
    onPress: () => {
      if (handleClick && typeof children === 'string') {
        handleClick(children);
      }
    },
  });

  const classes = useStyles();

  return (
    <ConditionalWrapper
      condition={!!tooltip}
      wrapper={(child) => (
        <Tooltip title={tooltip} arrow>
          {child}
        </Tooltip>
      )}
    >
      <span
        className={classNames(classes.root, layoutClass)}
        {...buttonProps}
        tabIndex={tooltip ? 0 : -1}
      >
        {text} {postfix && <span className={classes.postfix}>{postfix}</span>}
      </span>
    </ConditionalWrapper>
  );
};

export default memo<Props>(SecondaryTag);
