/* @flow */
import { createSlice } from '@reduxjs/toolkit';

const initialState: { joined: number[] } = {
  joined: [],
};

const joinedBraindatesSlice = createSlice({
  name: 'joinedBraindates',
  initialState,
  reducers: {
    joinedBraindateCall: (state: Object, data: Object) => {
      state.joined.push(data.payload);
    },
  },
});

export const { joinedBraindateCall } = joinedBraindatesSlice.actions;

export default joinedBraindatesSlice.reducer;
