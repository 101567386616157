/* @flow */
/* eslint-disable no-prototype-builtins */

import { uniq } from 'ramda';

import { assertNotEmptyString, assertObject } from '@braindate/util/lib/assert';

export function createReducer(initialState: any, handlers: Object) {
  // eslint-disable-next-line default-param-last
  return (state: any = initialState, action: Object) => {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}

export function createFilteredReducer(
  reducerFunction: (state: Object, action: Object) => Object,
  reducerPredicate: (action: Object) => boolean,
) {
  return (state: Object, action: Object) => {
    const isInitializationCall = state === undefined;
    const shouldRunWrappedReducer =
      reducerPredicate(action) || isInitializationCall;

    return shouldRunWrappedReducer ? reducerFunction(state, action) : state;
  };
}

/**
 * Patch `state` with new data from `action.entities[entityKey]`.
 * If `action.entities` has no key `entityKey`, a referenc to `state` is
 * returned. Patching will only occur at the first 2 levels, meaning that:
 *
 * if state equals { 1: {id: 1}} and new data equals { 1: {name: "Rosa"}},
 * result will be { 1: {id: 1, name: "Rosa"}}.
 *
 * if state equals { 1: {links: { facebook: ""}}} and new data equals
 * { 1: {links: { twitter: ""}}}, result will be { 1: {links: { twitter: ""}}}
 *
 * @param  {Object} state - State to patch
 * @param  {Object} action - Action from which to get the patching data
 * @param  {string} entityKey - Type of the data to patch
 * @param  {Function} middleware - Middleware to apply on the new state. It receives the old and the new state
 * @return {Object} Patched state
 */
export function patchEntities(
  state: Object,
  action: Object,
  entityKey: string,
  middleware: (oldObject: Object, newObject: Object) => Object = (a, b) => b,
): Object {
  assertObject(state, 'state');
  assertObject(action, 'action');
  assertNotEmptyString(entityKey, 'entityKey');

  const { entities } = action;

  assertObject(entities, 'action.entities');

  if (entityKey in entities) {
    const newData = entities[entityKey];

    assertObject(newData, `action.entities.${entityKey}`);

    const mergedEntities = {};

    for (const id in newData) {
      const oldEntity = state[id];
      const newEntity = newData[id];

      // Safe operation: {...obj} returns {} is obj is not an object
      const merged = { ...oldEntity, ...newEntity };
      mergedEntities[id] = middleware(oldEntity, merged);
    }

    return {
      ...state,
      ...mergedEntities,
    };
  }
  return state;
}

export function setRequestResult(state: Object, action: Object) {
  const { result, next, previous, count } = action;

  return {
    ...state,
    result,
    next,
    previous,
    isFetching: false,
    count,
  };
}

export function setLazyRequestResult(state: Object, action: Object) {
  const { result, next, previous, count } = action;

  return {
    ...state,
    result: uniq([...(state.result || []), ...result]),
    next,
    previous,
    isFetching: false,
    count,
  };
}

export function setRequestResultSlice(state: Object, action: Object) {
  const {
    payload: { result, next, previous, count },
  } = action;

  state.result = result;
  state.next = next;
  state.previous = previous;
  state.isFetching = false;
  state.count = count;
}

export function setRequestSimpleResultSlice(state: Object, action: Object) {
  const { payload } = action;

  state.result = payload;
  state.isFetching = false;
}

export function setLazyRequestResultSlice(state: Object, action: Object) {
  const {
    payload: { result, next, previous, count },
  } = action;

  state.result = uniq([...state.result, ...result]);
  state.next = next;
  state.previous = previous;
  state.isFetching = false;
  state.count = count;
  state.isFetched = true;
}

export function setBaseRequestResultSlice(state: Object, action: Object) {
  const {
    payload: { result },
  } = action;

  state.result = result;
  state.isFetching = false;
  state.isFetched = true;
}

export function resetSlice(state: Object) {
  state.result = null;
  state.isFetching = false;
}

export function resetResultSlice(state: Object) {
  state.result = [];
  state.next = null;
  state.previous = null;
}

export function setRequestResultAndValidate(
  state: Object,
  action: Object,
  isUniq?: boolean = false,
) {
  const { result, count, next, previous } = action;

  return {
    ...state,
    result: isUniq ? uniq(result) : result,
    count,
    next,
    previous,
    isFetching: false,
  };
}

export function setRequestResultUniqAndValidate(state: Object, action: Object) {
  return setRequestResultAndValidate(state, action, true);
}

export function setFetchingState(state: Object) {
  return {
    ...state,
    isFetching: true,
  };
}

export function setFetchingStateSlice(state: Object) {
  state.isFetching = true;
}

export function unsetFetchingState(state: Object) {
  return {
    ...state,
    isFetching: false,
  };
}

export function unsetFetchingStateSlice(state: Object) {
  state.isFetching = false;
}

export function toggleValueInArray(state: Object, action: Object, key: string) {
  const { value } = action;

  const values = state[key];
  const index = values.indexOf(value);

  let newValues;

  if (index === -1) {
    newValues = [...values, value];
  } else {
    newValues = [...values];

    newValues.splice(index, 1);
  }

  return {
    ...state,
    [key]: newValues,
  };
}

export function toggleValue(state: Object, key: string) {
  const value = state[key];

  return {
    ...state,
    [key]: !value,
  };
}
