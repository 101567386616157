/* @flow */
import { getPictureSizeMap } from '@braindate/util/lib/image';

import { liveChatIntercomScriptURL } from 'plugin/live-chat/setting/liveChatSettings';

// https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
// https://github.com/devrnt/react-use-intercom

import type {
  WidgetIntegrationActions,
  WidgetLoadParams,
  WidgetLoadSetupParams,
  WidgetLoadUserParams,
} from 'plugin/live-chat/type/liveChatType';

const debug = require('debug')('live-chat-actions:Intercom');

function getIntercomAppId(envSdkOptions: Object): ?string {
  return envSdkOptions.liveChatIntercomAppId;
}

export const intercomWidgetActions: WidgetIntegrationActions = {
  load: async ({
    sdkOptions,
    envSdkOptions,
    onOpenedStatusChange,
    getState,
    locale,
    name,
    email,
    userId,
    avatar,
    accentColor,
  }: WidgetLoadParams) => {
    debug('Setup');
    const appId = getIntercomAppId(envSdkOptions);

    if (appId) {
      if (!window.intercomSettings) {
        debug('Load Widget');
        window.intercomSettings = {
          api_base: 'https://api-iam.intercom.io',
          app_id: appId,
          hide_default_launcher: true,
          action_color: accentColor,
        };
        window.loadJS(
          liveChatIntercomScriptURL.replace('{app_id}', String(appId)),
          () => {
            if (window.Intercom) {
              window.Intercom('onHide', () => onOpenedStatusChange(false));
              window.Intercom('onShow', () => onOpenedStatusChange(true));
              intercomWidgetActions.identify({
                sdkOptions,
                envSdkOptions,
                locale,
                name,
                email,
                userId,
                avatar,
                getState,
              });
            }
          },
        );
      }
    }
  },
  kill: () => {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  },
  toggle: (openedStatus) => {
    if (window.Intercom) {
      if (openedStatus) {
        window.Intercom('hide');
      } else {
        window.Intercom('show');
      }
    }
  },
  identify: async ({
    envSdkOptions,
    locale,
    name,
    email,
    userId,
    avatar,
  }: WidgetLoadUserParams) => {
    const appId = getIntercomAppId(envSdkOptions);
    const avatars = avatar && getPictureSizeMap(avatar);
    const largerAvatarKey =
      avatars && avatars.length && avatars[avatars.length - 1].key;
    if (appId && window.Intercom) {
      window.Intercom('boot', {
        app_id: appId,
        name, // Full name
        email, // Email address
        user_id: userId, // current_user_id
        avatar: largerAvatarKey &&
          avatar && {
            type: 'avatar',
            image_url: avatar[largerAvatarKey],
          },
        language_override: locale,
      });
    }
  },

  update: ({ envSdkOptions }: WidgetLoadSetupParams) => {
    const appId = getIntercomAppId(envSdkOptions);
    if (appId && window.Intercom) {
      window.Intercom('update');
    }
  },
};
