/* @flow */

import * as types from 'src/shared/app/help/action/uiHelpButtonReducerActionsTypes';

export const hideHelpButton = () => ({
  type: types.HIDE_HELP_BUTTON,
});

export const resetHelpButtonHidden = () => ({
  type: types.RESET_HIDE_HELP_BUTTON,
});
