/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';

import styles from 'src/shared/app/base/component/data-entry/form/FormikSelectChoice.style';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/domain/user/l10n/userL10n.json';
import CloseButton from 'src/shared/ui/component/button/CloseButton';

const useStyles = createUseThemeStyles(styles);

type Props = {
  field: Object,
  onChange: (value: Object) => void,
  children: (value: Object) => Node,
  form: Object,
  disabled?: boolean,
};

const FormikSelectChoice = ({
  field,
  onChange,
  form,
  children,
  disabled,
}: Props): Node => {
  const classes = useStyles();
  const handleRemoveClick = () => {
    form.setFieldValue(field.name);
    if (onChange) {
      onChange('');
    }
  };
  const intl = useIntl();

  return (
    <div className={classes.selectedItemWrapper}>
      <div className={classes.selectedItem}>{children(field.value)}</div>
      {!disabled && (
        <CloseButton
          onClick={handleRemoveClick}
          ariaLabel={intl.formatMessage(messages.removeUser)}
        />
      )}
    </div>
  );
};

export default memo<Props>(FormikSelectChoice);
