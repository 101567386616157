(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "@braindate/util/lib/assert"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("@braindate/util/lib/assert"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.assert);
    global.reactionGetters = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _assert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getItemReactions = getItemReactions;
  /**
   * Get the reactions of an item
   * @param {Topic | Comment} item - Item to get the reactions from
   * @returns {ItemReactions}
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getItemReactions(item) {
    (0, _assert.assertObject)(item);
    return item.reactions;
  }
});