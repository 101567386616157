/* @flow */

import { createSlice } from '@reduxjs/toolkit';

export type CommentsSliceState = {
  topics: { [key: number]: number },
  newComment: number | null,
};

const initialState: CommentsSliceState = {
  topics: {},
  newComment: null,
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    showCommentsPage: {
      reducer(state, { payload }) {
        state.topics[payload.id] = payload.page;
      },
    },
    markCommentAsNew: {
      reducer(state, { payload }) {
        state.newComment = payload;
      },
    },
  },
});

export const { showCommentsPage, markCommentAsNew } = commentsSlice.actions;

export default commentsSlice.reducer;
