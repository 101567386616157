/* @flow */

import type { User } from '@braindate/domain/lib/user/type';
import {
  getUserFavoriteURL,
  getUserId,
  isUserFavorite,
} from '@braindate/domain/lib/user/util';

import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';
import { apiRoot } from 'src/shared/app/base/api/apiRoot';
import { defaultLimitPages } from 'src/shared/app/base/api/constant/apiConstant';
import type {
  ApiListResult,
  EndpointSelector,
  UseLazyQuery,
  UseMutation,
  UseQuery,
} from 'src/shared/app/base/api/type/apiQueryType';
import {
  getQueryTag,
  infiniteLoadingSettings,
  optimisticUpdate,
} from 'src/shared/app/base/api/utils/endpointUtils';
import {
  emailVerificationEndpoint,
  logoutEndpoint,
  userAvatarEndpoint,
  userCanCreateTopicEndpoint,
  userEndpoint,
  usersEndpoint,
} from 'src/shared/app/base/settings/endpointSettings';
import type { CanCreateTopic } from 'src/shared/app/topic/new/type/newTopicTypes';

type Api = {
  endpoints: {
    getUser: EndpointSelector<number | 'me', User>,
    getUsers: EndpointSelector<
      { limit?: number, page?: number, [string]: any },
      ApiListResult<User>,
    >,
    getOnlineUsers: EndpointSelector<Object, ApiListResult<User>>,
  },
  useGetUserQuery: UseQuery<User, number | 'me'>,
  useGetUsersQuery: UseQuery<ApiListResult<User>>,
  useLazyGetUsersQuery: UseLazyQuery<ApiListResult<User>, Object>,
  useGetUsersForMarketQuery: UseQuery<ApiListResult<User>>,
  useGetOnlineUsersQuery: UseQuery<ApiListResult<User>>,
  useGetUserCanCreateTopicQuery: UseQuery<CanCreateTopic>,

  usePatchUserMutation: UseMutation<$Shape<User>, User>,
  useSendEmailVerificationEmailMutation: UseMutation<number, void>,
  useUpdateUserAvatarMutation: UseMutation<UseUpdateAvatarParams, User>,
  useUserBookmarkMutation: UseMutation<User, User>,
  useLogOutUserMutation: UseMutation<void, void>,
};

const extendedApi: Api = apiRoot.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: (id) => ({
        url: userEndpoint(id),
        params: {
          annotate_keywords_state: true,
        },
      }),
      providesTags: (result, _, id) => getQueryTag(TAG_TYPE.USER, id),
    }),
    getUsers: builder.query({
      query: ({ limit, page, ...params }) => ({
        url: usersEndpoint(),
        params: {
          limit: limit || defaultLimitPages,
          ...(typeof page !== 'undefined'
            ? {
                offset: (page - 1) * (limit || defaultLimitPages),
              }
            : {}),
          ...params,
        },
      }),
      ...infiniteLoadingSettings<User>({
        tagType: TAG_TYPE.USER,
        idGetter: getUserId,
      }),
    }),
    getUsersForMarket: builder.query({
      query: ({ params, page = 1, limit }) => ({
        url: usersEndpoint(),
        params: {
          ...params,
          limit: limit || defaultLimitPages,
          offset: (page - 1) * (limit || defaultLimitPages),
          random_order: true,
          people_tab_optin: true,
          visible: true,
          for_marketplace: 1,
          extended_search: true,
          show_statistics: true,
          annotate_keywords_state: true,
        },
      }),
      ...infiniteLoadingSettings<User>({
        tagType: TAG_TYPE.USER,
        idGetter: getUserId,
      }),
    }),
    getOnlineUsers: builder.query({
      query: ({ page, limit }) => ({
        url: usersEndpoint(),
        params: {
          limit: limit || 3,
          offset: (page - 1) * (limit || 4),
          online_now: true,
          exclude_self: true,
          people_tab_optin: true,
        },
      }),
      ...infiniteLoadingSettings<User>({
        tagType: TAG_TYPE.USER,
        idGetter: getUserId,
      }),
    }),
    getUserCanCreateTopic: builder.query({
      query: (id: number) => ({
        url: userCanCreateTopicEndpoint(id),
      }),
    }),

    patchUser: builder.mutation({
      query: (data) => ({
        url: userEndpoint('me'),
        method: 'PATCH',
        params: data,
      }),
      invalidatesTags: getQueryTag(TAG_TYPE.USER, 'me'),
    }),
    sendEmailVerificationEmail: builder.mutation({
      query: (id) => ({
        url: emailVerificationEndpoint(id),
        method: 'GET',
      }),
      invalidatesTags: (_result, _, id) => getQueryTag(TAG_TYPE.USER, id),
    }),
    updateUserAvatar: builder.mutation({
      query: ({ file, crop }) => {
        const { x, y, width, height } = crop;
        const data = new FormData();
        data.append('image', file);
        data.append('center_x', x);
        data.append('center_y', y);
        data.append('width', width);
        data.append('height', height);

        return {
          url: userAvatarEndpoint('me'),
          method: 'POST',
          params: data,
        };
      },
      invalidatesTags: () => getQueryTag(TAG_TYPE.USER, 'me'),
    }),
    userBookmark: builder.mutation({
      query: (user: User) => ({
        url: getUserFavoriteURL(user),
        method: 'POST',
        params: { bookmarked: !isUserFavorite(user) },
      }),
      async onQueryStarted(user, mutation) {
        await optimisticUpdate<User>({
          extendedApi,
          mutation,
          type: TAG_TYPE.USER,
          id: getUserId(user),
          callback: (draft) => {
            draft.bookmarked = !draft.bookmarked;
            return draft;
          },
        });
      },
      invalidatesTags: (result, _, { user }) =>
        getQueryTag(TAG_TYPE.USER, getUserId(user)),
    }),
    logOutUser: builder.mutation({
      query: () => ({
        url: logoutEndpoint(),
        method: 'POST',
      }),
      invalidatesTags: getQueryTag(TAG_TYPE.USER, 'me'),
    }),
  }),
});

export const {
  endpoints,
  useGetUserQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetUsersForMarketQuery,
  useGetOnlineUsersQuery,
  useGetUserCanCreateTopicQuery,
  usePatchUserMutation,
  useSendEmailVerificationEmailMutation,
  useUpdateUserAvatarMutation,
  useUserBookmarkMutation,
  useLogOutUserMutation,
} = extendedApi;

export const { getUser, getUsers, getOnlineUsers } = endpoints;

type UseUpdateAvatarParams = {
  file: any,
  crop: Object,
};
