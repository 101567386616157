/* @flow */

import { assert, assertString } from '@braindate/util/lib/assert';

import type { ReactElement } from 'src/shared/core/type/reactTypes';

class ModalRegistry {
  #map = new Map();

  add(key: string, element: ReactElement) {
    assertString(key, 'key');
    assert(element, 'element');

    this.#map.set(key, element);
  }

  get(key: string) {
    return this.#map.get(key);
  }
}

export default new ModalRegistry();
