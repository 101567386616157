/* @flow */
import { rem } from 'polished';

import { lap } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { dialogElevation } from 'src/shared/app/base/style/token/elevation';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    width: 488,
    position: 'relative',
    borderRadius: 5,
    margin: [0, 0, 25, 0],
    height: 52,
    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.colorBase,
    outline: ({ theme, isFocused }: ThemeFunction<{ isFocused: boolean }>) =>
      isFocused ? `1px solid ${theme.accentColor}` : 'none',
    outlineOffset: '3px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    '& .MuiFormControl-root': {
      width: '100%',
      height: '100%',
    },

    '& .MuiInputBase-root': {
      paddingLeft: '0px !important',
      overflowY: 'scroll',
    },

    '& .MuiInputAdornment-root': {
      width: 52,
    },

    '& button': {
      padding: 0,
      borderRadius: 0,
      width: 52,
      height: 52,

      '&:focus': {
        outline: 'none !important',
      },
    },
  },

  menu: {
    maxHeight: 300,
    zIndex: `${dialogElevation + 1} !important`,
  },

  input: {
    width: '100%',
    height: 52,

    '& > input': {
      marginLeft: '3px !important',
      outline: 'none !important',
      borderBottom: 'none !important',
      paddingLeft: '16px !important',
      borderLeft: ({ theme }: ThemeFunction<{}>) =>
        `1px solid ${theme.calendarDisableButtonColor} !important`,
      height: '100%',
    },

    '& > input::placeholder': {
      fontSize: rem(14, baseFontSize),

      [lap]: {
        fontSize: rem(16, baseFontSize),
      },
    },
  },
};
