/* @flow */

import { combineReducers } from 'redux';

import marketQueryReducer from './marketQueryReducer';

// $FlowIssue
const marketSearchReducer = combineReducers({
  query: marketQueryReducer,
});

export default marketSearchReducer;
