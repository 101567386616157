(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./topicAssertions", "../../base/l10n/util", "../../user/util/userGetters", "../../reaction/util/reactionGetters"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./topicAssertions"), require("../../base/l10n/util"), require("../../user/util/userGetters"), require("../../reaction/util/reactionGetters"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.topicAssertions, global.util, global.userGetters, global.reactionGetters);
    global.topicGetters = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _topicAssertions, _util, _userGetters, _reactionGetters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getGroupTopic = getGroupTopic;
  _exports.getGroupTopicCohosts = getGroupTopicCohosts;
  _exports.getGroupTopicEndTime = getGroupTopicEndTime;
  _exports.getGroupTopicParticipants = getGroupTopicParticipants;
  _exports.getGroupTopicSpotLeftCount = getGroupTopicSpotLeftCount;
  _exports.getGroupTopicStartTime = getGroupTopicStartTime;
  _exports.getGroupTopicTimeSlot = getGroupTopicTimeSlot;
  _exports.getInspirationTopicId = getInspirationTopicId;
  _exports.getInspirationTopicKeywords = getInspirationTopicKeywords;
  _exports.getInspirationTopicTitle = getInspirationTopicTitle;
  _exports.getInspirationTopicUrl = getInspirationTopicUrl;
  _exports.getOneOnOneTopic = getOneOnOneTopic;
  _exports.getTopicActiveBraindate = getTopicActiveBraindate;
  _exports.getTopicAuthor = getTopicAuthor;
  _exports.getTopicBasedOnInterest = getTopicBasedOnInterest;
  _exports.getTopicBookableURL = getTopicBookableURL;
  _exports.getTopicBraindateCount = getTopicBraindateCount;
  _exports.getTopicBraindateURL = getTopicBraindateURL;
  _exports.getTopicCanJoin = getTopicCanJoin;
  _exports.getTopicCanJoinMessage = getTopicCanJoinMessage;
  _exports.getTopicCancelURL = getTopicCancelURL;
  _exports.getTopicCohostsURL = getTopicCohostsURL;
  _exports.getTopicCommentURL = getTopicCommentURL;
  _exports.getTopicCommentsCount = getTopicCommentsCount;
  _exports.getTopicConversationURL = getTopicConversationURL;
  _exports.getTopicCustomIcon = getTopicCustomIcon;
  _exports.getTopicCustomLabel = getTopicCustomLabel;
  _exports.getTopicCustomizationData = getTopicCustomizationData;
  _exports.getTopicDeleteURL = getTopicDeleteURL;
  _exports.getTopicEnabledReactions = getTopicEnabledReactions;
  _exports.getTopicFavoriteCount = getTopicFavoriteCount;
  _exports.getTopicFavoriteURL = getTopicFavoriteURL;
  _exports.getTopicFormat = getTopicFormat;
  _exports.getTopicHideURL = getTopicHideURL;
  _exports.getTopicHow = getTopicHow;
  _exports.getTopicId = getTopicId;
  _exports.getTopicIsNew = getTopicIsNew;
  _exports.getTopicKeywords = getTopicKeywords;
  _exports.getTopicKind = getTopicKind;
  _exports.getTopicLeaveURL = getTopicLeaveURL;
  _exports.getTopicLocalizedData = getTopicLocalizedData;
  _exports.getTopicLocations = getTopicLocations;
  _exports.getTopicModerationStatus = getTopicModerationStatus;
  _exports.getTopicModerationURL = getTopicModerationURL;
  _exports.getTopicParticipantsURL = getTopicParticipantsURL;
  _exports.getTopicPath = getTopicPath;
  _exports.getTopicPermalink = getTopicPermalink;
  _exports.getTopicShareURL = getTopicShareURL;
  _exports.getTopicStatistics = getTopicStatistics;
  _exports.getTopicTitle = getTopicTitle;
  _exports.getTopicURL = getTopicURL;
  _exports.getTopicURLs = getTopicURLs;
  _exports.getTopicUnbookableReason = getTopicUnbookableReason;
  _exports.getTopicViewCount = getTopicViewCount;
  _exports.getTopicWatchURL = getTopicWatchURL;
  _exports.isTopicDiscussed = isTopicDiscussed;
  _exports.isTopicFavorite = isTopicFavorite;
  _exports.isTopicRecorded = isTopicRecorded;
  _exports.isTopicVisible = isTopicVisible;
  /*
   |------------------------------------------------------------------------------
   | Base getters
   |------------------------------------------------------------------------------
   */
  /**
   * Get the id of a topic
   * @param   {Topic} topic - Topic to get the id from
   * @returns {number} Id
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getTopicId(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    return topic.id;
  }

  /**
   * Get the URL of a topic
   * @param   {Topic} topic - Topic to get the URL from
   * @returns {string} URL
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getTopicURL(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    return topic.url;
  }

  /**
   * Get the path of a topic
   * @param   {Topic} topic - Topic to get the path from
   * @returns {string} Path
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getTopicPath(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    return topic.path;
  }

  /**
   * Get the permalink of a topic
   * @param   {Topic} topic - Topic to get the permalink from
   * @returns {string} Permalink
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getTopicPermalink(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    return topic.permalink;
  }

  /**
   * Get the kind of a topic
   * @param   {Topic} topic - Topic to get the kind from
   * @returns {TopicKind} Kind
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getTopicKind(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    return topic.kind;
  }

  /**
   * Get the author of a topic
   * @param   {Topic} topic - Topic to get the author from
   * @returns {User} Author
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getTopicAuthor(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var author = topic.author;
    (0, _topicAssertions.assertTopicAuthor)(author);
    return author;
  }

  /**
   * Get the URLs of a topic
   * @param   {Topic} topic - Topic to get the URLs from
   * @returns {TopicURLs} URLs
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getTopicURLs(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var urls = topic.urls;
    (0, _topicAssertions.assertTopicURLs)(urls);
    return urls;
  }

  /**
   * Get the format of a topic
   * @param   {Topic} topic - Topic
   * @returns {string} Format
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getTopicFormat(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var braindateFormat = topic.braindate_format;
    return braindateFormat;
  }

  /**
   * Check if a topic is visible
   * @param   {Topic} topic - Topic to check
   * @returns {boolean} True if the topic is
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function isTopicVisible(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var isVisible = topic.is_visible;

    // If not defined, we assume it's not visible by default
    return typeof isVisible === "undefined" ? false : isVisible;
  }

  /**
   * Check if the authenticated user started a conversation about a topic
   * @param   {Topic} topic - Topic to check
   * @returns {boolean} True if the authenticated user did
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function isTopicDiscussed(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var hasConversation = topic.has_conversation;

    // If not defined, we assume there's no conversation by default
    return typeof hasConversation === "undefined" ? false : hasConversation;
  }

  /**
   * Check if a topic has been favorited by the authenticated user
   * @param   {Topic} topic - Topic to check
   * @returns {boolean} True if the topic has
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function isTopicFavorite(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var bookmarked = topic.bookmarked;

    // If not defined, we assume it's not bookmarked by default
    return typeof bookmarked === "undefined" ? false : bookmarked;
  }
  function getTopicUnbookableReason(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var unbookableReason = topic.unbookableReason;

    // If not defined, we return a default string
    return typeof unbookableReason === "undefined" ? null : unbookableReason;
  }

  /**
   * Get the statistics of a topic
   * @param   {Topic} topic - Topic to get the statistics from
   * @returns {TopicStatistics} Statistics
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * exists but is not an object
   */
  function getTopicStatistics(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var statistics = topic.statistics;
    if (statistics) {
      (0, _topicAssertions.assertTopicStatistics)(statistics);
      return statistics;
    }
  }

  /**
   * Get the customization data of a topic
   * @param   {Topic} topic - Topic to get the customization data from
   * @returns {TopicCustomizationData} Customization data
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * exists but is not an object
   */
  function getTopicCustomizationData(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var customization = topic.customization;
    if (customization) {
      (0, _topicAssertions.assertTopicCustomizationData)(customization);
      return customization;
    }
  }

  /**
   * Get the moderation status of a topic
   * @param   {Topic} topic - Topic to get the moderation status from
   * @returns {string} Moderation status
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getTopicModerationStatus(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    return topic.moderation;
  }

  /*
   |------------------------------------------------------------------------------
   | Advanced getters
   |------------------------------------------------------------------------------
   */

  /**
   * Get the title of a topic
   * @param   {Topic} topic - Topic to get the title from
   * @param   {string} [locale] - Locale in which to get the title
   * @returns {string} Title
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getTopicTitle(topic, locale) {
    (0, _topicAssertions.assertTopic)(topic);
    return (0, _util.getLocalizedData)(topic, locale).title || "";
  }

  /**
   * Get the how of a topic
   * @param   {Topic} topic - Topic to get the how from
   * @param   {string} [locale] - Locale in which to get the how
   * @returns {string} How
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getTopicHow(topic, locale) {
    (0, _topicAssertions.assertTopic)(topic);
    return (0, _util.getLocalizedData)(topic, locale).how || "";
  }

  /**
   * Get the keywords of a topic
   * @param   {Topic} topic - Topic to get the keywords from
   * @param   {string} [locale] - Locale in which to get the keywords
   * @returns {Array<string>} Keywords
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getTopicKeywords(topic, locale) {
    (0, _topicAssertions.assertTopic)(topic);
    return (0, _util.getLocalizedData)(topic, locale).keywords;
  }

  /**
   * Get the URL to favorite a topic
   * @param   {Topic} topic - Topic to get URL from
   * @returns {string} URL
   */
  function getTopicFavoriteURL(topic) {
    return getTopicURLs(topic).bookmark;
  }

  /**
   * Get the URL of the authenticated user's braindate about a topic
   * @param   {Topic} topic - Topic to get URL from
   * @returns {string | null} URL
   */
  function getTopicBraindateURL(topic) {
    return getTopicURLs(topic).braindate || null;
  }

  /**
   * Get the URL of the authenticated user's conversation about a topic
   * @param   {Topic} topic - Topic to get URL from
   * @returns {string} URL
   */
  function getTopicConversationURL(topic) {
    return getTopicURLs(topic).conversation;
  }

  /**
   * Get the URL to know if a topic can be booked
   * @param   {Topic} topic - Topic to get URL from
   * @returns {string} URL
   */
  function getTopicBookableURL(topic) {
    return getTopicURLs(topic).can_join;
  }

  /**
   * Get the URL to know the rejected message for moderation
   * @param   {Topic} topic - Topic to get URL from
   * @returns {string} URL
   */
  function getTopicModerationURL(topic) {
    return getTopicURLs(topic).moderation;
  }

  /**
   * Get the URL to add/delete cohost from a fishbowl topic
   * @param   {Topic} topic - Topic to get URL from
   * @returns {string} URL
   */
  function getTopicCohostsURL(topic) {
    return getTopicURLs(topic).cohosts;
  }

  /**
   * Get the URL of the conversation participants list of a topic
   * @param   {Topic} topic - Topic to get URL from
   * @returns {string | undefined} URL
   */
  function getTopicParticipantsURL(topic) {
    return getTopicURLs(topic).participants;
  }

  /**
   * Get the delete URL of the topic
   * @param   {Topic} topic - Topic to get URL from
   * @returns {string | undefined} URL
   */
  function getTopicDeleteURL(topic) {
    return getTopicURLs(topic).delete;
  }

  /**
   * Get the hide URL of the topic
   * @param   {Topic} topic - Topic to get URL from
   * @returns {string | undefined} URL
   */
  function getTopicHideURL(topic) {
    return getTopicURLs(topic).hide;
  }

  /**
   * Get the cancel URL of the topic
   * @param   {Topic} topic - Topic to get URL from
   * @returns {string | undefined} URL
   */
  function getTopicCancelURL(topic) {
    return getTopicURLs(topic).cancel;
  }

  /**
   * Get the leave URL of the topic
   * @param   {Topic} topic - Topic to get URL from
   * @returns {string | undefined} URL
   */
  function getTopicLeaveURL(topic) {
    return getTopicURLs(topic).leave;
  }

  /**
   * Get the comments URL of the topic
   * @param   {Topic} topic - Topic to get URL from
   * @returns {string} URL
   */
  function getTopicCommentURL(topic) {
    return getTopicURLs(topic).comment;
  }

  /**
   * Get the share URL of the topic
   * @param   {Topic} topic - Topic to get URL from
   * @returns {string | undefined} URL
   */
  function getTopicShareURL(topic) {
    return getTopicURLs(topic).share;
  }

  /**
   * Get the watch URL of the topic
   * @param   {Topic} topic - Topic to get URL from
   * @returns {string | undefined} URL
   */
  function getTopicWatchURL(topic) {
    return getTopicURLs(topic)['comments-notifications'];
  }

  /**
   * Get the braindate count of a topic
   * @param   {Topic} topic - Topic to get the braindate count from
   * @returns {number} Braindate count
   */
  function getTopicBraindateCount(topic) {
    var stats = getTopicStatistics(topic);
    return stats ? stats.braindates_count : undefined;
  }

  /**
   * Get the favorite count of a topic
   * @param   {Topic} topic - Topic to get the favorite count from
   * @returns {number} Favorite count
   */
  function getTopicFavoriteCount(topic) {
    var stats = getTopicStatistics(topic);
    return stats ? stats.bookmarked_count : undefined;
  }

  /**
   * Get the view count of a topic
   * @param   {Topic} topic - Topic to get the view count from
   * @returns {number} View count
   */
  function getTopicViewCount(topic) {
    var stats = getTopicStatistics(topic);
    return stats ? stats.views_count : undefined;
  }

  /**
   * Get the custom icon of a topic
   * @param   {Topic} topic - Topic to get the icon from
   * @returns {Picture} Icon URLs
   *
   * @throws Will throw an exception if parameter is not an object or if custom icon
   * attribute exists but is not an object
   */
  function getTopicCustomIcon(topic) {
    var data = getTopicCustomizationData(topic);
    if (data) {
      var picture = data.picture;
      if (picture) {
        (0, _topicAssertions.assertTopicCustomIcon)(picture);
        return picture;
      }
    }
  }

  /**
   * Get the localized data of a topic
   * @param   {Topic} topic - Topic to get the localized data from
   * @param   {string} [locale] - locale to get the data from
   * @returns {Object} Localized data or undefined
   */
  function getTopicLocalizedData(topic, locale) {
    var customTopicData = getTopicCustomizationData(topic);
    if (customTopicData) {
      return (0, _util.getLocalizedData)(customTopicData, locale);
    }
  }

  /**
   * Get the custom label of a topic
   * @param   {Topic} topic - Topic to get the label from
   * @param   {string} [locale] - locale to get the custom data from
   * @returns {string} Custom topic label
   */
  function getTopicCustomLabel(topic, locale) {
    var localizedData = getTopicLocalizedData(topic, locale);
    if (localizedData) {
      return localizedData.label;
    }
  }

  /**
   * Get the locations of a topic
   * @param   {Topic} topic - Topic
   * @returns {Array<Location>} Locations
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * exists but is not an object
   */
  function getTopicLocations(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var locations = topic.possible_locations;
    if (locations) {
      (0, _topicAssertions.assertTopicLocations)(locations);
      return locations;
    }
    return [];
  }

  /**
   * Get if the user can join a topic - Use only on topics in the market
   * @param   {Topic} topic - Topic to get the can join from
   * @returns {boolean} User can join
   */
  function getTopicCanJoin(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    return topic.can_join;
  }

  /**
   * Get the message to display if the user can join a topic - Use only on topics in the market
   * @param   {Topic} topic - Topic to get the can join message from
   * @returns {string | null} User can join message
   */
  function getTopicCanJoinMessage(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    return topic.can_join_message || null;
  }

  /*
   |------------------------------------------------------------------------------
   | Group braindate topic
   |------------------------------------------------------------------------------
   */

  /**
   * Assert a group topic and return the right cast
   * @param   {Topic} topic - Group topic to get the time slot from
   * @returns {GroupTopic} groupTopic
   *
   * @throws Will throw an exception if not a Group Topic
   */
  function getGroupTopic(topic) {
    (0, _topicAssertions.assertGroupTopic)(topic);
    var groupTopic = topic;
    return groupTopic;
  }

  /**
   * Assert a group topic and return the right cast
   * @param   {Topic} topic - Group topic to get the time slot from
   * @returns {GroupTopic} groupTopic
   *
   * @throws Will throw an exception if not a Group Topic
   */
  function getOneOnOneTopic(topic) {
    (0, _topicAssertions.assertOneOnOneTopic)(topic);
    var oneOnOneTopic = topic;
    return oneOnOneTopic;
  }

  /**
   * Get the time slot of a group topic
   * @param   {GroupTopic} topic - Group topic to get the time slot from
   * @returns {TimeSlotLocation} Time slot
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getGroupTopicTimeSlot(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var date = topic.date;
    (0, _topicAssertions.assertGroupTopicDate)(date);
    return date;
  }

  /**
   * Get the participants of a group topic
   * @param   {GroupTopic} topic - Group topic to get the participants from
   * @returns {Array<User>} Participants
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an array
   */
  function getGroupTopicParticipants(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var participants = topic.participants;
    if (participants) {
      (0, _topicAssertions.assertGroupTopicParticipants)(participants);
      return participants;
    }
  }

  /**
   * Get the spot left count of a group topic
   * @param   {GroupTopic} topic - Group topic to get the spot left count from
   * @returns {number} Spot left count
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getGroupTopicSpotLeftCount(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    return topic.spots_left;
  }

  /**
   * Get the start time of a group topic
   * @param   {GroupTopic} topic - Group topic to get the start time from
   * @returns {string} Start time
   */
  function getGroupTopicStartTime(topic) {
    return getGroupTopicTimeSlot(topic).start_time;
  }

  /**
   * Get the end time of a group topic
   * @param   {GroupTopic} topic - Group topic to get the end time from
   * @returns {string} End time
   */
  function getGroupTopicEndTime(topic) {
    return getGroupTopicTimeSlot(topic).end_time;
  }

  /**
   * Get the prefered meeting point of a topic
   * @param   {Topic} topic - Topic
   * @returns {string} Prefered meeting point
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getGroupTopicCohosts(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var cohosts = topic.cohosts;
    if (cohosts) {
      var participants = getGroupTopicParticipants(topic);
      if (participants) {
        var cohostParticipants = cohosts.map(function (cohost) {
          return participants.find(function (participant) {
            return (0, _userGetters.getUserURL)(participant) === cohost;
          });
        }).filter(Boolean);
        (0, _topicAssertions.assertTopicCohosts)(cohostParticipants);
        return cohostParticipants;
      }
    }
  }

  /**
   * Get if a topic has recorded status
   * @param   {Topic} topic - Topic
   * @returns {boolean} Topic is recorded or not
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function isTopicRecorded(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var recorded = topic.recorded;
    return recorded || false;
  }

  /**
   * Get if a topic is based on interests
   * @param   {Topic} topic - Topic
   * @param   {string} locale - Locale
   * @returns {Keyword} Topic based on interest keyword
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getTopicBasedOnInterest(topic, locale) {
    (0, _topicAssertions.assertOneOnOneTopic)(topic);
    var localizedData = (0, _util.getLocalizedData)(topic, locale);
    var basedOnInterest = localizedData.based_on_interest;
    return basedOnInterest;
  }

  /**
   * Get if a topic is new
   * @param   {Topic} topic - Topic
   * @returns {boolean} Topic is new or not
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getTopicIsNew(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var isNew = topic.is_new;
    return isNew;
  }

  /**
   * Gets the topic's active braindate
   * @param {Topic} topic  - Topic
   * @returns {string | undefined} Returns the active braindate path if it exists
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getTopicActiveBraindate(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var activeBraindate = topic.active_braindate;
    return activeBraindate;
  }

  /**
   * Gets the topic's enabled reactions
   * @param {Topic} topic  - Topic
   * @returns {Reaction[]} Returns the topic enabled reactions
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getTopicEnabledReactions(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var reactions = (0, _reactionGetters.getItemReactions)(topic);
    return reactions.filter(function (reaction) {
      return reaction.enabled;
    }).map(function (reaction) {
      return reaction.label;
    });
  }

  /**
   * Gets the topic's comments count
   * @param {Topic} topic  - Topic
   * @returns {number} Returns the topics count
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getTopicCommentsCount(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var comments_count = topic.comments_count;
    return comments_count;
  }

  /**
   * Gets the inspiration topic id
   * @param {InspirationTopic} inspirationTopic - Inspiration topic
   * @returns {number} Returns the inspiration topic id
   */
  function getInspirationTopicId(inspirationTopic) {
    (0, _topicAssertions.assertInspirationTopic)(inspirationTopic);
    return inspirationTopic.id;
  }

  /**
   * Gets the inspiration topic url
   * @param {InspirationTopic} inspirationTopic - Inspiration topic
   * @returns {string} Returns the inspiration topic url
   */
  function getInspirationTopicUrl(inspirationTopic) {
    (0, _topicAssertions.assertInspirationTopic)(inspirationTopic);
    return inspirationTopic.url;
  }

  /**
   * Gets the inspiration topic title
   * @param {InspirationTopic} inspirationTopic - Inspiration topic
   * @returns {string} Returns the inspiration topic title
   */
  function getInspirationTopicTitle(inspirationTopic, locale) {
    (0, _topicAssertions.assertInspirationTopic)(inspirationTopic);
    return (0, _util.getLocalizedData)(inspirationTopic, locale).title;
  }

  /**
   * Gets the inspiration topic keywords
   * @param {InspirationTopic} inspirationTopic - Inspiration topic
   * @returns {Keyword[]} Returns the inspiration topic keywords
   */
  function getInspirationTopicKeywords(inspirationTopic, locale) {
    (0, _topicAssertions.assertInspirationTopic)(inspirationTopic);
    return (0, _util.getLocalizedData)(inspirationTopic, locale).keywords;
  }
});