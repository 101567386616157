/* eslint-disable import/no-import-module-exports */
/* eslint-disable camelcase */
/* eslint-disable promise/no-nesting */
/* @flow */

import { loadableReady } from '@loadable/component';

import { injectTokenInCookie } from 'src/shared/app/authentication/util/authenticationUtils';
import { preloadedStateStateKey } from 'src/shared/app/base/constant/reduxConstants';
import {
  getStaticURL,
  isDevModeEnabled,
} from 'src/shared/app/base/selector/appEnvSelectors';
import { getEventSafely } from 'src/shared/domain/event/selector/eventSelectors';

import ClientApp from './component/ClientApp';
import { configureErrorReporting } from './config/errorReportingConfig';
import { configureL10n } from './config/l10nConfig';
import render from './core/render';
import { createReduxStore } from './factory/storeFactory';
import { captureException } from './service/errorReportingService';

/**
 * 1. Retrieve state
 * 2. Boot up app
 * 3. Configure app
 * 4. Load polyfills and dependencies
 * 5. Render app
 * 6. Enable hot reloading
 */

/*
 |------------------------------------------------------------------------------
 | 1. Retrieve state
 |------------------------------------------------------------------------------
 */

// Retrieve the state that was built server-side
const state = window[preloadedStateStateKey];

// Retreive token from query

// @TODO - TEMP-AUTH
injectTokenInCookie(getEventSafely(state));

// Allow the passed state to be garbage-collected, except in dev mode because
// hot reloading requires it to be attached to the window object at all time
if (!isDevModeEnabled(state)) {
  delete window[preloadedStateStateKey];
}

/*
 |------------------------------------------------------------------------------
 | 2. Boot up app
 |------------------------------------------------------------------------------
 */

// Set the public path
__webpack_public_path__ = getStaticURL(state);

// Create the redux store
const store = createReduxStore(state);

/*
 |------------------------------------------------------------------------------
 | 3. Configure app
 |------------------------------------------------------------------------------
 */

// Configure Sentry
configureErrorReporting(store);

/*
 |------------------------------------------------------------------------------
 | 4. Load polyfills and dependencies
 | 5. Render app
 |------------------------------------------------------------------------------
 */

// Load localization files and timezone polyfill, then render app
configureL10n(state)
  .then((messages) =>
    configureL10n(state)
      .finally(() => {
        loadableReady(() => render(ClientApp, store, messages));
      })
      .catch(captureException),
  )
  .catch(captureException);

/*
 |------------------------------------------------------------------------------
 | 6. Enable hot reloading
 |------------------------------------------------------------------------------
 */

// Available when running the webpack dev server with the `hot` flag
if (module.hot) {
  module.hot.accept();
}
