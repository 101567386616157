/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './UserOnlineBadge.style';

type Props = {
  layoutClass?: string,
  variant?: ?typeof large,
  onDarkBackground?: boolean,
};

const useStyles = createUseThemeStyles(styles);

const UserOnlineBadge = ({
  layoutClass,
  variant,
  onDarkBackground,
}: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */

  const classes = useStyles();

  const rootClasses = classNames(classes.root, layoutClass, {
    [classes['root-large']]: variant === large,
    [classes['root-darkBg']]: onDarkBackground,
  });

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  return <div className={rootClasses} />;
};

export const large = Symbol('large');

export default memo<Props>(UserOnlineBadge);
