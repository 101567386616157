(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.topicFontFamily = _exports.subheading2LineHeight = _exports.subheading2FontWeight = _exports.subheading2FontSize = _exports.subheading1LineHeight = _exports.subheading1FontWeight = _exports.subheading1FontSize = _exports.separatorFontFamily = _exports.ruleFontFamily = _exports.inputLineHeight = _exports.inputFontWeight = _exports.inputFontSize = _exports.highlightedInputFontFamily = _exports.headlineLineHeight = _exports.headlineFontWeight = _exports.headlineFontSize = _exports.captionLineHeight = _exports.captionFontWeight = _exports.captionFontSize = _exports.buttonLineHeight = _exports.buttonFontWeight = _exports.buttonFontSize = _exports.body2LineHeight = _exports.body2FontWeight = _exports.body2FontSize = _exports.body1LineHeight = _exports.body1FontWeight = _exports.body1FontSize = _exports.baseLineHeight = _exports.baseFontWeight = _exports.baseFontSize = _exports.baseFontFamily = void 0;
  /*
   |------------------------------------------------------------------------------
   | Typefaces
   |------------------------------------------------------------------------------
   */
  var baseFontFamily = _exports.baseFontFamily = "Raleway, Helvetica, Arial, sans-serif";
  var topicFontFamily = _exports.topicFontFamily = "Amiri, Georgia, Times, serif";
  var separatorFontFamily = _exports.separatorFontFamily = "Amiri, Georgia, Times, serif";
  var highlightedInputFontFamily = _exports.highlightedInputFontFamily = "Amiri, Georgia, Times, serif";
  var ruleFontFamily = _exports.ruleFontFamily = "Amiri, Georgia, Times, serif";

  /*
   |------------------------------------------------------------------------------
   | Measurements
   |------------------------------------------------------------------------------
   */

  var baseFontSize = _exports.baseFontSize = 14;
  var baseFontWeight = _exports.baseFontWeight = 500;
  var baseLineHeight = _exports.baseLineHeight = 14;
  var headlineFontSize = _exports.headlineFontSize = 18;
  var headlineLineHeight = _exports.headlineLineHeight = 21;
  var headlineFontWeight = _exports.headlineFontWeight = 700;
  var subheading1FontSize = _exports.subheading1FontSize = 15;
  var subheading1LineHeight = _exports.subheading1LineHeight = 18;
  var subheading1FontWeight = _exports.subheading1FontWeight = 700;
  var subheading2FontSize = _exports.subheading2FontSize = 14;
  var subheading2LineHeight = _exports.subheading2LineHeight = 16;
  var subheading2FontWeight = _exports.subheading2FontWeight = 600;
  var body1FontSize = _exports.body1FontSize = baseFontSize;
  var body1FontWeight = _exports.body1FontWeight = baseFontWeight;
  var body1LineHeight = _exports.body1LineHeight = baseLineHeight;
  var body2FontSize = _exports.body2FontSize = 15;
  var body2LineHeight = _exports.body2LineHeight = baseLineHeight;
  var body2FontWeight = _exports.body2FontWeight = 700;
  var captionFontSize = _exports.captionFontSize = 13;
  var captionLineHeight = _exports.captionLineHeight = 15;
  var captionFontWeight = _exports.captionFontWeight = baseFontWeight;
  var buttonFontSize = _exports.buttonFontSize = 15;
  var buttonFontWeight = _exports.buttonFontWeight = 600;
  var buttonLineHeight = _exports.buttonLineHeight = baseLineHeight;
  var inputLineHeight = _exports.inputLineHeight = baseLineHeight;
  var inputFontSize = _exports.inputFontSize = 16;
  var inputFontWeight = _exports.inputFontWeight = baseFontWeight;
});