/* @flow */

import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import messages from 'src/shared/app/interest/l10n/interestL10n';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';
import { trackGAEvent } from 'src/shared/core/service/tracking/gaService';

type Props = {
  notification: NotificationType<>,
};

const InterestNoResultNotification = ({ notification }: Props): Node => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
    trackGAEvent('Interest added', 'Dismiss notification', 'Notification');
  };

  return (
    <Notification timeout={5000} handleDismiss={handleDismiss}>
      {intl.formatMessage(messages.interestNoResultNotification)}
    </Notification>
  );
};

export default InterestNoResultNotification;
