/* @flow */

import { memo, useEffect, useRef } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';
import ReactModal from 'react-modal';
import { Transition } from 'react-transition-group';

import { modalId } from 'src/shared/app/base/constant/domConstants';
import useModalStatusContext from 'src/shared/app/base/modal/hook/useModalStatusContext';
import usePortalRegister from 'src/shared/app/base/portal/notification/hook/usePortalRegister';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import { notificationTransitionDuration } from 'src/shared/app/notification/constant/notificationConstants';
import {
  disableBodyScrolling,
  enableBodyScrolling,
} from 'src/shared/core/util/browserUtil';

import styles from './BaseModal.style';

type Props = {
  children: Node,
  width?: string | number,
  shouldCloseOnOverlayClick?: boolean,
  onAfterOpen?: () => void,
  handleClose?: () => void,
  onAfterClose?: () => void,
  isOpen?: boolean,
  isAnimated?: boolean,
  state?: string,
  ariaLabelledBy?: string,
  ariaDescribedBy?: string,
  ariaLabel?: string,
  baseClassName?: string,
};

const useStyles = createUseThemeStyles(styles);

const ModalElt = ({
  children,
  width,
  handleClose,
  onAfterClose,
  onAfterOpen,
  shouldCloseOnOverlayClick = true,
  isOpen = true,
  isAnimated,
  state,
  ariaLabelledBy = undefined,
  ariaDescribedBy = undefined,
  ariaLabel = undefined,
  baseClassName = undefined,
}: Props) => {
  // TODO - replace useModalStatusContext by usePortalRegister
  const { register, unregister } = useModalStatusContext();
  usePortalRegister(modalId);

  const selfId = useRef<symbol>(Symbol('self'));

  useEffect(() => {
    if (isOpen) {
      register(selfId.current);
      disableBodyScrolling();
    } else {
      enableBodyScrolling();
      unregister(selfId.current);
    }
    return () => {
      enableBodyScrolling();
      unregister(selfId.current);
    };
  }, []);

  const handleAfterClose = () => {
    if (isOpen) return;

    unregister(selfId.current);
    if (onAfterClose) {
      onAfterClose();
    }
  };

  const classes = useStyles({ width });

  const getAnimationClasses = (animationState) => {
    if (!isAnimated) return '';
    switch (animationState) {
      case Transition.ENTERING:
        return classes.contentEntering;
      case Transition.EXITING:
      case Transition.EXITED:
        return classes.contentExiting;
      default:
        break;
    }
  };

  const getParentSelector = () => {
    const modalContainer = document.getElementById(modalId);
    return modalContainer || document.body;
  };

  return (
    <ReactModal
      className={classNames(
        classes.content,
        baseClassName,
        getAnimationClasses(state),
      )}
      isOpen={isOpen}
      closeTimeoutMS={500}
      overlayClassName={{
        base: classNames(classes.overlay),
        afterOpen: classes['overlay-afterOpen'],
        beforeClose: classes['overlay-beforeClose'],
      }}
      aria={{
        labelledby: ariaLabelledBy,
        describedby: ariaDescribedBy,
        label: ariaLabel,
      }}
      parentSelector={getParentSelector}
      onAfterOpen={onAfterOpen}
      onAfterClose={handleAfterClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={handleClose}
    >
      {children}
    </ReactModal>
  );
};

const Modal = memo(ModalElt);

// eslint-disable-next-line react/prefer-exact-props
const BaseModal = (props: Props): Node => {
  const { isOpen, isAnimated = false } = props;
  /* *Note: having isOpen undefined will result in not being
  able to animate the modal. Also make sure that isOpen value is changed
  from outside of the component. */
  if (!isAnimated || isOpen === undefined) return <Modal {...props} />;
  return (
    <Transition
      in={isOpen}
      timeout={notificationTransitionDuration}
      enter
      exit
      appear
    >
      {(state) => <Modal {...props} state={state} />}
    </Transition>
  );
};

export default memo<Props>(BaseModal);
