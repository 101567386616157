/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider as ThemeProviderMui } from '@mui/styles';
import { configure } from 'react-hotkeys';
import { ThemeProvider } from 'react-jss';

import useCreateBaseStyleProps from 'src/shared/app/base/hook/useCreateBaseStyleProps';
import useMuiCreateTheme from 'src/shared/app/base/style/hook/useMuiCreateTheme';

configure({
  stopEventPropagationAfterIgnoring: false,
});

type Props = {
  children: Node,
};

const AppThemeProvider = ({ children }: Props): Node => {
  const { theme } = useCreateBaseStyleProps();

  const MuiTheme = useMuiCreateTheme(theme);

  return (
    <ThemeProvider theme={theme}>
      <ThemeProviderMui theme={MuiTheme}>
        <EmotionThemeProvider theme={MuiTheme}>{children}</EmotionThemeProvider>
      </ThemeProviderMui>
    </ThemeProvider>
  );
};

export default memo<{}>(AppThemeProvider);
