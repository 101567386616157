(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "@braindate/util/lib/assert", "./userAssertions", "../../base/l10n/util", "../../location/util/locationGetters", "../../event/util/eventGetters"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("@braindate/util/lib/assert"), require("./userAssertions"), require("../../base/l10n/util"), require("../../location/util/locationGetters"), require("../../event/util/eventGetters"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.assert, global.userAssertions, global.util, global.locationGetters, global.eventGetters);
    global.userGetters = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _assert, _userAssertions, _util, _locationGetters, _eventGetters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getConnectionInformationBraindates = getConnectionInformationBraindates;
  _exports.getUserActiveTopicCount = getUserActiveTopicCount;
  _exports.getUserAvatar = getUserAvatar;
  _exports.getUserBraindateCount = getUserBraindateCount;
  _exports.getUserCategories = getUserCategories;
  _exports.getUserCoachmarksFlags = getUserCoachmarksFlags;
  _exports.getUserCompany = getUserCompany;
  _exports.getUserDreamBraindate = getUserDreamBraindate;
  _exports.getUserExcitement = getUserExcitement;
  _exports.getUserFacebookLink = getUserFacebookLink;
  _exports.getUserFavoriteURL = getUserFavoriteURL;
  _exports.getUserFirstName = getUserFirstName;
  _exports.getUserFullName = getUserFullName;
  _exports.getUserFullPosition = getUserFullPosition;
  _exports.getUserGithubLink = getUserGithubLink;
  _exports.getUserId = getUserId;
  _exports.getUserInitials = getUserInitials;
  _exports.getUserIntent = getUserIntent;
  _exports.getUserInterests = getUserInterests;
  _exports.getUserKeywords = getUserKeywords;
  _exports.getUserKnowledge = getUserKnowledge;
  _exports.getUserLanguages = getUserLanguages;
  _exports.getUserLastName = getUserLastName;
  _exports.getUserLifeTitles = getUserLifeTitles;
  _exports.getUserLinkedInLink = getUserLinkedInLink;
  _exports.getUserLinks = getUserLinks;
  _exports.getUserLocale = getUserLocale;
  _exports.getUserLocations = getUserLocations;
  _exports.getUserLoveTalking = getUserLoveTalking;
  _exports.getUserMustShowCoachmark = getUserMustShowCoachmark;
  _exports.getUserPath = getUserPath;
  _exports.getUserPosition = getUserPosition;
  _exports.getUserProfileFieldValues = getUserProfileFieldValues;
  _exports.getUserPronouns = getUserPronouns;
  _exports.getUserResponseDelay = getUserResponseDelay;
  _exports.getUserStatistics = getUserStatistics;
  _exports.getUserTimezone = getUserTimezone;
  _exports.getUserTwitterUsername = getUserTwitterUsername;
  _exports.getUserURL = getUserURL;
  _exports.getUserURLs = getUserURLs;
  _exports.getUserUsername = getUserUsername;
  _exports.getUserWantLearn = getUserWantLearn;
  _exports.getUserWebsite = getUserWebsite;
  _exports.getUsersCompatibleLocations = getUsersCompatibleLocations;
  _exports.isUserEmailValidated = isUserEmailValidated;
  _exports.isUserFavorite = isUserFavorite;
  _exports.isUserOnline = isUserOnline;
  _exports.isUserProfileCompleted = isUserProfileCompleted;
  /*
   |------------------------------------------------------------------------------
   | Base getters
   |------------------------------------------------------------------------------
   */
  /**
   * Get the id of a user
   * @param   {User} user - User to get the id from
   * @returns {number} Id
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserId(user) {
    (0, _userAssertions.assertUser)(user);
    return user.id;
  }

  /**
   * Get the URL to favorite a topic
   * @param   {User} user -  User to get the URL from
   * @returns {string} URL
   */
  function getUserFavoriteURL(user) {
    // @TODO - Get from user.urls as Topic
    return getUserURLs(user).bookmark;
  }

  /**
   * Get the URL of a user
   * @param   {User} user - User to get the URL from
   * @returns {string} URL
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserURL(user) {
    (0, _userAssertions.assertUser)(user);
    return user.url;
  }

  /**
   * Get the URLs of a user
   * @param   {User} user - User to get the URLs from
   * @returns {UserURLs} URLs
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getUserURLs(user) {
    (0, _userAssertions.assertUser)(user);
    var urls = user.urls;
    (0, _userAssertions.assertUserURLs)(urls);
    return urls;
  }

  /**
   * Get the path of a user
   * @param   {User} user - User to get the path from
   * @returns {string} Path
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserPath(user) {
    (0, _userAssertions.assertUser)(user);
    var path = user.path;
    (0, _userAssertions.assertUserPath)(path);
    return user.path;
  }

  /**
   * Get the first name of a user
   * @param   {User} user - User to get the first name from
   * @returns {string} First name
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserFirstName(user) {
    (0, _userAssertions.assertUser)(user);
    return user.first_name;
  }

  /**
   * Get the last name of a user
   * @param   {User} user - User to get the last name from
   * @returns {string} Last name
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserLastName(user) {
    (0, _userAssertions.assertUser)(user);
    return user.last_name;
  }

  /**
   * Check if a user is online
   * @param   {User} user - User to check
   * @returns {boolean} True if the user is
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function isUserOnline(user) {
    (0, _userAssertions.assertUser)(user);
    return user.is_connected;
  }

  /**
   * Get the avatar URLs of a user
   * @param   {User} user - User to get the avatar URLs from
   * @returns {Picture} Avatar URLs
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * exists but is not an object
   */
  function getUserAvatar(user) {
    (0, _userAssertions.assertUser)(user);
    var avatar = user.avatar;
    if (avatar) {
      (0, _userAssertions.assertUserAvatar)(avatar);
      return avatar;
    }
  }

  /**
   * Get the locale of a user
   * @param   {User} user - User to get the locale from
   * @returns {string} Locale
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserLocale(user) {
    (0, _userAssertions.assertUser)(user);
    return user.language || "";
  }

  /**
   * Get the username of a user
   * @param   {User} user - User to get the username from
   * @returns {string} username
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserUsername(user) {
    (0, _userAssertions.assertUser)(user);
    return user.username || "";
  }

  /**
   * Get the links of a user
   * @param   {User} user - User to get the links from
   * @returns {Object} Links
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * exists but is not an object
   */
  function getUserLinks(user) {
    (0, _userAssertions.assertUser)(user);
    var links = user.links;
    if (links) {
      (0, _userAssertions.assertUserLinks)(links);
      return links;
    }
  }

  /**
   * Get the keywords of a user
   * @param   {User} user - User to get the keywords from
   * @returns {Array<UserKeyword>} Keyword data
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * exists but is not an array
   */
  function getUserKeywords(user) {
    (0, _userAssertions.assertUser)(user);
    var keywords = user.keywords;
    if (keywords) {
      (0, _userAssertions.assertUserKeywords)(keywords);
      return keywords;
    }
  }

  /**
   * Get the interests of a user
   * @param   {User} user - User to get the interests from
   * @returns {Array<UserKeyword>} Interests
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * exists but is not an array
   */
  function getUserInterests(user) {
    (0, _userAssertions.assertUser)(user);
    var interests = user.interests;
    if (interests) {
      (0, _userAssertions.assertUserKeywords)(interests);
      return interests;
    }
  }

  /**
   * Get the statistics of a user
   * @param   {User} user - User to get the statistics from
   * @returns {UserStatistics} Statistics
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * exists but is not an object
   */
  function getUserStatistics(user) {
    (0, _userAssertions.assertUser)(user);
    var statistics = user.statistics;
    if (statistics) {
      (0, _userAssertions.assertUserStatistics)(statistics);
      return statistics;
    }
  }

  /**
   * Get the locations of a user
   * @param   {User} user - User
   * @returns {Array<Location>} Locations
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * exists but is not an object
   */
  function getUserLocations(user) {
    (0, _userAssertions.assertUser)(user);
    var locations = user.locations;
    if (locations) {
      (0, _userAssertions.assertUserLocations)(locations);
      return locations;
    }
    return [];
  }

  /**
   * Get the timezone of a user
   * @param   {User} user - User
   * @returns {?string} timezone
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserTimezone(user) {
    (0, _userAssertions.assertUser)(user);
    var timezone = user.timezone;
    return timezone;
  }

  /**
   * Get the Coach marks flags
   * @param   {User} user - User
   * @returns {?object} Coach marks flags
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserCoachmarksFlags(user) {
    (0, _userAssertions.assertUser)(user);
    var guidanceFlags = user.guidance_flags;
    if (guidanceFlags) {
      (0, _userAssertions.assertUserFlags)(guidanceFlags);
      return guidanceFlags;
    }
  }
  function getUserProfileFieldValues(user) {
    (0, _userAssertions.assertUser)(user);
    var profileFieldValues = user.profile_field_values;
    if (profileFieldValues) {
      (0, _assert.assertArray)(profileFieldValues);
      return profileFieldValues;
    }
    return [];
  }

  /*
   |------------------------------------------------------------------------------
   | Advanced getters
   |------------------------------------------------------------------------------
   */

  /**
   * Get the full name of a user
   * @param   {User} user - User to get the full name from
   * @returns {string} Full name
   *
   * @throws Will throw an exception if first and last names are not strings
   */
  function getUserFullName(user) {
    var firstName = getUserFirstName(user);
    var lastName = getUserLastName(user);
    (0, _userAssertions.assertUserFirstName)(firstName);
    (0, _userAssertions.assertUserLastName)(lastName);
    return [firstName, lastName].join(" ").trim();
  }

  /**
   * Get the initials of a user
   * @param   {User} user - User to get the initials from
   * @returns {string} Initials
   *
   * @throws Will throw an exception if first and last names are not strings
   */
  function getUserInitials(user) {
    var firstName = getUserFirstName(user);
    var lastName = getUserLastName(user);
    (0, _userAssertions.assertUserFirstName)(firstName);
    (0, _userAssertions.assertUserLastName)(lastName);
    return [firstName[0], lastName[0]].join("").trim();
  }

  /**
   * Get the position of a user
   * @param   {User} user - User to get the position from
   * @param   {string} [locale] - Locale in which to get the position
   * @returns {string} Position
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserPosition(user, locale) {
    (0, _userAssertions.assertUser)(user);
    return (0, _util.getLocalizedData)(user, locale).position || "";
  }

  /**
   * Get the company of a user
   * @param   {User} user - User to get the company from
   * @param   {string} [locale] - Locale in which to get the company
   * @returns {string} Company
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserCompany(user, locale) {
    (0, _userAssertions.assertUser)(user);
    return (0, _util.getLocalizedData)(user, locale).company || "";
  }

  /**
   * Get the full position (i.e. with company) of a user
   * @param   {User} user - User to get the full position from
   * @param   {string} [locale] - Locale in which to get the full position
   * @param   {string} [separator] - Separator between position and company
   * @returns {string} Full position
   */
  function getUserFullPosition(user, locale) {
    var separator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ", ";
    var position = getUserPosition(user, locale);
    var company = getUserCompany(user, locale);
    return position || company ? [position, company].filter(Boolean).join(separator) : "";
  }

  /**
   * Get the life titles of a user
   * @param   {User} user - User to get the life titles from
   * @param   {string} [locale] - Locale in which to get the life titles
   * @returns {string} Life titles
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserLifeTitles(user, locale) {
    (0, _userAssertions.assertUser)(user);
    return (0, _util.getLocalizedData)(user, locale).roles || "";
  }

  /**
   * Get the excitement statement of a user
   * @param   {User} user - User to get the excitement statement from
   * @param   {string} [locale] - Locale in which to get the excitement statement
   * @returns {string} Excitement statement
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserExcitement(user, locale) {
    (0, _userAssertions.assertUser)(user);
    return (0, _util.getLocalizedData)(user, locale).excited_about || "";
  }

  /**
   * Get the intent of a user
   * @param   {User} user - User to get the intent from
   * @param   {string} [locale] - Locale in which to get the intent
   * @returns {string} Intent
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserIntent(user, locale) {
    (0, _userAssertions.assertUser)(user);
    return (0, _util.getLocalizedData)(user, locale).preferred_braindates || "";
  }

  /**
   * Get the dream braindate of a user
   * @param   {User} user - User to get the dream braindate from
   * @param   {string} [locale] - Locale in which to get the dream braindate
   * @returns {string} Dream braindate
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserDreamBraindate(user, locale) {
    (0, _userAssertions.assertUser)(user);
    return (0, _util.getLocalizedData)(user, locale).dream_braindate || "";
  }

  /**
   * Get the love talking about of a user
   * @param   {User} user - User to get the Love Talking About from
   * @param   {string} [locale] - Locale in which to get the Love Talking About
   * @returns {Array<string>} Love Talking About
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserLoveTalking(user, locale) {
    (0, _userAssertions.assertUser)(user);
    var loveTalkingAbout = (0, _util.getLocalizedData)(user, locale).love_talking_about;
    if (loveTalkingAbout) {
      (0, _assert.assertArray)(loveTalkingAbout);
      return loveTalkingAbout;
    }
  }

  /**
   * Get the want to learn of a user
   * @param   {User} user - User to get the Want To Learn from
   * @param   {string} [locale] - Locale in which to get the Want To Learn
   * @returns {Array<string>} Want To Learn
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserWantLearn(user, locale) {
    (0, _userAssertions.assertUser)(user);
    var wantToLearn = (0, _util.getLocalizedData)(user, locale).want_to_learn;
    if (wantToLearn) {
      (0, _assert.assertArray)(wantToLearn);
      return wantToLearn;
    }
  }

  /**
   * Get the knowledgeable about of a user
   * @param   {User} user - User to get the Knowledgeable About from
   * @param   {string} [locale] - Locale in which to get the Knowledgeable About
   * @returns {Array<string>} Knowledgeable About
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserKnowledge(user, locale) {
    (0, _userAssertions.assertUser)(user);
    var knowledgeableAbout = (0, _util.getLocalizedData)(user, locale).knowledgeable_about;
    if (knowledgeableAbout) {
      (0, _assert.assertArray)(knowledgeableAbout);
      return knowledgeableAbout;
    }
  }

  /**
   * Get the languages of a user
   * @param   {User} user - User to get the languages from
   * @returns {Array<string>} languages
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserLanguages(user) {
    (0, _userAssertions.assertUser)(user);
    var languages = user.languages;
    if (languages) {
      (0, _assert.assertArray)(languages);
      return languages;
    }
    return [];
  }

  /**
   * Get the pronouns of a user
   * @param   {User} user - User to get the pronouns  from
   * @param   {string} [locale] - Locale in which to get the pronouns
   * @returns {string} pronouns
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUserPronouns(user, locale) {
    (0, _userAssertions.assertUser)(user);
    return (0, _util.getLocalizedData)(user, locale).pronouns || "";
  }

  /**
   * Get the Facebook link of a user
   * @param   {User} user - User to get the Facebook link from
   * @returns {string} Facebook link
   */
  function getUserFacebookLink(user) {
    var links = getUserLinks(user);
    var value = links ? links.facebook : undefined;
    return value || "";
  }

  /**
   * Get the LinkedIn link of a user
   * @param   {User} user - User to get the LinkedIn link from
   * @returns {string} LinkedIn link
   */
  function getUserLinkedInLink(user) {
    var links = getUserLinks(user);
    var value = links ? links.linkedin : undefined;
    return value || "";
  }

  /**
   * Get the Twitter username of a user
   * @param   {User} user - User to get the Twitter username from
   * @returns {string} Twitter username
   */
  function getUserTwitterUsername(user) {
    var links = getUserLinks(user);
    var value = links ? links.twitter : undefined;
    return value || "";
  }

  /**
   * Get the website of a user
   * @param   {User} user - User to get the website from
   * @returns {string} Website
   */
  function getUserWebsite(user) {
    var links = getUserLinks(user);
    var value = links ? links.website : undefined;
    return value || "";
  }

  /**
   * Get the github of a user
   * @param   {User} user - User to get the github from
   * @returns {string} Github
   */
  function getUserGithubLink(user) {
    var links = getUserLinks(user);
    var value = links ? links.github : undefined;
    return value || "";
  }

  /**
   * Get the response delay of a user
   * @param   {User} user - User to get the response delay from
   * @returns {string} Response delay
   */
  function getUserResponseDelay(user) {
    var stats = getUserStatistics(user);
    var value = stats ? stats.response_delay : undefined;
    return value || "";
  }

  /**
   * Get the braindate count of a user
   * @param   {User} user - User to get the braindate count from
   * @returns {number} Braindate count
   */
  function getUserBraindateCount(user) {
    var stats = getUserStatistics(user);
    return stats ? stats.braindates_count : undefined;
  }

  /**
   * Get the topic count of a user
   * @param   {User} user - User to get the topic count from
   * @returns {number} Topic count
   */
  function getUserActiveTopicCount(user) {
    var stats = getUserStatistics(user);
    return stats ? stats.topics_active_count : undefined;
  }

  /**
   * Check if a user has been favorited by the authenticated user
   * @param   {User} user - User to check
   * @returns {boolean} True if the user has
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function isUserFavorite(user) {
    (0, _userAssertions.assertUser)(user);
    var bookmarked = user.bookmarked;

    // If not defined, we assume it's not bookmarked by default
    return typeof bookmarked === "undefined" ? false : bookmarked;
  }

  /**
   * Get all compatible location between two users
   * @param   {User} userA - User A
   * @param   {User} userB - User B
   * @returns {Array<Location>} True if the user has
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getUsersCompatibleLocations(userA, userB) {
    (0, _userAssertions.assertUser)(userA);
    (0, _userAssertions.assertUser)(userB);
    var locationsA = getUserLocations(userA);
    var locationsB = getUserLocations(userB);

    // If not defined, we assume it's not bookmarked by default
    return (0, _locationGetters.getLocationsCompatible)(locationsA, locationsB);
  }

  /**
   * Get the user categories
   * @param   {User} user - User to get the categories from
   * @returns {string[]} -
   */
  function getUserCategories(user) {
    (0, _userAssertions.assertUser)(user);
    var categories = user.categories;
    return categories;
  }

  /**
   * Get the user organized braindates of a User Connection Information
   * @param   {UserConnectionInformation} connectionInformation - Connection Information object
   * @returns {Array<Braindate>} Organized braindates
   */
  function getConnectionInformationBraindates(connectionInformation) {
    var organisedBraindates = connectionInformation.organised_braindates;
    (0, _assert.assertArray)(organisedBraindates);
    return organisedBraindates;
  }

  /**
   * Get if a coachmark needs to be shown tp the user
   * @param   {User} user - User
   * @param   {CoachmarkKey} key - Coachmark key
   * @returns {boolean} If that Coachmark needs to be shown
   */
  function getUserMustShowCoachmark(user, key) {
    (0, _assert.assertString)(key);
    var flags = getUserCoachmarksFlags(user);
    return !(flags && flags[key]);
  }

  /**
   * Check if the profile is completed
   * @param   {User} user - User to check
   * @param   {BraindateEvent} event - Event that contains required fields
   * @param   {string} [locale] - Locale
   * @returns {boolean} True if it is
   */
  function isUserProfileCompleted(user, event) {
    var requiredFields = (0, _eventGetters.getEventProfileRequiredFields)(event);
    var body = (0, _util.getLocalizedData)(user, getUserLocale(user));
    if (!getUserLastName(user) || !getUserFirstName(user)) {
      return false;
    }
    if (requiredFields) {
      for (var i = 0; i < requiredFields.length; i += 1) {
        if (!body[requiredFields[i]]) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Is the user email validated
   * @param {User} user User to check for
   * @returns {string} Returns true if the email is validated
   */
  function isUserEmailValidated(user) {
    (0, _userAssertions.assertUser)(user);
    var isEmailValidated = user.email_is_validated;
    return !!isEmailValidated;
  }
});