/* @flow */

import { Fragment, memo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import MainNav from 'src/shared/app/nav/component/main/MainNav';
import { selectHasBottomBar } from 'src/shared/app/nav/selector/navSelectors';
import {
  registerMobileNav,
  unRegisterMobileNav,
} from 'src/shared/app/nav/slice/navSlice';
import HideOnScroll from 'src/shared/ui/component/misc/HideOnScroll';

import styles from './MobileNavBar.style';

const useStyles = createUseThemeStyles(styles);

const MobileNavBar = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const hasBottomBar = useSelector(selectHasBottomBar);
  const { isDrawerMenuEnabled } = useFeatureDecisions();

  useEffect(() => {
    dispatch(registerMobileNav());
    return () => {
      dispatch(unRegisterMobileNav());
    };
  }, []);

  if (hasBottomBar || isDrawerMenuEnabled) return null;

  return (
    <>
      <div className={classes.spacer} />
      <HideOnScroll dir="up" ignoreFeatureFlag>
        <div className={classes.root}>
          <div className={classes.inner}>
            <MainNav isMobile />
          </div>
        </div>
      </HideOnScroll>
    </>
  );
};

export default memo<{}>(MobileNavBar);
