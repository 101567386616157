/* @flow */
import { em } from 'polished';

export default {
  root: {
    width: em(22),
    height: em(22),
    stroke: 'currentColor',
    fill: 'currentColor',
  },
};
