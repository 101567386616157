/* @flow */

import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { successColor } from 'src/shared/app/base/style/token/color';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    gap: '5px',
    color: ({ theme }: ThemeFunction<{}>) => theme.textColorTertiary,
    fontSize: rem(14, baseFontSize),
    lineHeight: '1.2',
    width: 'max-content',
  },
  checkInIcon: {
    width: 15,
    height: 15,
  },
  checkInIconActive: {
    color: successColor,
  },
};
