/* @flow */

import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

export default {
  alert: {
    fontWeight: 400,

    '& .MuiAlert-action': {
      position: ({ isCentered }: Object) => (isCentered ? 'absolute' : 'unset'),
      right: ({ isCentered }: Object) => (isCentered ? 10 : 'unset'),
    },
    '& .MuiButtonBase-root': {
      minWidth: 'auto',
      minHeight: 'auto',
    },
    justifyContent: ({ isCentered }: Object) =>
      isCentered ? 'center' : 'normal',
  },
  message: {
    fontSize: rem(16, baseFontSize),
  },
  link: {
    fontSize: rem(16, baseFontSize),
    fontWeight: 700,
    textDecoration: 'underline',
    textAlign: 'left',
  },
  icon: {
    alignItems: 'center',
  },
  checkIcon: {
    fontSize: '0.7em',
  },
  text: {
    margin: 0,
  },
};
