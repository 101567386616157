/* @flow */

import * as types from 'src/shared/app/topic/new/action/uiTopicActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  showSlackOptinModal: false,
};

const _showSlackOptinModal = (state: Object) => ({
  ...state,
  showSlackOptinModal: true,
});

const _dismissSlackOptinModal = (state: Object) => ({
  ...state,
  showSlackOptinModal: false,
});

export default createReducer(initialState, {
  [types.SHOW_SLACK_OPTIN_MODAL]: _showSlackOptinModal,
  [types.DISMISS_SLACK_OPTIN_MODAL]: _dismissSlackOptinModal,
});
