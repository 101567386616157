(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verticalSlidingDuration = _exports.tintDuration = _exports.pressDuration = _exports.fadeDuration = void 0;
  var fadeDuration = _exports.fadeDuration = "0.1s";
  var tintDuration = _exports.tintDuration = "0.1s";
  var pressDuration = _exports.pressDuration = "0.1s";
  var verticalSlidingDuration = _exports.verticalSlidingDuration = "0.5s";
});