import { createSlice } from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
  name: 'notificationUnreadCount',
  initialState: 0,
  reducers: {
    setUnreadNotificationsCount: (state, { payload }) => payload,
  },
});

export const { setUnreadNotificationsCount } = notificationSlice.actions;

export default notificationSlice.reducer;
