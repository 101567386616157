/* @flow */

export const supportForm = 'supportForm';
export const supportRetrieveEmailSuccess = 'supportRetrieveEmailSuccess';
export const supportLoginLinkSuccess = 'supportLoginLinkSuccess';
export const supportContactSuccess = 'supportContactSuccess';
export const supportPasswordSection = 'supportPasswordSection';
export const supportResetPasswordSection = 'supportResetPasswordSection';

export const passwordSupportLinkSection = 'passwordSupportLinkSection';
export const passwordSupportResetSection = 'passwordSupportResetSection';
export const passwordSupportContactSection = 'passwordSupportContactSection';
