(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "@braindate/util/lib/assert"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("@braindate/util/lib/assert"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.assert);
    global.locationAssertions = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _assert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertLocation = assertLocation;
  _exports.assertLocationBraindateRanges = assertLocationBraindateRanges;
  _exports.assertLocationURLs = assertLocationURLs;
  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertLocation(value) {
    (0, _assert.assertObject)(value, "location");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertLocationURLs(value) {
    (0, _assert.assertObject)(value, "location.urls");
  }

  // /**
  //  * Throw an exception if parameter is not an array
  //  * @param   {any} value - Value to check
  //  * @returns {undefined}
  //  */
  // export function assertLocationGroupBraindateSlots(value: any): void {
  //   assertArray(value, "location.group_braindate_ranges");
  // }

  // /**
  //  * Throw an exception if parameter is not an array
  //  * @param   {any} value - Value to check
  //  * @returns {undefined}
  //  */
  // export function assertLocationGroupBraindateAvailableSlots(value: any): void {
  //   assertArray(value, "location.group_braindate_time_slots_suggestions");
  // }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertLocationBraindateRanges(value) {
    (0, _assert.assertArray)(value, "location.braindate_ranges");
  }
});