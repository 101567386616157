/* @flow */

import type { Node } from 'react';

import MarketBasePromptCard from 'src/shared/app/market/guidance/component/MarketBasePromptCard';
import { getGuidanceIndexes } from 'src/shared/app/market/guidance/settings/guidanceSettings';

export function feedInsertGuidances(items: Array<any>, guidanceLabel: string) {
  getGuidanceIndexes(guidanceLabel).forEach((index) => {
    if (items.length > index) {
      items.splice(
        index,
        0,
        <MarketBasePromptCard
          key={guidanceLabel}
          guidanceLabel={guidanceLabel}
        />,
      );
    }
  });

  return items;
}

export function feedGetSponsorToInsert(
  itemIndex: number,
  interstitialIndexes: ?Array<number>,
  interstitialComponents: ?Array<Node>,
) {
  if (interstitialIndexes && interstitialComponents) {
    const indexOfInterstitial = interstitialIndexes.indexOf(itemIndex);
    if (indexOfInterstitial >= 0) {
      return interstitialComponents[indexOfInterstitial] || null;
    }
  }
  return null;
}

export function feedInsertSponsors(
  items: Array<any>,
  interstitialIndexes: ?Array<number>,
  interstitialComponents: ?Array<Node>,
) {
  if (interstitialIndexes && interstitialComponents && items.length > 0) {
    interstitialIndexes.forEach((sponsorPosition) => {
      const sponsorComponent = feedGetSponsorToInsert(
        sponsorPosition,
        interstitialIndexes,
        interstitialComponents,
      );
      if (sponsorComponent) {
        items.splice(sponsorPosition, 0, sponsorComponent);
      }
    });
  }
}
