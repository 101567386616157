(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "@braindate/util/lib/assert"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("@braindate/util/lib/assert"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.assert);
    global.userAssertions = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _assert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertUser = assertUser;
  _exports.assertUserActivity = assertUserActivity;
  _exports.assertUserAvatar = assertUserAvatar;
  _exports.assertUserFirstName = assertUserFirstName;
  _exports.assertUserFlags = assertUserFlags;
  _exports.assertUserKeywords = assertUserKeywords;
  _exports.assertUserLastName = assertUserLastName;
  _exports.assertUserLinks = assertUserLinks;
  _exports.assertUserLocations = assertUserLocations;
  _exports.assertUserPath = assertUserPath;
  _exports.assertUserStatistics = assertUserStatistics;
  _exports.assertUserURLs = assertUserURLs;
  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertUser(value) {
    (0, _assert.assertObject)(value, "user");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertUserURLs(value) {
    (0, _assert.assertObject)(value, "user.urls");
  }

  /**
   * Throw an exception if parameter is not a string
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertUserFirstName(value) {
    (0, _assert.assertString)(value, "user.first_name");
  }

  /**
   * Throw an exception if parameter is not a string
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertUserLastName(value) {
    (0, _assert.assertString)(value, "user.last_name");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertUserAvatar(value) {
    (0, _assert.assertObject)(value, "user.avatar");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertUserLinks(value) {
    (0, _assert.assertObject)(value, "user.links");
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertUserKeywords(value) {
    (0, _assert.assertObject)(value, "user.keywords");
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertUserActivity(value) {
    (0, _assert.assertObject)(value, "user.activity");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertUserStatistics(value) {
    (0, _assert.assertObject)(value, "user.statistics");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertUserLocations(value) {
    (0, _assert.assertObject)(value, "user.locations");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertUserPath(value) {
    (0, _assert.assertString)(value, "user.path");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertUserFlags(value) {
    (0, _assert.assertObject)(value, "user.guidance_flags");
  }
});