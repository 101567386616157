/* @flow */

import type { Location } from '@braindate/domain/lib/location/type';
import {
  isOnsiteLocation,
  isVirtualLocation,
} from '@braindate/domain/lib/location/util';

/**
 * Detect if we must use the membership timezone
 * @param {Array<Location>} eventLocations List of event Locations
 * @param {Array<Location>} userLocations List of user Locations
 * @returns {Boolean} True or false
 */
export function isUsingMembershipTimezone(
  eventLocations: Array<Location>,
  userLocations: Array<Location>,
): boolean {
  if (eventLocations.length === 0 && userLocations.length === 0) return true;
  if (eventLocations.length === 1) {
    const [location] = eventLocations;
    if (isVirtualLocation(location)) {
      return true;
    }
  }

  if (userLocations.length === 1) {
    const [location] = userLocations;
    if (isVirtualLocation(location)) {
      return true;
    }
  }
  return false;
}

/**
 * Return the must use location in a list.
 * If there are multiple locations, it will return the first onsite
 *
 * @param {Array<Location>} locations List of locations
 * @returns {Location} Return the location
 */
export function getSingleLocation(locations: Array<Location>): ?Location {
  if (locations.length === 1) {
    return locations[0];
  }
  return locations.find(isOnsiteLocation);
}

/**
 * Return the must use location from User and event Location
 * Will always check user locations and fallback to event locations
 * @param {Array<Location>} eventLocations List of event Locations
 * @param {Array<Location>} userLocations List of user Locations
 * @returns {Location} Return the location
 */
export function findLocationForTimezone(
  eventLocations: Array<Location>,
  userLocations: Array<Location>,
): ?Location {
  const userLocation = getSingleLocation(userLocations);
  if (userLocation) return userLocation;

  const eventLocation = getSingleLocation(eventLocations);
  return eventLocation;
}
