/* @flow */

import { pressDuration } from '@braindate/ui/lib/token/animation';

import { minTargetSize } from 'src/shared/app/base/style/token/box';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'none',

    width: minTargetSize,
    height: minTargetSize,

    transformOrigin: 'center center',
    transition: `transform ${pressDuration}`,

    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
    padding: 0,

    '&:hover': {
      transform: 'scale(1.1, 1.1)',
    },

    '&:active': {
      transform: 'scale(0.9, 0.9)',
    },
  },
  'root-isTrimmed': {
    width: 'auto',
    height: 'auto',
  },
};
