/* @flow */

import { useIntl } from 'react-intl';

import { getTimeFormat } from 'src/shared/app/base/date/util/dateUtils';
import useMembership from 'src/shared/domain/membership/hook/useMembership';

export default function useTimeFormat(): string {
  const { locale } = useIntl();
  const membership = useMembership();

  if (membership) {
    return getTimeFormat(membership, locale) || '';
  }

  return '';
}
