/* @flow */

export const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_FAILURE = 'FETCH_EVENT_FAILURE';

export const FETCH_PUBLIC_EVENT_REQUEST = 'FETCH_PUBLIC_EVENT_REQUEST';
export const FETCH_PUBLIC_EVENT_SUCCESS = 'FETCH_PUBLIC_EVENT_SUCCESS';
export const FETCH_PUBLIC_EVENT_FAILURE = 'FETCH_PUBLIC_EVENT_FAILURE';

export const RESET_EVENT = 'RESET_EVENT';
