/* @flow */
import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { BUTTON_SIZES } from 'src/shared/ui/component/button/BaseButton';

export default {
  root: {
    paddingLeft: ({ withIcon }: Object) => (withIcon ? '3.2em' : null),
    minHeight: ({ size }: Object) => (size === BUTTON_SIZES.SMALL ? '0' : null),
    padding: ({ size }: Object) =>
      size === BUTTON_SIZES.SMALL
        ? `${rem(5, baseFontSize)} ${rem(15, baseFontSize)}`
        : null,
    marginLeft: '0.7em',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '0.9em',
    transform: 'translateY(-50%)',
    maxWidth: '1.5em',
    maxHeight: '1.5em',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
};
