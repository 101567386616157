/* @flow */

import { em } from 'polished';

export default {
  root: {
    width: em(150),

    height: em(118),
  },
};
