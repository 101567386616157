/* @flow */

import { em } from 'polished';

import { tablet } from '@braindate/ui/lib/token/mediaQuery';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

import avatarSizes from './setting/avatarSizes';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ({ variant }: ThemeFunction<{ variant: string }>) =>
      avatarSizes[variant],
    height: ({ variant }: ThemeFunction<{ variant: string }>) =>
      avatarSizes[variant],

    backgroundColor: ({
      theme,
      isFilled,
    }: ThemeFunction<{ isFilled: boolean }>) =>
      isFilled ? theme.textColorQuaternary : theme.colorPrimary,
    borderColor: ({ isFilled, theme }: ThemeFunction<{ isFilled: boolean }>) =>
      isFilled ? theme.colorPrimary : theme.textColorQuaternary,
    borderStyle: ({ isFilled }: ThemeFunction<{ isFilled: boolean }>) =>
      isFilled ? 'solid' : 'dashed',
    borderWidth: '2px',
    marginLeft: -10,
    borderRadius: '50%',
    backgroundBlendMode: 'normal',
    boxSizing: 'border-box',
    zIndex: 0,
    color: ({ isFilled, theme }: ThemeFunction<{ isFilled: boolean }>) =>
      isFilled ? theme.colorPrimary : theme.textColorQuaternary,
    fontWeight: 600,
    fontSize: em(13),

    [tablet]: {
      fontSize: em(16),
    },
  },

  plus: {
    fontSize: '1.7em',
    fontWeight: 400,
    verticalAlign: 'middle',
  },
};

export default styles;
