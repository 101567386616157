/* @flow */

import { aaDarkGrey } from '@braindate/ui/lib/token/palette';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

import avatarSizes from './setting/avatarSizes';

type StyleProps = ThemeFunction<{ variant: string, customSize?: number }>;

export default {
  root: {
    width: '100%',
    maxWidth: ({ variant, customSize }: StyleProps) =>
      customSize || avatarSizes[variant],
    minWidth: ({ variant, customSize }: StyleProps) =>
      customSize || avatarSizes[variant],
    maxHeight: ({ variant, customSize }: StyleProps) =>
      customSize || avatarSizes[variant],
    minHeight: ({ variant, customSize }: StyleProps) =>
      customSize || avatarSizes[variant],
    height: '100%',

    backgroundColor: aaDarkGrey,
    borderRadius: '50%',
    borderColor: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    borderWidth: 2,
    aspectRatio: '1 / 1',
  },
};
