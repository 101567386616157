/* @flow */

import type { BraindateEvent } from '@braindate/domain/lib/event/type';
import { getEventPlugins } from '@braindate/domain/lib/event/util';
import { assertObject } from '@braindate/util/lib/assert';

import { liveChatPlugin } from 'plugin/live-chat/setting/liveChatSettings';

export function getLiveChatOptions(event: BraindateEvent): ?Object {
  const options = getEventPlugins(event)[liveChatPlugin];

  if (options) {
    assertObject(options, 'options');

    return options;
  }
}

export function getLiveChatProvider(sdkOptions: Object): ?string {
  return sdkOptions.provider;
}
