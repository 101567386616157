/* @flow */

import { queueNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import { convertEngagementTriggerToNotification } from 'src/shared/app/topic/new/util/newTopicUtil';

/*
 |------------------------------------------------------------------------------
 | Notifications
 |------------------------------------------------------------------------------
 */

export function showEngagementModal(data: string) {
  const notification = convertEngagementTriggerToNotification(data);
  if (notification) {
    return queueNotification(notification);
  }
}
