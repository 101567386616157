/* @flow */

import type { State } from 'src/shared/core/type/reduxTypes';

export function getPortalUtilityLength(portalKey: string) {
  return (state: State) =>
    Object.values(state.ui.portalRegistration).filter(
      // $FlowIssue https://github.com/facebook/flow/issues/2221
      (element) => element.portalKey === portalKey,
    ).length;
}
