/* @flow */

import { transparentize } from 'polished';

import {
  black,
  blueGrey,
  darkGreen,
  darkRed,
  darkYellow,
  green,
  mediumGrey,
  red,
  white,
  yellow,
} from '@braindate/ui/lib/token/palette';

export const textColor = white;
export const cardBaseColor = white;
export const baseColor = blueGrey;
export const darkTextColor = black;
export const darkCardBaseColor = '#222222';
export const darkThemeBaseColor = '#363739';

export const successColor = green;
export const darkSuccessColor = darkGreen;
export const warningColor = yellow;
export const darkWarningColor = darkYellow;
export const errorColor = red;
export const darkErrorColor = darkRed;
export const dateColor = 'rgb(124, 134, 152)';

export const overlayColor = transparentize(0.25, black);

export const borderColor = mediumGrey;

export const fishbowlGreen = '#28C18A';
export const fishbowlLightGreen = '#95DBC9';
export const fishbowlYellow = '#F9B131';

export const canlendarBackground = '#78CFB3';

export const infoGrey = '#40606A';
