/* @flow */

import type { Topic } from '@braindate/domain/lib/topic/type';

import type { Notification } from 'src/shared/app/notification/type/notificationTypes';

import * as types from './uiNotificationActionTypes';

export function queueNotification(notification: Notification<>) {
  return {
    type: types.QUEUE_NOTIFICATION,
    notification,
  };
}

export function dismissNotification(notification: string | Notification<>) {
  return {
    type: types.DISMISS_NOTIFICATION,
    notification,
  };
}

export function dismissNotificationsBundle(
  notifications: Array<Notification<>>,
) {
  return (dispatch: any) =>
    notifications.map<Notification<>>((notification) =>
      dispatch(dismissNotification(notification)),
    );
}

export function dismissTopicNotifications(topic: string | Topic) {
  return {
    type: types.DISMISS_TOPIC_NOTIFICATIONS,
    topic,
  };
}
