/* @flow */

import type { CheerPlugin } from '@braindate/domain/lib/event/type';

import { cheerPlugin as cheerPluginKey } from 'src/shared/app/feature/settings/featureSettings';
import useEventPlugin from 'src/shared/domain/event/hook/useEventPlugin';

const useCheerPlugin = (): CheerPlugin => {
  const cheerPlugin = useEventPlugin()[cheerPluginKey];

  return cheerPlugin || { enable_unwrapit: false };
};

export default useCheerPlugin;
