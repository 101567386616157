/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { VisuallyHidden } from 'react-aria';
import { useIntl } from 'react-intl';

import type { Topic } from '@braindate/domain/lib/topic/type';
import { getTopicFormat } from '@braindate/domain/lib/topic/util';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import messages from 'src/shared/domain/topic/l10n/topicL10n';
import SecondaryTag from 'src/shared/ui/component/data-display/SecondaryTag';
import InfoIcon from 'src/shared/ui/component/icon/InfoIcon';

import styles from './TopicFormatTag.style';

type Props = {
  topic: Topic,
};

const useStyles = createUseThemeStyles(styles);

const TopicFormatTag = ({ topic }: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */

  const intl = useIntl();
  const topicFormat = getTopicFormat(topic);
  const { isConversationFormatEnabled } = useFeatureDecisions();

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */

  const classes = useStyles();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  if (!isConversationFormatEnabled || !topicFormat) return null;
  const tooltipId = `tooltip-${topicFormat}`;

  return (
    <SecondaryTag
      aria-describedby={tooltipId}
      tooltip={intl.formatMessage(messages[`${topicFormat}Tooltip`])}
    >
      {intl.formatMessage(messages[topicFormat])}
      <InfoIcon className={classes.icon} />
      <VisuallyHidden id={tooltipId} role="tooltip">
        {intl.formatMessage(messages[`${topicFormat}Tooltip`])}
      </VisuallyHidden>
    </SecondaryTag>
  );
};

export default memo<Props>(TopicFormatTag);
