/* @flow */
import { useIntl } from 'react-intl';

import {
  getEventPlugins,
  getEventSplashSponsors,
  getEventSponsorLink,
  getEventSponsorLogo,
  getEventSponsorLogoAltText,
  getEventSponsorName,
  getEventSponsorPluginLevelIsEnabled,
  getEventSponsorPluginSplashTitle,
} from '@braindate/domain/lib/event/util';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import {
  loginSplashLevel,
  sponsorshipPlugin as sponsorshipPluginKey,
} from 'src/shared/app/feature/settings/featureSettings';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import ResponsivePicture from 'src/shared/ui/component/image/ResponsivePicture';

import styles from './IntroScreenSponsors.style';

const useStyles = createUseThemeStyles(styles);

const IntroScreenSponsors = () => {
  const intl = useIntl();
  const event = useEvent();
  const classes = useStyles();

  const sponsorshipPlugin = getEventPlugins(event)[sponsorshipPluginKey];
  const isSponsorPluginEnabled =
    sponsorshipPlugin &&
    getEventSponsorPluginLevelIsEnabled(sponsorshipPlugin, loginSplashLevel);

  const sponsorsHeaderTitle = sponsorshipPlugin
    ? getEventSponsorPluginSplashTitle(sponsorshipPlugin, intl.locale)
    : null;
  const eventSponsors = isSponsorPluginEnabled
    ? getEventSplashSponsors(event)
    : [];

  const sponsors = eventSponsors.map((sponsor) => ({
    name: getEventSponsorName(sponsor, intl.locale),
    logo: getEventSponsorLogo(sponsor),
    logoAltText: getEventSponsorLogoAltText(sponsor, intl.locale),
    url: getEventSponsorLink(sponsor),
  }));
  if (!sponsors.length) return null;

  return (
    <div className={classes.root}>
      {sponsorsHeaderTitle ? (
        <h2 className={classes.title}>{sponsorsHeaderTitle}</h2>
      ) : null}

      {sponsors.map(({ logo, logoAltText }, key) => (
        <ResponsivePicture
          className={classes.logo}
          picture={logo}
          alt={logoAltText}
          displayWidth={45}
          provideFallback
          key={key}
        />
      ))}
    </div>
  );
};

export default IntroScreenSponsors;
