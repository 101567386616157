(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./braindateAssertions", "../../user/util/userGetters", "../../conversation/util/conversationGetters", "./braindateConstant"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./braindateAssertions"), require("../../user/util/userGetters"), require("../../conversation/util/conversationGetters"), require("./braindateConstant"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.braindateAssertions, global.userGetters, global.conversationGetters, global.braindateConstant);
    global.braindateGetters = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _braindateAssertions, _userGetters, _conversationGetters, _braindateConstant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getBraindateCapacity = getBraindateCapacity;
  _exports.getBraindateCheckInStatus = getBraindateCheckInStatus;
  _exports.getBraindateCheckInURL = getBraindateCheckInURL;
  _exports.getBraindateConversation = getBraindateConversation;
  _exports.getBraindateEndTime = getBraindateEndTime;
  _exports.getBraindateFeedbackURL = getBraindateFeedbackURL;
  _exports.getBraindateGoogleCalSync = getBraindateGoogleCalSync;
  _exports.getBraindateId = getBraindateId;
  _exports.getBraindateLastActiveUser = getBraindateLastActiveUser;
  _exports.getBraindateLocation = getBraindateLocation;
  _exports.getBraindatePath = getBraindatePath;
  _exports.getBraindatePod = getBraindatePod;
  _exports.getBraindateSpotsLeft = getBraindateSpotsLeft;
  _exports.getBraindateStartTime = getBraindateStartTime;
  _exports.getBraindateStatus = getBraindateStatus;
  _exports.getBraindateTimeSlot = getBraindateTimeSlot;
  _exports.getBraindateURL = getBraindateURL;
  _exports.getBraindateURLs = getBraindateURLs;
  _exports.getBraindateiCalURL = getBraindateiCalURL;
  _exports.hasUserJoinedBraindate = hasUserJoinedBraindate;
  _exports.isBraindateCancelled = isBraindateCancelled;
  _exports.isBraindateConfirmed = isBraindateConfirmed;
  _exports.isBraindateDeclined = isBraindateDeclined;
  _exports.isBraindatePending = isBraindatePending;
  /**
   * Get the id of a braindate
   * @param   {Braindate} braindate - Braindate to get the id from
   * @returns {number} Id
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getBraindateId(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    return braindate.id;
  }

  /**
   * Get the URL of a braindate
   * @param   {Braindate} braindate - Braindate to get the URL from
   * @returns {string} URL
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getBraindateURL(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    return braindate.url;
  }

  /**
   * Get the path of a braindate
   * @param   {Braindate} braindate - Braindate to get the path from
   * @returns {string} Path
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getBraindatePath(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    return braindate.path;
  }

  /**
   * Check if a braindate is confirmed
   * @param   {Braindate} braindate - Braindate to check
   * @returns {boolean} True if the braindate is
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function isBraindateConfirmed(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    var status = getBraindateStatus(braindate);
    return status === _braindateConstant.STATUS.CONFIRMED;
  }

  /**
   * Check if a braindate is declined
   * @param   {Braindate} braindate - Braindate to check
   * @returns {boolean} True if the braindate is
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function isBraindateDeclined(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    var status = getBraindateStatus(braindate);
    return status === _braindateConstant.STATUS.DECLINED;
  }

  /**
   * Check if a braindate is cancelled
   * @param   {Braindate} braindate - Braindate to check
   * @returns {boolean} True if the braindate is
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function isBraindateCancelled(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    var status = getBraindateStatus(braindate);
    return status === _braindateConstant.STATUS.CANCELLED;
  }

  /**
   * Check if a braindate is pending
   * @param   {Braindate} braindate - Braindate to check
   * @returns {boolean} True if the braindate is
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function isBraindatePending(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    var status = getBraindateStatus(braindate);
    return status === _braindateConstant.STATUS.PENDING;
  }

  /**
   * Check if a braindate status
   * @param   {Braindate} braindate - Braindate to check
   * @returns {string} string of the status cancelled|confirmed|declined|pending
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getBraindateStatus(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    var status = braindate.status;
    (0, _braindateAssertions.assertBraindateStatus)(status);
    return status;
  }

  /**
   * Get the conversation of a braindate
   * @param   {Braindate} braindate - Braindate to get the conversation from
   * @returns {Conversation} Conversation
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getBraindateConversation(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    var conversation = braindate.conversation;
    (0, _braindateAssertions.assertBraindateConversation)(conversation);
    return conversation;
  }

  /**
   * Get the last active user of a braindate
   * @param   {Braindate} braindate - Braindate to get the last active user from
   * @returns {User} User
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getBraindateLastActiveUser(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    var user = braindate.last_user_proposition;
    (0, _braindateAssertions.assertBraindateLastActiveUser)(user);
    return user;
  }

  /**
   * Get the URLs of a braindate
   * @param   {Braindate} braindate - Braindate to get the URLs from
   * @returns {BraindateURLs} URLs
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   *  is not an object
   */
  function getBraindateURLs(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    var urls = braindate.urls;
    (0, _braindateAssertions.assertBraindateURLs)(urls);
    return urls;
  }

  /**
   * Get the capacity of a braindate
   * @param   {Braindate} braindate - Braindate to get the capacity from
   * @returns {number} Capacity
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getBraindateCapacity(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    return braindate.capacity;
  }

  /**
   * Get the spots left of a braindate
   * @param   {Braindate} braindate - Braindate to get the spots left from
   * @returns {number} Capacity
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getBraindateSpotsLeft(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    return braindate.spots_left;
  }

  /**
   * Get the date of a braindate
   * @param   {Braindate} braindate - Braindate to get the date from
   * @returns {TimeSlot} Date
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is defined but is not an object
   */
  function getBraindateTimeSlot(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    var date = braindate.date;
    if (date) {
      (0, _braindateAssertions.assertBraindateTimeSlot)(date);
      return date;
    }
  }

  /**
   * Get the pod index of a braindate
   * @param   {Braindate} braindate - Braindate to get the pod index from
   * @returns {number} Pod
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getBraindatePod(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    return braindate.pod;
  }

  /**
   * Get the check-in status of a braindate
   * @param   {Braindate} braindate - Braindate to get the check-in status from
   * @returns {Array<BraindateCheckInData>} Check-in status
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * exists but is not an array
   */
  function getBraindateCheckInStatus(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    var checkins = braindate.checkins;
    if (checkins) {
      (0, _braindateAssertions.assertBraindateCheckInStatus)(checkins);
      return checkins;
    }
  }

  /**
   * Get the location of a braindate
   * @param   {Braindate} braindate - Braindate
   * @returns {Location} Locations
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * exists but is not an object
   */
  function getBraindateLocation(braindate) {
    (0, _braindateAssertions.assertBraindate)(braindate);
    var location = braindate.location;
    if (location) return location;
  }

  /*
   |------------------------------------------------------------------------------
   | Advanced getters
   |------------------------------------------------------------------------------
   */

  /**
   * Get the URL to check in the braindate
   * @param   {Braindate} braindate - Braindate to get URL from
   * @returns {string} URL
   */
  function getBraindateCheckInURL(braindate) {
    return getBraindateURLs(braindate).checkin;
  }

  /**
   * Get the URL to give feedback to the braindate
   * @param   {Braindate} braindate - Braindate to get URL from
   * @returns {string} URL
   */
  function getBraindateFeedbackURL(braindate) {
    return getBraindateURLs(braindate).followup;
  }

  /**
   * Get the iCal URL of a braindate
   * @param   {Braindate} braindate - Braindate to get URL from
   * @returns {string} URL
   */
  function getBraindateiCalURL(braindate) {
    return getBraindateURLs(braindate)["ical-file"];
  }

  /**
   * Get the Google cal sync URL of a braindate
   * @param   {Braindate} braindate - Braindate to get URL from
   * @returns {string} URL
   */
  function getBraindateGoogleCalSync(braindate) {
    return getBraindateURLs(braindate)["google-calendar-export"];
  }

  /**
   * Get the start time of a braindate
   * @param   {Braindate} braindate - Braindate to get the start time from
   * @returns {string} Start time
   */
  function getBraindateStartTime(braindate) {
    var slot = getBraindateTimeSlot(braindate);
    if (slot) {
      return slot.start_time;
    }
  }

  /**
   * Get the end time of a braindate
   * @param   {Braindate} braindate - Braindate to get the end time from
   * @returns {string} End time
   */
  function getBraindateEndTime(braindate) {
    var slot = getBraindateTimeSlot(braindate);
    if (slot) {
      return slot.end_time;
    }
  }

  /**
   * Gets if the user has joined the braindate
   * @param   {Braindate} braindate - Braindate to get the end time from
   * @param   {User} user - User to check
   * @returns {boolean} If the user has joined or not
   */
  function hasUserJoinedBraindate(braindate, user) {
    var conversation = getBraindateConversation(braindate);
    var participants = (0, _conversationGetters.getConversationParticipants)(conversation);
    return participants.some(function (participant) {
      return (0, _userGetters.getUserId)(participant) === (0, _userGetters.getUserId)(user);
    });
  }
});