/* @flow */

import * as types from 'src/shared/app/market/action/marketActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  ordering: '',
};

const _setOrdering = (state: Object, action: Object) => {
  const { value } = action;

  return {
    ...state,
    ordering: value,
  };
};

const _resetOrdering = (state: Object) => ({
  ...state,
  ordering: '',
});

export default createReducer(initialState, {
  [types.SET_MARKET_SORT_ORDERING]: _setOrdering,
  [types.RESET_MARKET_SORT_ORDERING]: _resetOrdering,
});
