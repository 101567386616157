/* @flow */
import { memo } from 'react';

import { useIntl } from 'react-intl';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/braindate/l10n/braindateL10n';

import styles from './LiveTag.style';

const useStyles = createUseThemeStyles(styles);

const LiveTag = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.root}>{intl.formatMessage(messages.live)}</div>
  );
};

export default memo<{}>(LiveTag);
