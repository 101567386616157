/* @flow */
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { em, rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

import { getAlternateReadbleColor } from 'plugin/light/util/lightUtils';

export const Root = styled(Paper)(({ theme }) => ({
  width: '30em',
  maxWidth: '95%',
  backgroundColor: theme.palette.primary.main,
  color: getAlternateReadbleColor(theme.palette.primary.main),
}));

export default {
  wrapper: {
    position: 'relative',
    borderRadius: `10px`,
    fontSize: rem(18, baseFontSize),
    padding: rem(19, baseFontSize),
  },
  close: {
    position: 'absolute',
    top: 11,
    right: 27,
    width: 28,
    height: 28,
    minWidth: 'unset',
    minHeight: 'unset',
    padding: 7,
    color: ({ theme }: ThemeFunction<{}>) =>
      `${getAlternateReadbleColor(
        getAlternateReadbleColor(theme.accentColor),
      )} !important`,
    backgroundColor: ({ theme }: ThemeFunction<{}>) =>
      `${getAlternateReadbleColor(theme.accentColor)} !important`,
    '& svg': {
      marginBottom: 0,
    },
    '&:focus': {
      outlineColor: ({ theme }: ThemeFunction<{}>) =>
        `${theme.textColorReverse} !important`,
    },
  },

  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  title: {
    fontSize: rem(14, baseFontSize),
    fontWeight: 700,
    marginBottom: '1em',
  },
  strong: {
    fontWeight: 800,
    color: ({ theme }: ThemeFunction<{}>) =>
      getAlternateReadbleColor(theme.accentColor),
  },
  content: {
    fontSize: rem(14, baseFontSize),
    marginBottom: em(20, baseFontSize),

    '& p': {
      marginBottom: '1.5em',
    },
    '& p:last-child': {
      marginBottom: '0',
    },
  },

  dots: {
    display: 'flex',
    gap: '8px',
  },

  dot: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    transition: '0.15s ease-out',
    border: ({ theme }: ThemeFunction<{}>) =>
      `2px solid ${getAlternateReadbleColor(theme.accentColor)}`,
    backgroundColor: ({ theme }: ThemeFunction<{}>) =>
      getAlternateReadbleColor(theme.accentColor),
  },
  dotActive: {
    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
  },

  action: {
    color: ({ theme }: ThemeFunction<{}>) => theme.textColorTertiary,
    fontSize: rem(14, baseFontSize),
    textDecoration: 'underline',
    fontWeight: 600,
  },

  button: {
    backgroundColor: ({ theme }: ThemeFunction<{}>) =>
      getAlternateReadbleColor(theme.accentColor),
    color: ({ theme }: ThemeFunction<{}>) =>
      getAlternateReadbleColor(getAlternateReadbleColor(theme.accentColor)),
    '&:focus': {
      outlineColor: ({ theme }: ThemeFunction<{}>) =>
        `${theme.textColorReverse} !important`,
    },
  },
};
