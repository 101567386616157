/* @flow */

import produce from 'immer';

import type { Topic } from '@braindate/domain/lib/topic/type';
import { getTopicId } from '@braindate/domain/lib/topic/util';

import * as types from 'src/shared/app/notification/action/uiNotificationActionTypes';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import {
  getNotificationData,
  getNotificationId,
} from 'src/shared/app/notification/util/notificationUtil';
import { createReducer } from 'src/shared/core/util/reducerUtils';

/*
|------------------------------------------------------------------------------
| INITIAL STATE
|------------------------------------------------------------------------------
*/

const initialState = {
  queue: [],
};

/*
|------------------------------------------------------------------------------
| REDUCERS
|------------------------------------------------------------------------------
*/

const _queueNotification = (state: Object, action: Object): Object => {
  const { queue } = state;
  const { notification: newNotification } = action;

  const index = queue.findIndex(
    (n) => getNotificationId(n) === getNotificationId(newNotification),
  );

  if (index !== -1) {
    if (state.queue[index].active) {
      return state;
    }

    return produce(state, (draft) => {
      draft.queue[index].active = true;
    });
  }

  return {
    ...state,
    queue: [...queue, { ...newNotification, active: true }],
  };
};

const _dismissNotification = (state: Object, action: Object): Object => {
  const { queue } = state;
  const { notification } = action;
  const id =
    typeof notification === 'string'
      ? notification
      : getNotificationId(notification);
  const index = queue.findIndex((n) => getNotificationId(n) === id);

  if (index < 0) {
    return state;
  }

  return produce(state, (draft) => {
    draft.queue[index].active = false;
  });
};

const _dismissTopicNotifications = (
  state: { queue: Notification<Topic>[] },
  action: { topic: string | Topic },
): Object => {
  const { queue } = state;
  const { topic } = action;

  const topicId = typeof topic === 'string' ? topic : getTopicId(topic);

  const filteredNotifications = queue.filter((n) => {
    const notificationTopic = getNotificationData<Topic>(n);
    if (!notificationTopic) return true;

    try {
      return getTopicId(notificationTopic) !== topicId;
    } catch (err) {
      return true;
    }
  });

  return { ...state, queue: filteredNotifications };
};

/*
|------------------------------------------------------------------------------
| REDUCER
|------------------------------------------------------------------------------
*/

export default createReducer(initialState, {
  [types.QUEUE_NOTIFICATION]: _queueNotification,
  [types.DISMISS_NOTIFICATION]: _dismissNotification,
  [types.DISMISS_TOPIC_NOTIFICATIONS]: _dismissTopicNotifications,
});
