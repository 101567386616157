/* @flow */

import { rem } from 'polished';

import { tintDuration } from '@braindate/ui/lib/token/animation';
import { lap, tablet } from '@braindate/ui/lib/token/mediaQuery';
import { hoverSelector } from '@braindate/ui/lib/token/selector';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { minTargetSize } from 'src/shared/app/base/style/token/box';
import { pushDown } from 'src/shared/app/base/style/token/helper';
import { horizontalSmallDeviceBreakpoint } from 'src/shared/app/base/style/util/responsive';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    minWidth: minTargetSize,
    height: '100%',

    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
    textDecoration: 'none',

    transition: ['background-color', tintDuration],

    [hoverSelector]: {
      backgroundColor: 'unset',
      color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
      textDecoration: 'none',
    },
    '&:active': {
      extend: pushDown,
    },
    '&:focus-visible': {
      outlineOffset: -1,
    },

    [tablet]: {
      justifyContent: 'flex-start',
      height: 'auto',
      // padding: [rem(16, baseFontSize), 0],

      '&:focus-visible': {
        outlineOffset: 0,
      },
    },
  },
  'root-isSelected': {
    backgroundColor: 'transparent',
    color: ({ theme }: ThemeFunction<{}>) => [theme.accentColor, '!important'],
    pointerEvents: 'none',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: rem(13, baseFontSize),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'transparent',
    borderRadius: '50%',
    width: 42,
    height: 42,
    marginBottom: 4,

    [horizontalSmallDeviceBreakpoint]: {
      display: 'none',
    },

    [lap]: {
      fontSize: rem(12, baseFontSize),
      flexGrow: 0,
      width: 38,
      height: 38,
    },

    '& .external-link-image': {
      maxHeight: 39,
    },
  },
  'icon-noBorder': {
    border: 'none !important',
  },
  'icon-noActiveBackground': {
    backgroundColor: 'unset !important',
  },
  'icon-isSelected': {
    color: ({ theme }: ThemeFunction<{}>) => theme.textColorReverse,
    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    borderColor: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
  },
  iconInner: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  label: {
    textOverflow: 'ellipsis',
    textAlign: 'center',
    whiteSpace: 'normal',
    fontWeight: 600,
  },
};
