/* @flow */

import { useSelector } from 'react-redux';

import modalRegistry from 'src/shared/app/base/modal/registry/modalRegistry';
import {
  selectModalData,
  selectModalId,
} from 'src/shared/app/base/modal/selector/modalSelectors';
import type { ReactElement } from 'src/shared/core/type/reactTypes';

export default function useModalComponent(): [?ReactElement, ?Object] {
  return [
    modalRegistry.get(useSelector(selectModalId)),
    useSelector(selectModalData),
  ];
}
