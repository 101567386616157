import { useLocation } from 'react-router';

const useIsPathActive = (path: string | string[]) => {
  const { pathname, hash } = useLocation();

  const isPathActive = (onePath: string) => {
    const [linkPath, linkHash] = onePath.split('#');
    return pathname === linkPath && (linkHash ? hash === `#${linkHash}` : true);
  };

  return (Array.isArray(path) ? path : [path]).some(isPathActive);
};

export default useIsPathActive;
