/* @flow */

import { useState } from 'react';
import type { Node } from 'react';

import ModalStatusContext from 'src/shared/app/base/modal/context/ModalStatusContext';

type Props = {
  children: Node,
};

export default function ModalStatusProvider({ children }: Props) {
  const [modals, setModals] = useState(new Set());

  const register = (id) => {
    setModals((set) => {
      const newSet = new Set(set);
      if (!newSet.has(id)) {
        newSet.add(id);
        return newSet;
      }
      return set;
    });
  };

  const unregister = (id) => {
    setModals((set) => {
      const newSet = new Set(set);
      if (newSet.has(id)) {
        newSet.delete(id);
        return newSet;
      }
      return set;
    });
  };

  return (
    <ModalStatusContext.Provider value={{ modals, register, unregister }}>
      {children}
    </ModalStatusContext.Provider>
  );
}
