/* @flow */

import { em } from 'polished';

export default {
  root: {
    fontSize: '1.25em',
    width: em(15),
    height: em(9),
    position: 'relative',
    top: -1,
  },
};
