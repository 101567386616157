/* @flow */

import useAuthenticationToken from 'src/shared/app/authentication/hook/useAuthenticationToken';
import { useGetMembershipQuery } from 'src/shared/app/base/api/endpoint/membershipEndpoint';

export default function useMembership() {
  const token = useAuthenticationToken();
  const { data } = useGetMembershipQuery(undefined, { skip: !token });
  return data;
}
