/* @flow */

import { memo, useState } from 'react';
import type { Node } from 'react';

import { TextField } from '@mui/material';
import { useField } from 'formik';
import { useFocus } from 'react-aria';

import PasswordChecker from 'src/shared/app/base/component/data-entry/form/PasswordChecker';

type Props = {
  className: string,
  field: Object,
  inputProps: Object,
  handleFocus: (Event) => void,
  handleBlur: (Event) => void,
};

const FormikInput = ({
  className,
  field,
  inputProps,
  handleFocus,
  handleBlur,
}: Props): Node => {
  const {
    'data-testid': dataTestId,
    showPasswordChecker,
    tabIndex,
    ...restInputProps
  } = inputProps;

  const [isFocused, setIsFocused] = useState(false);
  const [, , { setValue }] = useField(field.name);

  const { focusProps } = useFocus({
    onFocus: (e) => {
      setIsFocused(true);
      handleFocus(e);
    },
    onBlur: (e) => {
      setIsFocused(false);
      if (field.blur) {
        field.blur(e);
      }
      handleBlur(e);
    },
  });

  const passwordChecker = showPasswordChecker && isFocused && (
    <PasswordChecker value={field.value} />
  );

  const handleChange = (e) => {
    if (restInputProps.parse) {
      const newValue = restInputProps.parse(e.target.value);
      return setValue(newValue);
    }
    return field.onChange(e);
  };

  return (
    <>
      <TextField
        variant="outlined"
        classes={{ root: className }}
        {...field}
        {...restInputProps}
        {...focusProps}
        onChange={handleChange}
        InputProps={{
          'data-testid': dataTestId,
          inputProps: { tabIndex },
        }}
      />
      {passwordChecker}
    </>
  );
};

export default memo<Props>(FormikInput);
