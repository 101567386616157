/* @flow */

import { memo, type Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import messages from 'src/shared/app/preference/l10n/changePasswordL10n';

type Props = {
  notification: Notification<>,
};

const ChangePasswordSuccessNotification = ({ notification }: Props): Node => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  const messageTxt = intl.formatMessage(
    messages.changePasswordNotificationSuccess,
  );

  return (
    <Notification handleDismiss={handleDismiss} timeout={5000}>
      {messageTxt}
    </Notification>
  );
};

export default memo<Props>(ChangePasswordSuccessNotification);
