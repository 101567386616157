/* @flow */
import { em } from 'polished';

export default {
  root: {
    width: em(30),
    height: em(30),
    fill: 'currentColor',
    stroke: 'none',
    fontSize: '0.5em',
  },
};
