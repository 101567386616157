/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { TextField } from '@mui/material';
import classNames from 'classnames';

type Props = {
  className: string,
  field: Object,
  inputProps: Object,
  handleFocus: () => void,
  handleBlur: () => void,
  hasError?: boolean,
};

const FormikTextArea = ({
  className,
  field,
  inputProps,
  handleFocus,
  handleBlur,
  hasError,
}: Props): Node => {
  const rootClasses = classNames(
    className,
    field.className,
    inputProps.className,
  );

  return (
    <TextField
      {...field}
      {...inputProps}
      classes={{ root: rootClasses }}
      error={hasError}
      onFocus={handleFocus}
      onBlur={(e) => {
        field.onBlur(e);

        handleBlur();
      }}
      size="small"
      multiline
      fullWidth
    />
  );
};

export default memo<Props>(FormikTextArea);
