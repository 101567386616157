/* @flow */

import { usePatchUserMutation } from 'src/shared/app/base/api/endpoint/usersEndpoint';

const useMarkGuidanceAsCompleted = () => {
  const [patchUser] = usePatchUserMutation();

  return (key: string) => {
    return patchUser({ guidance_flags: { [key]: true } });
  };
};

export default useMarkGuidanceAsCompleted;
