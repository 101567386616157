/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import BaseIcon from 'src/shared/ui/component/icon/BaseIcon';

import styles from './Avatar.style';
import svg from './default-avatar.svg';

import type { AvatarProps } from './type/avatarTypes';

type Props = AvatarProps;

const useStyles = createUseThemeStyles(styles);

const DefaultAvatar = ({
  layoutClass,
  variant,
  customSize,
  alt,
}: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */

  const classes = useStyles({ variant, customSize });

  const rootClasses = classNames(classes.root, layoutClass);

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  return <BaseIcon className={rootClasses} svg={svg} title={alt} />;
};

// Make variants available
export * from './setting/avatarVariants';

export default memo<Props>(DefaultAvatar);
