/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import HouseIcon from 'src/shared/ui/component/icon/HouseIcon';

import styles from './UserBadge.style';

type Props = {
  layoutClass?: string,
  variant?: ?typeof large,
  onDarkBackground?: boolean,
};

const useStyles = createUseThemeStyles(styles);

const UserBadge = ({ layoutClass, variant, onDarkBackground }: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */

  const classes = useStyles();

  const rootClasses = classNames(classes.root, layoutClass, {
    [classes['root-large']]: variant === large,
    [classes['root-darkBg']]: onDarkBackground,
  });

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  return (
    <div className={rootClasses}>
      <div className={classes.badgeIconWrapper}>
        <HouseIcon className={classes.houseIcon} />
      </div>
    </div>
  );
};

export const large = 'large';

export default memo<Props>(UserBadge);
