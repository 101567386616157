/* @flow */

import * as types from 'src/shared/app/chat/action/uiChatActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

/*
 |------------------------------------------------------------------------------
 | Initial state
 |------------------------------------------------------------------------------
 */

const initialState = {
  isPhoneNumberModalShown: false,
};

/*
 |------------------------------------------------------------------------------
 | Reducers
 |------------------------------------------------------------------------------
 */

const closePhoneNumberModal = (state: Object): Object => ({
  ...state,
  isPhoneNumberModalShown: false,
});

const openPhoneNumberModal = (state: Object): Object => ({
  ...state,
  isPhoneNumberModalShown: true,
});

export default createReducer(initialState, {
  [types.OPEN_PHONE_NUMBER_MODAL]: openPhoneNumberModal,
  [types.CLOSE_PHONE_NUMBER_MODAL]: closePhoneNumberModal,
});
