/* @flow */
import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';
import { array, boolean, number, object, string } from 'yup';

import type {
  BraindateEvent,
  BraindateEventSponsor,
} from '@braindate/domain/lib/event/type';
import {
  getEventDefaultMarketOrdering,
  getEventProfileCompanyLabel,
  getEventProfilePositionLabel,
} from '@braindate/domain/lib/event/util';
import { assertObject } from '@braindate/util/lib/assert';

import { isErrorReportingEnabled } from 'src/shared/app/base/selector/appEnvSelectors';
import { braindaterContext } from 'src/shared/app/market/constant/marketConstants';
import messages from 'src/shared/app/market/l10n/marketL10n';
import {
  getMarketCanJoinFilter,
  getMarketConversationTypeFilter,
  getMarketDateFilter,
  getMarketKindFilter,
  getMarketLanguageFilter,
  getMarketLocationFilter,
  getParticipantCategoriesFilter,
} from 'src/shared/app/market/selector/marketFilterSelectors';
import { getMarketSortOrdering } from 'src/shared/app/market/selector/marketSortSelectors';
import {
  allTopicsLimit,
  bookmarkedParam,
  canJoinFilterParam,
  companyFilter,
  conversationTypeFilterParam,
  dateIntervalFilterParam,
  defaultSortOptions,
  fishbowlKindFilter,
  groupKindFilter,
  jobTitleFilter,
  keywordParam,
  kindFilterParam,
  languageFilterParam,
  locationFilterParam,
  mostUsedFilter,
  mostViewedSortingOrder,
  newestFilter,
  newestSortingOrder,
  offerKindFilter,
  orderingParam,
  participantCategoriesFilterParam,
  randomSortingOrder,
  soonSortingOrder,
  superGroupKindFilter,
  upcomingFilterParam,
  upcomingSortingOrder,
} from 'src/shared/app/market/settings/marketSettings';
import type { MarketParams } from 'src/shared/app/market/type/marketFiltersType';
import { NOTIFICATION_COMPONENT_KEYS } from 'src/shared/app/notification/constant/notificationComponentsMap';
import {
  notificationInfoLevel,
  notificationTopType,
} from 'src/shared/app/notification/constant/notificationConstants';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import { conversationFormats } from 'src/shared/app/topic/settings/topicSettings';
import type { State } from 'src/shared/core/type/reduxTypes';
import { getEventSafely } from 'src/shared/domain/event/selector/eventSelectors';

import type { NavigateFunction } from 'react-router';

export function parseTagCloudsFromFeaturedKeywords(
  categories: Object,
  intl: IntlShape,
  event: BraindateEvent,
): Array<Object> {
  const maxCount = 5;

  let data = [];

  if (categories && typeof categories === 'object') {
    if (mostUsedFilter in categories) {
      const keywords = categories[mostUsedFilter];

      if (Array.isArray(keywords) && keywords.length) {
        data = [
          ...data,
          {
            label: intl.formatMessage(messages.trendingFilter),
            value: mostUsedFilter,
            tags: keywords.map(parseTagFromFeaturedKeyword).slice(0, maxCount),
          },
        ];
      }
    }

    if (newestFilter in categories) {
      const keywords = categories[newestFilter];

      if (Array.isArray(keywords) && keywords.length) {
        data = [
          ...data,
          {
            label: intl.formatMessage(messages.newestFilter),
            value: newestFilter,
            tags: keywords.map(parseTagFromFeaturedKeyword).slice(0, maxCount),
          },
        ];
      }
    }

    if (jobTitleFilter in categories) {
      const keywords = categories[jobTitleFilter];

      if (Array.isArray(keywords) && keywords.length) {
        data = [
          ...data,
          {
            label:
              getEventProfilePositionLabel(event, intl.locale) ||
              intl.formatMessage(messages.jobTitleFilter),
            value: jobTitleFilter,
            tags: keywords.map(parseTagFromFeaturedKeyword).slice(0, maxCount),
          },
        ];
      }
    }

    if (companyFilter in categories) {
      const keywords = categories[companyFilter];

      if (Array.isArray(keywords) && keywords.length) {
        data = [
          ...data,
          {
            label:
              getEventProfileCompanyLabel(event, intl.locale) ||
              intl.formatMessage(messages.companyFilter),
            value: companyFilter,
            tags: keywords.map(parseTagFromFeaturedKeyword).slice(0, maxCount),
          },
        ];
      }
    }
  }

  return data;
}

export function parseTagFromFeaturedKeyword(keyword: string): Object {
  return {
    value: keyword,
    label: keyword,
  };
}

export function convertSlackSharingToNotification(): Notification<> {
  return {
    id: 'slack-sharing-topic-created',
    level: notificationInfoLevel,
    type: notificationTopType,
    componentKey: NOTIFICATION_COMPONENT_KEYS.MARKET_SLACK_NOTIFICATION,
  };
}

export function countActiveFilters(filters: Object): number {
  assertObject(filters, 'filters');

  const filtersToIgnore = ['availableFilters', bookmarkedParam];

  let count = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in filters) {
    // eslint-disable-next-line no-continue
    if (filtersToIgnore.includes(key)) continue;

    if (!Array.isArray(filters[key]) && filters[key]) {
      count += 1;
    }
    if (Array.isArray(filters[key])) {
      if (filters[key].length > 0) {
        count += 1;
      }
    }
  }

  return count;
}

export const buildNewCloudValues = (
  clouds: Object[],
  newMarketQuery?: string,
): Object => {
  if (!newMarketQuery || !newMarketQuery.length) return {};

  return clouds.reduce((accumulator, { value: cloudKey, tags }) => {
    const cloudTagQuery: ?string = tags
      .map((tag) => tag.value)
      .find((tag) => (newMarketQuery ? tag === newMarketQuery : false));

    if (cloudTagQuery) {
      return {
        ...accumulator,
        [cloudKey]: cloudTagQuery,
      };
    }

    return accumulator;
  }, {});
};

function parseStringToBoolean(value: string) {
  if (value === 'true' || value === '1') {
    return true;
  }
  if (value === 'false' || value === '0') {
    return true;
  }
  return value;
}

export function extractURLSearchParamsToFilters(location: Object) {
  const { search } = location;

  const paramsSchema = object({
    [kindFilterParam]: array()
      .of(
        string().oneOf([
          offerKindFilter,
          groupKindFilter,
          fishbowlKindFilter,
          superGroupKindFilter,
        ]),
      )
      .transform((value, origin) => origin.split(',')),
    [languageFilterParam]: array().of(string()),
    [dateIntervalFilterParam]: string(),
    [locationFilterParam]: array().of(number()),
    [canJoinFilterParam]: boolean(),
    [orderingParam]: string().oneOf([
      newestSortingOrder,
      mostViewedSortingOrder,
      upcomingSortingOrder,
      soonSortingOrder,
      randomSortingOrder,
    ]),
    [conversationTypeFilterParam]: array()
      .of(string().oneOf(conversationFormats))
      .transform((value, origin) => origin.split(',')),
    [participantCategoriesFilterParam]: array()
      .of(string())
      .transform((value, origin) => origin.split(',')),
    [keywordParam]: string(),
  });

  const keys = [
    kindFilterParam,
    languageFilterParam,
    dateIntervalFilterParam,
    locationFilterParam,
    canJoinFilterParam,
    orderingParam,
    conversationTypeFilterParam,
    participantCategoriesFilterParam,
    keywordParam,
  ];

  const parsedSearch = search && search.replace('?', '');
  if (!parsedSearch || parsedSearch.length === 0) {
    return null;
  }

  const query = new URLSearchParams(location.search);

  const detectedParams = {};
  keys.forEach((key) => {
    const value = query.get(key);
    if (value) {
      const decodedValue = parseStringToBoolean(decodeURIComponent(value));
      detectedParams[key] = decodedValue;
    }
  });

  try {
    const validatedParams = paramsSchema.validateSync(detectedParams, {
      abortEarly: false,
    });

    return validatedParams;
  } catch (e) {
    if (isErrorReportingEnabled) {
      Sentry.captureException(e);
    }
    return null;
  }
}

export function syncFiltersToURL(
  location: Object,
  navigate: NavigateFunction,
  {
    kindFilter,
    languageFilter,
    dateFilter,
    canJoinFilter,
    locationFilter,
    conversationTypeFilter,
    participantCategoriesFilter,
    sortValue,
    keyword,
  }: MarketParams,
) {
  const query = new URLSearchParams(location.search);
  const mapping = [
    [kindFilterParam, kindFilter && kindFilter.join(',')],
    [languageFilterParam, languageFilter && languageFilter.join(',')],
    [orderingParam, sortValue],
    [dateIntervalFilterParam, dateFilter],
    [canJoinFilterParam, canJoinFilter],
    [locationFilterParam, locationFilter && locationFilter.join(',')],
    [
      conversationTypeFilterParam,
      conversationTypeFilter && conversationTypeFilter.join(','),
    ],
    [
      participantCategoriesFilterParam,
      participantCategoriesFilter && participantCategoriesFilter.join(','),
    ],
    [keywordParam, keyword],
  ];

  mapping.forEach(([key, value]) => {
    if (value) {
      query.set(key, value.toString());
    } else {
      query.delete(key);
    }
  });

  navigate(
    {
      pathname: location.pathname,
      search: query.toString(),
    },
    { replace: true },
  );
}

export function getMarketSponsorInterstitialIndexes(
  sponsors: BraindateEventSponsor[],
): number[] {
  return sponsors.map((_, key) => (key + 1) * 3);
}

export const getMarketFilterParams = (state: State) => {
  const selectedSortOrdering = getMarketSortOrdering(state);
  const defaultSortOrdering = getEventDefaultMarketOrdering(
    getEventSafely(state),
  );
  const sortOrdering =
    selectedSortOrdering ||
    (defaultSortOrdering ? defaultSortOptions[defaultSortOrdering] : null);

  return {
    [kindFilterParam]: getMarketKindFilter(state),
    [languageFilterParam]: getMarketLanguageFilter(state),
    [dateIntervalFilterParam]: getMarketDateFilter(state),
    [locationFilterParam]: getMarketLocationFilter(state),
    [canJoinFilterParam]: getMarketCanJoinFilter(state),
    [orderingParam]: sortOrdering,
    [conversationTypeFilterParam]: getMarketConversationTypeFilter(state),
    [participantCategoriesFilterParam]: getParticipantCategoriesFilter(state),
  };
};

export function parseMarketFilters(
  customParams: Object,
  statusFilter: string,
  query: string,
  context: string,
  firstTopic: number,
) {
  const params = {};

  if (customParams[kindFilterParam].length > 0) {
    params[kindFilterParam] = customParams[kindFilterParam].join(',');
  }

  if (customParams[languageFilterParam]) {
    params[languageFilterParam] = customParams[languageFilterParam];
  }

  if (customParams[dateIntervalFilterParam]) {
    const dateDateTime = DateTime.fromISO(
      customParams[dateIntervalFilterParam],
    );
    params[dateIntervalFilterParam] = `${dateDateTime
      .startOf('day')
      .toISO()}/${dateDateTime.endOf('day').toISO()}`;
  }

  if (customParams[locationFilterParam]) {
    params[locationFilterParam] = customParams[locationFilterParam];
  }

  if (customParams[canJoinFilterParam]) {
    params[canJoinFilterParam] = customParams[canJoinFilterParam];
  }

  if (statusFilter) {
    params[upcomingFilterParam] = statusFilter;
  }

  if (customParams[orderingParam]) {
    params[orderingParam] = customParams[orderingParam];
    if (customParams[orderingParam] === upcomingSortingOrder) {
      params[orderingParam] = `${soonSortingOrder},${params[orderingParam]}`;
    } else if (customParams[orderingParam] === randomSortingOrder) {
      params[randomSortingOrder] = true;
    }
  }

  if (query) {
    if (context === braindaterContext) {
      params.author = query;
    } else {
      params.q = query;
    }
  }

  if (customParams[participantCategoriesFilterParam]) {
    params[participantCategoriesFilterParam] =
      customParams[participantCategoriesFilterParam];
  }

  if (customParams[keywordParam]) {
    params.q = customParams[keywordParam];
  }

  if (
    !!customParams[conversationTypeFilterParam] &&
    !!customParams[conversationTypeFilterParam].length
  ) {
    params[conversationTypeFilterParam] =
      customParams[conversationTypeFilterParam].join(',');
  }

  if (firstTopic) {
    params.show_first = firstTopic;
  }

  params.limit = allTopicsLimit;

  return params;
}
