/* @flow */

import type { Location } from '@braindate/domain/lib/location/type';
import { getLocationTimezone } from '@braindate/domain/lib/location/util';
import type { Membership } from '@braindate/domain/lib/membership/type';
import {
  getMembershipFlags,
  getMembershipTimezone,
} from '@braindate/domain/lib/membership/util';
import { assertObject } from '@braindate/util/lib/assert';

import {
  emailUrgentOption,
  termsOptin,
} from 'src/shared/app/permission/settings/permissionSettings';
import {
  findLocationForTimezone,
  isUsingMembershipTimezone,
} from 'src/shared/domain/location/util/locationUtil';

export function isMembershipNotificationsAccepted(
  membership: Membership,
): boolean {
  // $FlowIssue
  return getMembershipFlags(membership)[emailUrgentOption];
}

export function isMembershipTermsAccepted(membership: Membership): boolean {
  // $FlowIssue
  return getMembershipFlags(membership)[termsOptin];
}

export function isMembershipModerationOptinCompleted(
  membership: Membership,
): boolean {
  // $FlowIssue
  return getMembershipFlags(membership).moderation_optin_completed;
}

export function getMembershipGuidance(membership: Membership): ?Object {
  assertObject(membership, 'membership');

  // $FlowIssue
  const { guidance } = membership;

  return guidance && typeof guidance === 'object' ? { ...guidance } : {};
}

export function getMembershipGuidanceLabel(membership: Membership): ?string {
  assertObject(membership, 'membership');

  const guidance = getMembershipGuidance(membership);

  return guidance && guidance.label;
}

export function getMembershipGuidanceData(membership: Membership): ?Object {
  assertObject(membership, 'membership');

  const guidance = getMembershipGuidance(membership);

  return guidance && guidance.data;
}

export function getMembershipGuidanceCount(membership: Membership): ?number {
  assertObject(membership, 'membership');

  const data = getMembershipGuidanceData(membership);

  return data && data.count;
}

export function getMembershipGuidanceObjectId(membership: Membership): ?number {
  assertObject(membership, 'membership');

  const data = getMembershipGuidanceData(membership);

  return data && data.object_id;
}

export function getMembershipGuidanceSearch(membership: Membership): ?string {
  assertObject(membership, 'membership');

  const data = getMembershipGuidanceData(membership);

  return data && data.search;
}

export function computeMembershipTimezone(
  membership: ?Membership,
  eventLocations: Array<Location>,
  userLocations: Array<Location>,
): ?string {
  if (isUsingMembershipTimezone(eventLocations, userLocations)) {
    const membershipTimezone = membership && getMembershipTimezone(membership);
    if (membershipTimezone && membershipTimezone !== 'UTC') {
      return membershipTimezone;
    }
  }

  const location = findLocationForTimezone(eventLocations, userLocations);
  return location ? getLocationTimezone(location) : null;
}
