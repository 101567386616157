/* @flow */

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  subtitle: {
    fontStyle: 'italic',
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
  },
};
