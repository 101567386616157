/* @flow */

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { getBraindateId } from '@braindate/domain/lib/braindate/util';

import type { Methods } from 'src/shared/app/base/middleware/httpMethodMiddleware';
import { feedbackEndpoint } from 'src/shared/app/base/settings/endpointSettings';
import { parseResponse } from 'src/shared/app/base/util/ajaxUtils';
import { showPostBraindateCheerModal } from 'src/shared/app/cheer/action/cheerAction';
import type { GetState } from 'src/shared/core/type/reduxTypes';

import * as types from './braindateActionTypes';

// Braindates

export function setBraindateToRespondTo(braindateId: number | null) {
  return {
    type: types.SET_RESPOND_TO_BRAINDATE,
    braindateId,
  };
}

export function sendFeedback(
  braindate: Braindate,
  browser: string,
  review: Object,
  rating: number,
): any {
  return (dispatch: any, _: GetState, { post }: Methods) =>
    post(feedbackEndpoint(getBraindateId(braindate)), {
      browser,
      answers: review,
      rating,
    })
      .then(parseResponse)
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        if (rating >= 4) {
          dispatch(showPostBraindateCheerModal(braindate));
        }
      });
}
