/* @flow */

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'inline-block',

    padding: '0.25em 0.5em',

    fontSize: '0.8125rem',
    fontWeight: 500,
    WebkitFontSmoothing: 'antialiased',
  },
  'root-error': {
    color: ({ theme }: ThemeFunction<{}>) => theme.errorMessageColor,
  },
  'root-maxValue': {
    color: ({ theme }: ThemeFunction<{}>) => theme.successTextColor,
  },
};
