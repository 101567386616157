(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./braindateGetters", "./braindateConstant"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./braindateGetters"), require("./braindateConstant"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.braindateGetters, global.braindateConstant);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _braindateGetters, _braindateConstant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_braindateGetters).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _braindateGetters[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _braindateGetters[key];
      }
    });
  });
  Object.keys(_braindateConstant).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _braindateConstant[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _braindateConstant[key];
      }
    });
  });
});