/* @flow */

import { useEffect } from 'react';

import ttiPolyfill from 'tti-polyfill';

import { trackGAUserTiming } from 'src/shared/core/service/tracking/gaService';

// Ref: https://github.com/GoogleChromeLabs/tti-polyfill
export default function useTTITracking() {
  useEffect(() => {
    ttiPolyfill
      .getFirstConsistentlyInteractive()
      .then((tti) => {
        if (tti) {
          trackGAUserTiming(
            'Performance metrics',
            'time-to-interactive',
            Math.round(tti),
          );
        }
      })
      .catch(() => {
        // TODO
      });
  }, []);
}
