/* @flow */

import { memo, type Node } from 'react';

import withForwardRef from '@braindate/util/lib/react/hoc/withForwardRef';

import Button, { VARIANT } from 'src/shared/ui/component/button/Button';
import type { Props as ButtonProps } from 'src/shared/ui/component/button/Button';

const SecondaryButton = (props: ButtonProps): Node => (
  <Button {...props} variant={VARIANT.SECONDARY} />
);

export default memo<ButtonProps>(withForwardRef(SecondaryButton));
