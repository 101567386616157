/* @flow */

import type { BraindateEvent } from '@braindate/domain/lib/event/type';
import { getEventPlugins } from '@braindate/domain/lib/event/util';
import { assertObject } from '@braindate/util/lib/assert';

import { klikPlugin } from 'src/shared/app/feature/settings/featureSettings';

export function getKlikOptions(event: BraindateEvent): ?Object {
  const options = getEventPlugins(event)[klikPlugin];

  if (options) {
    assertObject(options, 'options');

    return options;
  }
}

export function getKlikProfileURL(event: BraindateEvent): ?string {
  return (getKlikOptions(event) || {}).profile_url;
}

export function getKlikPolicyURL(event: BraindateEvent): ?string {
  return (getKlikOptions(event) || {}).preferences_url;
}
