(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "../../event/util/eventGetters", "../../event/util/eventCheckers", "./userGetters"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("../../event/util/eventGetters"), require("../../event/util/eventCheckers"), require("./userGetters"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.eventGetters, global.eventCheckers, global.userGetters);
    global.userUtils = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _eventGetters, _eventCheckers, _userGetters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseLastName = _exports.parseFirstName = _exports.areAllUserSignupFieldsFilled = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } // Utils
  // Type

  /*
   |------------------------------------------------------------------------------
   | Base getters
   |------------------------------------------------------------------------------
   */

  /**
   * Verifies that the user's last name isn't too long for display
   * @param   {User} user - User to get the id from
   * @returns {string} Formatted result
   *
   * @throws Will throw an exception if parameter is not an object
   */
  var parseLastName = _exports.parseLastName = function parseLastName(user) {
    var name = (0, _userGetters.getUserLastName)(user);
    var firstLetter = name.substr(0, 1);
    var otherLetters = name.match(/[\s-]\w/g) || [];
    var mergedLetters = "".concat(firstLetter).concat(otherLetters.join(""));
    return mergedLetters;
  };

  /**
   * Verifies that the user's fist name isn't too long for display
   * @param   {User} user - User to get the id from
   * @returns {string} Formatted result
   *
   * @throws Will throw an exception if parameter is not an object
   */
  var parseFirstName = _exports.parseFirstName = function parseFirstName(user) {
    var lastName = parseLastName(user);
    var name = (0, _userGetters.getUserFirstName)(user);
    if (name.length + lastName.length < 35) {
      return name;
    }
    return name.split(" ")[0];
  };

  /**
   * Verifies that all the required fields are filled
   * @param   {User} user - User to get the data from
   * @param   {Event} event - User to get the data from
   * @param   {string} locale - locale
   * @returns {boolean} Formatted result
   *
   * @throws Will throw an exception if parameter is not an object
   */
  var areAllUserSignupFieldsFilled = _exports.areAllUserSignupFieldsFilled = function areAllUserSignupFieldsFilled(user, event, locale) {
    var _getUserLoveTalking, _getUserKnowledge, _getUserWantLearn;
    var userProfileFields = (0, _userGetters.getUserProfileFieldValues)(user);
    var profileFieldsDefinitions = (0, _eventGetters.getEventProfileFieldDefinitions)(event).filter(function (field) {
      return !!field.required;
    });
    var allProfileFieldsAreCompleted = profileFieldsDefinitions.length === 0 || profileFieldsDefinitions.every(function (_ref) {
      var id = _ref.id;
      var userProfileField = userProfileFields.find(function (_ref2) {
        var field_definition = _ref2.field_definition;
        return field_definition.id === id;
      });
      return !!(userProfileField !== null && userProfileField !== void 0 && userProfileField.value);
    });
    var emailValidated = (0, _userGetters.isUserEmailValidated)(user);
    var allDefaultFieldsAreCompleted = [!!(0, _userGetters.getUserFirstName)(user), !!(0, _userGetters.getUserLastName)(user)].concat(_toConsumableArray((0, _eventCheckers.isEventUserProfileFieldIsRequired)(event, "avatar") ? [!!(0, _userGetters.getUserAvatar)(user)] : []), _toConsumableArray((0, _eventCheckers.isEventUserProfileFieldIsRequired)(event, "company") ? [!!(0, _userGetters.getUserCompany)(user, locale)] : []), _toConsumableArray((0, _eventCheckers.isEventUserProfileFieldIsRequired)(event, "languages") ? [!!(0, _userGetters.getUserLanguages)(user).length] : []), _toConsumableArray((0, _eventCheckers.isEventUserProfileFieldIsRequired)(event, "position") ? [!!(0, _userGetters.getUserPosition)(user, locale)] : []), _toConsumableArray((0, _eventCheckers.isEventUserProfileFieldIsRequired)(event, "pronouns") ? [!!(0, _userGetters.getUserPosition)(user, locale)] : []), _toConsumableArray((0, _eventCheckers.isEventUserProfileFieldIsRequired)(event, "love_talking_about") ? [!!((_getUserLoveTalking = (0, _userGetters.getUserLoveTalking)(user, locale)) !== null && _getUserLoveTalking !== void 0 && _getUserLoveTalking.length)] : []), _toConsumableArray((0, _eventCheckers.isEventUserProfileFieldIsRequired)(event, "knowledgeable_about") ? [!!((_getUserKnowledge = (0, _userGetters.getUserKnowledge)(user, locale)) !== null && _getUserKnowledge !== void 0 && _getUserKnowledge.length)] : []), _toConsumableArray((0, _eventCheckers.isEventUserProfileFieldIsRequired)(event, "want_to_learn") ? [!!((_getUserWantLearn = (0, _userGetters.getUserWantLearn)(user, locale)) !== null && _getUserWantLearn !== void 0 && _getUserWantLearn.length)] : [])).every(function (field) {
      return !!field;
    });
    return emailValidated && allDefaultFieldsAreCompleted && allProfileFieldsAreCompleted;
  };
});