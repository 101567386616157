/* @flow */

import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import messages from 'src/shared/app/notification/l10n/notificationL10n.json';
import { supportSentErrorNotificationTimeout } from 'src/shared/app/support/settings/supportSettings';

type Props = {
  notification: Notification<>,
};

const SupportSentErrorNotificationDumb = ({ notification }: Props): Node => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  return (
    <Notification
      timeout={supportSentErrorNotificationTimeout}
      handleDismiss={handleDismiss}
      withError
    >
      {intl.formatMessage(messages.supportSentErrorNotification)}
    </Notification>
  );
};

export default SupportSentErrorNotificationDumb;
