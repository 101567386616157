/* @flow */

import { assertFunction, assertString } from '@braindate/util/lib/assert';

import { timeFormatOpts } from 'src/shared/app/base/date/util/dateFormatters';
import {
  supportedLocales,
  timeFormat12h,
  timeFormat24h,
} from 'src/shared/app/locale/settings/localeSettings';

export function isLocaleSupportedByApp(locale: string): boolean {
  assertString(locale, 'locale');

  return supportedLocales.includes(locale.toLowerCase());
}

export function getDateTimeFormatConstructor(): Function {
  let nativeIntl;

  if (typeof window !== 'undefined') {
    nativeIntl = window.Intl;
  } else if (typeof global !== 'undefined') {
    nativeIntl = global.Intl;
  } else {
    throw new Error('Detected environment was neither node nor a browser');
  }

  const constr = nativeIntl.DateTimeFormat;

  assertFunction(constr, 'constr');

  return constr;
}

export function getLocaleTimeFormat(locale: string): string {
  assertString(locale, 'locale');

  const Constr = getDateTimeFormatConstructor();
  const formatter = new Constr(locale, timeFormatOpts);
  const date = formatter.format(new Date());

  return date.match(/[a|p]m/i) ? timeFormat12h : timeFormat24h;
}

export function getShortLocale(locale: string): string {
  // TODO: assertions
  //
  // Roughly based on https://en.wikipedia.org/wiki/IETF_language_tag
  const pattern = /^([a-z]{2})(?:-([a-zA-z]{2}))?/;
  const matches = locale.match(pattern);

  return matches && matches.length > 1 ? matches[1] : locale;
}

export function matchSupportedLocale(
  defaultLocale: string,
  locales: Array<string>,
  locale: string,
): string {
  // TODO: assertions

  if (locales.indexOf(locale) > -1) {
    return locale;
  }
  // Roughly based on https://en.wikipedia.org/wiki/IETF_language_tag
  const pattern = /^([a-z]{2})(?:-([a-zA-z]{2}))?/;
  const matches = locale.match(pattern);
  const shortLocale = matches ? matches[1] : '';

  if (locales.indexOf(shortLocale) > -1) {
    return shortLocale;
  }
  return defaultLocale;
}
