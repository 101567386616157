/* @flow */

import { NoSsr } from '@mui/base';
import { useIntl } from 'react-intl';
import Joyride, { EVENTS } from 'react-joyride';
import { useTheme } from 'react-jss';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { isEventUserGuidanceEnabled } from '@braindate/domain/lib/event/util';
import { getUserMustShowCoachmark } from '@braindate/domain/lib/user/util';
import { isObject } from '@braindate/util/lib/type';

import { hasLoggedInFromBos } from 'src/shared/app/authentication/selector/base/appAuthenticationBaseSelectors';
import {
  modalId,
  portalTakeOverId,
} from 'src/shared/app/base/constant/domConstants';
import usePeriodicRender from 'src/shared/app/base/hook/usePeriodicRender';
import usePortalRegistrationLength from 'src/shared/app/base/portal/notification/hook/usePortalRegistrationLength';
import { getRoutePath } from 'src/shared/app/base/route/util/routeUtils';
import { toursDefaultStep } from 'src/shared/app/coachmark/config/tourConfig';
import useMarkGuidanceAsCompleted from 'src/shared/app/coachmark/hook/useMarkGuidanceAsCompleted';
import type {
  Step,
  Tour as TourType,
} from 'src/shared/app/coachmark/type/tourType';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import useSelf from 'src/shared/domain/user/hook/useSelf';

import { getReadableColor } from 'plugin/light/util/lightUtils';

import TourTooltip from './TourTooltip';

type Props = {
  tour: TourType,
  run?: boolean,
  showOnTakeOver?: boolean,
};

const useIntlText = (intl) => (txt) =>
  isObject(txt) ? intl.formatMessage(txt) : txt;

const Tour = ({ tour, run = true, showOnTakeOver = false }: Props) => {
  const { accentColor, colorPrimary } = useTheme();
  const user = useSelf();
  const navigate = useNavigate();
  const intl = useIntl();
  const event = useEvent();
  const markGuidanceAsCompleted = useMarkGuidanceAsCompleted();

  const getIntlText = useIntlText(intl);
  const isUserGuidanceEnabled = isEventUserGuidanceEnabled(event);
  usePeriodicRender(1);
  const openedTakeOverLength = usePortalRegistrationLength(portalTakeOverId);
  const openedModalLength = usePortalRegistrationLength(modalId);
  const loggedInWithBOS = useSelector(hasLoggedInFromBos);

  const mustShow = user && getUserMustShowCoachmark(user, tour.key);

  const hideTour =
    loggedInWithBOS ||
    !mustShow ||
    (showOnTakeOver ? false : openedTakeOverLength > 0) ||
    openedModalLength > 0;

  const handleCallback = ({ type, step, action }) => {
    if (action !== 'update' && [EVENTS.TOUR_END, EVENTS.ERROR].includes(type)) {
      markGuidanceAsCompleted(tour.key);

      if (type === 'link' && step && step.primaryActionRoute) {
        const redirectTo = getRoutePath(step.primaryActionRoute());
        navigate(redirectTo);
      }
    }
  };

  if (!isUserGuidanceEnabled) {
    return null;
  }

  return (
    <NoSsr>
      <Joyride
        callback={handleCallback}
        key={tour.key}
        steps={tour.steps.map(
          ({
            targetClassname,
            content,
            title,
            primaryAction,
            secondaryAction,
            ...s
          }: Step) => ({
            disableBeacon: toursDefaultStep.disableBeacon,
            ...s,
            target: `.${targetClassname}`,
            content: getIntlText(content),
            title: getIntlText(title),
            secondaryAction: getIntlText(secondaryAction),
            ...(primaryAction
              ? {
                  primaryAction: getIntlText(primaryAction),
                }
              : {}),
          }),
        )}
        continuous
        showSkipbutton
        run={hideTour ? false : run}
        showProgress
        tooltipComponent={TourTooltip}
        scrollOffset={200}
        styles={{
          options: {
            arrowColor: accentColor,
            backgroundColor: accentColor,
            overlayColor: 'rgba(0, 0, 0, 0.7)',
            primaryColor: colorPrimary,
            textColor: getReadableColor(accentColor),
          },
        }}
      />
    </NoSsr>
  );
};

export default Tour;
