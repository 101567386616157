(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "@braindate/util/lib/assert", "@braindate/util/lib/type", "../setting"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("@braindate/util/lib/assert"), require("@braindate/util/lib/type"), require("../setting"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.assert, global.type, global.setting);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _assert, _type, _setting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLocalizedData = getLocalizedData;
  /**
   * Get localized data from a localizable object. If `data` contains a `body`
   * (value of `defaultLocalizableKey`) attribute that is an object, the function
   * will parse that object and return the value of the attribute whose key is
   * `tag`. Otherwise, the function will directly parse `data` and do the same
   * operation.
   *
   * If `tag` is not defined or does not exist in the lookup object,
   * `defaultLocale` is used. However note that if `tag` contains a region subtag,
   * the function will try to find the primary language subtag in the localizable
   * object before falling back to the default locale. If the default locale is
   * not in the object, the function will simply use the first available locale.
   * If no locale are available, it returns an empty object.
   *
   * Reference: https://en.wikipedia.org/wiki/IETF_language_tag
   *
   * @param  {LocalizableData} data - Object from which to get the data
   * @param  {string} [tag] - Language tag of the locale in which to get the data
   * @returns {Object} Localized data
   *
   * @throws Will throw an exception if parameters are invalid or if `data` has a
   * body attribute that is not an object
   */
  function getLocalizedData(data, tag) {
    (0, _assert.assertObject)(data, "data");
    (0, _assert.assertLooseString)(tag, "tag");
    var dict;
    if (_setting.defaultLocalizableKey in data) {
      var body = data[_setting.defaultLocalizableKey];
      (0, _assert.assertObject)(body, "data.body");
      dict = body;
    } else {
      dict = data;
    }
    var availableLocales = Object.keys(dict);

    // If no locales are availables, return an empty object
    if (availableLocales.length === 0) {
      return {};
    }
    var locale;
    if (tag && tag in dict) {
      locale = tag;
    } else {
      // Roughly based on ISO 639-1 and ISO 3166-1 alpha-2 but not case sensitive
      var languageTagPattern = /^([a-z]{2})(?:-([A-Z]{2}))?/i;
      var matches = tag && tag.match(languageTagPattern);
      // $FlowIssue: matches is defined if condition is met
      var language = (0, _type.isArray)(matches) && matches.length > 1 ? matches[1] : "";
      if (language && language in dict) {
        locale = language;
      } else if (_setting.defaultLocale in dict) {
        locale = _setting.defaultLocale;
      } else {
        // If the content is user generated, it can be any language.
        // Pick the first one if all else fails.
        locale = availableLocales[0];
      }
    }
    var result = dict[locale];
    return (0, _type.isObject)(result) ? result : {};
  }
});