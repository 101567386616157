/* @flow */

import { useEffect } from 'react';

export default function useTrackAbandons() {
  useEffect(() => {
    // Stop listening for load abandonment
    document.removeEventListener('visibilitychange', window.__trackAbandons);
  }, []);
}
