/* @flow */

import type { Node } from 'react';

type Props = {
  condition: boolean,
  wrapper: (children: Node) => Node,
  elseWrapper?: (children: Node) => Node,
  children: Node,
};

const ConditionalWrapper = ({
  condition,
  wrapper,
  elseWrapper,
  children,
}: Props) => {
  if (condition) {
    return wrapper(children);
  }

  if (elseWrapper) {
    return elseWrapper(children);
  }

  return children;
};

export default ConditionalWrapper;
