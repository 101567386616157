/* @flow */

import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { getBraindatePath } from '@braindate/domain/lib/braindate/util/';
import { getTopicTitle } from '@braindate/domain/lib/topic/util/';
import type { User } from '@braindate/domain/lib/user/type';
import { getUserFirstName } from '@braindate/domain/lib/user/util/';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/cheer/l10n/cheerl10n.json';
import UserAvatar, {
  small,
} from 'src/shared/components/domain/user/avatar/UserAvatar';
import FieldUserAutocomplete from 'src/shared/components/domain/user/form/common/FieldUserAutocomplete';
import { getBraindateTopic } from 'src/shared/domain/braindate/util/braindateUtil';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import GroupAvatarQueue from 'src/shared/ui/component/image/GroupAvatarQueue';
import LinkWithChevron from 'src/shared/ui/component/link/LinkWithChevron';

import CheerDetails from '!@svgr/webpack?modules!src/shared/app/cheer/assets/CheerDetails.svg';

import styles from './PostBraindateCheerModalForm.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  braindate: Braindate,
  participants: User[],
  userToCheer: User | null,
  handleSubmit: (user: User) => void,
};

const PostBraindateCheerModalForm = ({
  braindate,
  participants,
  userToCheer,
  handleSubmit,
}: Props) => {
  const intl = useIntl();
  const classes = useStyles();

  const topic = getBraindateTopic(braindate);

  return (
    <Formik initialValues={{ user: userToCheer }} onSubmit={handleSubmit}>
      {({ values }) => (
        <Form noValidate>
          <div className={classes.root}>
            <div className={classes.details}>
              <CheerDetails />
            </div>

            <div className={classes.users}>
              <LinkWithChevron href={getBraindatePath(braindate)}>
                <span className={classes.participants}>
                  {participants.length === 1 ? (
                    <UserAvatar user={participants[0]} variant={small} />
                  ) : (
                    <GroupAvatarQueue participants={participants} />
                  )}
                </span>
                <span className={classes.names}>
                  {intl.formatList(participants.map(getUserFirstName), {
                    type: 'conjunction',
                  })}
                </span>
              </LinkWithChevron>
            </div>

            <h2 className={classes.title}>
              {getTopicTitle(topic, intl.locale)}
            </h2>
            <p className={classes.description}>
              {intl.formatMessage(messages.postBraindateDescription)}
            </p>

            <div className={classes.wrapper}>
              {participants.length > 1 ? (
                <FieldUserAutocomplete
                  label={intl.formatMessage(messages.sendCheersTo)}
                  className={classes.input}
                  possibleValues={participants}
                />
              ) : null}
            </div>

            <div className={classes.wrapper}>
              <PrimaryButton
                isDisabled={!values.user}
                layoutClass={classes.action}
                type="submit"
              >
                {intl.formatMessage(messages.sendCheers)}
              </PrimaryButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PostBraindateCheerModalForm;
