/* @flow */

export default {
  root: {
    width: 10,
    height: 10,
    marginBottom: '2px',
  },
  'root-large': {
    width: 18,
    height: 18,
  },
};
