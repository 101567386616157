/* @flow */

import { createSelector } from '@reduxjs/toolkit';

const _isTouchDevice = (state) => state.app.device.isTouchDevice;

export const isTouchDevice: (state: Object) => boolean = createSelector(
  [_isTouchDevice],
  (x: boolean): boolean => x,
);
