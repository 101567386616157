/* @flow */

import { combineReducers } from 'redux';

import liveChatReducer from 'plugin/live-chat/reducer/liveChatReducer';
import slackPluginReducer from 'plugin/slack/reducer/slackPluginReducer';

// $FlowIssue
export default combineReducers({
  liveChat: liveChatReducer,
  slackPlugin: slackPluginReducer,
});
