/* @flow */

import { useStore } from 'react-redux';

import type { State } from 'src/shared/core/type/reduxTypes';

export default function useReduxState(): State {
  const store = useStore();
  const state: State = store.getState();

  return state;
}
