/* @flow */

import { getMembershipId } from '@braindate/domain/lib/membership/util';

import { createReducer } from 'src/shared/core/util/reducerUtils';
import * as membershipTypes from 'src/shared/domain/membership/action/membershipActionTypes';

const initialState = {};

const _updateGuidance = (state: Object, action: Object) => {
  const { guidance, membership } = action;
  const membershipId = getMembershipId(membership);
  const prevMembership = state[membershipId];

  return {
    ...state,
    [membershipId]: {
      ...prevMembership,
      guidance,
    },
  };
};

const _deleteGuidance = (state: Object, action: Object) => {
  const { membership } = action;
  const membershipId = getMembershipId(membership);
  const prevMembership = state[membershipId];

  return {
    ...state,
    [membershipId]: {
      ...prevMembership,
      guidance: {},
    },
  };
};

const _patchMembershipFailure = (state: Object, { exception }: Object) => {
  return {
    ...state,
    patchMembershipFailure: exception.json,
  };
};

const _resetPatchMembership = (state: Object) => {
  return {
    ...state,
    patchMembershipFailure: null,
  };
};

export default createReducer(initialState, {
  [membershipTypes.FETCH_GUIDANCE_MARKET_PROMPT_SUCCESS]: _updateGuidance,
  [membershipTypes.DELETE_GUIDANCE_MARKET_PROMPT_SUCCESS]: _deleteGuidance,
});
