/* @flow */

import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { dismissMarketTopicNotFoundError } from 'src/shared/app/market/action/uiMarketActions';
import messages from 'src/shared/app/market/l10n/marketL10n';
import ConfirmationModal from 'src/shared/ui/component/messaging/ConfirmationModal';

export const marketErrorModalId = 'market-error-modal';

type Props = {
  onClose?: () => void,
};

const MarketErrorModalDumb = ({ onClose }: Props): Node => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch(dismissMarketTopicNotFoundError());

    if (onClose) {
      onClose();
    }
  };

  return (
    <ConfirmationModal
      title={intl.formatMessage(messages.topicNotFound)}
      message={intl.formatMessage(messages.topicNotFoundDescription)}
      handleConfirm={handleConfirm}
    />
  );
};

export default MarketErrorModalDumb;
