(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "@braindate/util/lib/assert"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("@braindate/util/lib/assert"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.assert);
    global.braindateRangeAssertions = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _assert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertBraindateRange = assertBraindateRange;
  _exports.assertBraindateRangeDate = assertBraindateRangeDate;
  _exports.assertBraindateRangeKind = assertBraindateRangeKind;
  _exports.assertBraindateRangeLocation = assertBraindateRangeLocation;
  _exports.assertBraindateRangeUrl = assertBraindateRangeUrl;
  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertBraindateRange(value) {
    (0, _assert.assertObject)(value, "braindateRange");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertBraindateRangeUrl(value) {
    (0, _assert.assertString)(value, "braindateRange.url");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertBraindateRangeKind(value) {
    (0, _assert.assertString)(value, "braindateRange.kind");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertBraindateRangeDate(value) {
    (0, _assert.assertObject)(value, "braindateRange.date");
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertBraindateRangeLocation(value) {
    (0, _assert.assertObject)(value, "braindateRange.location");
  }
});