(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tvPx = _exports.tv = _exports.tabletPx = _exports.tablet = _exports.reducedMotion = _exports.phabletPx = _exports.phablet = _exports.lapPx = _exports.lap = _exports.hdpi = _exports.deskPx = _exports.desk = void 0;
  /*
   |------------------------------------------------------------------------------
   | Resolution
   |------------------------------------------------------------------------------
   */
  var hdpi = _exports.hdpi = "@media only screen and (-moz-min-device-pixel-ratio: 1.5),\n  only screen and (-o-min-device-pixel-ratio: 3/2),\n  only screen and (-webkit-min-device-pixel-ratio: 1.5),\n  only screen and (min-device-pixel-ratio: 1.5)";

  /*
   |------------------------------------------------------------------------------
   | Breakpoints
   |------------------------------------------------------------------------------
   */

  var phablet = _exports.phablet = "@media only screen and (min-width: 37.5rem)"; /* 600px */
  var phabletPx = _exports.phabletPx = 600;
  var tablet = _exports.tablet = "@media only screen and (min-width: 50rem)"; /* 800px */
  var tabletPx = _exports.tabletPx = 800;
  var lap = _exports.lap = "@media only screen and (min-width: 75rem)"; /* 1200px */
  var lapPx = _exports.lapPx = 1200;
  var desk = _exports.desk = "@media only screen and (min-width: 87.5rem)"; /* 1400px */
  var deskPx = _exports.deskPx = 1400;
  var tv = _exports.tv = "@media only screen and (min-width: 100rem)"; /* 1600px */
  var tvPx = _exports.tvPx = 1600;

  /*
   |------------------------------------------------------------------------------
   | Accessibility
   |------------------------------------------------------------------------------
   */

  var reducedMotion = _exports.reducedMotion = "@media (prefers-reduced-motion: reduce)";
});