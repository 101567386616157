/* @flow */

import { useSelector } from 'react-redux';

import { getNotificationQueue } from 'src/shared/app/notification/selector/uiNotificationSelectors';

export default function useNotificationPortal(): Object {
  return {
    queue: useSelector(getNotificationQueue),
  };
}
