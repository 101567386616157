/* @flow */

import { rem } from 'polished';

import { fadeDuration } from '@braindate/ui/lib/token/animation';
import { hoverSelector } from '@braindate/ui/lib/token/selector';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

const badgeButtonOutlineStyle = {
  outline: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
  outlineOffset: '-5px !important',
  outlineStyle: 'solid !important',
  outlineWidth: '1px !important',
};

export default {
  root: {
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 0,
    position: 'relative',

    textDecoration: 'none',
    transition: `background-color ${fadeDuration}, opacity ${fadeDuration}`,
    opacity: ({ isFetching }: ThemeFunction<{ isFetching: boolean }>) =>
      isFetching ? 0.7 : 1,
    overflow: 'hidden',

    [hoverSelector]: {
      backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.accentColorHover,
    },
    '&:active': {
      backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.accentColorHover,
    },
  },
  link: {
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
    textDecoration: 'none',
    display: 'flex',
    padding: [12, 10],
    paddingRight: 50,
    [hoverSelector]: {
      textDecoration: 'none',
    },
    '&:focus': {
      outlineWidth: 0,
      backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.accentColorHover,
    },
  },
  text: {
    marginLeft: rem(10, baseFontSize),
    flex: 1,
  },

  avatar: {
    width: '100%',
    maxWidth: 35,
    marginTop: ({ smallAvatar }: ThemeFunction<{ smallAvatar: boolean }>) =>
      smallAvatar ? 7 : 0,

    '& svg': {
      width: '100%',
    },
  },

  topic: {
    fontSize: rem(14, baseFontSize),
    fontStyle: 'italic',
  },
  firstLetter: {
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 600,
    padding: '8px 12px 3px',
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    fontSize: rem(14, baseFontSize),
    transition: ['background-color', fadeDuration],
    color: ({ theme, unread }: ThemeFunction<{ unread: boolean }>) =>
      unread ? theme.accentColor : theme.textColorSecondary,
  },
  type: {
    display: 'flex',
    alignItems: 'center',
  },

  notificationMessage: {
    marginTop: 5,
    fontSize: rem(14, baseFontSize),
    alignSelf: 'start',

    display: 'flex',
    alignItems: 'center',
  },
  notificationIcon: {
    paddingRight: rem(5, baseFontSize),
    fontSize: rem(12, baseFontSize),
  },
  date: {
    fontSize: rem(14, baseFontSize),
    marginTop: 8,
  },
  actionButton: {
    position: 'absolute',
    top: 0,
    right: 8,
    width: 26,
    height: 26,
    backgroundColor: 'transparent',
    padding: 0,

    [hoverSelector]: {
      ...badgeButtonOutlineStyle,
    },

    '&:focus': {
      ...badgeButtonOutlineStyle,
    },
  },
};
