/* @flow */

import { memo, useEffect } from 'react';
import type { Node } from 'react';

import {
  Box,
  Menu,
  MenuItem,
  MenuProps,
  SwipeableDrawer,
  useMediaQuery,
} from '@mui/material';
import { useLocation } from 'react-router-dom';

import { phablet } from '@braindate/ui/lib/token/mediaQuery';

import usePrevious from 'src/shared/core/util/reactUtils';

import MenuPopoverItem from './MenuPopoverItem';

type Props = {
  anchor: HTMLElement,
  renderDrawerOnMobile?: boolean,
  onAfterClose?: () => void,
  children: Array<typeof MenuPopoverItem | typeof MenuItem>,
};

const MenuPopover = ({
  open,
  onClose,
  anchor,
  ariaLabelledby,
  onAfterClose,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  children,
  renderDrawerOnMobile = false,
  ...props
}: Props & MenuProps): Node => {
  const { pathname } = useLocation();
  const isDesktop = useMediaQuery(phablet);

  const baseProps = {
    open,
    onClose,
    'aria-labelledby': ariaLabelledby,
  };

  const previous = usePrevious(pathname);
  useEffect(() => {
    if (previous && previous !== pathname) {
      onClose();
    }
  }, [pathname, previous]);

  const onTransitionEnd = () => {
    if (open) return;
    if (onAfterClose) {
      onAfterClose();
    }
  };

  return isDesktop || !renderDrawerOnMobile ? (
    <Menu
      onClick={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
      onTransitionEnd={onTransitionEnd}
      anchorEl={anchor}
      {...baseProps}
      {...props}
    >
      {children}
    </Menu>
  ) : (
    <SwipeableDrawer
      {...baseProps}
      onOpen={() => {}}
      onTransitionEnd={onTransitionEnd}
      keepMounted={false}
      anchor="bottom"
      {...props}
    >
      <Box pb={2}>{children}</Box>
    </SwipeableDrawer>
  );
};

export default memo<Props>(MenuPopover);
