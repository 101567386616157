/* @flow */
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    position: 'absolute',
    top: '100%',
    '& svg': {
      width: 14,
      height: 14,
      marginRight: 5,
    },
    padding: '0.25em 0',
    display: 'flex',
    alignItems: 'center',
    color: ({ theme }: ThemeFunction<{}>) => theme.errorMessageColor,
    fontSize: 12,
    fontWeight: 500,
    WebkitFontSmoothing: 'antialiased',
  },
};
