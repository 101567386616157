/* @flow */

import { normalizeEvent } from '@braindate/domain/lib/event/util';

import {
  eventEndpoint,
  forStartupParam,
} from 'src/shared/app/base/settings/endpointSettings';
import { parseResponse } from 'src/shared/app/base/util/ajaxUtils';
import type { GetState } from 'src/shared/core/type/reduxTypes';
import { patchEntities } from 'src/shared/domain/base/action/entityActions';

import * as types from './eventActionTypes';

// Single event

export function fetchEvent(id: number | string, data?: Object) {
  return (dispatch: any, _: GetState, { get }: Object) => {
    dispatch(fetchEventRequest());

    return get(eventEndpoint(id), data)
      .then(parseResponse)
      .then((json) => {
        const normalizedData = normalizeEvent(json);
        const { entities, result } = normalizedData;

        dispatch(patchEntities(entities));
        // $FlowIssue
        dispatch(fetchEventSuccess(result));

        return json;
      })
      .catch((exception) => {
        dispatch(fetchEventFailure(exception));

        throw exception;
      });
  };
}

export function fetchEventRequest() {
  return {
    type: types.FETCH_EVENT_REQUEST,
  };
}

export function fetchEventSuccess(result: number) {
  return {
    type: types.FETCH_EVENT_SUCCESS,
    result,
  };
}

export function fetchEventFailure(exception: Error) {
  return {
    type: types.FETCH_EVENT_FAILURE,
    exception,
  };
}

// Reset

export function resetEvent() {
  return {
    type: types.RESET_EVENT,
  };
}

// Public

export function fetchPublicEvent(id: number | string) {
  return (dispatch: any, _: GetState, { get }: Object) => {
    dispatch(fetchPublicEventRequest());

    return get(eventEndpoint(id), { [forStartupParam]: true })
      .then(parseResponse)
      .then((json) => {
        dispatch(fetchPublicEventSuccess(json.id, json));

        return json;
      })
      .catch((e) => {
        dispatch(fetchPublicEventFailure(e));

        throw e;
      });
  };
}

export function fetchPublicEventRequest() {
  return {
    type: types.FETCH_PUBLIC_EVENT_REQUEST,
  };
}

export function fetchPublicEventSuccess(result: number, entities: Object) {
  return {
    type: types.FETCH_PUBLIC_EVENT_SUCCESS,
    result,
    entities,
  };
}

export function fetchPublicEventFailure(exception: Error) {
  return {
    type: types.FETCH_PUBLIC_EVENT_FAILURE,
    exception,
  };
}
