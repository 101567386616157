/* @flow */

import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
  },
  content: {
    width: '100%',
    maxWidth: '40em',
    padding: 30,
    margin: '10px auto',
    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.colorPrimary,
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
    position: 'relative',
    maxHeight: '100vh',
    overflow: 'auto',
    '&:focus': {
      outline: 'none',
    },
  },

  btnSkip: {
    background: 'none',
    padding: 0,
    minWidth: 'auto',
    textDecoration: 'underline',
    fontWeight: 'inherit',
    textTransform: 'none',
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
  },

  actions: {
    textAlign: 'center',
  },

  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
  },
  star: {
    fill: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    stroke: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
  },
  scaleHint: {
    width: 210,
    display: 'flex',
    justifyContent: 'space-between',
  },

  labelSubtitle: {
    fontWeight: 300,
    fontStyle: 'italic',
    fontSize: rem(14, baseFontSize),
  },
  checkboxList: {
    paddingTop: '1em',
  },
  group: {
    paddingTop: '2em',
  },
  subTextarea: {
    marginLeft: 22,
    paddingLeft: rem(16, baseFontSize),
    '& label': {
      fontWeight: 300,
      fontStyle: 'italic',
      fontSize: rem(14, baseFontSize),
      marginTop: 0,
    },
  },
  more: {
    marginLeft: 22,
    paddingLeft: rem(16, baseFontSize),
    '& label': {
      fontWeight: 300,
      fontStyle: 'italic',
      fontSize: rem(14, baseFontSize),
      marginTop: 0,
    },
  },
  chatIcon: {
    textAlign: 'center',
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
  },
};
