/* @flow */

import type { Node } from 'react';

import classNames from 'classnames';

import withForwardRef from '@braindate/util/lib/react/hoc/withForwardRef';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './Card.style';

type Props = {
  forwardedRef?: { current: null | HTMLElement },
  layoutClass?: string,
  rootLayoutClass?: string,
  children?: Node,
  isDisabled?: boolean,
  isFaded?: boolean,
  isAccent?: boolean,
  withPadding?: boolean,
  withBorder?: boolean,
  disableChildrenMargin?: boolean,
};

const useStyles = createUseThemeStyles(styles);

const Card = ({
  forwardedRef,
  layoutClass,
  rootLayoutClass,
  children,
  isFaded,
  isDisabled,
  isAccent,
  withPadding,
  withBorder,
  disableChildrenMargin,
}: Props): Node => {
  const classes = useStyles({
    isAccent,
    withPadding,
    withBorder,
    disableChildrenMargin,
  });

  // Classes

  const rootClasses = classNames(classes.root, {
    [classes['root-isFaded']]: isFaded,
    [classes['root-isDisabled']]: isDisabled,
  });

  return (
    <div
      className={classNames(rootClasses, rootLayoutClass)}
      ref={forwardedRef}
    >
      <div className={classNames(classes.wrapper, layoutClass)}>{children}</div>
    </div>
  );
};

export default withForwardRef(Card);
