/* @flow */

import type { Node } from 'react';

import { useDispatch } from 'react-redux';

import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';
import { getNotificationData } from 'src/shared/app/notification/util/notificationUtil';

type Props = {
  notification: NotificationType<>,
};

const InvalidActionNotification = ({ notification }: Props): Node => {
  const data = getNotificationData(notification);
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  return data && data.text ? (
    <Notification timeout={8000} handleDismiss={handleDismiss} withWarning>
      {data.text}
    </Notification>
  ) : null;
};

export default InvalidActionNotification;
