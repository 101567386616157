/* @flow */

import { memo } from 'react';
import type { ComponentType, Node } from 'react';

import BraindateLogo from 'src/shared/app/base/component/template/component/BraindateLogo';
import TemplatePortals from 'src/shared/app/base/component/template/component/TemplatePortals';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import IntroScreen from 'src/shared/app/onboarding/component/intro/IntroScreen';
import IntroScreenSponsors from 'src/shared/app/onboarding/component/intro/IntroScreenSponsors';

import BackgroundMobile from '!@svgr/webpack?modules!src/shared/app/base/component/template/assets/background-mobile.svg';

import styles from './OnboardingTemplate.style';

type Props = {
  Switch: ComponentType<any>,
};

const useStyles = createUseThemeStyles(styles);

const OnboardingTemplate = ({ Switch }: Props): Node => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.intro}>
        <BackgroundMobile className={classes.background} />
        <IntroScreen />
      </div>

      <div className={classes.content}>
        <TemplatePortals />
        <BraindateLogo className={classes.logo} />
        <Switch layoutClass={classes.main} />
        <IntroScreenSponsors />
      </div>
    </div>
  );
};

export default memo<Props>(OnboardingTemplate);
