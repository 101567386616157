/* @flow */
import { rem } from 'polished';

import { lap } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    minHeight: rem(37, baseFontSize),
    marginTop: 11,
    display: 'flex',
    alignItems: 'center',

    [lap]: {
      maxWidth: '80%',
    },
  },
  topicTitle: {
    margin: 0,
    textAlign: 'left',
    textTransform: 'none',
    fontSize: rem(14, baseFontSize),
    fontWeight: 600,
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,

    [lap]: {
      fontSize: rem(20, baseFontSize),
    },
  },
};
