/* @flow */

import type { State } from 'src/shared/core/type/reduxTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';
import * as types from 'src/shared/domain/keyword/action/keywordActionTypes';

/*
 |------------------------------------------------------------------------------
 | Initial state
 |------------------------------------------------------------------------------
 */

const initialState = {
  isFetching: false,
  result: [],
};

/*
 |------------------------------------------------------------------------------
 | Reducers
 |------------------------------------------------------------------------------
 */

const handleRequest = (state: State) => ({
  ...state,
  isFetching: true,
  result: [],
});

const handleSuccess = (state: State, { result }: Object) => ({
  ...state,
  isFetching: false,
  result: result.results, // Ignore pages for now
});

const reset = (state: State) => ({
  ...state,
  isFetching: false,
  result: [],
});

export default createReducer(initialState, {
  [types.FETCH_KEYWORDS_REQUEST]: handleRequest,
  [types.FETCH_KEYWORDS_SUCCESS]: handleSuccess,
  [types.FETCH_KEYWORDS_FAILURE]: reset,
  [types.RESET_KEYWORDS]: reset,
});
