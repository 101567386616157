/* @flow */

import { trackGAEvent } from 'src/shared/core/service/tracking/gaService';

export const EVENTS_CATEGORY = {
  NOTIFICATION: 'Notification',
};

const trackingEvents = {
  notification: {
    readAll: (origin?: string) =>
      trackGAEvent(EVENTS_CATEGORY.NOTIFICATION, 'Read All', origin),
    click: (origin?: string) =>
      trackGAEvent(EVENTS_CATEGORY.NOTIFICATION, 'Click', origin),
    loadMore: (origin?: string) =>
      trackGAEvent(EVENTS_CATEGORY.NOTIFICATION, 'Load more', origin),
  },
};

export default trackingEvents;
