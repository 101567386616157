/* @flow */

import { memo, useEffect } from 'react';
import type { Node } from 'react';

import NoSsr from '@mui/material/NoSsr';

import { portalNotificationId } from 'src/shared/app/base/constant/domConstants';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import NotificationBanner from 'src/shared/ui/component/messaging/NotificationBanner';
import Portal from 'src/shared/ui/component/misc/Portal';

import styles from './Notification.style';

type Props = {
  timeout?: number,
  withInfo?: boolean,
  withWarning?: boolean,
  withError?: boolean,
  href?: string,
  icon?: Node,
  handleDismiss?: () => void,
  children: Node,
  isCentered?: boolean,
};

const useStyles = createUseThemeStyles(styles);

let timeoutId: number;

const Notification = ({
  withInfo,
  withWarning,
  withError,
  href,
  icon,
  timeout,
  handleDismiss,
  children,
  isCentered,
}: Props): Node => {
  useEffect(() => {
    if (timeout) {
      timeoutId = window.setTimeout(handleDismiss, timeout);
    }

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, []);

  const classes = useStyles();

  return (
    <NoSsr>
      <Portal id={portalNotificationId}>
        <div className={classes.root}>
          <NotificationBanner
            withInfo={withInfo}
            withWarning={withWarning}
            withError={withError}
            href={href}
            icon={icon}
            handleDismiss={handleDismiss}
            isCentered={isCentered}
          >
            {children}
          </NotificationBanner>
        </div>
      </Portal>
    </NoSsr>
  );
};

export default memo<Props>(Notification);
