/* @flow */

import { combineReducers } from 'redux';

import appUnreadCountNotificationSlice from './slices/appUnreadCountNotificationSlice';

// $FlowIssue
const appNotificationsReducer = combineReducers({
  unreadCount: appUnreadCountNotificationSlice,
});

export default appNotificationsReducer;
