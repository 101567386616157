/* @flow */
import { memo, type Node } from 'react';

type Props = {
  qty: number,
  renderSkeleton: () => Node,
};

const FeedQueryLoading = ({ qty, renderSkeleton }: Props) => {
  return (
    <>
      {Array(qty)
        .fill(0)
        .map((_, key) => (
          <li key={key}>{renderSkeleton()}</li>
        ))}
    </>
  );
};

export default memo<Props>(FeedQueryLoading);
