/* @flow */

import type { BraindateEvent } from '@braindate/domain/lib/event/type';
import { getEventPlugins } from '@braindate/domain/lib/event/util';
import { assertObject } from '@braindate/util/lib/assert';

import { customMenuPlugin } from 'src/shared/app/feature/settings/featureSettings';

export function getCustomMenuLinks(event: BraindateEvent): ?Object {
  const options = getEventPlugins(event)[customMenuPlugin];

  if (options) {
    assertObject(options, 'options');

    return options.links || [];
  }
}
