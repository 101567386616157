/* @flow */
import { DateTime } from 'luxon';

import type { BraindateEvent } from '@braindate/domain/lib/event/type';
import { getEventDefaultMarketOrdering } from '@braindate/domain/lib/event/util';

import {
  getMarketCanJoinFilter,
  getMarketConversationTypeFilter,
  getMarketDateFilter,
  getMarketKindFilter,
  getMarketLanguageFilter,
  getMarketLocationFilter,
  getParticipantCategoriesFilter,
} from 'src/shared/app/market/selector/marketFilterSelectors';
import { getMarketSortOrdering } from 'src/shared/app/market/selector/marketSortSelectors';
import {
  canJoinFilterParam,
  conversationTypeFilterParam,
  dateIntervalFilterParam,
  defaultSortOptions,
  keywordParam,
  kindFilterParam,
  languageFilterParam,
  locationFilterParam,
  orderingParam,
  participantCategoriesFilterParam,
} from 'src/shared/app/market/settings/marketSettings';
import {
  extractURLSearchParamsToFilters,
  syncFiltersToURL,
} from 'src/shared/app/market/util/marketUtils';
import type { GetState, State } from 'src/shared/core/type/reduxTypes';

import * as types from './marketActionTypes';

import type { Location, NavigateFunction } from 'react-router';

export function setMarketStatusFilter(value: string) {
  return {
    type: types.SET_MARKET_STATUS_FILTER,
    value,
  };
}

export function resetMarketStatusFilter() {
  return {
    type: types.RESET_MARKET_STATUS_FILTER,
  };
}

export function setMarketKindFilter(value: Array<string>) {
  return {
    type: types.SET_MARKET_KIND_FILTER,
    value,
  };
}

export function resetMarketKindFilter() {
  return {
    type: types.RESET_MARKET_KIND_FILTER,
  };
}

export function setMarketLanguageFilter(value: Array<string>) {
  return {
    type: types.SET_MARKET_LANGUAGE_FILTER,
    value,
  };
}

export function resetMarketLanguageFilter() {
  return {
    type: types.RESET_MARKET_LANGUAGE_FILTER,
  };
}

export function setMarketDateFilter(value: DateTime) {
  return {
    type: types.SET_MARKET_DATE_FILTER,
    value,
  };
}

export function setMarketLocationFilter(value: Array<number>) {
  return {
    type: types.SET_MARKET_LOCATION_FILTER,
    value,
  };
}

export function setMarketCanJoinFilter(value: boolean) {
  return {
    type: types.SET_MARKET_CANJOIN_FILTER,
    value,
  };
}

export function setMarketConversationTypeFilter(value: Array<string>) {
  return {
    type: types.SET_MARKET_CONVERSATION_TYPE,
    value,
  };
}

export function setParticipantCategoryFilter(value: Array<string>) {
  return {
    type: types.SET_PARTICIPANT_CATEGORY,
    value,
  };
}

export function resetMarketFilters() {
  return {
    type: types.RESET_MARKET_FILTERS,
  };
}

export function setMarketFiltersFromURLSearch(
  location: Object,
  event: BraindateEvent,
) {
  return (dispatch: any) => {
    const returnValue: Promise<?Object> = new Promise((resolve) => {
      const values = extractURLSearchParamsToFilters(location);
      if (!values) {
        resolve();
        return;
      }

      if (values[kindFilterParam]) {
        dispatch(setMarketKindFilter(values[kindFilterParam]));
      }

      if (values[dateIntervalFilterParam]) {
        dispatch(
          setMarketDateFilter(
            DateTime.fromISO(values[dateIntervalFilterParam]),
          ),
        );
      }

      if (values[languageFilterParam]) {
        dispatch(setMarketDateFilter(values[languageFilterParam]));
      }

      if (values[locationFilterParam]) {
        dispatch(setMarketLocationFilter(values[locationFilterParam]));
      }

      if (values[canJoinFilterParam]) {
        dispatch(setMarketCanJoinFilter(values[canJoinFilterParam]));
      }

      if (values[orderingParam]) {
        dispatch(setMarketSortOrdering(values[orderingParam]));
      } else {
        const defaultMarketOrdering = getEventDefaultMarketOrdering(event);
        if (defaultMarketOrdering) {
          dispatch(
            setMarketSortOrdering(defaultSortOptions[defaultMarketOrdering]),
          );
        }
      }

      if (values[conversationTypeFilterParam]) {
        dispatch(
          setMarketConversationTypeFilter(values[conversationTypeFilterParam]),
        );
      }

      if (values[participantCategoriesFilterParam]) {
        dispatch(
          setParticipantCategoryFilter(
            values[participantCategoriesFilterParam],
          ),
        );
      }

      if (values[keywordParam]) {
        dispatch(setMarketQuery(values[keywordParam]));
      }

      resolve(values);
    });
    return returnValue;
  };
}

export function setMarketSortOrdering(value: string) {
  return {
    type: types.SET_MARKET_SORT_ORDERING,
    value,
  };
}

export function resetMarketSortOrdering() {
  return {
    type: types.RESET_MARKET_SORT_ORDERING,
  };
}

const syncMarketUrlQuery = (
  value?: string,
  location?: Location,
  navigate?: NavigateFunction,
  state: State,
) => {
  if (location && navigate) {
    const kindValue = getMarketKindFilter(state);
    const languageValue = getMarketLanguageFilter(state);
    const dateValue = getMarketDateFilter(state);
    const locationValue = getMarketLocationFilter(state);
    const canJoinValue = getMarketCanJoinFilter(state);
    const sortOrdering = getMarketSortOrdering(state);
    const conversationTypeValue = getMarketConversationTypeFilter(state);
    const participantCategoriesFilter = getParticipantCategoriesFilter(state);

    syncFiltersToURL(location, navigate, {
      kindFilter: kindValue,
      languageFilter: languageValue,
      dateFilter: dateValue,
      canJoinFilter: canJoinValue,
      locationFilter: locationValue,
      conversationTypeFilter: conversationTypeValue,
      participantCategoriesFilter,
      sortValue: sortOrdering,
      keyword: value || '',
    });
  }
};

export function setMarketQuery(
  value: string,
  location?: Location,
  navigate?: NavigateFunction,
) {
  return (dispatch: Function, getState: GetState) => {
    syncMarketUrlQuery(value, location, navigate, getState());

    return dispatch({
      type: types.SET_MARKET_QUERY,
      value,
    });
  };
}

export function setMarketQueryContext(context: string) {
  return {
    type: types.SET_MARKET_QUERY_CONTEXT,
    context,
  };
}

export function resetMarketQuery(
  location?: Location,
  navigate?: NavigateFunction,
) {
  return (dispatch: Function, getState: GetState) => {
    syncMarketUrlQuery(undefined, location, navigate, getState());

    return dispatch({
      type: types.RESET_MARKET_QUERY,
    });
  };
}
