/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/notification/l10n/notificationL10n';
import BellIcon from 'src/shared/ui/component/icon/BellIcon';
import SubHeading from 'src/shared/ui/component/text/Subheading';

import styles from './EmptyNotificationFeed.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  small?: boolean,
};

const EmptyNotificationFeed = ({ small = false }: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */

  const intl = useIntl();

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */

  const classes = useStyles({ small });

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  const title = intl.formatMessage(messages.emptyNotificationTitle);
  const subtitle = intl.formatMessage(messages.emptyNotificationDescription);

  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        <BellIcon className={classes.icon} />
      </div>
      <div className={classes.text}>
        <SubHeading layoutClass={classes.title}>{title}</SubHeading>
        <div className={classes.content}>{subtitle}</div>
      </div>
    </div>
  );
};

export default memo<Props>(EmptyNotificationFeed);
