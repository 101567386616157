/* @flow */

import { SwipeableDrawer, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { tablet } from '@braindate/ui/lib/token/mediaQuery';

import Modal from 'src/shared/app/base/modal/component/Modal';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import { getNotificationData } from 'src/shared/app/notification/util/notificationUtil';
import ConditionalWrapper from 'src/shared/ui/component/misc/ConditionalWrapper';

import CheckInModalDumb from './CheckInModalDumb';

type Props = {
  notification: Notification<Braindate>,
};

const CheckInModal = ({ notification }: Props) => {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(tablet);

  const braindate = getNotificationData(notification);

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  return (
    <ConditionalWrapper
      condition={isDesktop}
      wrapper={(child) => (
        <Modal content={child} handleClose={handleDismiss} hideActions />
      )}
      elseWrapper={(child) => (
        <SwipeableDrawer
          anchor="bottom"
          open
          onOpen={() => {}}
          onClose={handleDismiss}
          keepMounted={false}
          disableDiscovery
          disableSwipeToOpen
          PaperProps={{
            sx: {
              height: 'max-content',
              minHeight: '65vh',
            },
          }}
        >
          {child}
        </SwipeableDrawer>
      )}
    >
      <CheckInModalDumb braindate={braindate} handleDismiss={handleDismiss} />
    </ConditionalWrapper>
  );
};

export default CheckInModal;
