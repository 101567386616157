/* @flow */

import { sidebarElevation } from 'src/shared/app/base/style/token/elevation';

export default {
  close: {
    position: 'absolute',
    top: 10,
    right: 20,
    zIndex: sidebarElevation,
  },
};
