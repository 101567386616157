/* @flow */

import { skipToken } from '@reduxjs/toolkit/query';

import type { User } from '@braindate/domain/lib/user/type';

import useAuthenticationToken from 'src/shared/app/authentication/hook/useAuthenticationToken';
import { useGetUserQuery } from 'src/shared/app/base/api/endpoint/usersEndpoint';

export default function useSelf(): ?User {
  const token = useAuthenticationToken();
  const { data } = useGetUserQuery(token ? 'me' : skipToken);
  return data;
}
