import useNextURL from 'src/shared/app/market/hook/useGetNextPath';
import { useGetMembershipQuery } from 'src/shared/app/base/api/endpoint/membershipEndpoint';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';

export default function useOnboardingRedirection(path: string) {
    const { isOnboardingNextEnabled, } = useFeatureDecisions();
    const { data: membership, isFetching: isMembershipFetching } = useGetMembershipQuery();
    const shouldRedirectToOnboarding = !isMembershipFetching && isOnboardingNextEnabled && membership && !membership.flags.onboarding_done;
    const nextUrl = useNextURL();
    const url = nextUrl || '/next';
    const redirectPath = `${url}/onboarding`;
    const redirectURL = redirectPath;
  
    return {
        shouldRedirectToOnboarding,
        redirectToOnboardingURL: redirectURL
    };
  }