/* @flow */

import type {
  Membership,
  MembershipOAuthStatus,
} from '@braindate/domain/lib/membership/type';
import { getMembershipId } from '@braindate/domain/lib/membership/util';

import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';
import { apiRoot } from 'src/shared/app/base/api/apiRoot';
import type {
  EndpointSelector,
  UseLazyQuery,
  UseMutation,
  UseQuery,
} from 'src/shared/app/base/api/type/apiQueryType';
import {
  getQueryTag,
  optimisticUpdate,
} from 'src/shared/app/base/api/utils/endpointUtils';
import {
  membershipCalendarSyncStatusEndpoint,
  membershipEndpoint,
} from 'src/shared/app/base/settings/endpointSettings';

type Api = {
  endpoints: {
    getMembership: EndpointSelector<void, Membership>,
  },
  useGetMembershipQuery: UseQuery<Membership, void>,
  useGetOauthStatusQuery: UseQuery<MembershipOAuthStatus, number>,
  useLazyGetOauthStatusQuery: UseLazyQuery<MembershipOAuthStatus, number>,

  usePatchMembershipMutation: UseMutation<Object, Object>,
  useMarkTogethernessAsSeenMutation: UseMutation<Object, Object>,

  useCompleteOnboardingMutation: UseMutation<void, void>,
  useCompleteFavoriteTutorialMutation: UseMutation<void, void>,
};

const extendedApi: Api = apiRoot.injectEndpoints({
  endpoints: (builder) => ({
    getMembership: builder.query({
      query: () => ({
        url: membershipEndpoint(),
      }),
      providesTags: (result) =>
        result ? getQueryTag(TAG_TYPE.MEMBERSHIP, getMembershipId(result)) : [],
    }),
    getOauthStatus: builder.query({
      query: (id: number) => ({
        url: membershipCalendarSyncStatusEndpoint(id),
        method: 'GET',
      }),
      providesTags: (_result, _, id: number) => [
        { type: TAG_TYPE.MEMBERSHIP_OAUTH_STATUS, id },
      ],
    }),
    patchMembership: builder.mutation({
      query: (data) => ({
        url: membershipEndpoint(),
        method: 'PATCH',
        params: data,
      }),
      invalidatesTags: [TAG_TYPE.MEMBERSHIP],
    }),
    completeOnboarding: builder.mutation({
      query: () => ({
        url: membershipEndpoint(),
        method: 'PATCH',
        params: {
          flags: {
            onboarding_completed: true,
          },
        },
      }),
      async onQueryStarted(_, mutation) {
        optimisticUpdate<Membership>({
          extendedApi,
          mutation,
          type: TAG_TYPE.MEMBERSHIP,
          callback: (draft) => {
            draft.flags.onboarding_completed = true;
            return draft;
          },
        });
      },
      invalidatesTags: [TAG_TYPE.MEMBERSHIP],
    }),
    completeFavoriteTutorial: builder.mutation({
      query: () => ({
        url: membershipEndpoint(),
        method: 'PATCH',
        params: {
          flags: {
            favorite_tutorial_completed: true,
          },
        },
      }),
      async onQueryStarted(_, mutation) {
        optimisticUpdate<Membership>({
          extendedApi,
          mutation,
          type: TAG_TYPE.MEMBERSHIP,
          callback: (draft) => {
            draft.flags.favorite_tutorial_completed = true;
            return draft;
          },
        });
      },
      invalidatesTags: [TAG_TYPE.MEMBERSHIP],
    }),
    markTogethernessAsSeen: builder.mutation({
      query: () => ({
        url: membershipEndpoint(),
        method: 'PATCH',
        params: {
          flags: {
            togetherness_seen: true,
          },
        },
      }),
      async onQueryStarted(_, mutation) {
        optimisticUpdate<Membership>({
          extendedApi,
          mutation,
          type: TAG_TYPE.MEMBERSHIP,
          callback: (draft) => {
            draft.flags.togetherness_seen = true;
            return draft;
          },
        });
      },
      invalidatesTags: [TAG_TYPE.MEMBERSHIP],
    }),
  }),
});

export const {
  useGetMembershipQuery,
  useGetOauthStatusQuery,
  useLazyGetOauthStatusQuery,
  usePatchMembershipMutation,
  useCompleteOnboardingMutation,
  useCompleteFavoriteTutorialMutation,
  useMarkTogethernessAsSeenMutation,
  endpoints,
} = extendedApi;

export const { getMembership } = endpoints;
