/* @flow */

import * as types from 'src/shared/app/booking/action/uiBookingActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

/*
 |------------------------------------------------------------------------------
 | Initial state
 |------------------------------------------------------------------------------
 */

const initialState = {
  isBookingScheduleShown: false,
  isAcceptTimeScheduleShown: false,
  isBookingConfirmationModalShown: false,
  isEventOverModalShown: false,
  isBraindatingUnavailableModalShown: false,
  bookingMessage: {},
};

/*
 |------------------------------------------------------------------------------
 | Reducers
 |------------------------------------------------------------------------------
 */

const showBookingSchedule = (state: Object): Object => ({
  ...state,
  isBookingScheduleShown: true,
});

const hideBookingSchedule = (state: Object): Object => ({
  ...state,
  isBookingScheduleShown: false,
});

const showAcceptTimeSchedule = (state: Object): Object => ({
  ...state,
  isAcceptTimeScheduleShown: true,
});

const hideAcceptTimeSchedule = (state: Object): Object => ({
  ...state,
  isAcceptTimeScheduleShown: false,
});

const showBookingConfirmationModal = (state: Object): Object => ({
  ...state,
  isBookingConfirmationModalShown: true,
});

const hideBookingConfirmationModal = (state: Object): Object => ({
  ...state,
  isBookingConfirmationModalShown: false,
});

const toggleEventOverModal = (state: Object): Object => ({
  ...state,
  isEventOverModalShown: !state.isEventOverModalShown,
});

const toggleBraindatingUnavailableModal = (state: Object): Object => ({
  ...state,
  isBraindatingUnavailableModalShown: !state.isBraindatingUnavailableModalShown,
});

const setBookingMessage = (
  state: Object,
  { message, topicId }: Object,
): Object => ({
  ...state,
  bookingMessage: {
    ...state.bookingMessage,
    [topicId]: message,
  },
});

const resetBookingMessage = (state: Object, { topicId }: Object): Object => ({
  ...state,
  bookingMessage: {
    ...state.bookingMessage,
    [topicId]: '',
  },
});

export default createReducer(initialState, {
  [types.OPEN_BOOKING_SCHEDULE]: showBookingSchedule,
  [types.CLOSE_BOOKING_SCHEDULE]: hideBookingSchedule,
  [types.OPEN_ACCEPT_TIME_SCHEDULE]: showAcceptTimeSchedule,
  [types.CLOSE_ACCEPT_TIME_SCHEDULE]: hideAcceptTimeSchedule,
  [types.OPEN_BOOKING_CONFIRMATION_MODAL]: showBookingConfirmationModal,
  [types.CLOSE_BOOKING_CONFIRMATION_MODAL]: hideBookingConfirmationModal,
  [types.OPEN_EVENT_OVER_MODAL]: toggleEventOverModal,
  [types.CLOSE_EVENT_OVER_MODAL]: toggleEventOverModal,
  [types.OPEN_BRAINDATING_UNAVAILABLE_MODAL]: toggleBraindatingUnavailableModal,
  [types.CLOSE_BRAINDATING_UNAVAILABLE_MODAL]:
    toggleBraindatingUnavailableModal,
  [types.SAVE_BOOKING_MESSAGE]: setBookingMessage,
  [types.CLEAR_BOOKING_MESSAGE]: resetBookingMessage,
});
