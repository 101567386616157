/* @flow */
import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  form: { width: '100%' },
  close: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    paddingBottom: rem(13),
    paddingTop: rem(47),
  },
  submit: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: rem(26),
    paddingBottom: rem(56),
  },
  messageConfirmation: {
    fontSize: rem(16, baseFontSize),
    fontFamily: ({ theme }: ThemeFunction<{}>) => theme.primaryFontFamily,
    fontWeight: 400,
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    textAlign: 'center',
    paddingTop: rem(26),
    paddingBottom: rem(56),
  },
};
