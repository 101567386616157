/* @flow */

import * as types from 'src/shared/app/locale/action/localeActionTypes';
import { defaultLocale } from 'src/shared/app/locale/settings/localeSettings';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = defaultLocale;

const _setLocale = (state: string, action: Object) => action.locale;

export default createReducer(initialState, {
  [types.SET_LOCALE]: _setLocale,
});
