/* @flow */

import { createElement, memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import type { BaseUiComponent } from 'src/shared/ui/base/type/uiTypes';
import { createBaseUiComponentProps } from 'src/shared/ui/base/util/uiUtils';

import styles from './Subheading.style';

type Props = {
  ...BaseUiComponent,
  withError?: boolean,
  withWarning?: boolean,
  children: Node,
};

const useStyles = createUseThemeStyles(styles);

// eslint-disable-next-line react/prefer-exact-props
const Subheading = (props: Props): Node => {
  const { as, withError, withWarning, children } = props;

  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */

  const classes = useStyles();

  const rootClasses = classNames(classes.root, {
    [classes['root-withError']]: withError,
    [classes['root-withWarning']]: withWarning,
  });

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  return createElement(
    as || 'h2',
    { ...createBaseUiComponentProps(props, rootClasses) },
    children,
  );
};

export default memo<Props>(Subheading);
