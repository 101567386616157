/* @flow */

import { lap } from '@braindate/ui/lib/token/mediaQuery';

import { minTargetSize } from 'src/shared/app/base/style/token/box';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    position: 'relative',
  },
  item: {
    height: '100%',
    position: 'relative',
    backgroundColor: 'unset',
    borderRadius: 0,
    margin: 0,
    padding: 0,
    width: '100%',

    minWidth: minTargetSize,

    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
    textDecoration: 'none',

    '&:focus-visible': {
      outlineOffset: -1,
    },
  },
  mobileOnly: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',

    [lap]: {
      display: 'none',
    },
  },
  mobileMenu: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: [10, 20],
  },
  mobileMenuItem: {
    display: 'block',
    padding: '7px 0',
    '&:last-of-type': {
      paddingTop: 7,
    },
  },
  mobileMenuItemLink: {
    padding: '7px 0 7px 0',
  },
};
