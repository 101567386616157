/* @flow */

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';
import { topBarMarginBottom } from 'src/shared/app/nav/style/nav.style';

// This is used to hide any content that scroll behind the Bar.
const sideWidth = 30;
const overlayBase = {
  content: '""',
  position: 'absolute',
  background: ({ theme }: ThemeFunction<{}>) => theme.colorBase,
};
const overlaySides = {
  ...overlayBase,
  top: 0,
  bottom: 0,
  width: sideWidth,
};

export default {
  root: {
    display: ({ show }: ThemeFunction<{ show: boolean }>) =>
      show ? 'block' : 'none',
    width: '100%',
    background: ({ theme }: ThemeFunction<{}>) => theme.colorBase,
    paddingBottom: 16,
    '&:before': {
      ...overlaySides,
      left: -30,
    },
    '&:after': {
      ...overlaySides,
      right: -30,
    },
  },
  fixedElt: {
    display: ({ showOverlay }: ThemeFunction<{ showOverlay: boolean }>) =>
      showOverlay ? 'block' : 'none',
  },
  overlay: {
    ...overlayBase,
    left: sideWidth * -1,
    right: sideWidth * -1,
    top: topBarMarginBottom * -1,
    height: topBarMarginBottom,
  },
};
