/* @flow */
import { memo } from 'react';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateConversation,
  getBraindateSpotsLeft,
} from '@braindate/domain/lib/braindate/util';
import { getConversationParticipants } from '@braindate/domain/lib/conversation/util';
import { isGroupTopic } from '@braindate/domain/lib/topic/util';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import BraindateCardAuthor from 'src/shared/app/braindate/component/card/BraindateCardAuthor';
import UpcomingBraindateCardAction from 'src/shared/app/braindate/component/card/upcoming/UpcomingBraindateCardAction';
import { getBraindateTopic } from 'src/shared/domain/braindate/util/braindateUtil';
import GroupAvatarQueue from 'src/shared/ui/component/image/GroupAvatarQueue';

import styles from './UpcomingBraindateCardFooter.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  braindate: Braindate,
  showCheckin: boolean,
  isPreview?: boolean,
};

const UpcomingBraindateCardFooter = ({
  braindate,
  showCheckin,
  isPreview,
}: Props) => {
  const classes = useStyles();

  const topic = getBraindateTopic(braindate);
  const isGroupBraindate = isGroupTopic(topic);

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        {isGroupBraindate && (
          <GroupAvatarQueue
            // $FlowIssue Will always be defined if it's a group braindate
            spots={getBraindateSpotsLeft(braindate)}
            participants={getConversationParticipants(
              getBraindateConversation(braindate),
            )}
          />
        )}

        <BraindateCardAuthor
          braindate={braindate}
          isPreview={isPreview}
          withAvatar={!isGroupBraindate}
          variant={isGroupBraindate ? 'horizontal' : 'vertical'}
        />
      </div>

      <div className={classes.right}>
        <UpcomingBraindateCardAction
          braindate={braindate}
          showCheckin={showCheckin}
        />
      </div>
    </div>
  );
};

export default memo<Props>(UpcomingBraindateCardFooter);
