/* @flow */
import { em } from 'polished';

export default {
  root: {
    width: em(34),
    height: em(21),
    fontSize: '0.75em',
  },
};
