/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import {
  hasURLProtocol,
  isURLAnchor,
  isURLMailto,
} from '@braindate/util/lib/url';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './BaseLink.style';

type Props = {
  children: Node,
  href?: string | null,
  state?: Object,
  className?: ?string,
  onClick?: ?(e: Event) => void,
  handleMouseOver?: () => void,
  newWindow?: boolean,
  tabIndex?: number,
  'aria-haspopup'?: ?string | boolean,
};

const useStyles = createUseThemeStyles(styles);

const BaseLink = ({
  children,
  href,
  state,
  className,
  onClick,
  handleMouseOver,
  newWindow,
  tabIndex,
  'aria-haspopup': ariaHaspopup,
}: Props): Node => {
  const useAnchorTag =
    href &&
    typeof href === 'string' &&
    (hasURLProtocol(href) || isURLAnchor(href) || isURLMailto(href));
  const useButtonTag = !href;
  const classes = useStyles();

  /*
   |----------------------------------------------------------------------------
   | Event Handlers
   |----------------------------------------------------------------------------
   */

  const handleClick = (e: MouseEvent) => {
    const { currentTarget } = e;

    if (
      currentTarget instanceof Element &&
      currentTarget.getAttribute('href') === '#'
    ) {
      e.preventDefault();
    }

    if (onClick) {
      onClick(e);
    }
  };

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  const props = {
    className,
    children,
    onClick: onClick ? handleClick : null,
    onMouseOver: handleMouseOver || null,
    href: undefined,
    role: undefined,
    'aria-haspopup': ariaHaspopup,
    tabIndex,
  };

  if (useAnchorTag) {
    props.href = href || '#';
  }

  if (useAnchorTag) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        {...props}
        className={classNames(classes.link, className)}
        target={newWindow ? '_blank' : null}
      />
    );
  }
  if (useButtonTag) {
    return (
      <button {...props} className={classNames([classes.button, className])} />
    );
  }

  // $FlowIssue
  return (
    <Link
      {...props}
      state={state}
      className={classNames(classes.link, className)}
      to={href}
    />
  );
};

export default memo<Props>(BaseLink);
