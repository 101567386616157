/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './LengthCounter.style';

type Props = {
  count: number,
  maxLength: number,
};

const useStyles = createUseThemeStyles(styles);

const LengthCounter = ({ count, maxLength }: Props): Node => {
  const classes = useStyles();

  // Classes
  const rootClasses = classNames(classes.root, {
    [`${classes['root-error']}`]: count > Number(maxLength),
    [`${classes['root-maxValue']}`]: count === Number(maxLength),
  });

  return <span className={rootClasses}>{`${count}/${maxLength}`}</span>;
};

export default memo<Props>(LengthCounter);
