/* @flow */

import { lap } from '@braindate/ui/lib/token/mediaQuery';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: 596,
    padding: 20,

    [lap]: {
      padding: 0,
    },
  },
  title: {
    margin: [0, 0, 20, 0],
    fontWeight: 600,
    textAlign: 'left',
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '12px',
  },
  card: {
    width: 'calc(50% - 6px)',
    height: 160,
    position: 'relative',
    outline: `2px dashed transparent`,
    transition: '0.25s ease-out',
    overflow: 'hidden',
    cursor: 'pointer',

    '& button': {
      position: 'absolute',
      top: '110%',
      opacity: 0,
      transition: '0.25s ease-out',
      zIndex: 1,
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
      transition: '0.25s ease-out',
    },

    '&:hover, &:focus': {
      outline: ({ theme }: ThemeFunction<{}>) =>
        `2px dashed ${theme.textColorTertiary}`,

      '& button': {
        opacity: 1,
        top: '60%',
      },

      '&:after': {
        opacity: 1,
      },
    },
  },
  cardCreate: {
    outline: ({ theme }: ThemeFunction<{}>) =>
      `2px dashed ${theme.textColorTertiary}`,
  },
};
