/* @flow */
import { useMemo } from 'react';

import { createTheme } from '@mui/material/styles';
import { rem } from 'polished';

import { tablet } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import {
  dialogElevation,
  popOverElevation,
} from 'src/shared/app/base/style/token/elevation';
import { hoverSelector } from 'src/shared/app/base/style/token/selector';
import type { Theme } from 'src/shared/app/base/types/themeTypes';

import { getReadableColor } from 'plugin/light/util/lightUtils';

export default function useMuiCreateTheme(theme: Theme) {
  const MuiTheme = useMemo(
    () =>
      createTheme({
        braindate: theme,
        palette: {
          primary: {
            main: theme.accentColor,
            textColor: getReadableColor(theme.accentColor),
          },
          secondary: {
            main: theme.accentColorSoft,
            textColor: getReadableColor(theme.accentColorSoft),
          },
          mode: theme.name,
          success: {
            light: '#006239',
            main: '#00BC4B',
            dark: '#006239',
          },
        },
        components: {
          MuiAutocomplete: {
            styleOverrides: {
              clearIndicator: {
                minWidth: 0,
                minHeight: 0,
              },
            },
          },
          MuiChip: {
            styleOverrides: {
              root: {
                borderRadius: theme.buttonBorderRadius,
              },
              label: {
                fontSize: rem(14, baseFontSize),
              },
            },
            variants: [
              {
                props: { color: 'info' },
                style: {
                  backgroundColor: `${theme.colorPrimary} !important`,
                  color: `${theme.textColor} !important`,
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  '& .MuiChip-label': {
                    fontSize: rem(12, baseFontSize),
                  },
                },
              },
            ],
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                fontSize: rem(16, baseFontSize),
              },
            },
          },
          MuiPopover: {
            defaultProps: {
              disableScrollLock: true,
            },
            styleOverrides: {
              root: {
                zIndex: popOverElevation,
              },
              paper: {
                borderRadius: 14,
              },
            },
          },
          MuiMenu: {
            defaultProps: {
              disableScrollLock: true,
            },
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: 12,
              },
            },
            defaultProps: {
              arrow: true,
              enterTouchDelay: 0,
            },
          },
          MuiSelect: {
            defaultProps: {
              variant: 'outlined',
            },
            styleOverrides: {
              nativeInput: {
                display: 'none',
              },
            },
          },
          MuiSwitch: {
            defaultProps: {
              disableFocusRipple: true,
              disableTouchRipple: true,
            },
            styleOverrides: {
              root: {
                height: '26px !important',
                padding: 0,
                paddingRight: 6,
                paddingLeft: 6,
                overflow: 'visible',
              },
              track: {
                borderRadius: 15,
              },
              switchBase: {
                paddingTop: 3,
                paddingLeft: 9,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
              input: {
                '&:focus + .MuiSwitch-thumb': {
                  outline: `2px solid ${theme.accentColor}`,
                  outlineOffset: '2px',
                },
              },
              thumb: {
                width: 20,
                height: 20,
              },
            },
          },
          MuiSwipeableDrawer: {
            defaultProps: {
              disableDiscovery: true,
              disableSwipeToOpen: true,
            },
          },
          MuiDrawer: {
            styleOverrides: {
              root: {
                zIndex: dialogElevation - 1,
              },
              paper: {
                paddingTop: 24,
                borderTopLeftRadius: 18,
                borderTopRightRadius: 18,

                [tablet]: {
                  paddingTop: 0,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                },

                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 16,
                  left: 'calc(50% - 19px)',
                  width: 38,
                  height: 4,
                  backgroundColor: theme.calendarDisableButtonBackgroundColor,

                  [tablet]: {
                    display: 'none',
                  },
                },
              },
            },
          },
          MuiIconButton: {
            disableFocusRipple: true,
            disableTouchRipple: true,
            styleOverrides: {
              root: {
                borderRadius: `${theme.buttonBorderRadius} !important`,
              },
            },
            variants: [
              {
                props: { variant: 'contained' },
                style: {
                  color: theme.colorPrimary,
                  backgroundColor: theme.accentColor,
                  border: `1px solid ${theme.colorPrimary}`,
                  boxShadow: theme.cardShadowBox,

                  [hoverSelector]: {
                    color: theme.accentColor,
                    backgroundColor: theme.colorPrimary,
                    border: `1px solid ${theme.accentColor}`,
                  },
                },
              },
              {
                props: { variant: 'outlined' },
                style: {
                  color: theme.accentColor,
                  backgroundColor: theme.colorPrimary,
                  border: `1px solid ${theme.accentColor}`,
                  boxShadow: theme.cardShadowBox,

                  [hoverSelector]: {
                    color: theme.colorPrimary,
                    backgroundColor: theme.accentColor,
                    border: `1px solid ${theme.colorPrimary}`,
                  },
                },
              },
            ],
          },
        },
        shape: {
          borderRadius: 8,
        },
        typography: {
          fontFamily: theme.secondaryFontFamily,
          variants: {
            h1: {
              fontFamily: theme.primaryFontFamily,
            },
            h2: {
              fontFamily: theme.primaryFontFamily,
            },
            h3: {
              fontFamily: theme.primaryFontFamily,
            },
          },
        },
      }),
    [theme],
  );
  return MuiTheme;
}
