/* @flow */

import { em } from 'polished';

export default {
  root: {
    width: em(169),
    height: em(21),
  },
};
