/* @flow */

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  manualAction: {
    display: 'flex',
    justifyContent: ({ align }: ThemeFunction<{ align: string }>) => align,
  },
};
