/* @flow */

import { assertObject } from '@braindate/util/lib/assert';

import { getLocale } from 'src/shared/app/locale/selector/localeSelectors';
import { getLocaleMessages } from 'src/shared/app/locale/settings/localeSettings';
import type { State } from 'src/shared/core/type/reduxTypes';

/**
 * Load files required for localization, depending on the locale stored in the
 * state. Check Luxon's documentation for more details.
 * @param   {State} state - State of the application
 * @returns {Promise} Promise
 *
 * @throws Will throw an exception if parameters are not valid
 */
export function configureL10n(state: State): Promise<?Object> {
  assertObject(state, 'state');

  return new Promise((resolve) => {
    const messages = getLocaleMessages(getLocale(state));

    if (messages) {
      resolve(messages);
    } else {
      // If the loading of the translations fails, render the app with the
      // default language
      resolve();
    }
  }).catch((e) => {
    // eslint-disable-next-line no-console
    console.error(e);
  });
}
