/* @flow */

import { createSelector } from '@reduxjs/toolkit';
import { equals } from 'ramda';

import { marketFilterInitialState } from 'src/shared/app/market/reducer/filter/marketFilterReducer';
import { bookmarkedParam } from 'src/shared/app/market/settings/marketSettings';
import type { State } from 'src/shared/core/type/reduxTypes';

const _getSegment = (state: State) => state.app.market.filter;

const _getMarketStatusFilter = (state: State) => _getSegment(state).status;
const _getMarketKindFilter = (state: State) => _getSegment(state).kind;
const _getMarketLanguageFilter = (state: State) => _getSegment(state).language;
const _getMarketDateFilter = (state: State) => _getSegment(state).date;
const _getMarketFilters = (state: State) => _getSegment(state);
const _getMarketLocationFilter = (state: State) =>
  _getSegment(state).selfLocation;
const _getMarketCanJoinFilter = (state: State) => _getSegment(state).canJoin;
const _getMarketConversationTypeFilter = (state: State) =>
  _getSegment(state).conversationType;
const _getParticipantCategoriesFilter = (state: State) =>
  _getSegment(state).participantCategories;

export const getMarketStatusFilter: (state: State) => string = createSelector(
  [_getMarketStatusFilter],
  (x: string): string => x,
);

export const getMarketKindFilter: (state: State) => Array<string> =
  createSelector(
    [_getMarketKindFilter],
    (x: Array<string>): Array<string> => x,
  );

export const getMarketBookmarkFilter: (state: State) => boolean =
  createSelector(
    [_getMarketStatusFilter],
    (x: string): boolean => x === bookmarkedParam,
  );

export const getMarketLanguageFilter: (state: State) => Array<string> =
  createSelector(
    [_getMarketLanguageFilter],
    (x: Array<string>): Array<string> => x,
  );

export const getMarketDateFilter: (state: State) => string = createSelector(
  [_getMarketDateFilter],
  (x: string): string => x,
);

export const getMarketLocationFilter: (state: State) => Array<number> =
  createSelector(
    [_getMarketLocationFilter],
    (x: Array<number>): Array<number> => x,
  );

export const getMarketCanJoinFilter: (state: State) => boolean = createSelector(
  [_getMarketCanJoinFilter],
  (x: boolean): boolean => x,
);

export const getMarketConversationTypeFilter: (state: State) => Array<string> =
  createSelector(
    [_getMarketConversationTypeFilter],
    (x: Array<string>): Array<string> => x,
  );

export const getParticipantCategoriesFilter: (state: State) => Array<string> =
  createSelector(
    [_getParticipantCategoriesFilter],
    (x: Array<string>): Array<string> => x,
  );

export const getMarketFilters: (state: State) => Object = createSelector(
  [_getMarketFilters],
  (x: Object): Object => x,
);

export const hasMarketFiltersChanged: (state: State) => boolean =
  createSelector(
    [_getMarketFilters],
    (x: Object): boolean => !equals(x, marketFilterInitialState),
  );
