/* @flow */

export const SHOW_MARKET_AUTOCOMPLETE = 'SHOW_MARKET_AUTOCOMPLETE';
export const HIDE_MARKET_AUTOCOMPLETE = 'HIDE_MARKET_AUTOCOMPLETE';

export const SET_MARKET_FIRST_TOPIC = 'SET_MARKET_FIRST_TOPIC';
export const RESET_MARKET_FIRST_TOPIC = 'RESET_MARKET_FIRST_TOPIC';

export const SHOW_MARKET_TOPIC_NOT_FOUND = 'SHOW_MARKET_TOPIC_NOT_FOUND';
export const DISMISS_MARKET_TOPIC_NOT_FOUND = 'DISMISS_MARKET_TOPIC_NOT_FOUND';

export const FREEZE_MARKET_TOPICS = 'FREEZE_MARKET_TOPICS';
export const UNFREEZE_MARKET_TOPICS = 'UNFREEZE_MARKET_TOPICS';

export const SET_MARKET_CURRENT_PAGE = 'SET_MARKET_CURRENT_PAGE';
export const RESET_MARKET_CURRENT_PAGE = 'RESET_MARKET_CURRENT_PAGE';
export const SHOW_TOCIC_INSPIRATIONS = 'SHOW_TOCIC_INSPIRATIONS';
