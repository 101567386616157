/* @flow */

import { combineReducers } from 'redux';

import uiAvailabilityReducer from 'src/shared/app/account/availability/reducer/uiAvailabilityReducer';

// $FlowIssue
const appBraindatesReducer = combineReducers({
  availability: uiAvailabilityReducer,
});

export default appBraindatesReducer;
