/* @flow */

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';
import primaryButton from 'src/shared/ui/component/button/PrimaryButton.style';
import secondaryButton from 'src/shared/ui/component/button/SecondaryButton.style';

export default {
  root: {},

  primary: {
    ...primaryButton.root,
  },
  secondary: {
    ...secondaryButton.root,
  },

  'root-withError': {
    color: ({ theme }: ThemeFunction<{}>) => theme.errorMessageColor,
    borderColor: ({ theme }: ThemeFunction<{}>) => theme.errorMessageColor,
    '&:hover': {
      backgroundColor: ({ theme }: ThemeFunction<{}>) =>
        theme.errorMessageColor,
    },
  },
};
