/* @flow */

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'block',

    width: 8,
    height: 8,

    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.successTextColor,
    borderRadius: '50%',
  },
  'root-large': {
    width: 14,
    height: 14,

    borderWidth: 1,
  },
  'root-darkBg': {
    borderColor: ({ theme }: ThemeFunction<{}>) => theme.colorBase,
  },
};
