/* @flow */

// https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk

import { liveChatHubspotScriptURL } from 'plugin/live-chat/setting/liveChatSettings';

import type {
  WidgetIntegrationActions,
  WidgetLoadParams,
  WidgetLoadUserParams,
} from 'plugin/live-chat/type/liveChatType';

const debug = require('debug')('live-chat-actions:Hubspot');

function getHubspotAppId(envSdkOptions: Object): string {
  return envSdkOptions.liveChatHubspotAppId;
}

// Keep this for future iteration
// async function getHubspotToken({
//   email,
//   getState,
// }: Object): Promise<string | null> {
//   const request = email
//     ? await get(getState, '', {})
//     : { data: { token: null } };
//   const {
//     data: { token },
//   } = request;

//   return token;
// }

export const hubspotWidgetActions: WidgetIntegrationActions = {
  load: async ({ envSdkOptions, onOpenedStatusChange }: WidgetLoadParams) => {
    debug('Setup');
    const hubspotAppId = getHubspotAppId(envSdkOptions);
    if (!hubspotAppId) return;

    window.hsConversationsSettings = {
      loadImmediately: false,
    };

    window.loadJS(
      liveChatHubspotScriptURL.replace('{app_id}', hubspotAppId),
      () => {
        debug('Load Widget');
        if (!window.HubSpotConversations) {
          window.hsConversationsOnReady = [
            () => {
              if (!window.HubSpotConversations) return;
              window.HubSpotConversations.on('conversationStarted', () => {
                onOpenedStatusChange(true);
              });
              window.HubSpotConversations.on('widgetOpened', () => {
                onOpenedStatusChange(true);
              });
              window.HubSpotConversations.on('widgetClosed', (_payload) => {
                onOpenedStatusChange(false);
                window.HubSpotConversations.widget.remove();
              });
            },
          ];
        }
      },
    );
  },
  kill: () => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove();
    }
  },
  toggle: (openedStatus) => {
    if (window.HubSpotConversations) {
      if (openedStatus) {
        window.HubSpotConversations.widget.remove();
      } else {
        if (!window.HubSpotConversations.widget.status().loaded) {
          window.HubSpotConversations.widget.load();
        }
        window.HubSpotConversations.widget.open();
      }
    }
  },
  identify: async ({
    email: _email,
    getState: _getState,
  }: WidgetLoadUserParams) => {
    if (window.HubSpotConversations) {
      // const token = await getHubspotToken({ email, getState });
      // window.hsConversationsSettings = {
      //   loadImmediately: false,
      //   ...(email && token
      //     ? {
      //         identificationEmail: email,
      //         identificationToken: token,
      //       }
      //     : {}),
      // };
      // hubspotWidgetActions.update();
    }
  },

  update() {
    if (window.HubSpotConversations) {
      const status = window.HubSpotConversations.widget.status();
      if (status.loaded) {
        window.HubSpotConversations.widget.refresh();
      } else {
        window.HubSpotConversations.widget.load();
      }
    }
  },
};
