/* @flow */
import { createSlice } from '@reduxjs/toolkit';

import { getMembershipId } from '@braindate/domain/lib/membership/util';

import { getMembership } from 'src/shared/app/base/api/endpoint/membershipEndpoint';
import type { SlackPluginStatus } from 'src/shared/app/base/plugin/slack/types/SlackPluginStatus';
import { getAPIURL } from 'src/shared/app/base/selector/appEnvSelectors';
import { parseResponse } from 'src/shared/app/base/util/ajaxUtils';
import {
  setFetchingStateSlice,
  unsetFetchingStateSlice,
} from 'src/shared/core/util/reducerUtils';

export type SlackPluginOptionsState = {
  isFetching: Boolean,
  status: SlackPluginStatus,
};

export const slackPluginStatusSlice = createSlice({
  name: 'slackPlugin',
  initialState: { isFetching: false, status: undefined },
  reducers: {
    fetchStatusRequest: setFetchingStateSlice,
    fetchStatusSuccess: (
      state: SlackPluginOptionsState,
      { payload }: { payload: SlackPluginStatus },
    ) => {
      state.status = payload;
      unsetFetchingStateSlice(state);
    },
    fetchStatusFailure: unsetFetchingStateSlice,
  },
});

export const { fetchStatusRequest, fetchStatusSuccess, fetchStatusFailure } =
  slackPluginStatusSlice.actions;

export function fetchSlackPluginOptions(): Promise<void> {
  // $FlowIssue
  return async (
    dispatch: Function,
    getState: Function,
    { get }: { get: Function },
  ) => {
    dispatch(fetchStatusRequest());
    const state = getState();
    const apiUrl = getAPIURL(state);

    const membership = getMembership.select()(state).data;
    const membershipId = membership && getMembershipId(membership);

    try {
      const response = await get(`${apiUrl}plugins/slack/slack_sync_status/`, {
        membership_id: membershipId,
      });

      const data = await parseResponse(response);
      dispatch(fetchStatusSuccess(data));
    } catch (e) {
      fetchStatusFailure(e);
    }
  };
}

export default slackPluginStatusSlice.reducer;
