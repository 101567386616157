/* @flow */

import type { Location } from '@braindate/domain/lib/location/type';

import useAuthenticationToken from 'src/shared/app/authentication/hook/useAuthenticationToken';
import { useGetLocationsQuery } from 'src/shared/app/base/api/endpoint/locationsEndpoint';

export default function useLocations(): Array<Location> {
  const token = useAuthenticationToken();
  const { data } = useGetLocationsQuery(undefined, { skip: !token });
  return data || [];
}
