/* @flow */

import { rem } from 'polished';

import { lap, tablet } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { gutter } from 'src/shared/app/base/style/token/grid';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

type StyleProps = ThemeFunction<{
  titleColor: string,
  bannerOverlayColor?: string,
  isTransparent: boolean,
}>;

export default {
  root: {
    height: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,

    [tablet]: {
      padding: [0, gutter],
    },
  },
  content: {
    borderRadius: 25,
    padding: [7, 14],
    backgroundColor: ({ theme, bannerOverlayColor }: StyleProps) =>
      bannerOverlayColor || theme.colorBase,
    boxShadow: ({ theme, isTransparent }: StyleProps) =>
      isTransparent ? 'none' : theme.shadowBox,
    [tablet]: {
      padding: [20, 30],
    },
  },
  hero: {
    margin: '0 auto',
    color: ({ theme }: StyleProps) => theme.accentColor,
    fontSize: rem(17, baseFontSize),

    [tablet]: {
      width: 'max-content',
      fontSize: rem(20, baseFontSize),
    },
  },
  name: {
    display: 'block',
    margin: '2px auto',
    color: ({ theme }: StyleProps) => theme.accentColor,
    fontWeight: 700,
    fontSize: rem(19, baseFontSize),

    [tablet]: {
      margin: '16px auto',
      fontSize: rem(26, baseFontSize),
    },
  },
  heroSubTitle: {
    margin: '0 auto',
    color: ({ theme, titleColor }: StyleProps) => titleColor || theme.textColor,

    fontSize: rem(17, baseFontSize),
    [tablet]: {
      width: 'max-content',
      fontSize: rem(18, baseFontSize),
    },
  },
  branding: {
    position: 'absolute',
    left: 12,
    right: 12,
    top: 12,
    display: 'flex',
    justifyContent: 'space-between',

    [tablet]: {
      right: 'auto',
      flexDirection: 'column',
    },
  },
  logo: {
    width: 145,
    height: 43,

    [lap]: {
      width: 170,
      height: 50,
    },
  },
  link: {
    position: 'absolute',
    right: 16,
    top: 16,

    [tablet]: {
      position: 'fixed',
      right: 24,
      top: 24,
    },
  },
  body: {
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    maxWidth: 'max-content',
    padding: [0, 15],

    [tablet]: {
      margin: 0,
    },
  },
  submit: {
    display: 'flex',
    justifyContent: 'center',

    marginTop: 24,
  },
  footer: {
    position: 'absolute',
    bottom: 16,
    left: 0,
    width: '100%',
  },
};
