/* @flow */

import * as types from 'src/shared/app/authentication/action/support/supportLoginLinkActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

/*
|------------------------------------------------------------------------------
| INITIAL STATE
|------------------------------------------------------------------------------
*/

const initialState = {
  isFetching: false,
  failure: false,
  success: false,
  showEncryptedEmail: false,
  resendEmailActive: true,
  username: null,
  isLoginLinkResent: false,
  loginLinkResendError: null,
};

/*
|------------------------------------------------------------------------------
| REDUCERS
|------------------------------------------------------------------------------
*/

const _loginLinkRequest = (state: Object): Object => ({
  ...state,
  isFetching: true,
  success: false,
  failure: false,
});

const _loginLinkFailure = (state: Object): Object => ({
  ...state,
  failure: true,
  success: false,
  isFetching: false,
});

const _reset = (state: Object): Object => ({
  ...state,
  failure: false,
  success: false,
  isFetching: false,
});

const _loginLinkSuccess = (state: Object, action: Object): Object => {
  const { username, encryptedEmail } = action;

  return {
    ...state,
    success: true,
    failure: false,
    isFetching: false,
    showEncryptedEmail: encryptedEmail,
    username,
  };
};

const _loginLinkResentRequest = (state: Object): Object => ({
  ...state,
  isFetching: true,
  loginLinkResendError: null,
});

const _loginLinkResentSuccess = (state: Object): Object => ({
  ...state,
  isFetching: false,
  isLoginLinkResent: true,
});

const _loginLinkResentFailure = (state: Object, { exception }): Object => ({
  ...state,
  isFetching: false,
  isLoginLinkResent: false,
  loginLinkResendError: exception,
});

/*
|------------------------------------------------------------------------------
| REDUCER
|------------------------------------------------------------------------------
*/

export default createReducer(initialState, {
  [types.SUPPORT_LOGIN_LINK_REQUEST]: _loginLinkRequest,
  [types.SUPPORT_LOGIN_LINK_FAILURE]: _loginLinkFailure,
  [types.SUPPORT_LOGIN_LINK_SUCCESS]: _loginLinkSuccess,
  [types.RESET_SUPPORT_LOGIN_LINK]: _reset,
  [types.SUPPORT_RESEND_LOGIN_LINK_REQUEST]: _loginLinkResentRequest,
  [types.SUPPORT_RESEND_LOGIN_LINK_SUCCESS]: _loginLinkResentSuccess,
  [types.SUPPORT_RESEND_LOGIN_LINK_FAILURE]: _loginLinkResentFailure,
});
