/* @flow */

import { lap } from '@braindate/ui/lib/token/mediaQuery';

import { MAX_TABLET_GRID_WIDTH } from 'src/shared/app/base/style/token/grid';
import { listReset } from 'src/shared/app/base/style/token/helper';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

type StyleProps = ThemeFunction<{
  grid?: boolean,
  itemWidth?: number,
  isCompact?: boolean,
  shouldAddEmptyElement?: boolean,
}>;

export default {
  root: {
    ...listReset,

    '& > li': {
      width: '100%',
      marginBottom: ({ grid }: StyleProps) => (grid ? 0 : 32),

      '&:last-child': {
        marginBottom: 0,
      },
    },

    flexDirection: 'column',
    flexWrap: 'no-wrap',
    width: '100%',

    marginBottom: ({ isCompact }: StyleProps) => (isCompact ? 12 : 30),

    margin: [0, 'auto'],
    maxWidth: ({ grid }: StyleProps) =>
      grid ? 'unset' : MAX_TABLET_GRID_WIDTH,
  },
  grid: {
    display: ({ grid }: StyleProps) => (grid ? 'grid' : 'flex'),
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    gridTemplateColumns: ({ grid, itemWidth }: StyleProps) =>
      grid && itemWidth
        ? `repeat(auto-fill, minmax(${itemWidth || 0}px, 1fr))`
        : '',

    [lap]: {
      justifyContent: 'space-between',
    },

    '&:after': {
      content: '""',
      display: ({ shouldAddEmptyElement }: StyleProps) =>
        shouldAddEmptyElement ? 'block' : 'none',
      width: ({ itemWidth }: StyleProps) => itemWidth,
    },

    'row-gap': ({ isCompact }: StyleProps) => (isCompact ? 'unset' : '30px'),
    'column-gap': ({ grid, isCompact }: StyleProps) => {
      if (isCompact) return 'unset';
      return grid ? '20px' : 'unset';
    },
  },
  // If we ever want to use css grids, we should use some code like this
  // grid: {
  //   display: 'grid',
  //   gridTemplateColumns: 'repeat(auto-fill, calc(100% / 3))',
  //   justifyContent: 'space-between',

  //   '& > li': {
  //     width: '100%',
  //     display: 'flex',
  //     justifyContent: 'center',
  //   },
  // },
};
