(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "polished", "../palette/index"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("polished"), require("../palette/index"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.polished, global.index);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _polished, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.warningColor = _exports.textColor = _exports.successColor = _exports.overlayColor = _exports.errorColor = _exports.darkWarningColor = _exports.darkTextColor = _exports.darkSuccessColor = _exports.darkErrorColor = _exports.darkBaseColor = _exports.cardBaseColor = _exports.borderColor = _exports.baseColor = void 0;
  /*
   |------------------------------------------------------------------------------
   | Base
   |------------------------------------------------------------------------------
   */
  var baseColor = _exports.baseColor = _index.blueGrey;
  var darkBaseColor = _exports.darkBaseColor = (0, _polished.darken)(0.05, baseColor);
  var borderColor = _exports.borderColor = _index.mediumGrey;

  /*
   |------------------------------------------------------------------------------
   | Text
   |------------------------------------------------------------------------------
   */

  var textColor = _exports.textColor = _index.white;
  var darkTextColor = _exports.darkTextColor = _index.black;

  /*
   |------------------------------------------------------------------------------
   | Status
   |------------------------------------------------------------------------------
   */

  var successColor = _exports.successColor = _index.green;
  var darkSuccessColor = _exports.darkSuccessColor = _index.darkGreen;
  var warningColor = _exports.warningColor = _index.yellow;
  var darkWarningColor = _exports.darkWarningColor = _index.darkYellow;
  var errorColor = _exports.errorColor = _index.red;
  var darkErrorColor = _exports.darkErrorColor = _index.darkRed;

  /*
   |------------------------------------------------------------------------------
   | Elements
   |------------------------------------------------------------------------------
   */

  var cardBaseColor = _exports.cardBaseColor = _index.white;
  var overlayColor = _exports.overlayColor = (0, _polished.transparentize)(0.25, _index.black);
});