/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import type { Button } from '@braindate/ui/lib/component/button/type/buttonTypes';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import BaseButton from 'src/shared/ui/component/button/BaseButton';

import styles from './TextButton.style';

type Props = Button & {
  withError?: boolean,
  isDisabled?: boolean,
};

const useStyles = createUseThemeStyles(styles);

const TextButton = ({
  withError,
  isDisabled,
  layoutClass,
  ...props
}: Props): Node => {
  const classes = useStyles();

  const rootClasses = classNames(classes.root, {
    [classes['root-withError']]: withError,
    [classes['root-isDisabled']]: isDisabled,
  });

  return (
    <BaseButton
      {...props}
      isDisabled={isDisabled}
      className={classNames(rootClasses, layoutClass)}
    />
  );
};

export default memo<Props>(TextButton);
