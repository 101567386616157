/* @flow */
import { createSelector } from '@reduxjs/toolkit';

import { assertObject, assertString } from '@braindate/util/lib/assert';

import type { UiHelpButtonReducerStateType } from 'src/shared/app/help/reducer/uiHelpButtonReducer';
import type { State } from 'src/shared/core/type/reduxTypes';

const getValue = (
  state: State,
  key: $Keys<UiHelpButtonReducerStateType>,
): any => {
  assertObject(state, 'state');
  assertString(key, 'key');

  return state.ui.helpButton[key];
};

const _isHelpButtonHidden = (state) => getValue(state, 'hideButton');

export const isHelpButtonHidden: (state: Object) => boolean = createSelector(
  [_isHelpButtonHidden],
  (isHidden: boolean): boolean => isHidden,
);
