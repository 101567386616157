/* @flow */

import { lap } from '@braindate/ui/lib/token/mediaQuery';

import { topAppBarElevation } from 'src/shared/app/base/style/token/elevation';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

type StyleProps = ThemeFunction<{
  isOpened: boolean,
}>;

export default {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    padding: [0, 15],
    left: ({ isOpened }: StyleProps) => (isOpened ? 0 : '100%'),
    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.colorBase,
    transition: '0.25s ease-out',
    zIndex: topAppBarElevation + 1,
    minWidth: 250,

    [lap]: {
      position: 'initial',
      minWidth: 335,
      padding: 0,
    },
  },
  container: {
    position: 'relative',
    marginBottom: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 15,
    padding: [0, 12],
    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.secondaryTagColor,

    width: '100%',
    height: 30,
  },
  input: {
    height: 42,
  },
  cancel: {
    [lap]: {
      display: 'none',
    },
  },
};
