/* @flow */
import type { State } from 'src/shared/core/type/reduxTypes';

import {
  getLiveChatActions,
  getLiveChatLoadParams,
  getOpenedStatus,
} from 'plugin/live-chat/selector/liveChatSelectors';

import {
  LIVE_CHAT_UPDATE_OPENED_STATUS,
  LIVE_CHAT_UPDATE_STATUS,
} from './liveChatActionTypes';

import type { Dispatch } from 'redux';

const debug = require('debug')('live-chat-actions');

export function updateStatus(status: string) {
  return {
    type: LIVE_CHAT_UPDATE_STATUS,
    status,
  };
}

export function updateOpenedStatus(status: boolean) {
  return {
    type: LIVE_CHAT_UPDATE_OPENED_STATUS,
    status,
  };
}

type LiveChatActionFunction = (
  dispatch: Dispatch<any>,
  getState: () => State,
) => void;

export type liveChatsActionsType = {
  load: LiveChatActionFunction,
  kill: LiveChatActionFunction,
  toggle: LiveChatActionFunction,
  identify: LiveChatActionFunction,
  update: LiveChatActionFunction,
};

export const liveChatsActions: liveChatsActionsType = {
  load: (dispatch, getState) => {
    const options = getLiveChatLoadParams(getState(), dispatch);
    const { load } = getLiveChatActions(getState());
    debug('load', options);
    load(options);
  },
  kill: (dispatch, getState) => {
    const { kill } = getLiveChatActions(getState());
    debug('kill');
    kill();
  },
  toggle: (dispatch, getState) => {
    const openedStatus = getOpenedStatus(getState());
    const { toggle } = getLiveChatActions(getState());
    debug('toggle', openedStatus);
    toggle(openedStatus);
  },
  identify: (dispatch, getState) => {
    const options = getLiveChatLoadParams(getState(), dispatch);
    const { identify } = getLiveChatActions(getState());
    debug('identify', options);
    identify(options);
  },
  update: (dispatch, getState) => {
    const options = getLiveChatLoadParams(getState(), dispatch);
    const { update } = getLiveChatActions(getState());
    debug('update', options);
    update(options);
  },
};
