/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { useField } from 'formik';
import { useId } from 'react-aria';
import { useIntl } from 'react-intl';

import styles from 'src/shared/app/base/component/data-entry/form-builder/FormBuilder.style';
import FormBuilderField from 'src/shared/app/base/component/data-entry/form-builder/FormBuilderField';
import messages from 'src/shared/app/base/component/data-entry/form-builder/l10n/formBuilderL10n';
import type { Field } from 'src/shared/app/base/component/data-entry/form-builder/type/formBuilderTypes';
import { getFieldTitle } from 'src/shared/app/base/component/data-entry/form-builder/utils/formBuilderUtils';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import ErrorMessage from 'src/shared/ui/component/form/ErrorMessage';

const useStyles = createUseThemeStyles(styles);

const FormBuilderCheckboxList = ({ field, name }: Field): Node => {
  const classes = useStyles();
  const intl = useIntl();
  const labelId = useId();
  const [, { error }] = useField(name);

  return (
    <>
      <label id={labelId}>
        {getFieldTitle(field, intl)}
        <br />
        <span className={classes.labelSubtitle}>
          {intl.formatMessage(messages.checklistChoose)}
        </span>
      </label>

      <div className={classes.checkboxList}>
        {field.choices?.map((choice) => (
          <FormBuilderField
            key={choice.key}
            field={choice}
            type="checkbox"
            name={`${name}.${choice.key}`}
            aria-describedby={labelId}
          />
        ))}
      </div>

      {error ? (
        <ErrorMessage className={classes.error}>{error}</ErrorMessage>
      ) : null}
    </>
  );
};

export default memo<Field>(FormBuilderCheckboxList);
