/* @flow */

import {
  userRecommendationTopicEndpoint,
  userRecommendationUserEndpoint,
} from 'src/shared/app/base/settings/endpointSettings';
import { parseResponse } from 'src/shared/app/base/util/ajaxUtils';
import type { GetState } from 'src/shared/core/type/reduxTypes';

/*
 |------------------------------------------------------------------------------
 | Recommendation
 |------------------------------------------------------------------------------
 */

export function userRecommendTopic(
  receiverUserId: number,
  recommendedTopicUrl: string,
  message: string,
) {
  return (dispatch: any, _: GetState, { post }: Object) =>
    post(userRecommendationTopicEndpoint(receiverUserId), {
      message,
      topic: recommendedTopicUrl,
    })
      .then(parseResponse)
      .catch((e) => {
        throw e;
      });
}

export function userRecommendUser(
  receiverUserId: number,
  recommendedUserUrl: string,
  eventUrl: string,
  message: string,
) {
  return (dispatch: any, _: GetState, { post }: Object) =>
    post(userRecommendationUserEndpoint(receiverUserId), {
      message,
      user: recommendedUserUrl,
      event: eventUrl,
    })
      .then(parseResponse)
      .catch((e) => {
        throw e;
      });
}
