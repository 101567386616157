/* @flow */

import { configureStore } from '@reduxjs/toolkit';

import { assertObject } from '@braindate/util/lib/assert';

import { apiRoot } from 'src/shared/app/base/api/apiRoot';
import httpMethodMiddleware from 'src/shared/app/base/middleware/httpMethodMiddleware';
import rootReducer from 'src/shared/app/base/reducer/rootReducer';
import type { State, Store } from 'src/shared/core/type/reduxTypes';

/**
 * Create the redux store
 * @param   {State} preloadedState - Initial state from which to create the store
 * @returns {Store} Store
 *
 * @throws Will throw an exception if parameters are not valid
 */
export function createReduxStore(preloadedState: State): Store {
  assertObject(preloadedState, 'preloadedState');

  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .prepend(httpMethodMiddleware)
        .concat(apiRoot.middleware),
    preloadedState,
  });
}
