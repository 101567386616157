/* @flow */

import { horizontalSmallDeviceBreakpoint } from 'src/shared/app/base/style/util/responsive';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.colorBase,
    overflowX: 'hidden',
    width: '100%',
    minWidth: '100%',
    position: 'sticky',

    [horizontalSmallDeviceBreakpoint]: {
      backgroundColor: 'unset',
      position: 'static !important',
      minWidth: 'auto !important',
      width: 'auto !important',
    },
  },
};
