/* @flow */

import { verticalSlidingDuration } from '@braindate/ui/lib/token/animation';
import { aaDarkGrey } from '@braindate/ui/lib/token/palette';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',

    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.colorBase,

    transition: `transform ${verticalSlidingDuration} ease-out`,
  },
  'root-isHidden': {
    transform: 'translate3d(0, 100%, 0)',
  },
  closeBtn: {
    position: 'absolute',
    top: 0,
    left: 0,

    fill: aaDarkGrey,
  },
  inner: {
    position: 'relative',
  },
};
