/* @flow */

import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { borderRadius } from 'src/shared/app/base/style/token/box';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    width: '80%',
    maxWidth: 400,
    padding: [rem(16, baseFontSize)],

    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.colorPrimary,
    borderRadius,
  },
  message: {
    margin: [0, 0, rem(16, baseFontSize)],
  },
};
