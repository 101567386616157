(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "@braindate/util/lib/assert", "./topicCheckers"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("@braindate/util/lib/assert"), require("./topicCheckers"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.assert, global.topicCheckers);
    global.topicFilters = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _assert, _topicCheckers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterGroupTopics = filterGroupTopics;
  /**
   * Filter topics by group topics
   * @param   {Array<Topic>} topics - Topics to filter
   * @returns {Array<Topic>} Group topics
   */
  function filterGroupTopics(topics) {
    (0, _assert.assertArray)(topics, "topics");

    // $FlowIssue: returned items are necessarily of GroupTopic type
    return topics.filter(_topicCheckers.isGroupTopic);
  }
});