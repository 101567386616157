/* @flow */
import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { defaultDisabledStyle } from 'src/shared/app/base/style/element/base/all';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {},
  inputLabel: {
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
  },
  label: {
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
    fontSize: ({ isLarge }: ThemeFunction<{ isLarge: boolean }>) =>
      `${rem(isLarge ? 18 : 16, baseFontSize)} !important`,
  },
  labelContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  labelChecked: {
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
  },
  checkbox: {
    color: ({ theme }: ThemeFunction<{}>) => `${theme.textColor} !important`,
  },
  checkedCheckbox: {
    color: ({ theme }: ThemeFunction<{}>) => `${theme.accentColor} !important`,
  },
  disabledCheckbox: {
    ...defaultDisabledStyle,
    pointerEvent: 'none',
  },
  optionTooltip: {
    border: 0,
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: 1,
  },
};
