/* @flow */

export const SET_CONVERSATION_BACK_URL = 'SET_CONVERSATION_BACK_URL';
export const RESET_CONVERSATION_BACK_URL = 'RESET_CONVERSATION_BACK_URL';

export const SHOW_ACTION_CONFIRMATION_MODAL = 'SHOW_ACTION_CONFIRMATION_MODAL';
export const DISMISS_ACTION_CONFIRMATION_MODAL =
  'DISMISS_ACTION_CONFIRMATION_MODAL';
export const MARK_CONVERSATION_AS_INITIALIZED =
  'MARK_CONVERSATION_AS_INITIALIZED';
export const UNMARK_CONVERSATION_AS_INITIALIZED =
  'UNMARK_CONVERSATION_AS_INITIALIZED';
export const DEFROST_CONVERSATION = 'DEFROST_CONVERSATION';
