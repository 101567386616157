/* @flow */

export const technicalSupport = 'technical';
export const generalSupport = 'general';
export const feedback = 'feedback';
export const other = 'other';

export const supportSentNotificationTimeout = 4000; // miliseconds
export const supportSentErrorNotificationTimeout = 6000; // miliseconds

export const maxFileSize = 5; // MB
