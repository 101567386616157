/* @flow */

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getUserId } from '@braindate/domain/lib/user/util';

import { isErrorReportingEnabled } from 'src/shared/app/base/selector/appEnvSelectors';
import { registerSentryUserFromBrowser } from 'src/shared/app/base/util/sentryUtils';
import useSelf from 'src/shared/domain/user/hook/useSelf';

export default function useUserReportingRegistration() {
  if (useSelector(isErrorReportingEnabled)) {
    // @TODO - Wrong use of hook
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useSelf();
    const userId = user ? getUserId(user) : null;

    // @TODO - Wrong use of useEffect
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (user) {
        registerSentryUserFromBrowser(user);
      }
    }, [userId]);
  }
}
