(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./eventCheckers", "./eventGetters", "./eventNormalization", "./eventSponsorGetters"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./eventCheckers"), require("./eventGetters"), require("./eventNormalization"), require("./eventSponsorGetters"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.eventCheckers, global.eventGetters, global.eventNormalization, global.eventSponsorGetters);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _eventCheckers, _eventGetters, _eventNormalization, _eventSponsorGetters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_eventCheckers).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _eventCheckers[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _eventCheckers[key];
      }
    });
  });
  Object.keys(_eventGetters).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _eventGetters[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _eventGetters[key];
      }
    });
  });
  Object.keys(_eventNormalization).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _eventNormalization[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _eventNormalization[key];
      }
    });
  });
  Object.keys(_eventSponsorGetters).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _eventSponsorGetters[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _eventSponsorGetters[key];
      }
    });
  });
});