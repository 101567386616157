/* @flow */

import type { Braindate } from '@braindate/domain/lib/braindate/type';

import { queueNotification } from 'src/shared/app/notification/action/uiNotificationActions';

import { buildVirtualBraindateEarlyReminder } from 'plugin/virtual-braindate/util/virtualBraindateUtils';

export function showVirtualBraindateEarlyReminder(braindate: Braindate) {
  return queueNotification(
    buildVirtualBraindateEarlyReminder((braindate: Braindate)),
  );
}
