/* @flow */

import { partial } from 'ramda';

import {
  deleteFn,
  get,
  patch,
  post,
  put,
  smartDelete,
  smartGet,
  smartPost,
} from 'src/shared/app/base/util/ajaxUtils';

type urlParam = string | ((string) => string);
type methodReturn = (url: urlParam, data?: Object) => Promise<any>;

export type Methods = {
  get: methodReturn,
  smartGet: methodReturn,
  post: methodReturn,
  smartPost: methodReturn,
  patch: methodReturn,
  put: methodReturn,
  deleteFn: methodReturn,
  smartDelete: methodReturn,
};

function createHttpMethodMiddleware(extraArgument: any) {
  return ({ dispatch, getState }: Object) =>
    (next: any) =>
    (action: Object) => {
      const methods: Methods = {
        get: partial(get, [getState]),
        smartGet: partial(smartGet, [getState]),
        post: partial(post, [getState]),
        smartPost: partial(smartPost, [getState]),
        patch: partial(patch, [getState]),
        put: partial(put, [getState]),
        deleteFn: partial(deleteFn, [getState]),
        smartDelete: partial(smartDelete, [getState]),
        // Be able to use "normal http methods" for RTKQuery
        GET: partial(get, [getState]),
        PATCH: partial(patch, [getState]),
        POST: partial(post, [getState]),
        PUT: partial(put, [getState]),
        DELETE: partial(deleteFn, [getState]),
      };

      if (typeof action === 'function') {
        return action(dispatch, getState, methods, extraArgument);
      }

      return next(action);
    };
}

const httpMethodMiddleware = createHttpMethodMiddleware();
httpMethodMiddleware.withExtraArgument = createHttpMethodMiddleware;

export default httpMethodMiddleware;
