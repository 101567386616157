/* @flow */
import { memo } from 'react';
import type { Node } from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './ErrorMessage.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  id?: string,
  className?: string,
  children: Node,
};

/**
 * Must be in a relative container
 * @param {Props} param0 Props
 * @returns {React.Node} Error message
 */
const ErrorMessage = ({ id, className, children }: Props) => {
  const classes = useStyles();

  return (
    <span className={classNames(classes.root, className)} role="alert" id={id}>
      <ErrorIcon /> {children}
    </span>
  );
};

export default memo<Props>(ErrorMessage);
