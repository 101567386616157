/* @flow */

import { DateTime, Settings } from 'luxon';
import { sort, uniqBy } from 'ramda';

import type {
  Conversation,
  ConversationMessage,
} from '@braindate/domain/lib/conversation/type';
import {
  getConversationLastReadTimestamp,
  getConversationMessages,
} from '@braindate/domain/lib/conversation/util';
import type { User } from '@braindate/domain/lib/user/type';
import { getUserId } from '@braindate/domain/lib/user/util';
import { assertArray, assertObject } from '@braindate/util/lib/assert';

/*
 |------------------------------------------------------------------------------
 | ASSERTIONS
 |------------------------------------------------------------------------------
 */

/**
 * Throw an error if argument is not an object
 * @param  {any} value - Value to check
 * @return {undefined}
 */
export function assertConversation(value: any): void {
  assertObject(value, 'conversation');
}

/**
 * Throw an error if argument is not an object
 * @param  {any} value - Value to check
 * @return {undefined}
 */
export function assertConversationMessage(value: any): void {
  assertObject(value, 'message');
}

/**
 * Throw an error if argument is not an object
 * @param  {any} value - Value to check
 * @return {undefined}
 */
export function assertConversationMessageAuthor(value: any): void {
  assertObject(value, 'message.author');
}

/**
 * Throw an error if argument is not an object
 * @param  {any} value - Value to check
 * @return {undefined}
 */
export function assertConversationMessageData(value: any): void {
  assertObject(value, 'message.data');
}

/*
 |------------------------------------------------------------------------------
 | GETTERS
 |------------------------------------------------------------------------------
 */

/**
 * Get the id of a conversation message
 * @param  {ConversationMessage} message - Message to get the id from
 * @return {number} Id
 *
 * @throws Will throw an error if argument is not an object
 */
export function getMessageId(message: ConversationMessage): number {
  assertConversationMessage(message);

  return message.id;
}

/**
 * Get the template of a conversation message
 * @param  {ConversationMessage} message - Message to get the template from
 * @return {string} Template
 *
 * @throws Will throw an error if argument is not an object
 */
export function getMessageTemplate(message: ConversationMessage): string {
  assertConversationMessage(message);

  return message.template;
}

/**
 * Get the timestamp of a conversation message
 * @param  {ConversationMessage} message - Message to get the timestamp from
 * @return {string} Timestamp
 *
 * @throws Will throw an error if argument is not an object
 */
export function getMessageTimestamp(message: ConversationMessage): string {
  assertConversationMessage(message);

  return message.timestamp;
}

/**
 * Check if a conversation message was created by the system (rather than by
 * a user)
 * @param  {ConversationMessage} message - Message to check
 * @return {boolean} True if it was
 *
 * @throws Will throw an error if argument is not an object
 */
export function isMessageAutomatic(message: ConversationMessage): boolean {
  assertConversationMessage(message);

  return message.automatic;
}

/**
 * Get the author of a conversation message. A copy of the original object
 * is returned.
 * @param  {ConversationMessage} message - Message to get the author from
 * @return {User} Author
 *
 * @throws Will throw an error if argument is not an object or if the author
 * attribute exists and is not an object
 */
export function getMessageAuthor(message: ConversationMessage): ?User {
  assertConversationMessage(message);

  const { author } = message;

  if (author) {
    assertConversationMessageAuthor(author);

    return { ...author };
  }
  return null;
}

/**
 * Get the action of a conversation message
 * @param  {ConversationMessage} message - Message to get the text from
 * @return {string} Action
 *
 * @throws Will throw an error if argument is not an object
 */
export function getMessageAction(message: ConversationMessage): string {
  assertConversationMessage(message);

  return message.action || '';
}

/**
 * Get the text of a conversation message
 * @param  {ConversationMessage} message - Message to get the text from
 * @return {string} Text
 *
 * @throws Will throw an error if argument is not an object
 */
export function getMessageText(message: ConversationMessage): string {
  assertConversationMessage(message);

  return message.text || '';
}

/**
 * Get the data of a conversation message. A copy of the original object
 * is returned.
 * @param  {ConversationMessage} message - Message to get the data from
 * @return {Object} Data
 *
 * @throws Will throw an error if argument is not an object or if the data
 * attribute exists and is not an object
 */
export function getMessageData(message: ConversationMessage): Object {
  assertConversationMessage(message);

  const { data } = message;

  if (data) {
    assertConversationMessageData(data);

    return { ...data };
  }

  return {};
}

/*
 |------------------------------------------------------------------------------
 | CHECKERS
 |------------------------------------------------------------------------------
 */

/**
 * Check if a conversation message was sent by the CUI agent
 * @param  {ConversationMessage} message - Message to check
 * @return {boolean} True if it was
 */
export function isMessageFromRobot(message: ConversationMessage): boolean {
  assertConversationMessage(message);

  return isMessageAutomatic(message) && !getMessageAuthor(message);
}

/**
 * Check if a conversation message was sent by the authenticated user
 * @param  {ConversationMessage} message - Message to check
 * @param  {User} selfUser - Authenticated user
 * @return {boolean} True if it was
 */
export function isOwnMessage(
  message: ConversationMessage,
  selfUser: User,
): boolean {
  assertConversationMessage(message);
  assertObject(selfUser, 'selfUser');

  const author = getMessageAuthor(message);

  return !!author && getUserId(author) === getUserId(selfUser);
}

/**
 * Check if a conversation message was read by the authenticated user
 * @param  {ConversationMessage} message - Message to check
 * @param  {Conversation} conversation - Conversation that the message is part of
 * @return {boolean} True if it was
 */
export function isMessageRead(
  message: ConversationMessage,
  conversation: Conversation,
): boolean {
  assertConversationMessage(message);
  assertConversation(conversation);

  const opts = { zone: Settings.defaultZone };
  const lastReadDateTime = DateTime.fromISO(
    getConversationLastReadTimestamp(conversation),
    opts,
  );
  const messageDateTime = DateTime.fromISO(getMessageTimestamp(message), opts);

  return lastReadDateTime.diff(messageDateTime).milliseconds > 0;
}

/**
 * Sort message by ID and uniqID
 * @param  {Array<ConversationMessage>} messages Messages
 * @return {Array<ConversationMessage>} Sorted message
 */
export function sortMessages(
  messages: Array<ConversationMessage>,
): Array<ConversationMessage> {
  assertArray(messages);

  const sortByID = (a, b) => getMessageId(a) - getMessageId(b);
  return sort(sortByID, uniqBy(getMessageId, messages));
}

export function isMessageLastFromTemplate(
  conversation: Conversation,
  message: ConversationMessage,
): boolean {
  const foundMessages = getConversationMessages(conversation)?.filter(
    (msg) => getMessageTemplate(msg) === getMessageTemplate(message),
  );

  if (!foundMessages || !foundMessages.length) return false;

  return (
    getMessageId(foundMessages[foundMessages.length - 1]) ===
    getMessageId(message)
  );
}
