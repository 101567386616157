import { useSelector } from 'react-redux';

import { getMarketStatusFilter } from 'src/shared/app/market/selector/marketFilterSelectors';
import {
  getMarketQuery,
  getMarketQueryContext,
} from 'src/shared/app/market/selector/marketSearchQuerySelectors';
import { getMarketFirstTopicId } from 'src/shared/app/market/selector/uiMarketSelectors';
import {
  getMarketFilterParams,
  parseMarketFilters,
} from 'src/shared/app/market/util/marketUtils';

const useMarketParams = () => {
  const statusFilter = useSelector(getMarketStatusFilter);
  const userParams = useSelector(getMarketFilterParams);
  const query = useSelector(getMarketQuery);
  const context = useSelector(getMarketQueryContext);
  const firstTopic = useSelector(getMarketFirstTopicId);

  const params = parseMarketFilters(
    userParams,
    statusFilter,
    query,
    context,
    firstTopic,
  );

  return params;
};

export default useMarketParams;
