/* @flow */

import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  label: {
    margin: '8px 0',
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
    fontWeight: 500,
    paddingLeft: '1.7em !important',
    fontSize: rem(14, baseFontSize),
  },
  icon: {
    marginRight: 5,
    maxWidth: 15,
    maxHeight: 15,
  },
  helper: {
    marginLeft: 5,
    width: '14px !important',
    height: '14px !important',
  },
};
