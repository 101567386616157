/* @flow */

import { createSelector } from '@reduxjs/toolkit';

const _getSegment = (state) => state.app.market.search.query;

const _getMarketQuery = (state) => _getSegment(state).queryValue;
const _getMarketQueryContext = (state) => _getSegment(state).queryContext;

export const getMarketQuery: (state: Object) => string = createSelector(
  [_getMarketQuery],
  (x: string): string => x,
);

export const getMarketQueryContext: (state: Object) => string = createSelector(
  [_getMarketQueryContext],
  (x: string): string => x,
);
