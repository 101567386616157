/* @flow */

import * as types from 'src/shared/app/authentication/action/support/supportRetrieveUsernameActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

/*
|------------------------------------------------------------------------------
| INITIAL STATE
|------------------------------------------------------------------------------
*/

const initialState = {
  isFetching: false,
  failure: false,
  success: false,
  username: null,
  membership_id: null,
};

/*
|------------------------------------------------------------------------------
| REDUCERS
|------------------------------------------------------------------------------
*/

const _retrieveEmailRequest = (state: Object): Object => ({
  ...state,
  isFetching: true,
  success: false,
  failure: false,
});

const _retrieveEmailFailure = (state: Object): Object => ({
  ...state,
  failure: true,
  success: false,
  isFetching: false,
});

const _retrieveEmailSuccess = (state: Object, action: Object): Object => {
  const { username, membership_id: membershipId } = action;
  return {
    ...state,
    success: true,
    failure: false,
    isFetching: false,
    username,
    membership_id: membershipId,
  };
};

const _resetRetrievedUsername = (state: Object): Object => ({
  ...state,
  failure: false,
  success: false,
  isFetching: false,
});

/*
|------------------------------------------------------------------------------
| REDUCER
|------------------------------------------------------------------------------
*/

export default createReducer(initialState, {
  [types.SUPPORT_RETRIEVE_EMAIL_REQUEST]: _retrieveEmailRequest,
  [types.SUPPORT_RETRIEVE_EMAIL_FAILURE]: _retrieveEmailFailure,
  [types.SUPPORT_RETRIEVE_EMAIL_SUCCESS]: _retrieveEmailSuccess,
  [types.RESET_RETRIEVED_USERNAME]: _resetRetrievedUsername,
});
