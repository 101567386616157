import { useEffect } from 'react';
import { oidcLoginFeature } from 'src/shared/app/feature/settings/featureSettings';

import useEventFeatures from 'src/shared/domain/event/hook/useEventFeatures';

export default function useOpenidConnectTokenRefresh() {
  // This allows the OIDC token to be refreshed before it expires
  const refreshToken = () => {
    fetch('/auth/refresh', { method: 'POST' })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            const { expiresIn } = data;
            // We refresh the token 5 minutes before it expires
            setTimeout(refreshToken, (expiresIn - 5 * 60) * 1000);
          });
        }
      })
      .catch((error) => {
        // Can’t refresh the token,
        // once the access token expires, the user will be redirected to the login page
      });
  };

  const features = useEventFeatures();
  const { [oidcLoginFeature]: oidcFeature } = features;

  useEffect(() => {
    if (oidcFeature) {
      refreshToken();
    }
  }, [oidcLoginFeature]);
}
