/* @flow */

import { memo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { SwitchTransition, Transition } from 'react-transition-group';

import type { Cheer } from '@braindate/domain/lib/cheer/type';

import { useCheerMarkAsReadMutation } from 'src/shared/app/base/api/endpoint/cheersEndpoint';
import ResponsiveModal from 'src/shared/app/base/modal/component/ResponsiveModal';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import CheerModalWrapper from 'src/shared/app/cheer/component/modal/CheerModalWrapper';
import CheerRecipientModalContent from 'src/shared/app/cheer/component/modal/recipient/CheerRecipientModalContent';
import CheerRecipientModalIntro from 'src/shared/app/cheer/component/modal/recipient/CheerRecipientModalIntro';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import { getNotificationData } from 'src/shared/app/notification/util/notificationUtil';

import styles from './CheerRecipientModal.style';

type Props = {
  notification: Notification<Cheer[]>,
};

const useStyles = createUseThemeStyles(styles);

const CheerRecipientModal = ({ notification }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const cheers = getNotificationData(notification);
  const [currentCheer, setCurrentCheer] = useState<number>(-1);
  const cheer = cheers[currentCheer];

  const [markAsRead] = useCheerMarkAsReadMutation();

  const hasMoreCheer = currentCheer < cheers.length - 1;
  const handleNextCheer = async () => {
    setCurrentCheer((prevState) => prevState + 1);
  };

  const handleClose = async () => {
    await Promise.all(cheers.map(markAsRead));
    dispatch(dismissNotification(notification));
  };

  return (
    <ResponsiveModal
      onClose={handleClose}
      modalProps={{ baseClassName: classes.root }}
    >
      <SwitchTransition mode="out-in">
        <Transition key={currentCheer} timeout={250}>
          {(state) =>
            !cheer ? (
              <CheerModalWrapper transitionState={state} key={0}>
                <CheerRecipientModalIntro onClick={handleNextCheer} />
              </CheerModalWrapper>
            ) : (
              <CheerRecipientModalContent
                cheer={cheer}
                transitionState={state}
                onNextClick={hasMoreCheer ? handleNextCheer : undefined}
                key={currentCheer + 1}
              />
            )
          }
        </Transition>
      </SwitchTransition>
    </ResponsiveModal>
  );
};

export default memo<Props>(CheerRecipientModal);
