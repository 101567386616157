/* @flow */

import { createSelector } from '@reduxjs/toolkit';

import { getTokenInCookie } from 'src/shared/app/base/util/tokenUtils';
import { isClient } from 'src/shared/core/util/ssrUtil';

/*
|------------------------------------------------------------------------------
| INPUTS
|------------------------------------------------------------------------------
*/

const _segment = (state) => state.app.authentication;

const _getAuthenticationToken = (state) => {
  // @TODO - TEMP-AUTH
  if (isClient) {
    return getTokenInCookie();
  }
  const { token, tokenBearer } = _segment(state);
  if (!token) return null;

  if (!tokenBearer) return `Token ${token}`;

  return `${tokenBearer} ${token}`;
};
const _isTokenPassedAsQueryParam = (state) =>
  _segment(state).tokenPassedAsQueryParam;
const _getAuthenticationResetToken = (state) => _segment(state).resetToken;
const _getAuthenticationUid = (state) => _segment(state).uid;
const _getAuthenticationCid = (state) => _segment(state).cid;
const _getAuthenticationUsername = (state) => _segment(state).email;
const _getInitialUrl = (state) => _segment(state).url;
const _isPasswordSet = (state) => _segment(state).isPasswordSet;
const _isPasswordLinkSent = (state) => _segment(state).isPasswordLinkSent;
const _isPasswordLinkInvalid = (state) => _segment(state).isPasswordLinkInvalid;
const _isPasswordReset = (state) => _segment(state).isPasswordReset;
const _loggedInFromBos = (state): boolean => _segment(state).loggedInWithBOS;

/*
|------------------------------------------------------------------------------
| SELECTORS
|------------------------------------------------------------------------------
*/

export const getAuthenticationToken: (state: Object) => string = createSelector(
  [_getAuthenticationToken],
  (x: string): string => x,
);

export const isTokenPassedAsQueryParam: (state: Object) => boolean =
  createSelector([_isTokenPassedAsQueryParam], (x: boolean): boolean => x);

export const getAuthenticationResetToken: (state: Object) => string =
  createSelector([_getAuthenticationResetToken], (x: string): string => x);

export const getAuthenticationUid: (state: Object) => string = createSelector(
  [_getAuthenticationUid],
  (x: string): string => x,
);

export const getAuthenticationCid: (state: Object) => string = createSelector(
  [_getAuthenticationCid],
  (x: string): string => x,
);

export const getAuthenticationUsername: (state: Object) => string =
  createSelector([_getAuthenticationUsername], (x: string): string => x);

export const getInitialUrl: (state: Object) => string = createSelector(
  [_getInitialUrl],
  (x: string): string => x,
);

export const isPasswordSet: (state: Object) => boolean = createSelector(
  [_isPasswordSet],
  (x: boolean): boolean => x,
);

export const isPasswordLinkSent: (state: Object) => boolean = createSelector(
  [_isPasswordLinkSent],
  (x: boolean): boolean => x,
);

export const isPasswordLinkInvalid: (state: Object) => boolean = createSelector(
  [_isPasswordLinkInvalid],
  (x: boolean): boolean => x,
);

export const isPasswordReset: (state: Object) => boolean = createSelector(
  [_isPasswordReset],
  (x: boolean): boolean => x,
);

export const hasLoggedInFromBos: (state: Object) => boolean = createSelector(
  [_loggedInFromBos],
  (x: boolean): boolean => x,
);
