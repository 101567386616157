/* @flow */
import { em } from 'polished';

export default {
  root: {
    fontSize: em(17),
    width: em(28),
    height: em(28),
  },
};
