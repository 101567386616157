/* @flow */
import { getRoutePath } from 'src/shared/app/base/route/util/routeUtils';

import type { Location } from 'react-router-dom';

export function getSearchStringFromQuery(query: Object | string) {
  const queryObject = new URLSearchParams(query);
  return queryObject.toString();
}

export function getSearchStringFromLocation(location: Location) {
  const { search } = location;
  return getSearchStringFromQuery(search);
}

export function getPathWithSearchString(location: Location, route: Object) {
  const search = getSearchStringFromLocation(location);
  const routePath = getRoutePath(route);
  if (search) {
    return `${routePath}?${getSearchStringFromLocation(location)}`;
  }
  return routePath;
}
