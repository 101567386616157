/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './Subheading.style';

type Props = {
  layoutClass?: string,
  withError?: boolean,
  withWarning?: boolean,
  withDisabled?: boolean,
  ariaDescribedby?: string,
  // $FlowIssue
  children?: Node,
};

const useStyles = createUseThemeStyles(styles);

const Subheading = ({
  layoutClass,
  withError,
  withWarning,
  withDisabled,
  children,
  ariaDescribedby,
}: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */

  const classes = useStyles();

  const rootClasses = classNames(classes.root, layoutClass, {
    [classes['root-withError']]: withError,
    [classes['root-withWarning']]: withWarning,
    [classes['root-withDisabled']]: withDisabled,
  });

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  return (
    <h2 className={rootClasses} aria-describedby={ariaDescribedby}>
      {children}
    </h2>
  );
};

export default memo<Props>(Subheading);
