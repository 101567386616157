/* @flow */

import { detect } from 'detect-browser';

const debug = require('debug')('browserUtil');

/**
 * Scroll to the top of the window
 * @return {undefined}
 */
export function scrollToTop(): void {
  window.scrollTo(0, 0);
}

/**
 * Scroll to the bottom of the window
 * @param {HTMLElement} element Element to scroll, used with overflow: scroll/auto
 * @param {boolean} smooth Is the scroll smooth
 * @return {undefined}
 */
export function scrollToBottom(
  element: ?HTMLElement,
  smooth: boolean = true,
): void {
  if (element) {
    element.scrollTo(0, element.scrollHeight);
  } else {
    // $FlowIssue: no use case exists where document.body is null
    window.scrollTo({
      left: 0,
      top: document.body?.scrollHeight,
      behavior: smooth ? 'smooth' : 'instant',
    });
  }
}

export function scrollClosestScrollable(
  element?: HTMLElement,
  smooth?: boolean = false,
) {
  // Traverse up the DOM tree to find the closest scrollable parent
  let scrollableParent = element?.parentElement;
  while (scrollableParent) {
    const overflow = getComputedStyle(scrollableParent)?.overflow;
    if (
      overflow === 'auto' ||
      overflow === 'scroll' ||
      scrollableParent.tagName === 'body'
    ) {
      break;
    }
    scrollableParent = scrollableParent.parentElement;
  }

  // $FlowIssue
  scrollToBottom(scrollableParent || null, smooth);
}

/**
 * Disable body scrolling
 * @return {undefined}
 */
export function disableBodyScrolling(): void {
  // $FlowIssue: no use case exists where document.body is null
  document.body.style.overflow = 'hidden';
}

/**
 * Enable body scrolling
 * @return {undefined}
 */
export function enableBodyScrolling(): void {
  // $FlowIssue: no use case exists where document.body is null
  document.body.style.overflow = 'visible';
}

/**
 * Check whether OS is Android
 * @return {boolean} True if it is
 */
export function isAndroid(): boolean {
  return !!navigator.userAgent.match(/android/i);
}

/**
 * Check whether browser is IE
 * @return {boolean} True if it is
 */
export function isIE() {
  if (
    typeof window !== 'undefined' &&
    window.navigator &&
    typeof window.navigator === 'object' &&
    typeof window.navigator.userAgent === 'string' &&
    typeof window.navigator.appVersion === 'string'
  ) {
    return (
      window.navigator.userAgent.indexOf('MSIE') !== -1 ||
      window.navigator.appVersion.indexOf('Trident/') > 0
    );
  }
  return false;
}

export function isIE11() {
  if (typeof window !== 'undefined') {
    return !!window.MSInputMethodContext && !!document.documentMode;
  }
  return false;
}

/**
 * Check whether OS is iOS
 * @return {boolean} True if it is
 */
export function isiOS(): boolean {
  if (
    typeof window !== 'undefined' &&
    window.navigator &&
    typeof window.navigator === 'object' &&
    typeof window.navigator.userAgent === 'string'
  ) {
    return !!(
      window.navigator.userAgent.match(/iPad|iPhone|iPod/) && !window.MSStream
    );
  }
  return false;
}

// See https://stackoverflow.com/questions/3007480/determine-if-user-navigated-from-mobile-safari/29696509#29696509
export function isiOSSafari(): boolean {
  if (
    typeof window !== 'undefined' &&
    window.navigator &&
    typeof window.navigator === 'object' &&
    typeof window.navigator.userAgent === 'string'
  ) {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    return iOSSafari;
  }
  return false;
}

export const browser = {
  isBrowser(name: string) {
    const detectedBrowser = detect();
    debug('Detect browser', detectedBrowser);
    return detectedBrowser.name === name;
  },
  isEdge() {
    return this.isBrowser('edge');
  },
  isChrome() {
    return this.isBrowser('chrome');
  },
  isiOs() {
    return this.isBrowser('ios');
  },
  isSafari() {
    return this.isBrowser('ios') || this.isBrowser('safari');
  },
};

/**
 * Detect whether the browser is a PWA
 * @returns {boolean} True if the browser is a PWA
 */
export const isPWA = () => {
  return (
    typeof window !== 'undefined' &&
    (window.matchMedia('(display-mode: standalone)').matches ||
      window.navigator.standalone)
  );
};

export const getBrowserInfo = () => {
  const detectedBrowser = detect();
  const { name, version, os } = detectedBrowser;
  return `${name} ${version} (${os})`;
};

export const isWebview = (userAgent: string) => {
  return /webview|wv|ip((?!.*Safari)|(?=.*like Safari))/i.test(userAgent);
};

export const base64ToUint8Array = (base64: string) => {
  const padding = '='.repeat((4 - (base64.length % 4)) % 4);
  const b64 = (base64 + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(b64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
