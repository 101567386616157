(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./eventSponsorAssertions", "../../base/l10n/util", "@braindate/util/lib/assert"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./eventSponsorAssertions"), require("../../base/l10n/util"), require("@braindate/util/lib/assert"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.eventSponsorAssertions, global.util, global.assert);
    global.eventSponsorGetters = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _eventSponsorAssertions, _util, _assert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getEventSponsorIntroduction = getEventSponsorIntroduction;
  _exports.getEventSponsorLink = getEventSponsorLink;
  _exports.getEventSponsorLogo = getEventSponsorLogo;
  _exports.getEventSponsorLogoAltText = getEventSponsorLogoAltText;
  _exports.getEventSponsorName = getEventSponsorName;
  _exports.getEventSponsorPluginLevelIsEnabled = getEventSponsorPluginLevelIsEnabled;
  _exports.getEventSponsorPluginMainNavTitle = getEventSponsorPluginMainNavTitle;
  _exports.getEventSponsorPluginMarketTitle = getEventSponsorPluginMarketTitle;
  _exports.getEventSponsorPluginSplashTitle = getEventSponsorPluginSplashTitle;
  _exports.getEventSponsorPluginVideoCallTitle = getEventSponsorPluginVideoCallTitle;
  _exports.getEventSponsorTag = getEventSponsorTag;
  _exports.getEventSponsorTagline = getEventSponsorTagline;
  /*
   |------------------------------------------------------------------------------
   | Base getters
   |------------------------------------------------------------------------------
   */
  /**
   * Get the logo of a sponsor
   * @param   {BraindateEventSponsor} sponsor - Sponsor to get the logo from
   * @returns {Picture} Logo
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getEventSponsorLogo(sponsor) {
    (0, _eventSponsorAssertions.assertEventSponsor)(sponsor);
    var logo = sponsor.logo;
    (0, _eventSponsorAssertions.assertEventSponsorLogo)(logo);
    return logo;
  }

  /**
   * Get the logo alt text of a sponsor
   * @param   {BraindateEventSponsor} sponsor - Sponsor to get the logo from
   * @param   {string} [locale] - Locale in which to get the name
   * @returns {string} Logo
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getEventSponsorLogoAltText(sponsor, locale) {
    (0, _eventSponsorAssertions.assertEventSponsor)(sponsor);
    var logoAltText = sponsor.logo_alt_text;
    if (logoAltText) {
      (0, _assert.assertString)(logoAltText);
      return logoAltText;
    }
    return getEventSponsorName(sponsor, locale);
  }

  /**
   * Get the link of a sponsor
   * @param   {BraindateEventSponsor} sponsor - Sponsor to get the link from
   * @returns {string} Link
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventSponsorLink(sponsor) {
    (0, _eventSponsorAssertions.assertEventSponsor)(sponsor);
    return sponsor.href;
  }

  /*
   |------------------------------------------------------------------------------
   | Advanced getters
   |------------------------------------------------------------------------------
   */

  /**
   * Get the name of a sponsor
   * @param   {BraindateEventSponsor} sponsor - Sponsor to get the name from
   * @param   {string} [locale] - Locale in which to get the name
   * @returns {string} Name
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventSponsorName(sponsor, locale) {
    (0, _eventSponsorAssertions.assertEventSponsor)(sponsor);
    return (0, _util.getLocalizedData)(sponsor, locale).name || "";
  }

  /**
   * Get the tagline of a sponsor
   * @param   {BraindateEventSponsor} sponsor - Sponsor to get the tagline from
   * @param   {string} [locale] - Locale in which to get the tagline
   * @returns {string} Tagline
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventSponsorTagline(sponsor, locale) {
    (0, _eventSponsorAssertions.assertEventSponsor)(sponsor);
    return (0, _util.getLocalizedData)(sponsor, locale).tagline || "";
  }

  /**
   * Get the introduction text of a sponsor
   * @param   {BraindateEventSponsor} sponsor - Sponsor to get the introduction from
   * @param   {string} [locale] - Locale in which to get the introduction
   * @returns {string} Introduction
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventSponsorIntroduction(sponsor, locale) {
    (0, _eventSponsorAssertions.assertEventSponsor)(sponsor);
    return (0, _util.getLocalizedData)(sponsor, locale).introduction || "";
  }

  /**
   * Get the tag of a sponsor
   * @param   {BraindateEventSponsor} sponsor - Sponsor to get the tag from
   * @param   {string} [locale] - Locale in which to get the tag
   * @returns {string} Tag
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventSponsorTag(sponsor, locale) {
    (0, _eventSponsorAssertions.assertEventSponsor)(sponsor);
    return (0, _util.getLocalizedData)(sponsor, locale).tag || "";
  }

  /**
   * Get the sponsor plugin splash title
   * @param   {BraindateEventSponsorPlugin} sponsorPlugin - Sponsor plugin
   * @param   {string} [locale] - Locale in which to get the name
   * @returns {string} Name
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventSponsorPluginSplashTitle(sponsorPlugin, locale) {
    (0, _eventSponsorAssertions.assertEventSponsorPlugin)(sponsorPlugin);
    return (0, _util.getLocalizedData)(sponsorPlugin, locale).splash_title || "";
  }

  /**
   * Get the sponsor plugin main nav title
   * @param   {BraindateEventSponsorPlugin} sponsorPlugin - Sponsor plugin
   * @param   {string} [locale] - Locale in which to get the name
   * @returns {string} Name
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventSponsorPluginMainNavTitle(sponsorPlugin, locale) {
    (0, _eventSponsorAssertions.assertEventSponsorPlugin)(sponsorPlugin);
    return (0, _util.getLocalizedData)(sponsorPlugin, locale).main_nav_title || "";
  }

  /**
   * Get the sponsor plugin market header title
   * @param   {BraindateEventSponsorPlugin} sponsorPlugin - Sponsor plugin
   * @param   {string} [locale] - Locale in which to get the name
   * @returns {string} Name
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventSponsorPluginMarketTitle(sponsorPlugin, locale) {
    (0, _eventSponsorAssertions.assertEventSponsorPlugin)(sponsorPlugin);
    return (0, _util.getLocalizedData)(sponsorPlugin, locale).market_header_title || "";
  }

  /**
   * Get the sponsor plugin video call title
   * @param   {BraindateEventSponsorPlugin} sponsorPlugin - Sponsor plugin
   * @param   {string} [locale] - Locale in which to get the name
   * @returns {string} Name
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventSponsorPluginVideoCallTitle(sponsorPlugin, locale) {
    (0, _eventSponsorAssertions.assertEventSponsorPlugin)(sponsorPlugin);
    return (0, _util.getLocalizedData)(sponsorPlugin, locale).video_call_title || "";
  }

  /**
   * Get the sponsor plugin Level is enabled/disabled
   * @param   {BraindateEventSponsorPlugin} sponsorPlugin - Sponsor plugin
   * @param   {string} [level] - Level name used as a type
   * @returns {string} Name
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getEventSponsorPluginLevelIsEnabled(sponsorPlugin, level) {
    (0, _eventSponsorAssertions.assertEventSponsorPlugin)(sponsorPlugin);
    if (!sponsorPlugin.levels) return false;
    (0, _assert.assertArray)(sponsorPlugin.levels);
    return sponsorPlugin.levels.includes(level);
  }
});