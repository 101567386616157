/* @flow */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  forceVideoOverlay: false,
  overlayShown: false,
  isTimezoneConfirmed: false,
};

const virtualBraindateSlice = createSlice({
  name: 'virtualBraindate',
  initialState,
  reducers: {
    forceVideoOverlay(state, { payload }) {
      state.forceVideoOverlay = payload;
    },
    setTimezoneConfirmed(state, { payload }) {
      state.isTimezoneConfirmed = payload;
    },
    setOverlayShown(state, { payload }) {
      state.overlayShown = payload;
    },
  },
});

export const { forceVideoOverlay, setTimezoneConfirmed, setOverlayShown } =
  virtualBraindateSlice.actions;

export default virtualBraindateSlice.reducer;
