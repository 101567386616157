/* @flow */
import { em } from 'polished';

export default {
  root: {
    fontSize: em(24),
    width: em(11),
    height: em(15),
    position: 'relative',
    top: '-0.1em',
  },
};
