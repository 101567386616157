/* @flow */

import { addPhoneNumberReminder } from 'src/shared/app/base/constant/socketConstants';
import { queueNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import { NOTIFICATION_COMPONENT_KEYS } from 'src/shared/app/notification/constant/notificationComponentsMap';
import {
  notificationInfoLevel,
  notificationModalType,
} from 'src/shared/app/notification/constant/notificationConstants';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';

const convertSmsPhoneReminderToNotification = (): NotificationType<any> => ({
  id: addPhoneNumberReminder,
  level: notificationInfoLevel,
  type: notificationModalType,
  componentKey: NOTIFICATION_COMPONENT_KEYS.ADD_SMS_PHONE_REMINDER,
});

const showPostSMSPhoneReminder = () =>
  queueNotification(convertSmsPhoneReminderToNotification());

export default showPostSMSPhoneReminder;
