(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./locationAssertions", "./locationGetters", "./locationCheckers"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./locationAssertions"), require("./locationGetters"), require("./locationCheckers"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.locationAssertions, global.locationGetters, global.locationCheckers);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _locationAssertions, _locationGetters, _locationCheckers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_locationAssertions).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _locationAssertions[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _locationAssertions[key];
      }
    });
  });
  Object.keys(_locationGetters).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _locationGetters[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _locationGetters[key];
      }
    });
  });
  Object.keys(_locationCheckers).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _locationCheckers[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _locationCheckers[key];
      }
    });
  });
});