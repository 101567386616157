/* @flow */

// eslint-disable-next-line import/no-unresolved
import classNames from 'classnames';
import { MuiTelInput } from 'mui-tel-input';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import ErrorMessage from 'src/shared/ui/component/form/ErrorMessage';

import styles from './PhoneInput.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  value: string,
  layoutClass?: string,
  placeholder?: string,
  onChange: (newValue: string) => void,
  error?: string | null,
};

const PhoneInput = ({
  value,
  layoutClass,
  placeholder,
  onChange,
  error,
}: Props) => {
  const classes = useStyles({ isFocused: false });

  const handleChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <div className={classNames(classes.root, layoutClass)}>
      <MuiTelInput
        value={value}
        onChange={handleChange}
        InputProps={{ placeholder, classes: { root: classes.input } }}
        MenuProps={{ classes: { root: classes.menu } }}
        error={!!error}
      />
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </div>
  );
};

export default PhoneInput;
