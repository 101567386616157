/* @flow */
import { memo } from 'react';

import MenuPopover from 'src/shared/ui/component/menu/MenuPopover';
import MenuPopoverItem from 'src/shared/ui/component/menu/MenuPopoverItem';
import type { MenuPopoverItemProps } from 'src/shared/ui/component/menu/MenuPopoverItem';

type Props = {
  isOpen?: boolean,
  anchor: HTMLElement,
  options: MenuPopoverItemProps[],
  className?: string,
  onClose: () => void,
};

const OptionsMenu = ({
  isOpen = true,
  options,
  anchor,
  className,
  onClose,
}: Props) => {
  if (!options.length) return null;

  return (
    <div className={className}>
      <MenuPopover
        open={isOpen}
        anchor={anchor}
        onClose={onClose}
        onAfterClose={onClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        renderDrawerOnMobile
      >
        {options.map((item, key) => (
          <MenuPopoverItem
            {...item}
            onClick={() => {
              if (item.onClick) item.onClick();
              onClose();
            }}
            key={`menu-item-${key}`}
          />
        ))}
      </MenuPopover>
    </div>
  );
};

export default memo<Props>(OptionsMenu);
