/* @flow */
import { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import { useIntl } from 'react-intl';

import { getEventName } from '@braindate/domain/lib/event/util';
import type { Location } from '@braindate/domain/lib/location/type';
import { isVirtualLocation } from '@braindate/domain/lib/location/util';
import type { Topic } from '@braindate/domain/lib/topic/type';
import {
  getTopicLocations,
  getTopicTitle,
} from '@braindate/domain/lib/topic/util';

import { useTopicShareMutation } from 'src/shared/app/base/api/endpoint/topicsEndpoint';
import type { SocialSharingData } from 'src/shared/app/base/api/endpoint/topicsEndpoint';
import useIsErrorReportingEnabled from 'src/shared/app/base/hook/useIsErrorReportingEnabled';
import useErrorModal from 'src/shared/app/base/modal/hook/useErrorModal';
import { trackGAEvent } from 'src/shared/core/service/tracking/gaService';
import isHTTP4xxError from 'src/shared/core/util/httpUtils';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import messages from 'src/shared/domain/topic/l10n/topicL10n';

import {
  getSocialSharingHashtag,
  getSocialSharingTwitterHandle,
  isSocialSharingBraindateHashtagHidden,
} from 'plugin/social-sharing/util/socialSharingUtils';

type XType = 'x';
type LinkedInType = 'linkedin';
type FacebookType = 'facebook';
type SocialType = XType | LinkedInType | FacebookType;

export const x: XType = 'x';
export const linkedin: LinkedInType = 'linkedin';
export const facebook: FacebookType = 'facebook';

export const useShareToSocial = (
  topic?: Topic,
): [(social: SocialType) => void, SocialSharingData | null, boolean] => {
  const event = useEvent();
  const intl = useIntl();
  const isErrorReportingEnabled = useIsErrorReportingEnabled();
  const triggerErrorModal = useErrorModal();

  const [shareData, setShareData] = useState<SocialSharingData | null>();
  const [activateTopicShare, { isLoading }] = useTopicShareMutation();

  const eventHashtag = getSocialSharingHashtag(event, intl.locale);
  const twitterHandle = getSocialSharingTwitterHandle(event, intl.locale);
  const hideBraindateHashtag = isSocialSharingBraindateHashtagHidden(event);

  const title = !!topic && getTopicTitle(topic, intl.locale);
  const eventName = getEventName(event, intl.locale);

  const topicLocations: Location[] = topic ? getTopicLocations(topic) : [];
  const isVirtualBraindate = topicLocations.some(isVirtualLocation);

  const fetchShareData = async () => {
    if (!topic) return;

    try {
      // $FlowIssue Flow isn't able to handle rtk mutation
      const { data } = await activateTopicShare(topic);
      setShareData(data);
    } catch (error) {
      if (isErrorReportingEnabled && !isHTTP4xxError(error)) {
        Sentry.captureException(error);
      }
      triggerErrorModal(error);
    }
  };

  useEffect(() => {
    if (!topic) return;
    fetchShareData();
  }, [topic]);

  const getText = () => {
    const params = {
      title,
      eventName: encodeURIComponent(eventName),
      eventHandle: twitterHandle,
    };

    if (isVirtualBraindate) {
      return intl.formatMessage(
        twitterHandle
          ? messages.virtualShareText
          : messages.virtualShareTextWithoutHandle,
        params,
      );
    }

    return intl.formatMessage(
      twitterHandle ? messages.shareText : messages.shareTextWithoutHandle,
      params,
    );
  };

  const getHashtags = () => {
    if (!hideBraindateHashtag) {
      if (eventHashtag) return `braindate,${eventHashtag}`;
      return `braindate`;
    }

    if (eventHashtag) {
      return `${eventHashtag}`;
    }
  };

  const getHref = (platform: SocialType) => {
    if (!shareData) return;
    const text = getText();
    const hashtags = getHashtags();
    const { url } = shareData;

    return {
      [x]: `https://x.com/intent/tweet?ref_src=twsrc%5Etfw&text=${text}&url=${url}${
        hashtags && hashtags.length ? `&hashtags=${hashtags}` : ''
      }`,
      [facebook]: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      [linkedin]: `https://www.linkedin.com/shareArticle?url=${window.encodeURIComponent(
        url,
      )}&utm_source=braindate&utm_medium=linkedIn`,
    }[platform];
  };

  const share = (platform: SocialType) => {
    if (!shareData) return;
    trackGAEvent('Topic details', 'Share on linkedIn');

    const href = getHref(platform);

    if (typeof window !== 'undefined' && !!window.open) {
      window.open(href, 'newwindow', 'width=500,height=400');
    }
  };

  return [share, shareData, isLoading];
};

export default useShareToSocial;
