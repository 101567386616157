/* @flow */

import { DateTime, Interval } from 'luxon';

import type { TimeSlot } from '@braindate/domain/lib/base/type';
import {
  assertArray,
  assertObject,
  assertString,
  assertValidDateTime,
  assertValidInterval,
} from '@braindate/util/lib/assert';

/**
 * Throw an exception if parameter is not a TimeSlot
 * @param   {any} value - Value to check
 * @param   {string} name - Display name of the value
 * @returns {undefined}
 */
export function assertTimeSlot(value: any, name: string) {
  assertObject(value, name);
  assertString(value.start_time, `${name}.start_time`);
  assertString(value.end_time, `${name}.end_time`);
}

/**
 * Sort intervals chronologically by start time. The function returns the
 * array passed as argument.
 * @param   {Array<Interval>} intervals - Intervals to sort
 * @returns {Array<Interval>} Sorted intervals
 *
 * @throws Will throw an exception if parameter is not an array of valid
 * instances of Interval
 */
export function sortIntervals(intervals: Array<Interval>): Array<Interval> {
  assertArray(intervals, 'intervals');

  return intervals.sort((interval1, interval2) => {
    assertValidInterval(interval1, 'interval1');
    assertValidInterval(interval2, 'interval2');

    return interval1.start.toMillis() - interval2.start.toMillis();
  });
}

/**
 * Create a DateTime object from an ISO 8601 string, in the specified timezone
 * @param   {string} date - ISO string to create the DateTime object from
 * @param   {string} zone - Timezone in which to display the date
 * @returns {DateTime} DateTime object
 */
export function createDateTimeFromISO(date: string, zone: ?string): DateTime {
  const dateTime = DateTime.fromISO(date, zone ? { zone } : {});

  if (dateTime.invalid) {
    throw new Error(dateTime.invalidExplanation);
  }

  return dateTime;
}

/**
 * Create an Interval object from a TimeSlot object, in the specified timezone
 * @param   {TimeSlot} slot - Slot to create the Interval object from
 * @param   {string} zone - Timezone in which to display the interval dates
 * @returns {DateTime} Interval object
 *
 * @throws Will throw an exception if parameters are invalid (timezone needs
 * to be valid according to Luxon) or if the result is an invalid
 * Interval object
 */
export function createIntervalFromTimeSlot(
  slot: TimeSlot,
  zone: string,
): Interval {
  assertTimeSlot(slot, 'slot');
  assertString(zone, 'zone');

  const interval = Interval.fromISO(`${slot.start_time}/${slot.end_time}`, {
    zone,
  });

  if (interval.invalid) {
    throw new Error(interval.invalidExplanation);
  }

  return interval;
}

/**
 * Check whether the date time passed as argument is happening today (given the
 * timezone of the date time)
 * @param   {DateTime} dateTime - Date time to check
 * @returns {boolean} True if it is
 *
 * @throws Will throw an exception if parameter is not a valid instance
 * of DateTime
 */
export function isDateTimeToday(dateTime: DateTime): boolean {
  assertValidDateTime(dateTime, 'dateTime');

  const { zoneName: zone } = dateTime;

  return DateTime.local().setZone(zone).hasSame(dateTime, 'day');
}
