/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { useNavigate } from 'react-router-dom';

import BaseButton from '@braindate/ui/lib/component/button/BaseButton';
import type { Props as BaseButtonProps } from '@braindate/ui/lib/component/button/BaseButton';
import withForwardRef from '@braindate/util/lib/react/hoc/withForwardRef';
import { hasURLProtocol } from '@braindate/util/lib/url';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './BaseButton.style';
// eslint-disable-next-line no-duplicate-imports

export const BUTTON_SIZES = {
  NORMAL: 'normal',
  SMALL: 'small',
};

const useStyles = createUseThemeStyles(styles);

type Props = {
  ...BaseButtonProps,
  href?: string,
  onClick?: (e: Event) => void,
  stopPropagation?: boolean,
  icon?: Node,
  isDisabled: boolean,
  size?: 'normal' | 'small',
};

const BaseButtonWithClick = ({
  href,
  onClick,
  stopPropagation,
  icon,
  children,
  className,
  isDisabled,
  size = BUTTON_SIZES.NORMAL,
  forwardedRef,
  ...props
}: Props): Node => {
  const classes = useStyles({ withIcon: !!icon, size });
  const navigate = useNavigate();
  const handleClick = (e: MouseEvent) => {
    if (onClick || href) {
      e.preventDefault();

      if (stopPropagation) {
        e.stopPropagation();
      }
    }

    const { currentTarget } = e;

    if (currentTarget instanceof HTMLInputElement) {
      currentTarget.blur();
    }

    if (onClick) {
      onClick(e);
    }

    if (href) {
      if (hasURLProtocol(href)) {
        window.open(href, '_blank');
      } else {
        navigate(href);
      }
    }
  };

  return (
    <BaseButton
      {...props}
      ref={forwardedRef}
      isDisabled={isDisabled}
      className={[className, classes.root]}
      onClick={handleClick}
    >
      {icon && <div className={classes.icon}>{icon}</div>}
      {children}
    </BaseButton>
  );
};

export default memo<Props>(withForwardRef(BaseButtonWithClick));
