/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import type { User } from '@braindate/domain/lib/user/type';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import UserAvatar from 'src/shared/components/domain/user/avatar/UserAvatar';

import styles from './GroupAvatarQueueAvatar.style';

type Props = {
  participant: User,
  isFaded?: boolean,
  variant?: string,
};

const useStyles = createUseThemeStyles(styles);

const GroupAvatarQueueAvatar = ({
  participant,
  isFaded,
  variant,
}: Props): Node => {
  const classes = useStyles({ isFaded, variant });

  return (
    <UserAvatar
      variant={variant}
      user={participant}
      layoutClass={classes.root}
      showOnlineBadge
    />
  );
};

export default memo<Props>(GroupAvatarQueueAvatar);
