/* @flow */

import { createReducer } from 'src/shared/core/util/reducerUtils';
import * as braindateTypes from 'src/shared/domain/braindate/action/braindateActionTypes';

/*
|------------------------------------------------------------------------------
 | Initial State
 |------------------------------------------------------------------------------
 */

const initialState = {
  respondToBraindate: {
    id: null,
    isShown: null,
  },
};

/*
 |------------------------------------------------------------------------------
 | Reducers
 |------------------------------------------------------------------------------
 */

const setRespondToBraindate = (
  state: Object,
  { braindateId }: { braindateId: number | null },
) => ({
  ...state,
  respondToBraindate: {
    id: braindateId,
    isShown: !!braindateId,
  },
});

/*
 |------------------------------------------------------------------------------
 | Reducer
 |------------------------------------------------------------------------------
 */

export default createReducer(initialState, {
  [braindateTypes.SET_RESPOND_TO_BRAINDATE]: setRespondToBraindate,
});
