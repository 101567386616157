/* @flow */

export const liveChatPlugin = 'live-chat';
export const liveChatTawkScriptURL =
  'https://embed.tawk.to/{property_id}/{widget_id}';
export const liveChatIntercomScriptURL =
  'https://widget.intercom.io/widget/{app_id}';
export const liveChatHubspotScriptURL = 'https://js.hs-scripts.com/{app_id}.js';

export const liveChatAriaHasPopup = 'true';
