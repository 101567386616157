/* @flow */

import { useIntl } from 'react-intl';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/cheer/l10n/cheerl10n';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import CheerIcon from 'src/shared/ui/component/icon/CheerIcon';

import CheerDetails from '!@svgr/webpack?modules!src/shared/app/cheer/assets/CheerDetails.svg';

import styles from './CheerRecipientModalIntro.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  onClick: () => void,
};

const CheerRecipientModalIntro = ({ onClick }: Props) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CheerDetails className={classes.details} />

      <p className={classes.title}>
        {intl.formatMessage(messages.someoneSentYouCheers)}
      </p>

      <PrimaryButton layoutClass={classes.button} onClick={onClick}>
        <CheerIcon />
        {intl.formatMessage(messages.openCheers)}
      </PrimaryButton>
    </div>
  );
};

export default CheerRecipientModalIntro;
