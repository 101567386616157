/* @flow */

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    width: '35px !important',
    height: '35px !important',
    borderRadius: '50%',
    padding: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    backgroundColor: ({ theme }: ThemeFunction<{}>) =>
      theme.calendarDisableButtonBackgroundColor,
    cursor: 'pointer',
  },
  active: {
    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    color: ({ theme }: ThemeFunction<{}>) => theme.textColorReverse,
  },
};
