/* @flow */
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

import avatarSizes from './setting/avatarSizes';

export default {
  root: {
    position: 'relative',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'row',
    width: ({ variant }: ThemeFunction<{ variant: string }>) =>
      avatarSizes[variant],
    height: ({ variant }: ThemeFunction<{ variant: string }>) =>
      avatarSizes[variant],
    opacity: ({ isFaded }: ThemeFunction<{ isFaded: boolean }>) =>
      isFaded ? 0.5 : 1,
    border: ({ theme }: ThemeFunction<{}>) => `${theme.colorPrimary} solid 2px`,
  },
};
