/* @flow */

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  error: {
    padding: '0.25em 0',
    color: ({ theme }: ThemeFunction<{}>) => theme.errorMessageColor,
    fontSize: 12,
    fontStyle: 'italic',
    fontWeight: 500,
    WebkitFontSmoothing: 'antialiased',
  },
};
