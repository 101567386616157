/* @flow */

import { useEffect, useRef, useState } from 'react';

import { assertNumber } from '@braindate/util/lib/assert';

export default function usePeriodicRender(interval: number) {
  assertNumber(interval, 'interval');

  const intervalRef = useRef();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    intervalRef.current = window.setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, interval * 1000);

    return () => {
      const { current: id } = intervalRef;

      if (id) {
        window.clearInterval(id);
      }
    };
  }, []);

  return counter;
}
