/* @flow */

import { rem } from 'polished';

import { fadeDuration, pressDuration } from '@braindate/ui/lib/token/animation';
import { hoverSelector } from '@braindate/ui/lib/token/selector';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    textAlign: 'center',
    textDecoration: 'none',
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,

    transition: [
      ['opacity', fadeDuration],
      ['transform', pressDuration],
    ],

    [hoverSelector]: {
      opacity: 0.5,
    },
    '&:active': {
      transform: 'scale(0.90, 0.90)',
    },

    '& svg': {
      width: 'auto',
      maxHeight: 60,
    },
  },

  platform: {
    display: 'inline',
  },
  logo: {
    objectFit: 'contain',
    objectPosition: 'left',
  },

  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: 0,
  },
  eventName: {
    marginBottom: 10,
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
    '& sup': {
      fontWeight: 600,
      fontSize: rem(5.25, baseFontSize),
    },
    '& i': {
      fontWeight: 300,
      fontSize: rem(12, baseFontSize),
    },
  },
  braindate: {
    textTransform: 'capitalize',
    fontSize: rem(14, baseFontSize),
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
    fontWeight: 700,
  },
};
