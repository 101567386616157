/* @flow */

import { DateTime, Interval, Settings } from 'luxon';
import { always, both, complement, either, sortBy, when } from 'ramda';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateConversation,
  getBraindateEndTime,
  getBraindateLastActiveUser,
  getBraindateLocation,
  getBraindateStartTime,
  getBraindateTimeSlot,
  isBraindateCancelled,
  isBraindateConfirmed,
  isBraindateDeclined,
} from '@braindate/domain/lib/braindate/util';
import type { ConversationParticipant } from '@braindate/domain/lib/conversation/type';
import {
  getConversationLastUpdateTimestamp,
  getConversationParticipantParticipationId,
  getConversationParticipants,
  getConversationTopic,
  getConversationUnreadNotificationsCount,
} from '@braindate/domain/lib/conversation/util';
import { isVirtualLocation } from '@braindate/domain/lib/location/util';
import type { Topic } from '@braindate/domain/lib/topic/type';
import { getTopicId, isFishbowlTopic } from '@braindate/domain/lib/topic/util';
import type { User } from '@braindate/domain/lib/user/type';
import { getUserId } from '@braindate/domain/lib/user/util';
import { assertArray, assertObject } from '@braindate/util/lib/assert';

import {
  createInterval,
  isDateInInterval,
} from 'src/shared/app/base/date/util/dateUtils';
import { createDateTimeFromISO } from 'src/shared/app/booking/util/dateUtils';
import {
  minutesPastBraindate,
  minutesToBraindate,
} from 'src/shared/app/check-in/settings/checkInSettings';
import { getConversationTopicAuthor } from 'src/shared/domain/conversation/util/conversationUtil';

import { EXTENSION_TIME_MINUTES } from 'plugin/virtual-braindate/constant/virtualBraindateConstants';
import { virtualBraindateBeforeDuration } from 'plugin/virtual-braindate/setting/virtualBraindateSettings';

/*
|------------------------------------------------------------------------------
| GETTERS
|------------------------------------------------------------------------------
*/

export function isBraindateCheckInReady(braindate: Braindate): boolean {
  assertObject(braindate, 'braindate');

  if (!getBraindateTimeSlot(braindate)) {
    return false;
  }

  if (!isActiveBraindate(braindate)) {
    return false;
  }

  const zone = Settings.defaultZone;
  const checkInTime = getActiveBraindateCheckInTime(braindate);
  const checkInDateTime = DateTime.fromISO(checkInTime, { zone });
  const maxTime = getActiveBraindateMaxCheckInTime(braindate);
  const maxDateTime = DateTime.fromISO(maxTime, { zone });

  return (
    checkInDateTime.diffNow().milliseconds < 0 &&
    maxDateTime.diffNow().milliseconds > 0
  );
}

export function isBraindateVideoCallReady(braindate: Braindate): boolean {
  assertObject(braindate, 'braindate');
  if (
    !braindate ||
    !getBraindateEndTime(braindate) ||
    !getBraindateStartTime(braindate)
  ) {
    return false;
  }

  const endTime = createDateTimeFromISO(
    // $FlowIssue
    getBraindateEndTime(braindate),
  );
  const conversation = getBraindateConversation(braindate);
  const topic = getConversationTopic(conversation);

  if (isFishbowlTopic(topic)) {
    if (endTime.diffNow().valueOf() <= 0) return false;
  } else if (
    endTime.plus({ minutes: EXTENSION_TIME_MINUTES }).diffNow().valueOf() <= 0
  ) {
    return false;
  }

  const startTime = createDateTimeFromISO(
    // $FlowIssue
    getBraindateStartTime(braindate),
  );

  return startTime.diffNow().valueOf() < virtualBraindateBeforeDuration * 1000;
}

export function getBraindateParticipants(
  braindate: Braindate,
): Array<ConversationParticipant> {
  const conversation = getBraindateConversation(braindate);

  return getConversationParticipants(conversation);
}

export function getBraindateOtherParticipants(
  braindate: Braindate,
  user: User,
): Array<ConversationParticipant> {
  return getBraindateParticipants(braindate).filter(
    (p) => getUserId(p) !== getUserId(user),
  );
}

export function getBraindateOtherParticipant(
  braindate: Braindate,
  user: User,
): ConversationParticipant {
  const participants: ConversationParticipant[] = sortBy(
    getConversationParticipantParticipationId,
    getBraindateParticipants(braindate),
  );

  if (getUserId(participants[0]) === getUserId(user)) {
    return participants[1];
  }

  return participants[0];
}

export function getGroupBraindateHost(braindate: Braindate): User {
  const conversation = getBraindateConversation(braindate);

  return getConversationTopicAuthor(conversation);
}

export function getBraindateTopic(braindate: Braindate): Topic {
  const conversation = getBraindateConversation(braindate);

  return getConversationTopic(conversation);
}

/*
|------------------------------------------------------------------------------
| ACTIVE BRAINDATE GETTERS
|------------------------------------------------------------------------------
*/

export function getActiveBraindateCheckInTime(braindate: Braindate): string {
  const location = getBraindateLocation(braindate);
  const isVirtual = location && isVirtualLocation(location);

  const start = getBraindateStartTime(braindate);
  const startDateTime = DateTime.fromISO(start, {
    zone: Settings.defaultZone,
  });

  return startDateTime
    .minus({
      minutes: isVirtual
        ? virtualBraindateBeforeDuration / 60
        : minutesToBraindate,
    })
    .toISO();
}

export function getActiveBraindateMaxCheckInTime(braindate: Braindate): string {
  const start = getBraindateStartTime(braindate);
  const startDateTime = DateTime.fromISO(start, {
    zone: Settings.defaultZone,
  });

  return startDateTime.plus({ minutes: minutesPastBraindate }).toISO();
}

/*
|------------------------------------------------------------------------------
| CONVERSATION GETTERS
|------------------------------------------------------------------------------
*/

/**
 * Get the count of the braindate conversation unread messages
 * @param  {Braindate} braindate - Braindate to get the count from
 * @return {number} Count
 */
export function getBraindateUnreadMessageCount(braindate: Braindate): ?number {
  return getConversationUnreadNotificationsCount(
    getBraindateConversation(braindate),
  );
}

/**
 * Get the last activity time of the braindate
 * @param  {Braindate} braindate - Braindate to get the last activity time from
 * @return {string} Last activity time
 */
export function getBraindateLastActivityTime(braindate: Braindate): string {
  return getConversationLastUpdateTimestamp(
    getBraindateConversation(braindate),
  );
}

/*
|------------------------------------------------------------------------------
| CHECKERS
|------------------------------------------------------------------------------
*/

/**
 * Check if the braindate is active (confirmed and not cancelled)
 * @param  {Braindate} braindate - Braindate to check
 * @return {boolean} True if the braindate is active
 */
export function isActiveBraindate(braindate: Braindate): boolean {
  return isBraindateConfirmed(braindate) && !isBraindateCancelled(braindate);
}

/**
 * Check if the braindate is now live
 * @param {Braindate} braindate - Braindate to check
 * @returns {boolean} True if the braindate is live
 */
export function isBraindateLive(braindate: Braindate): boolean {
  const now = DateTime.now();

  const braindateTimeSlot = getBraindateTimeSlot(braindate);
  if (!braindateTimeSlot) return false;

  return isDateInInterval(now, createInterval(braindateTimeSlot));
}

/**
 * Check if the braindate is past
 * @param  {Braindate} braindate - Braindate to check
 * @return {boolean} True if the braindate is
 */
export function isBraindatePast(braindate: Braindate): boolean {
  if (!('date' in braindate)) return false;
  const time = getBraindateEndTime(braindate);
  const dateTime = DateTime.fromISO(time);

  return DateTime.local().ts > dateTime.ts;
}

/**
 * Check if the braindate is upcoming
 * @param  {Braindate} braindate - Braindate to check
 * @return {boolean} True if the braindate is
 */
export function isBraindateUpcoming(braindate: Braindate): boolean {
  const time = getBraindateStartTime(braindate);
  const dateTime = DateTime.fromISO(time);

  return dateTime.toMillis() > DateTime.now().toMillis();
}

/**
 * Check if the braindate is today
 * @param  {Braindate} braindate - Braindate to check
 * @return {boolean} True if the braindate is
 */
export function isBraindateToday(braindate: Braindate): boolean {
  const time = getBraindateStartTime(braindate);
  const zone = Settings.defaultZone;
  const dateTime = DateTime.fromISO(time, { zone });
  const today = DateTime.local().setZone(zone);

  return dateTime.hasSame(today, 'day');
}

export function isBraindateLastActiveUserSelf(
  braindate: Braindate,
  user: User,
) {
  const lastActiveUser = getBraindateLastActiveUser(braindate);

  return lastActiveUser && getUserId(lastActiveUser) === getUserId(user);
}

export function hasOtherCancelledBraindate(
  braindate: Braindate,
  user: User,
): boolean {
  return (
    !isBraindateLastActiveUserSelf(braindate, user) &&
    isBraindateCancelled(braindate)
  );
}

export function hasOtherRescheduledBraindate(
  braindate: Braindate,
  user: User,
): boolean {
  return (
    !hasOtherCancelledBraindate(braindate, user) &&
    !isBraindateLastActiveUserSelf(braindate, user) &&
    !isBraindateConfirmed(braindate)
  );
}

export function hasPendingBraindates(
  braindates: Braindate[],
  user: User,
  self: User,
): boolean {
  if (!self) return false;

  const now = DateTime.now();

  const futureOrPendingBraindates = braindates.filter((braindate) => {
    const braindateTimeSlot = getBraindateTimeSlot(braindate);
    // If no timeslot it means braindate is pending
    if (!braindateTimeSlot) return true;

    if (
      isBraindateConfirmed(braindate) ||
      isBraindateDeclined(braindate) ||
      isBraindateCancelled(braindate)
    ) {
      return false;
    }

    const braindateInterval = Interval.fromDateTimes(
      DateTime.fromISO(braindateTimeSlot.start_time),
      DateTime.fromISO(braindateTimeSlot.end_time),
    );

    return braindateInterval.isAfter(now);
  });

  return futureOrPendingBraindates.some((braindate) => {
    const participant = getBraindateOtherParticipant(braindate, self);
    return !!participant && getUserId(participant) === getUserId(user);
  });
}

/*
|------------------------------------------------------------------------------
| FILTERS
|------------------------------------------------------------------------------
*/

/**
 * Filter braindates by invitations
 * @param  {Array<Braindate>} braindates - Braindates to filter
 * @return {Array<Braindate>} Invitations
 */
export function getBraindateInvitations(
  braindates: Array<Braindate>,
): Array<Braindate> {
  assertArray(braindates, 'braindates');

  return braindates.filter(
    either(complement(isBraindateConfirmed), complement(isBraindateCancelled)),
  );
}

/**
 * Filter braindates by topic
 * @param  {Array<Braindate>} braindates - Braindates to filter
 * @param  {Topic} topic - Topic to find
 * @return {Array<Braindate>} Invitations
 */
export function getBraindatesByTopic(
  braindates: Array<Braindate>,
  topic: Topic,
): Array<Braindate> {
  return braindates.filter(
    (braindate) =>
      getTopicId(getBraindateTopic(braindate)) === getTopicId(topic),
  );
}

/**
 * Filter braindates by other user
 * @param  {Array<Braindate>} braindates - Braindates to filter
 * @param  {User} user - User
 * @return {Array<Braindate>} Invitations
 */
export function getBraindatesByOtherUser(
  braindates: Array<Braindate>,
  user: User,
): Array<Braindate> {
  return braindates.filter((braindate) =>
    getBraindateParticipants(braindate).some(
      (p) => getUserId(p) === getUserId(user),
    ),
  );
}

/**
 * Filter braindates by declined invitations
 * @param  {Array<Braindate>} braindates - Braindates to filter
 * @return {Array<Braindate>} Declined invitations
 */
export function getDeclinedBraindateInvitations(
  braindates: Array<Braindate>,
): Array<Braindate> {
  return getBraindateInvitations(braindates).filter(isBraindateDeclined);
}

/**
 * Filter braindates by active invitations
 * @param  {Array<Braindate>} braindates - Braindates to filter
 * @return {Array<Braindate>} Active invitations
 */
export function getActiveBraindateInvitations(
  braindates: Array<Braindate>,
): Array<Braindate> {
  return getBraindateInvitations(braindates).filter(
    complement(isBraindateDeclined),
  );
}

/**
 * Filter braindates by pending invitations
 * @param  {Array<Braindate>} braindates - Braindates to filter
 * @param  {User} user - Authenticated user
 * @return {Array<Braindate>} Pending invitations
 */
export function getPendingBraindateInvitations(
  braindates: Array<Braindate>,
  user: User,
): Array<Braindate> {
  return getActiveBraindateInvitations(braindates).filter((braindate) => {
    const lastActiveUser = getBraindateLastActiveUser(braindate);

    return lastActiveUser && getUserId(lastActiveUser) !== getUserId(user);
  });
}

/**
 * Filter braindates by awaiting invitations
 * @param  {Array<Braindate>} braindates - Braindates to filter
 * @param  {User} user - Authenticated user
 * @return {Array<Braindate>} Awaiting invitations
 */
export function getAwaitingBraindateInvitations(
  braindates: Array<Braindate>,
  user: User,
): Array<Braindate> {
  return getActiveBraindateInvitations(braindates).filter((braindate) => {
    const lastActiveUser = getBraindateLastActiveUser(braindate);

    return lastActiveUser && getUserId(lastActiveUser) === getUserId(user);
  });
}

/**
 * Filter braindates by confirmed braindates
 * @param  {Array<Braindate>} braindates - Braindates to filter
 * @return {Array<Braindate>} Confirmed braindates
 */
export function getConfirmedBraindates(
  braindates: Array<Braindate>,
): Array<Braindate> {
  assertArray(braindates, 'braindates');

  return braindates.filter(either(isBraindateConfirmed, isBraindateCancelled));
}

export function getBraindatesToCheckIn(
  braindates: Array<Braindate>,
): Array<Braindate> {
  assertArray(braindates, 'braindates');

  return getActiveBraindates(braindates).filter(
    both(isBraindateConfirmed, isBraindateCheckInReady),
  );
}

/**
 * Filter braindates by active braindates
 * @param  {Array<Braindate>} braindates - Braindates to filter
 * @return {Array<Braindate>} Active braindates
 */
export function getActiveBraindates(
  braindates: Array<Braindate>,
): Array<Braindate> {
  return getConfirmedBraindates(braindates).filter(
    complement(isBraindateCancelled),
  );
}

/**
 * Filter braindates by cancelled braindates
 * @param  {Array<Braindate>} braindates - Braindates to filter
 * @return {Array<Braindate>} Cancelled braindates
 */
export function getCancelledBraindates(
  braindates: Array<Braindate>,
): Array<Braindate> {
  return getConfirmedBraindates(braindates).filter(isBraindateCancelled);
}

/**
 * Filter braindates by past active braindates
 * @param  {Array<Braindate>} braindates - Braindates to filter
 * @param  {boolean} excludeToday - If true, exclude today's braindates
 * @return {Array<Braindate>} Past active braindates
 */
export function getPastActiveBraindates(
  braindates: Array<Braindate>,
  excludeToday?: boolean = false,
): Array<Braindate> {
  return getActiveBraindates(braindates)
    .filter(isBraindatePast)
    .filter(when(always(excludeToday), complement(isBraindateToday)));
}

/**
 * Filter braindates by live active braindates
 * @param  {Array<Braindate>} braindates - Braindates to filter
 * @return {Array<Braindate>} Live active braindates
 */
export function getLiveActiveBraindates(
  braindates: Array<Braindate>,
): Array<Braindate> {
  return getActiveBraindates(braindates).filter(isBraindateLive);
}

/**
 * Filter braindates by upcoming active braindates
 * @param  {Array<Braindate>} braindates - Braindates to filter
 * @return {Array<Braindate>} Upcoming active braindates
 */
export function getConfirmedActiveBraindates(
  braindates: Array<Braindate>,
): Array<Braindate> {
  return getActiveBraindates(braindates).filter(isBraindateUpcoming);
}

/**
 * Filter braindates by today active braindates
 * @param  {Array<Braindate>} braindates - Braindates to filter
 * @return {Array<Braindate>} Today active braindates
 */
export function getTodayActiveBraindates(
  braindates: Array<Braindate>,
): Array<Braindate> {
  return getActiveBraindates(braindates).filter(isBraindateToday);
}

/*
|------------------------------------------------------------------------------
| REDUCERS
|------------------------------------------------------------------------------
*/

/**
 * Get the count of braindates unread messages
 * @param  {Array<Braindate>} braindates - Braindates to get the count from
 * @return {number} Count
 */
export function getBraindatesUnreadMessageCount(
  braindates: Array<Braindate>,
): number {
  assertArray(braindates, 'braindates');

  return braindates.reduce(
    (acc, braindate) => acc + getBraindateUnreadMessageCount(braindate),
    0,
  );
}

/*
|------------------------------------------------------------------------------
| SORTING
|------------------------------------------------------------------------------
*/

/**
 * Sort braindates by the most recent activity time
 * @param  {Array<Braindates>} braindates - Braindates to sort
 * @return {undefined}
 */
export function sortBraindatesByMostRecentActivity(
  braindates: Array<Braindate>,
): void {
  assertArray(braindates, 'braindates');

  braindates.sort((a, b) => {
    const dateTimeA = DateTime.fromISO(getBraindateLastActivityTime(a));
    const dateTimeB = DateTime.fromISO(getBraindateLastActivityTime(b));

    return dateTimeB.ts - dateTimeA.ts;
  });
}

/**
 * Sort active braindates by the most recent start time
 * @param  {Array<Braindates>} braindates - Braindates to sort
 * @return {undefined}
 */
export function sortActiveBraindatesByMostRecent(
  braindates: Array<Braindate>,
): void {
  assertArray(braindates, 'braindates');

  braindates.sort((a, b) => {
    const dateTimeA = DateTime.fromISO(getBraindateStartTime(a));
    const dateTimeB = DateTime.fromISO(getBraindateStartTime(b));

    return dateTimeA.ts - dateTimeB.ts;
  });
}

/**
 * Sort active braindates by the most recent start time
 * @param  {Array<Braindates>} braindates - Braindates to filter
 * @param  {User} user - User to look for
 * @param  {User} self - Self user
 * @param  {boolean | void} onlyConfirmed - Whether to get only confirmed braindates or not
 * @return {Array<Braindate>} Common braindates
 */
export function getCommonBraindatesWithUser(
  braindates: Braindate[],
  user: User,
  self: User,
  onlyConfirmed?: boolean,
): Array<Braindate> {
  const braindatesToFilter = onlyConfirmed
    ? getConfirmedBraindates(braindates)
    : braindates;

  return braindatesToFilter.filter((braindate) => {
    const participants = getBraindateOtherParticipants(braindate, self);
    if (!participants.length) return false;

    return participants.some(
      (participant) => getUserId(participant) === getUserId(user),
    );
  });
}
