/* @flow */

import { createSelector } from '@reduxjs/toolkit';

const _getSegment = (state) => state.app.market.sort;

const _getMarketSortOrdering = (state) => _getSegment(state).ordering;

export const getMarketSortOrdering: (state: Object) => string = createSelector(
  [_getMarketSortOrdering],
  (x: string): string => x,
);
