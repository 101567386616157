/* @flow */

import { createSlice } from '@reduxjs/toolkit';

export type UICheerState = {
  sent: number | null,
};

export const cheerInitialState: UICheerState = {
  sent: null,
};

const cheerSlice = createSlice({
  name: 'cheer',
  initialState: cheerInitialState,
  reducers: {
    newCheerSuccess: (state, { payload }: { payload: number }) => {
      state.sent = payload;
    },
  },
});

export const { newCheerSuccess } = cheerSlice.actions;

export default cheerSlice.reducer;
