/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';

import type { Button as ButtonType } from '@braindate/ui/lib/component/button/type/buttonTypes';
import withForwardRef from '@braindate/util/lib/react/hoc/withForwardRef';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import BaseButton from 'src/shared/ui/component/button/BaseButton';
import ConditionalWrapper from 'src/shared/ui/component/misc/ConditionalWrapper';

import styles from './Button.style';

export const VARIANT: { PRIMARY: 'primary', SECONDARY: 'secondary' } = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export type Props = ButtonType & {
  fitText?: ?boolean,
  stopPropagation?: ?boolean,
  withSuccess?: ?boolean,
  withEvenSide?: ?boolean,
  onClick?: ?(Event) => void,
  onMouseOver?: ?(Event) => void,
  withEvenSides?: ?boolean,
  color?: string,
  variant?: 'primary' | 'secondary',
  tooltip?: string,
  'aria-disabled'?: boolean,
};

const useStyles = createUseThemeStyles(styles);

const Button = ({
  layoutClass,
  href,
  onClick,
  onMouseOver,
  isDisabled,
  isSubmitting,
  isFetching,
  large,
  fitText,
  stopPropagation,
  withSuccess,
  hasFocus,
  children,
  id,
  withEvenSides,
  variant = VARIANT.SECONDARY,
  color,
  tooltip,
  'aria-disabled': ariaDisabled,
  ...props
}: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles({
    withSuccess,
    customColor: color,
    ariaDisabled,
  });

  const rootClasses = classNames(classes.root, layoutClass, {
    [classes.primary]: variant === VARIANT.PRIMARY,
    [classes.secondary]: variant === VARIANT.SECONDARY,
  });

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  return (
    <ConditionalWrapper
      condition={!!tooltip}
      wrapper={(child) => (
        <Tooltip title={tooltip} arrow placement="top">
          {child}
        </Tooltip>
      )}
    >
      <BaseButton
        {...props}
        className={rootClasses}
        href={href}
        onClick={onClick}
        onMouseOver={onMouseOver}
        isDisabled={isDisabled}
        aria-disabled={ariaDisabled}
        isSubmitting={isSubmitting}
        isFetching={isFetching}
        large={large}
        fitText={fitText}
        stopPropagation={stopPropagation}
        withSuccess={withSuccess}
        hasFocus={hasFocus}
        id={id}
        withEvenSides={withEvenSides}
      >
        {children}
      </BaseButton>
    </ConditionalWrapper>
  );
};

export default memo<Props>(withForwardRef(Button));
