/* @flow */

import { getConversationId } from '@braindate/domain/lib/conversation/util';

import * as types from 'src/shared/app/conversation/action/uiConversationActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

/*
 |------------------------------------------------------------------------------
 | Initial state
 |------------------------------------------------------------------------------
 */

const initialState = {
  backUrl: '',
  pendingAction: null,
  isInitialized: {},
};

/*
 |------------------------------------------------------------------------------
 | Reducers
 |------------------------------------------------------------------------------
 */

const setBackUrl = (state: Object, { url }: Object) => ({
  ...state,
  backUrl: url,
});

const resetBackUrl = (state: Object) => ({
  ...state,
  backUrl: '',
});

const setPendingAction = (state: Object, { action }: Object) => ({
  ...state,
  pendingAction: action,
});

const resetPendingAction = (state: Object) => ({
  ...state,
  pendingAction: null,
});

const markAsInitialized = (state: Object, { conversation }: Object) => ({
  ...state,
  isInitialized: {
    ...state.isInitialized,
    [getConversationId(conversation)]: true,
  },
});

const unmarkAsInitialized = (state: Object, { conversation }: Object) => ({
  ...state,
  isInitialized: {
    ...state.isInitialized,
    [getConversationId(conversation)]: false,
  },
});

export default createReducer(initialState, {
  [types.SET_CONVERSATION_BACK_URL]: setBackUrl,
  [types.RESET_CONVERSATION_BACK_URL]: resetBackUrl,
  [types.SHOW_ACTION_CONFIRMATION_MODAL]: setPendingAction,
  [types.DISMISS_ACTION_CONFIRMATION_MODAL]: resetPendingAction,
  [types.MARK_CONVERSATION_AS_INITIALIZED]: markAsInitialized,
  [types.UNMARK_CONVERSATION_AS_INITIALIZED]: unmarkAsInitialized,
});
