import { User } from "@braindate/common/src/types/user";
import { apiRoot } from "../apiRoot";
import { getTogethernessEndpoint } from "../../settings/endpointSettings";


const extendedApi = apiRoot.injectEndpoints({
  endpoints: (builder) => ({
    getTogetherness: builder.query<User[], { eventId: number }>({
      query: ({ eventId }) => ({
        url: getTogethernessEndpoint(eventId),
      }),
    }),
  }),
});

export const { useGetTogethernessQuery } = extendedApi;