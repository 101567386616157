/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { portalTakeOverId } from 'src/shared/app/base/constant/domConstants';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './TakeOverPortal.style';

const useStyles = createUseThemeStyles(styles);

const TakeOverPortal = (): Node => {
  const classes = useStyles();
  return <div className={classes.root} id={portalTakeOverId} />;
};

export default memo<{}>(TakeOverPortal);
