/* @flow */

import { getEventDefaultTheme } from '@braindate/domain/lib/event/util';

import useEvent from './useEvent';

export default function useEventDefaultTheme(): string {
  const event = useEvent();

  return getEventDefaultTheme(event);
}
