/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { useTheme } from 'react-jss';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

import { hasURLProtocol } from '@braindate/util/lib/url';

type PropsLinkRenderer = {
  href: string,
};
const LinkRenderer = ({ href, ...props }: PropsLinkRenderer) =>
  hasURLProtocol(href) ? (
    // $FlowIssue
    <a rel="noopener noreferrer" target="_blank" href={href} {...props} />
  ) : (
    // $FlowIssue
    <Link to={href} {...props} />
  );

const StrongRenderer = (props) => {
  const { accentColor } = useTheme();
  return <strong style={{ color: accentColor }} {...props} />;
};

type Props = {
  children: string,
};

const Markdown = ({ children }: Props): Node => (
  <ReactMarkdown
    source={children}
    renderers={{
      strong: StrongRenderer,
      a: LinkRenderer,
    }}
  />
);

export default memo<Props>(Markdown);
