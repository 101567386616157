/* @flow */

import classNames from 'classnames';

import {
  assertLooseString,
  assertObject,
  assertString,
} from '@braindate/util/lib/assert';
import { isObject } from '@braindate/util/lib/type';

/**
 * Concatenate the `layoutClass` prop to the class passed as parameter
 * @param  {Object} props     - Props to get the layout class from
 * @param  {string} className - Class to concatenate the layout class to
 * @return {string}           - The concatenated class
 */
export function enhanceClassWithLayoutClass(
  props: Object,
  className: string,
): string {
  assertObject(props, 'props');
  assertString(className, 'className');

  return classNames(className, props.layoutClass);
}

/**
 * Return the attributes specified in the `htmlAttributes` props. Return an
 * empty object if the prop is not specified.
 * @param  {Object} props - Props to get the html attributes from
 * @return {Object}       - HTML attributes
 */
export function parseHtmlAttributesFromProps(props: Object): Object {
  assertObject(props, 'props');

  const { htmlAttributes: attrs } = props;

  return isObject(attrs) ? { ...attrs } : {};
}

/**
 * Create the props specific to a base UI component from the props passed as
 * parameter
 * @param  {Object} props     - Props to parse
 * @param  {string} className - Class to concatenate the `layoutClass` prop to
 * @return {Object}           - Base UI component specific props
 */
export function createBaseUiComponentProps(
  props: Object,
  className: string,
): Object {
  assertObject(props, 'props');
  assertLooseString(className, 'className');

  const newProps = { ...parseHtmlAttributesFromProps(props) };

  if (className) {
    newProps.className = enhanceClassWithLayoutClass(props, className);
  }

  return newProps;
}
