(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "@braindate/util/lib/assert"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("@braindate/util/lib/assert"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.assert);
    global.braindateAssertions = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _assert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertBraindate = assertBraindate;
  _exports.assertBraindateCheckInStatus = assertBraindateCheckInStatus;
  _exports.assertBraindateConversation = assertBraindateConversation;
  _exports.assertBraindateLastActiveUser = assertBraindateLastActiveUser;
  _exports.assertBraindateLocation = assertBraindateLocation;
  _exports.assertBraindateStatus = assertBraindateStatus;
  _exports.assertBraindateTimeSlot = assertBraindateTimeSlot;
  _exports.assertBraindateURLs = assertBraindateURLs;
  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertBraindate(value) {
    (0, _assert.assertObject)(value, "braindate");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertBraindateConversation(value) {
    (0, _assert.assertObject)(value, "braindate.conversation");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertBraindateLastActiveUser(value) {
    (0, _assert.assertObject)(value, "braindate.last_user_proposition");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertBraindateURLs(value) {
    (0, _assert.assertObject)(value, "braindate.urls");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertBraindateTimeSlot(value) {
    (0, _assert.assertObject)(value, "braindate.date");
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertBraindateCheckInStatus(value) {
    (0, _assert.assertArray)(value, "braindate.checkins");
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertBraindateLocation(value) {
    (0, _assert.assertObject)(value, "braindate.location");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertBraindateStatus(value) {
    (0, _assert.assertString)(value, "braindate.status");
  }
});