(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./notificationAssertions", "@braindate/util/lib/assert"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./notificationAssertions"), require("@braindate/util/lib/assert"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.notificationAssertions, global.assert);
    global.notificationGetters = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _notificationAssertions, _assert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getNotificationClearURL = getNotificationClearURL;
  _exports.getNotificationDate = getNotificationDate;
  _exports.getNotificationId = getNotificationId;
  _exports.getNotificationIsRead = getNotificationIsRead;
  _exports.getNotificationMarkReadURL = getNotificationMarkReadURL;
  _exports.getNotificationMessage = getNotificationMessage;
  _exports.getNotificationPath = getNotificationPath;
  _exports.getNotificationSender = getNotificationSender;
  _exports.getNotificationTemplate = getNotificationTemplate;
  _exports.getNotificationURL = getNotificationURL;
  _exports.getNotificationURLs = getNotificationURLs;
  /**
   * Get the id of a notification
   * @param   {Notification} notification - Notification to get the id from
   * @returns {number} Id
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getNotificationId(notification) {
    (0, _notificationAssertions.assertNotification)(notification);
    return notification.id;
  }

  /**
   * Get the URL of a notification
   * @param   {Notification} notification - Notification to get the URL from
   * @returns {string} URL
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getNotificationURL(notification) {
    (0, _notificationAssertions.assertNotification)(notification);
    return notification.url;
  }

  /**
   * Get the URLs of a notification
   * @param   {Notification} notification - Notification to get the URLs from
   * @returns {NotificationURLs} URLs
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getNotificationURLs(notification) {
    (0, _notificationAssertions.assertNotification)(notification);
    var urls = notification.urls;
    (0, _notificationAssertions.assertNotificationURLs)(urls);
    return urls;
  }

  /**
   * Get the path of a notification
   * @param   {Notification} notification - Notification
   * @returns {string} Path
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getNotificationPath(notification) {
    (0, _notificationAssertions.assertNotification)(notification);
    var path = notification.path;
    (0, _assert.assertString)(path);
    return path;
  }

  /**
   * Get the Template of a notification
   * @param   {Notification} notification - Notification
   * @returns {string} Template
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getNotificationTemplate(notification) {
    (0, _notificationAssertions.assertNotification)(notification);
    var template = notification.template;
    (0, _assert.assertString)(template);
    return template;
  }

  /**
   * Get the date of a notification
   * @param   {Notification} notification - Notification
   * @returns {string} Date
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getNotificationDate(notification) {
    (0, _notificationAssertions.assertNotification)(notification);
    var dateSent = notification.date_sent;
    (0, _assert.assertString)(dateSent);
    return dateSent;
  }

  /**
   * Get if notification is read
   * @param   {Notification} notification - Notification
   * @returns {boolean} If is read
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getNotificationIsRead(notification) {
    (0, _notificationAssertions.assertNotification)(notification);
    var read = notification.read;
    (0, _assert.assertBoolean)(read);
    return read;
  }

  /**
   * Get the message of a notification
   * @param   {Notification} notification - Notification
   * @returns {string} Message
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getNotificationMessage(notification) {
    (0, _notificationAssertions.assertNotification)(notification);
    var message = notification.message;
    (0, _assert.assertString)(message);
    return message;
  }

  /**
   * Get the sender user of a notification
   * @param   {Notification} notification - Notification
   * @returns {User} User
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getNotificationSender(notification) {
    (0, _notificationAssertions.assertNotification)(notification);
    var sender = notification.sender;
    if (sender) {
      (0, _notificationAssertions.assertNotificationSender)(sender);
      return sender;
    }
  }

  /*
   |------------------------------------------------------------------------------
   | Advanced getters
   |------------------------------------------------------------------------------
   */

  /**
   * Get the URL of the notificatios's "mark as read" endpoint
   * @param   {Notification} notification - Notification
   * @returns {string} URL
   */
  function getNotificationMarkReadURL(notification) {
    return getNotificationURLs(notification).mark_as_read;
  }

  /**
   * Get the URL of the notificatios's "clear" endpoint
   * @param   {Notification} notification - Notification
   * @returns {string} URL
   */
  function getNotificationClearURL(notification) {
    return getNotificationURLs(notification).clear;
  }
});