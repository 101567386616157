/* @flow */

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { hasURLProtocol } from '@braindate/util/lib/url';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import type { DrawerMenuItemType } from 'src/shared/app/nav/component/drawermenu/DrawerMenu';
import useIsPathActive from 'src/shared/core/hooks/useIsPathActive';
import ConditionalWrapper from 'src/shared/ui/component/misc/ConditionalWrapper';

import styles from './MobileDrawerMenuList.style';

const useStyles = createUseThemeStyles(styles);

const MobileDrawerMenuListItem = ({
  to,
  title,
  icon,
  target,
  match,
  onClick,
}: DrawerMenuItemType) => {
  const classes = useStyles();

  const isActive = useIsPathActive(match ? [...match, to] : to || '');

  const getIcon = () => {
    if (icon.src && icon.alt) {
      return (
        // $FlowIssue
        <img className={classes.img} src={icon.src} alt={icon.alt} />
      );
    }

    return icon;
  };

  const isExternalLink = to && hasURLProtocol(to);
  const linkProps = {
    to,
    target,
    className: classNames(classes.link, { [classes.active]: isActive }),
    onClick,
  };

  return (
    <li className={classes.item}>
      <ConditionalWrapper
        condition={isExternalLink}
        wrapper={(linkChild) => <a {...linkProps}>{linkChild}</a>}
        elseWrapper={(linkChild) => <Link {...linkProps}>{linkChild}</Link>}
      >
        <>
          <span
            className={classNames(classes.iconWrapper, {
              [classes.iconWrapperActive]: isActive,
            })}
          >
            {getIcon()}
          </span>
          {title}
        </>
      </ConditionalWrapper>
    </li>
  );
};

export default MobileDrawerMenuListItem;
