/* @flow */
import { memo, useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import { IconButton, Skeleton, Tooltip, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { useId } from 'react-aria';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { phablet } from '@braindate/ui/lib/token/mediaQuery';

import { useGetTopicQuery } from 'src/shared/app/base/api/endpoint/topicsEndpoint';
import useCopyToClipboard from 'src/shared/app/base/hook/useCopyToClipboard';
import Modal from 'src/shared/app/base/modal/component/Modal';
import { clearModal } from 'src/shared/app/base/modal/slice/modalSlice';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import useShareToSocial, {
  facebook,
  linkedin,
  x,
} from 'src/shared/app/social/hooks/useShareToSocial';
import messages from 'src/shared/domain/topic/l10n/topicL10n';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import ConditionalWrapper from 'src/shared/ui/component/misc/ConditionalWrapper';

import styles from './SocialSharingModal.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  topicId: number,
  onAfterClose?: () => void,
};

const SocialSharingModal = ({ topicId, onAfterClose }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const isLarge = useMediaQuery(phablet);
  const mediaId = useId();

  const [isOpened, setIsOpened] = useState<boolean>(true);
  const { data: topic } = useGetTopicQuery(topicId);

  const { copyToClipboard, isCopied, isSupported } = useCopyToClipboard();

  const [shareToSocial, shareData, isLoading] = useShareToSocial(topic);
  const { url, image_url } = shareData || {};

  useEffect(() => () => clearTimeout(), []);

  const handleCopyLink = () => {
    if (!url) return;
    copyToClipboard(url);
  };

  const handleModalClose = () => {
    dispatch(clearModal());
    setIsOpened(false);
    if (onAfterClose) {
      onAfterClose();
    }
  };
  return (
    <Modal
      title={intl.formatMessage(messages.shareYourTopic)}
      width={isLarge ? 600 : '90vw'}
      isOpen={isOpened}
      handleClose={handleModalClose}
      onAfterClose={handleModalClose}
      hideActions
      content={
        <div className={classes.root}>
          <div className={classes.imageContainer}>
            {image_url ? (
              <img
                src={image_url}
                className={classes.image}
                alt={intl.formatMessage(messages.sharingImage)}
              />
            ) : null}

            {isLoading ? (
              <Skeleton
                classes={{ root: classes.image }}
                sx={{ width: styles.image.width, height: styles.image.height }}
                variant="rectangular"
              />
            ) : null}
          </div>

          <div className={classes.actions}>
            <PrimaryButton
              onClick={() => shareToSocial(linkedin)}
              layoutClass={classes.button}
              aria-label={intl.formatMessage(messages.postOnLinkedIn)}
            >
              <LinkedInIcon className={classes.icon} />
              {intl.formatMessage(messages.postOnLinkedIn)}
            </PrimaryButton>
          </div>

          <label id={mediaId} className={classes.shareTo}>
            {intl.formatMessage(messages.shareTo)}
          </label>

          <div className={classes.icons}>
            <IconButton
              onClick={() => shareToSocial(x)}
              size="small"
              classes={{ root: classes.iconButton }}
              disabled={isLoading}
              aria-label={intl.formatMessage(messages.X)}
              aria-describedby={mediaId}
            >
              <XIcon className={classNames(classes.icon, classes.x)} />
            </IconButton>

            <IconButton
              onClick={() => shareToSocial(facebook)}
              size="small"
              classes={{ root: classes.iconButton }}
              disabled={isLoading}
              aria-label={intl.formatMessage(messages.facebook)}
              aria-describedby={mediaId}
            >
              <FacebookIcon
                className={classNames(classes.icon, classes.facebook)}
              />
            </IconButton>

            {isSupported && (
              <ConditionalWrapper
                condition={!isLoading}
                wrapper={(child) => (
                  <Tooltip
                    title={intl.formatMessage(messages.copyLinkButton)}
                    placement="top"
                  >
                    {child}
                  </Tooltip>
                )}
              >
                <IconButton
                  onClick={handleCopyLink}
                  size="small"
                  aria-label={
                    isCopied
                      ? intl.formatMessage(messages.linkCopiedMessage)
                      : intl.formatMessage(messages.copyLinkButton)
                  }
                  aria-describedby={mediaId}
                  classes={{ root: classes.iconButton }}
                  disabled={isLoading}
                  aria-live="assertive"
                >
                  {isCopied ? <CheckIcon /> : <ContentCopyIcon />}
                </IconButton>
              </ConditionalWrapper>
            )}
          </div>
        </div>
      }
    />
  );
};

export default memo<Props>(SocialSharingModal);
