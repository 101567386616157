/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import BaseIcon from 'src/shared/ui/component/icon/BaseIcon';

import svg from './CheerInsightfulIcon.svg';
import styles from './IrlIcon.style';

type Props = {
  className?: string,
  title?: string,
};

const useStyles = createUseThemeStyles(styles);

const CheerInsightfulIcon = ({ className, title }: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */

  const classes = useStyles();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  return (
    <BaseIcon
      className={classNames(classes.root, className)}
      svg={svg}
      title={title}
    />
  );
};

export default memo<Props>(CheerInsightfulIcon);
