/* @flow */

import { em } from 'polished';

import { tablet } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

export default {
  root: {
    position: 'relative',
    padding: [20, 0],

    [tablet]: {
      padding: 0,
    },
  },
  details: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,

    '& svg': {
      width: 155,
    },
  },
  users: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  participants: {
    marginRight: 10,
  },
  names: {
    minWidth: 0,
    maxWidth: 150,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: em(14, baseFontSize),
    fontWeight: 600,
  },
  title: {
    textAlign: 'center',
    margin: '15px auto',
    maxWidth: 360,
    fontSize: em(16, baseFontSize),
  },
  description: {
    textAlign: 'center',
    padding: [0, 20],
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 3,
  },
  input: {
    width: '100%',
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  action: {
    gap: '5px',
  },
};
