(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "luxon", "./topicGetters", "../setting", "../../user/util", "./topicAssertions"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("luxon"), require("./topicGetters"), require("../setting"), require("../../user/util"), require("./topicAssertions"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.luxon, global.topicGetters, global.setting, global.util, global.topicAssertions);
    global.topicCheckers = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _luxon, _topicGetters, _setting, _util, _topicAssertions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasUserJoinedTopic = hasUserJoinedTopic;
  _exports.isFishbowlTopic = isFishbowlTopic;
  _exports.isGroupTopic = isGroupTopic;
  _exports.isInterestBasedBraindatingTopic = isInterestBasedBraindatingTopic;
  _exports.isOneOnOneTopic = isOneOnOneTopic;
  _exports.isSuperGroupTopic = isSuperGroupTopic;
  _exports.isTopicOver = isTopicOver;
  _exports.isUserTopicAuthor = isUserTopicAuthor;
  _exports.isUserTopicCohost = isUserTopicCohost;
  /**
   * Check if topic is a group topic
   * @param   {Topic} topic - Topic to check
   * @returns {boolean} True if it is
   */
  function isGroupTopic(topic) {
    return (0, _topicGetters.getTopicKind)(topic) === _setting.topicGroupKind || isFishbowlTopic(topic) || isSuperGroupTopic(topic);
  }

  /**
   * Check if topic is a one-on-one topic
   * @param   {Topic} topic - Topic to check
   * @returns {boolean} True if it is
   */
  function isOneOnOneTopic(topic) {
    return (0, _topicGetters.getTopicKind)(topic) === _setting.topicOneOnOneKind;
  }

  /**
   * Check if topic is a fishbowl topic
   * @param   {Topic} topic - Topic to check
   * @returns {boolean} True if it is
   */
  function isFishbowlTopic(topic) {
    return (0, _topicGetters.getTopicKind)(topic) === _setting.topicFishbowlKind;
  }

  /**
   * Check if topic is a supergroup topic
   * @param   {Topic} topic - Topic to check
   * @returns {boolean} True if it is
   */
  function isSuperGroupTopic(topic) {
    return (0, _topicGetters.getTopicKind)(topic) === _setting.topicSuperGroupKind;
  }

  /**
   * Check if topic is a connect on interest topic
   * @param {Topic} topic - Topic to check
   * @param {boolean} locale - Current locale
   * @returns {boolean} True if it is
   */
  function isInterestBasedBraindatingTopic(topic, locale) {
    (0, _topicAssertions.assertOneOnOneTopic)(topic);
    return !!(0, _topicGetters.getTopicBasedOnInterest)(topic, locale);
  }

  /**
   * Check if a user is the author of a topic
   * @param   {User} user - User to check against
   * @param   {Topic} topic - Topic to check
   * @returns {boolean} True if the user is
   */
  function isUserTopicAuthor(user, topic) {
    return (0, _util.getUserId)(user) === (0, _util.getUserId)((0, _topicGetters.getTopicAuthor)(topic));
  }

  /**
   * Check if a user has joined a topic
   * @param   {User} user - User to check against
   * @param   {Topic} topic - Topic to check
   * @returns {boolean} True if the user has joined the topic
   */
  function hasUserJoinedTopic(user, topic) {
    return topic.participants && topic.participants.some(function (participant) {
      return participant.id === user.id;
    });
  }

  /**
   * Check if a user is a cohost of a topic
   * @param   {User} user - User to check against
   * @param   {GroupTopic} topic - Topic to check
   * @returns {boolean} True if the user is
   */
  function isUserTopicCohost(user, topic) {
    var cohosts = (0, _topicGetters.getGroupTopicCohosts)(topic);
    return cohosts && cohosts.some(function (cohost) {
      return (0, _util.getUserId)(user) === (0, _util.getUserId)(cohost);
    });
  }

  /**
   * Check if a group topic is over
   * @param   {GroupTopic} topic - Topic to check
   * @returns {boolean} True if the user is
   */
  function isTopicOver(topic) {
    (0, _topicAssertions.assertTopic)(topic);
    var endTime = topic.date && (0, _topicGetters.getGroupTopicEndTime)(topic);
    return endTime &&
    // $FlowIssue
    _luxon.DateTime.fromISO(endTime)
    // $FlowIssue: FIXME
    .diffNow().valueOf() < 0;
  }
});