/* @flow */

import * as types from 'src/shared/app/perf/action/perfActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  server: {},
};

const _monitor = (state: Object, action: Object) => {
  const { server } = state;
  const { key, value } = action;

  return {
    ...state,
    server: {
      ...server,
      [key]: value,
    },
  };
};

export default createReducer(initialState, {
  [types.MONITOR_SERVER_METRIC]: _monitor,
});
