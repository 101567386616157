/* @flow */

import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',
    height: '100%',

    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.colorBase,
    fill: ({ theme }: ThemeFunction<{}>) => theme.textColor,
  },
  error: {
    marginBottom: rem(24, baseFontSize),

    WebkitFontSmoothing: 'antialiased',
  },
};
