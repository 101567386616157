/* eslint-disable jsx-a11y/no-autofocus */
/* @flow */

import { createRef, memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';
import { useIntl } from 'react-intl';

import messages from 'src/shared/app/base/l10n/uiL10n';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import CircledCrossIcon from 'src/shared/ui/component/icon/CircledCrossIcon';
import CrossIcon from 'src/shared/ui/component/icon/CrossIcon';

import styles from './CloseButton.style';

type Props = {
  small?: boolean,
  circled?: boolean,
  layoutClass?: string,
  forceBlur?: boolean,
  isTrimmed?: boolean,
  tabIndex: number,
  onClick: (MouseEvent) => void,
  ariaLabel?: string,
  autoFocus?: boolean,
} & HTMLButtonElement;

const useStyles = createUseThemeStyles(styles);

const CloseButton = ({
  small,
  circled,
  layoutClass,
  isTrimmed,
  onClick,
  forceBlur,
  ariaLabel,
  autoFocus,
  ...props
}: Props): Node => {
  const intl = useIntl();

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();

    onClick(e);

    if (forceBlur) {
      const { current: rootElt } = rootRef;

      if (rootElt) {
        rootElt.blur();
      }
    }
  };

  const classes = useStyles();

  const rootClasses = classNames(classes.root, layoutClass, {
    [classes['root-isTrimmed']]: isTrimmed,
  });

  const rootRef = createRef();

  const IconComponent = circled ? CircledCrossIcon : CrossIcon;

  return (
    <button
      {...props}
      className={rootClasses}
      onClick={handleClick}
      type="button"
      ref={rootRef}
      autoFocus={autoFocus}
      aria-label={ariaLabel || intl.formatMessage(messages.close)}
    >
      <IconComponent large={!small} />
    </button>
  );
};

export default memo(CloseButton);
