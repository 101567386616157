/* @flow */

import { em } from 'polished';

export default {
  root: {
    fontSize: em(16),
    width: em(37),
    height: em(40),
  },
};
