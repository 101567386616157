(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.locationEntityKey = _exports.locationContextVirtual = _exports.locationContextOnsite = void 0;
  /**
   * Entity key used by the location schema
   * @type {string}
   */
  var locationEntityKey = _exports.locationEntityKey = "locations";

  /**
   * Value of location context for "virtual" event
   * @type {string}
   */
  var locationContextVirtual = _exports.locationContextVirtual = "virtual";

  /**
   * Value of location context for "onsite" event
   * @type {string}
   */
  var locationContextOnsite = _exports.locationContextOnsite = "onsite";
});