(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "normalizr", "../setting"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("normalizr"), require("../setting"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.normalizr, global.setting);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _normalizr, _setting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eventsSchema = _exports.eventSchema = void 0;
  /**
   * Schema for a single event
   * @type {schema.Entity}
   */
  var eventSchema = _exports.eventSchema = new _normalizr.schema.Entity(_setting.eventEntityKey);

  /**
   * Schema for multiple events
   * @type {Array<schema.Entity>}
   */
  var eventsSchema = _exports.eventsSchema = [eventSchema];
});