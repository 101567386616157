/* @flow */

import { memo } from 'react';
import type { ComponentType, Node } from 'react';

import TemplatePortals from 'src/shared/app/base/component/template/component/TemplatePortals';

type Props = {
  Switch: ComponentType<any>,
};

const BlankTemplate = ({ Switch }: Props): Node => (
  <>
    <TemplatePortals />
    <Switch />
  </>
);

export default memo<Props>(BlankTemplate);
