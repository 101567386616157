/* @flow */

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export const defaultDisabledStyle = {
  opacity: 0.38,
};

export default {
  '*, *:before, *:after': {
    // Apply the same box-sizing value to all elements
    boxSizing: 'inherit',
  },
  '*:focus:not(.ignore-focus)': {
    outlineColor: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
  },
};
