/* @flow */

import { memo, useState } from 'react';
import type { Node } from 'react';

import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { SwitchTransition, Transition } from 'react-transition-group';

import type { ComplaintContentType } from '@braindate/domain/lib/complaint/type';

import { usePostComplaintMutation } from 'src/shared/app/base/api/endpoint/complaintsEndpoints';
import useIsErrorReportingEnabled from 'src/shared/app/base/hook/useIsErrorReportingEnabled';
import ResponsiveModal from 'src/shared/app/base/modal/component/ResponsiveModal';
import useErrorModal from 'src/shared/app/base/modal/hook/useErrorModal';
import { clearModal } from 'src/shared/app/base/modal/slice/modalSlice';
import ReportingForm from 'src/shared/components/domain/complaints/components/ReportingForm';
import ReportingModalWrapper from 'src/shared/components/domain/complaints/components/ReportingModalWrapper';
import ReportingSuccess from 'src/shared/components/domain/complaints/components/ReportingSuccess';
import isHTTP4xxError from 'src/shared/core/util/httpUtils';

export const recommendationModalId = 'recommendationModalId';

type Props = {
  contentType: ComplaintContentType,
  content: { id: number },
};

const ReportingModal = ({ contentType, content }: Props): Node => {
  const dispatch = useDispatch();
  const isErrorReportingEnabled = useIsErrorReportingEnabled();
  const triggerErrorModal = useErrorModal();

  const [isOpened, setIsOpened] = useState<boolean>(true);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [postComplaint] = usePostComplaintMutation();

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await postComplaint({
        content_type: contentType,
        object_id: content.id,
        ...values,
      });
      setShowSuccess(true);
    } catch (error) {
      if (isErrorReportingEnabled && !isHTTP4xxError(error)) {
        Sentry.captureException(error);
      }
      triggerErrorModal(error);
    } finally {
      setSubmitting(false);
    }
    setSubmitting(false);
  };

  return (
    <ResponsiveModal
      forceOpen={isOpened}
      onClose={() => dispatch(clearModal())}
      modalProps={{
        width: 620,
        hideActions: true,
      }}
    >
      <Formik
        initialValues={{
          reason: '',
          more: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <SwitchTransition mode="out-in">
            <Transition key={showSuccess} timeout={250}>
              {(state) =>
                showSuccess ? (
                  <ReportingModalWrapper transitionState={state}>
                    <ReportingSuccess
                      contentType={contentType}
                      onClose={() => setIsOpened(false)}
                    />
                  </ReportingModalWrapper>
                ) : (
                  <ReportingModalWrapper transitionState={state}>
                    <ReportingForm
                      contentType={contentType}
                      isSubmitting={isSubmitting}
                    />
                  </ReportingModalWrapper>
                )
              }
            </Transition>
          </SwitchTransition>
        )}
      </Formik>
    </ResponsiveModal>
  );
};

export default memo<Props>(ReportingModal);
