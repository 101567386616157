/* @flow */
import { em } from 'polished';

export default {
  root: {
    fontSize: em(17),
    width: 17,
    height: 17,
  },
};
