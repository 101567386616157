/* @flow */
import { useMemo } from 'react';

import { FocusTrap } from '@mui/base';

import { portalTakeOverId } from 'src/shared/app/base/constant/domConstants';
import useModalStatusContext from 'src/shared/app/base/modal/hook/useModalStatusContext';
import usePortalRegistrationLength from 'src/shared/app/base/portal/notification/hook/usePortalRegistrationLength';
import NotificationPortal from 'src/shared/app/notification/component/NotificationPortal';
import TakeOverPortal from 'src/shared/app/notification/component/TakeOverPortal';

const TemplatePortals = () => {
  const takeOverLength = usePortalRegistrationLength(portalTakeOverId);

  const { modals: openedModals } = useModalStatusContext();
  const hasModals = openedModals.size > 0;

  const shouldTrapFocus = useMemo(() => {
    if (takeOverLength) {
      if (hasModals) return false;
      return true;
    }

    return false;
  }, [hasModals, takeOverLength]);

  return (
    <FocusTrap open={shouldTrapFocus}>
      <div>
        <NotificationPortal />
        <TakeOverPortal />
      </div>
    </FocusTrap>
  );
};

export default TemplatePortals;
