/* @flow */

import { useDispatch } from 'react-redux';

import useSidebar from 'src/shared/app/base/component/template/hooks/useSidebar';
import useOnboardingRedirection from 'src/shared/app/base/route/hook/useOnboardingRedirection';
import useTogethernessRedirection from 'src/shared/app/base/route/hook/useTogethernessRedirection';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';

import useVirtualBraindate from 'plugin/virtual-braindate/hook/useVirtualBraindate';

export default function useRouteConfig(): Object {
  const { areEmailNotificationsEnabled, isSignupEnabled, isSsoFeatureEnabled } =
    useFeatureDecisions();
  const dispatch = useDispatch();
  const isVirtualBraindateEnabled = useVirtualBraindate();
  const { shouldRedirectToTogetherness } = useTogethernessRedirection();
  const { shouldRedirectToOnboarding, redirectToOnboardingURL } =
    useOnboardingRedirection();
  const { hasSidebar } = useSidebar();

  return {
    dispatch,
    areEmailNotificationsEnabled,
    isSignupEnabled,
    isVirtualBraindateEnabled,
    hasSidebar,
    isSsoFeatureEnabled,
    shouldRedirectToTogetherness,
    redirectToOnboardingURL,
    shouldRedirectToOnboarding,
  };
}
