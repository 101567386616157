/* @flow */
import { tablet } from '@braindate/ui/lib/token/mediaQuery';

export default {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  avatar: {
    display: 'flex',

    [tablet]: {
      display: 'none',
    },
  },
};
