/* @flow */

import { useEffect } from 'react';

import { useIntl } from 'react-intl';

import { getMembershipTimeFormat } from '@braindate/domain/lib/membership/util';

import useAuthenticationToken from 'src/shared/app/authentication/hook/useAuthenticationToken';
import { usePatchMembershipMutation } from 'src/shared/app/base/api/endpoint/membershipEndpoint';
import { getLocaleTimeFormat } from 'src/shared/app/locale/util/localeUtils';
import useMembership from 'src/shared/domain/membership/hook/useMembership';

export default function useTimeFormatPatch() {
  const token = useAuthenticationToken();
  const { locale } = useIntl();
  const membership = useMembership();
  const membershipTimeFormat = membership
    ? getMembershipTimeFormat(membership)
    : '';

  const [patchMembership] = usePatchMembershipMutation();

  useEffect(() => {
    if (token && !membershipTimeFormat) {
      patchMembership({
        preferences: {
          time_format: getLocaleTimeFormat(locale),
        },
      });
    }
  }, [token, membershipTimeFormat]);
}
