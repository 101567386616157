/* @flow */

import { memo, useState } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';

import messages from 'src/shared/app/base/l10n/errorL10n';
import Modal from 'src/shared/app/base/modal/component/Modal';
import useClearModalDispatch from 'src/shared/app/base/modal/hook/useClearModalDispatch';

type Props = {
  title?: string,
  message: string,
  onClose?: () => void,
};

const ErrorModal = ({ title, message, onClose }: Props): Node => {
  const intl = useIntl();
  const dispatchClearModal = useClearModalDispatch();

  const [isShown, setIsShown] = useState<boolean>(true);

  const handleClose = () => {
    dispatchClearModal();
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isShown}
      title={title || intl.formatMessage(messages.genericError)}
      message={message}
      handleClose={() => setIsShown(false)}
      onAfterClose={handleClose}
      isAnimated
    />
  );
};

export default memo<Props>(ErrorModal);
