/* @flow */
import { lap } from '@braindate/ui/lib/token/mediaQuery';

import { takeOverElevation } from 'src/shared/app/base/style/token/elevation';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

import {
  templateAside,
  templateContainer,
  templateContent,
  templateContentInner,
  templateMain,
  templateRoot,
} from './style/template.style';

export default {
  root: {
    extend: templateRoot,
    padding: 0,
  },

  main: {
    extend: templateMain,
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  aside: {
    extend: templateAside,
  },

  content: {
    extend: templateContent,
    [lap]: {
      paddingBottom: 0,
    },
  },
  takeOverContainer: {
    zIndex: takeOverElevation,
  },
  contentInner: {
    extend: templateContentInner,
    width: '100%',
    maxWidth: ({
      contentMaxWidth,
    }: ThemeFunction<{ contentMaxWidth?: number }>) => contentMaxWidth,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  container: {
    extend: templateContainer,
  },
};
