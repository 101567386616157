/* @flow */

const testAtLeastChars = (value: string) => {
  return value.length >= 8;
};

const testUpperAndLower = (value: string) => {
  return /[a-z]/.test(value) && /[A-Z]/.test(value);
};

const testOneNumber = (value: string) => {
  return /[0-9]/.test(value);
};

const testSpecialChar = (value: string) => {
  return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value);
};

export const isPasswordValid = (value: string) => {
  return [
    testAtLeastChars,
    testUpperAndLower,
    testOneNumber,
    testSpecialChar,
  ].every((fnc) => fnc(value));
};

export const validatePasswordRules = (value: string) => {
  return {
    atLeastChars: testAtLeastChars(value),
    upperAndLower: testUpperAndLower(value),
    oneNumber: testOneNumber(value),
    specialChar: testSpecialChar(value),
  };
};
