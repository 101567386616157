(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeFormat24h = _exports.timeFormat12h = _exports.defaultLocalizableKey = _exports.defaultLocale = void 0;
  /**
   * Default locale in which to get localized data
   * @type {string}
   */
  var defaultLocale = _exports.defaultLocale = "en";

  /**
   * Default key of the attribute in which to look for localizable data
   * @type {string}
   */
  var defaultLocalizableKey = _exports.defaultLocalizableKey = "body";

  /**
   * Value of the 12-hour format
   * @type {string}
   */
  var timeFormat12h = _exports.timeFormat12h = "12h";

  /**
   * Value of the 24-hour format
   * @type {string}
   */
  var timeFormat24h = _exports.timeFormat24h = "24h";
});