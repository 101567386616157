/* @flow */
import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  message: {
    width: '100%',
    maxWidth: 410,
    marginTop: 0,
    textAlign: 'center',
    padding: 10,
  },
  title: ({ theme, withLargeTitle }: Object) =>
    withLargeTitle
      ? {
          fontSize: rem(30, baseFontSize),
          fontWeight: 600,
          color: theme.accentColor,
          marginTop: 0,
          textAlign: 'center',
        }
      : {
          fontSize: rem(18, baseFontSize),
          fontWeight: 700,
          color: theme.accentColor,
          marginTop: '0.3em',
        },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '8px',
    marginTop: 8,
  },
  closeCtn: {
    position: 'absolute',
    top: 3,
    right: 3,
    zIndex: 1,
  },
  content: {
    width: '100%',
    fontSize: rem(16, baseFontSize),
    fontWeight: 400,
    lineHeight: 1.2,
    textAlign: 'center',
    maxHeight: '85vh',

    '& p': {
      marginBottom: '1.2em',
    },
  },
  icon: {
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    fontSize: '3em',
  },
};
