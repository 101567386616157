/* @flow */

import type { BraindateEvent } from '@braindate/domain/lib/event/type';
import { getEventPlugins } from '@braindate/domain/lib/event/util';
import { assertObject } from '@braindate/util/lib/assert';

import { signupPlugin } from 'src/shared/app/feature/settings/featureSettings';

export function getSignupOptions(event: BraindateEvent): ?Object {
  const options = getEventPlugins(event)[signupPlugin];

  if (options) {
    assertObject(options, 'options');

    return options;
  }
}

export function isLoginShownBeforeSignup(event: BraindateEvent): boolean {
  return !!(getSignupOptions(event) || {}).show_login_first;
}

export function getCommunicationOptin(event: BraindateEvent): boolean {
  const { communication_optin: communicationOptin } =
    getSignupOptions(event) || {};

  return !!communicationOptin;
}
