/* @flow */

import { combineReducers } from 'redux';

import publicReducer from 'src/shared/domain/event/reducer/publicReducer';

import entitiesReducer from './entitiesReducer';

// $FlowIssue
const domainReducer = combineReducers({
  entities: entitiesReducer,
  public: publicReducer,
});

export default domainReducer;
