/* @flow */

import { lap } from '@braindate/ui/lib/token/mediaQuery';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

import { getReadableColor } from 'plugin/light/util/lightUtils';

export default {
  card: {
    width: '100%',
    minHeight: 188,
    maxWidth: 867,
  },
  cardContent: {
    border: ({ theme }: ThemeFunction<{}>) =>
      `1px solid ${theme.braindateCardTimeInactive}`,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 188,

    [lap]: {
      flexDirection: 'row',
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    padding: [24, 13],
    minWidth: 0,

    [lap]: {
      padding: [24, 24, 22, 24],
    },
  },
  buttonMenu: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: ({ theme }: ThemeFunction<{}>) =>
      getReadableColor(theme.accentColor),
  },
};
