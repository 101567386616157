/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import type { Location } from '@braindate/domain/lib/location/type';
import {
  getLocationBraindateMeetingPoint,
  getLocationLabel,
  isVirtualLocation,
} from '@braindate/domain/lib/location/util';
import type { TopicKind } from '@braindate/domain/lib/topic/type';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/domain/location/l10n/locationL10n';

import styles from './LocationsInfo.style';

type Props = {
  location: Location,
  className?: string,
  withAccent?: boolean,
  withIcon?: boolean,
  kind?: ?TopicKind,
  showMeetingPoint?: boolean,
  isBlock?: boolean,
  isCompactForMobile?: boolean,
};

const useStyles = createUseThemeStyles(styles);

const LocationsInfo = ({
  location,
  className,
  withAccent = false,
  withIcon = true,
  kind,
  showMeetingPoint = false,
  isBlock = false,
  isCompactForMobile = false,
}: Props): Node => {
  const classes = useStyles({
    withAccent,
    isBlock: isBlock && !isVirtualLocation(location),
    isCompactForMobile,
  });
  const intl = useIntl();

  const getPodName = () => {
    if (!showMeetingPoint) return null;
    if (isVirtualLocation(location)) return null;
    if (!kind) return null;

    return getLocationBraindateMeetingPoint(location, kind);
  };

  const getText = () => {
    if (!location) return;
    if (isVirtualLocation(location)) {
      return intl.formatMessage(messages.locationInfoVirtual);
    }

    const podName = getPodName();
    const locationName = getLocationLabel(location);

    return podName ? `${podName} — ${locationName}` : locationName;
  };

  const text = getText();

  return (
    <div className={classnames([className, classes.root])}>
      {withIcon && <LocationOnOutlinedIcon classes={{ root: classes.icon }} />}
      <div className={classes.text}>{text}</div>
    </div>
  );
};

export default memo<Props>(LocationsInfo);
