/* @flow */
import { em } from 'polished';

export default {
  root: {
    width: em(15),
    height: em(15),

    transformOrigin: 'center center',
    display: 'block',
    transform: 'rotate(90deg)',
  },
  'root-right': {
    transform: 'rotate(-90deg)',
  },
  'root-up': {
    transform: 'rotate(180deg)',
  },
  'root-down': {
    transform: 'rotate(0)',
  },
};
