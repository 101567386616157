/* @flow */

import { em } from 'polished';

export default {
  root: {
    fontSize: em(22),
    width: em(16),
    height: em(16),
    verticalAlign: 'middle',
    fill: 'currentColor',
    stroke: 'none',
  },
};
