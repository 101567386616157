/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  isFishbowlTopic,
  isGroupTopic,
} from '@braindate/domain/lib/topic/util';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import CheckInActionButton from 'src/shared/app/check-in/component/notification/CheckInActionButton';
import messages from 'src/shared/app/check-in/l10n/checkInTakeOverL10n';
import { getBraindateTopic } from 'src/shared/domain/braindate/util/braindateUtil';

import styles from './CheckInTakeOver.style';

type Props = {
  braindate: Braindate,
  onClick?: () => void,
};

const useStyles = createUseThemeStyles(styles);

const CheckInTakeOverVirtualAction = ({ braindate, onClick }: Props): Node => {
  const intl = useIntl();
  const topic = getBraindateTopic(braindate);
  const isGroup = isGroupTopic(topic);
  const isFishbowl = isFishbowlTopic(topic);

  const classes = useStyles();

  return (
    <div className={classes.meeting}>
      <p className={classes.instructions}>
        {isFishbowl
          ? intl.formatMessage(messages.takeOverFishbowlCTA)
          : intl.formatMessage(messages.takeOverCTA, {
              isGroupBraindate: isGroup,
            })}
      </p>
      <CheckInActionButton braindate={braindate} onClick={onClick} />
    </div>
  );
};

export default memo<Props>(CheckInTakeOverVirtualAction);
