/* @flow */

import { Fragment, memo } from 'react';
import type { Node } from 'react';

import { Meta, Title } from 'react-head';
import { useIntl } from 'react-intl';

import { areTitleWordsCapitalized } from 'src/shared/app/locale/settings/localeSettings';
import { capitalize, capitalizeWords } from 'src/shared/core/util/stringUtil';

type Props = {
  children?: string,
};

const DocumentTitle = ({ children }: Props): Node => {
  const { locale } = useIntl();

  const pageText =
    children &&
    (areTitleWordsCapitalized(locale)
      ? capitalizeWords(children)
      : capitalize(children));
  // Don'T compute this in JSX because SSR adds weird tags
  const text = `${pageText ? `${pageText} - ` : ''}Braindate`;

  return (
    <>
      <Title>{text}</Title>
      <Meta name="og:title" content={text} />
      <Meta name="twitter:title" content={text} />
    </>
  );
};

export default memo<Props>(DocumentTitle);
