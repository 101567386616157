/* @flow */

import { useState } from 'react';

import * as Sentry from '@sentry/react';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { SwitchTransition, Transition } from 'react-transition-group';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { getBraindateId } from '@braindate/domain/lib/braindate/util';
import type { Cheer } from '@braindate/domain/lib/cheer/type';
import { getCheerId } from '@braindate/domain/lib/cheer/util/cheerGetters';
import type { User } from '@braindate/domain/lib/user/type';

import { useSendCheerMutation } from 'src/shared/app/base/api/endpoint/cheersEndpoint';
import useIsErrorReportingEnabled from 'src/shared/app/base/hook/useIsErrorReportingEnabled';
import useErrorModal from 'src/shared/app/base/modal/hook/useErrorModal';
import CheerInfos from 'src/shared/app/cheer/component/modal/new/CheerInfos';
import CheerSuggestions from 'src/shared/app/cheer/component/modal/new/CheerSuggestions';
import { newCheerSuccess } from 'src/shared/app/cheer/reducer/cheerSlice';
import {
  getCheerFormSchema,
  showCheerSuccessNotification,
} from 'src/shared/app/cheer/util/cheerUtils';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import { getNotificationData } from 'src/shared/app/notification/util/notificationUtil';

type Props = {
  notification: Notification<{
    user: User,
    braindate: Braindate,
  }>,
  handleClose: () => void,
};

const NewCheerForm = ({ notification, handleClose }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { user, braindate } = getNotificationData(notification);

  const [sendCheers, { isLoading }] = useSendCheerMutation();

  const isErrorReportingEnabled = useIsErrorReportingEnabled();
  const triggerErrorModal = useErrorModal();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const onSubmit = async (values: Cheer) => {
    const { braindate: braindateToCheerFor } = values;

    try {
      // $FlowIssue sendcheers return type is wrong, todo: Change this to the value when other PR is merged
      const { data: newCheer, error } = await sendCheers(values);
      if (error) throw error;

      handleClose();
      dispatch(showCheerSuccessNotification(getCheerId(newCheer)));
      dispatch(newCheerSuccess(getBraindateId(braindateToCheerFor)));
    } catch (error) {
      if (isErrorReportingEnabled && !error.status) {
        const errorId = Sentry.captureException(error);
        return triggerErrorModal(errorId, undefined, true);
      }
      triggerErrorModal(error);
    }
  };

  return (
    <Formik
      initialValues={{
        category: '',
        braindate: braindate || null,
        recipient: user,
        message: '',
        send_gift: false,
      }}
      validationSchema={getCheerFormSchema(intl)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form noValidate>
          <SwitchTransition mode="out-in">
            <Transition key={currentStep} timeout={250}>
              {(state) =>
                [
                  <CheerSuggestions
                    transitionState={state}
                    onNext={handleNext}
                    key={0}
                  />,
                  <CheerInfos
                    transitionState={state}
                    defaultBraindate={braindate}
                    isPosting={isLoading}
                    onSubmit={handleSubmit}
                    key={1}
                  />,
                ][currentStep]
              }
            </Transition>
          </SwitchTransition>
        </Form>
      )}
    </Formik>
  );
};

export default NewCheerForm;
