/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';
import { useIntl } from 'react-intl';

import type { User } from '@braindate/domain/lib/user/type';
import {
  getUserCompany,
  getUserFullName,
  getUserPosition,
} from '@braindate/domain/lib/user/util';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import UserAvatar, {
  medium as avatarMedium,
  small as avatarSmall,
} from 'src/shared/components/domain/user/avatar/UserAvatar';
import messages from 'src/shared/domain/user/l10n/userL10n';

import styles from './UserBanner.style';

type Props = {
  user: User,
  variant?: typeof small,
  withIcon?: boolean,
};

const useStyles = createUseThemeStyles(styles);

const UserBanner = ({ user, variant, withIcon }: Props): Node => {
  const intl = useIntl();
  const name = getUserFullName(user);
  const position = getUserPosition(user, intl.locale);
  const company = getUserCompany(user, intl.locale);
  const title =
    position && company
      ? intl.formatMessage(messages.fullPosition, {
          position,
          company,
        })
      : position || company;

  const classes = useStyles();

  const textClasses = classNames(classes.text, {
    [`${classes['text-withIcon']}`]: withIcon,
  });

  return (
    <div className={classes.root}>
      <UserAvatar
        user={user}
        variant={variant === small ? avatarSmall : avatarMedium}
        showOnlineBadge
      />
      <div className={textClasses}>
        <span className={classes.name}>{name}</span>
        <span className={classes.title}>{title}</span>
      </div>
    </div>
  );
};

export const small = 'small';

export default memo<Props>(UserBanner);
