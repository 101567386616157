/* @flow */

export const SET_QUICK_ACTION_NAME = 'SET_QUICK_ACTION_NAME';
export const UNSET_QUICK_ACTION_NAME = 'UNSET_QUICK_ACTION_NAME';

export const SET_QUICK_ACTION_DATA = 'SET_QUICK_ACTION_DATA';
export const UNSET_QUICK_ACTION_DATA = 'UNSET_QUICK_ACTION_DATA';
export const SET_QUICK_ACTION_BRAINDATE_ID = 'SET_QUICK_ACTION_BRAINDATE_ID';
export const UNSET_QUICK_ACTION_BRAINDATE_ID =
  'UNSET_QUICK_ACTION_BRAINDATE_ID';

export const SET_QUICK_ACTION_LOADING = 'SET_QUICK_ACTION_LOADING';
export const RESET_QUICK_ACTION_LOADING = 'RESET_QUICK_ACTION_LOADING';
