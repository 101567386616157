/* @flow */

import { em } from 'polished';

export default {
  root: {
    fontSize: em(14),
    width: em(16),
    height: em(16),
    verticalAlign: 'middle',
  },
};
