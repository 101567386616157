/* @flow */

import { DateTime, Interval, Settings } from 'luxon';
import { sort } from 'ramda';

import type { TimeSlot } from '@braindate/domain/lib/base/type';
import type { Membership } from '@braindate/domain/lib/membership/type';
import { getMembershipTimeFormat } from '@braindate/domain/lib/membership/util';
import {
  assert,
  assertArray,
  assertNotEmptyString,
  assertNumber,
  assertObject,
  assertValidDateTime,
  assertValidInterval,
} from '@braindate/util/lib/assert';

import { getLocaleTimeFormat } from 'src/shared/app/locale/util/localeUtils';

/**
 * Create an instance of Interval from an object with `start_time` and
 * `end_time` attributes. If the argument is an instance of Interval, it is
 * returned as is.
 * @param  {Object} interval - Object to create an interval from
 * @throws {TypeError} `interval` must be an object
 * @throws {TypeError} `interval.start_time` must be an non empty string
 * @throws {TypeError} `interval.end_time` must be an non empty string
 * @throws {Error} `startDateTime` must be a valid instance of DateTime
 * @throws {Error} `endDateTime` must be a valid instance of DateTime
 * @return {Interval} Interval
 */
export function createInterval(interval: Object): Interval {
  assertObject(interval, 'interval');

  if (interval instanceof Interval) {
    return interval;
  }

  const { start_time: start, end_time: end } = interval;

  if (start instanceof DateTime && end instanceof DateTime) {
    return createIntervalFromDateTimes(interval);
  }

  assertNotEmptyString(start, 'interval.start_time');
  assertNotEmptyString(end, 'interval.end_time');

  const startDateTime = DateTime.fromISO(start, {
    zone: Settings.defaultZone,
  });
  const endDateTime = DateTime.fromISO(end, { zone: Settings.defaultZone });

  assertValidDateTime(startDateTime, 'startDateTime');
  assertValidDateTime(endDateTime, 'endDateTime');

  return Interval.fromDateTimes(startDateTime, endDateTime);
}

/**
 * Create an instance of Interval from an object with `start_time` and
 * `end_time` attributes. If the argument is an instance of Interval, it is
 * returned as is.
 * @param  {Object} interval - Object to create an interval from
 * @throws {TypeError} `interval` must be an object
 * @throws {Error} `startDateTime` must be a valid instance of DateTime
 * @throws {Error} `endDateTime` must be a valid instance of DateTime
 * @return {Interval} Interval
 */
export function createIntervalFromDateTimes(interval: Object): Interval {
  assertObject(interval, 'interval');

  if (interval instanceof Interval) {
    return interval;
  }

  const { start_time: start, end_time: end } = interval;

  assertValidDateTime(start, 'start');
  assertValidDateTime(end, 'end');

  return Interval.fromDateTimes(start, end);
}

export function createTimeSlotFromInterval(interval: Interval): TimeSlot {
  assertValidInterval(interval, 'interval');

  const { start, end } = interval;

  return {
    start_time: start.toISO(),
    end_time: end.toISO(),
  };
}

/**
 * Create instances of Interval from objects, with the `createInterval` function
 * @param  {Array<Object>} intervals - Array of objects to create intervals from
 * @throws {TypeError} `intervals` must be an array
 * @return {Array<Interval>} Interval
 */
export function createIntervals(intervals: Array<Object>): Array<Interval> {
  assertArray(intervals, 'intervals');

  return intervals.map(createInterval);
}

/**
 * Return the unique start dates (with the `YYYY-MM-DD` format) of the
 * specified intervals, sorted chronologically
 * @param  {Array<Interval>} intervals - Intervals to get the start dates from
 * @throws {TypeError} `intervals` must be an array
 * @return {Array<string>} Dates
 */
export function getDatesFromIntervals(
  intervals: Array<Interval>,
): Array<DateTime> {
  assertArray(intervals, 'intervals');

  const dates = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const interval of intervals) {
    assertValidInterval(interval, 'interval');

    const { start, end } = interval;

    let dayCount = interval.count('day');

    // If end of the interval is the following day at midnight, don't count
    // the following day as it is empty
    dayCount = end.equals(start.plus({ day: 1 }).startOf('day'))
      ? dayCount - 1
      : dayCount;

    for (let i = 0; i < dayCount; i += 1) {
      const newDate = start.plus({ days: i }).startOf('day');

      if (!dates.find((date) => date.equals(newDate))) {
        dates.push(newDate);
      }
    }
  }

  return dates;
}

export function convertToDateTime(date: string | Date | DateTime): DateTime {
  assert(date, 'date');

  if (date instanceof Date) {
    return convertJSDateToDateTime(date);
  }
  if (date instanceof DateTime) {
    return date;
  }
  if (Number.isInteger(date)) {
    return DateTime.fromMillis(date, { zone: Settings.defaultZone });
  }
  if (typeof date === 'string') {
    return DateTime.fromISO(date, { zone: Settings.defaultZone });
  }

  throw new Error(
    `date can't be converted to an instance of DateTime. It's neither an instance of Date or DateTime nor a string (${typeof date} instead).`,
  );
}

/**
 * Return a JS date with the default timezone set on DateTime
 * A JS date always contain the browser local and we need to create the Datetime without the timzone information.
 * @param {Date} date - JS Date
 * @return {DateTime} - DateTime with right timezone
 */
export function convertJSDateToDateTime(date: Date): DateTime {
  return DateTime.fromObject({
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds(),
  });
}

export function convertToDate(date: any): ?Date {
  assert(date, 'date');

  if (date instanceof Date) {
    return date;
  }
  const dateTime = convertToDateTime(date);

  if (dateTime.isValid) {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateWithLocalTimezone = dateTime.setZone(localTimezone);

    const offsetInMinutes = dateTime.offset - dateWithLocalTimezone.offset;

    return dateTime.plus({ minutes: offsetInMinutes }).toJSDate();
  }
}

export function forceLeadingZero(value: number | string): string {
  const castedValue = Number(value);

  return Number.isInteger(castedValue) && castedValue > 9
    ? String(castedValue)
    : `0${castedValue}`;
}

export function getTimeFormat(membership: Membership, locale: string) {
  return getMembershipTimeFormat(membership) || getLocaleTimeFormat(locale);
}

export function printTime(
  hours: number | string,
  minutes: number | string,
  period?: string,
): string {
  const castedHours = Number(hours);
  const castedMinutes = Number(minutes);

  assertNumber(castedHours, 'castedHours');
  assertNumber(castedMinutes, 'castedMinutes');

  let h = castedHours;

  if (period && period.toLowerCase() === 'pm') {
    if (castedHours < 12) {
      h = castedHours + 12;
    }
  } else if (period && period.toLowerCase() === 'am') {
    if (castedHours === 12) {
      h = 0;
    }
  }

  const dateTime = DateTime.fromObject({
    hours: h,
    minutes: castedMinutes,
  });

  return dateTime.toFormat('HH:mm');
}

export function isDateToday(date: any): boolean {
  return DateTime.local().hasSame(convertToDateTime(date), 'day');
}

export function isDateYesterday(date: any): boolean {
  const otherDay = DateTime.fromISO(convertToDateTime(date));
  const difference = otherDay.diffNow('day');

  return Math.round(difference.days) === -1;
}

export function isDateInInterval(
  date: string | Date | DateTime,
  interval: { start_time: string, end_time: string } | Object | Interval,
): boolean {
  const dateTime = convertToDateTime(date);
  const intervals = createInterval(interval);

  return intervals.contains(dateTime);
}

export function isIntervalSameAs(
  interval: Interval,
  intervals: Array<Interval>,
) {
  assertValidInterval(interval, 'interval');
  assertArray(intervals, 'intervals');

  return intervals.some((i) => {
    assertValidInterval(i, 'i');

    return i.equals(interval);
  });
}

export function isIntervalEngulfed(
  interval: Interval,
  intervals: Array<Interval>,
): boolean {
  assertValidInterval(interval, 'interval');
  assertArray(intervals, 'intervals');

  return intervals.some((i) => {
    assertValidInterval(i, 'i');

    return i.engulfs(interval);
  });
}

export function addIntervalToUniqueArray(
  interval: Interval,
  intervals: Array<Interval>,
): Array<Interval> {
  assertValidInterval(interval, 'interval');
  assertArray(intervals, 'intervals');

  if (isIntervalSameAs(interval, intervals)) {
    return [...intervals];
  }
  return [...intervals, interval];
}

export function removeIntervalFromArray(
  interval: Interval,
  intervals: Array<Interval>,
): Array<Interval> {
  assertValidInterval(interval, 'interval');
  assertArray(intervals, 'intervals');

  const index = intervals.findIndex((i) => {
    assertValidInterval(i, 'i');

    return interval.equals(i);
  });

  if (index === -1) {
    return [...intervals];
  }
  return [...intervals.slice(0, index), ...intervals.slice(index + 1)];
}

/**
 * Exclude past dates. If `includeToday` is true, include today's date even
 * if it has passed.
 * @param  {Array<DateTime>} dateTimes - Dates to filter
 * @param  {Boolean} includeToday - If true, include today's date
 * @throws {TypeError} `dateTimes` must be an array
 * @throws {TypeError} `dateTime` must be a valid instance of DateTime
 * @return {Array<DateTime>} Filtered dates
 */
export function filterByFutureDateTime(
  dateTimes: Array<DateTime>,
  includeToday?: boolean = false,
): Array<DateTime> {
  assertArray(dateTimes, 'dateTimes');

  const now = DateTime.local();

  let today;

  if (includeToday) {
    today = now.startOf('day');
  }

  return dateTimes.filter((dateTime) => {
    assertValidDateTime(dateTime, 'dateTime');

    return (today || now).ts <= dateTime.ts;
  });
}

/**
 * Sort specified intervals chronologically by their start time. Array specified
 * as parameter is not mutated.
 * @param  {Array<Interval>} intervals - Intervals to sort
 * @throws {TypeError} `intervals` must be an array
 * @throws {Error} `interval` must be a valid instance of Interval
 * @return {Array<Interval>} Sorted intervals
 */
export function sortIntervals(intervals: Array<Interval>): Array<Interval> {
  assertArray(intervals, 'intervals');

  return [...intervals].sort((intervalA, intervalB) => {
    assertValidInterval(intervalA, 'interval');
    assertValidInterval(intervalB, 'interval');

    return intervalA.start.ts - intervalB.start.ts;
  });
}

/**
 * Checks if the two sent dates are the same
 * @param {string | Date | DateTime} date1 First date to check
 * @param {string | Date | DateTime} date2 Second date to check
 * @returns {boolean} True is days are the same
 */
export function isSameDay(
  date1: string | Date | DateTime,
  date2: string | Date | DateTime,
) {
  const day1 = convertToDateTime(date1);
  const day2 = convertToDateTime(date2);
  return day1.startOf('day').toMillis() === day2.startOf('day').toMillis();
}

/**
 * Checks if the two sent dates are the same
 * @param {string | Date | DateTime} date1 First date to check
 * @param {string | Date | DateTime} date2 Second date to check
 * @returns {boolean} True is days are the same
 */
export function isSameDate(
  date1: string | Date | DateTime,
  date2: string | Date | DateTime,
) {
  return (
    convertToDateTime(date1).toMillis() === convertToDateTime(date2).toMillis()
  );
}

/**
 * Gets the minimum date in an array
 * @param {DateTime[]} dates Dates to check
 * @returns {DateTime | null} Returns the minimum date
 */
export function getMinDate(dates: DateTime[]): DateTime {
  if (!dates.length) return null;
  const sorted = sort(
    (a, b) => a - b,
    dates.map((date) => date.toMillis()),
  );
  return convertToDateTime(sorted[0]);
}

/**
 * Gets the maximum date in an array
 * @param {DateTime[]} dates Dates to check
 * @returns  {DateTime | null} Returns the maximum date
 */
export function getMaxDate(dates: DateTime[]): DateTime {
  if (!dates.length) return null;
  const sorted = sort(
    (a, b) => b - a,
    dates.map((date) => date.toMillis()),
  );
  return convertToDateTime(sorted[0]);
}
