/* @flow */

import { useEffect } from 'react';

export default function useJSClass() {
  useEffect(() => {
    const htmlElt = document.documentElement;

    if (htmlElt) {
      htmlElt.classList.add('js');
    }
  }, []);
}
