/* @flow */

import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'inline-block',

    padding: '6px 16px',

    color: ({ theme }: ThemeFunction<{}>) => theme.secondaryTagTextColor,
    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.secondaryTagColor,
    borderRadius: ({ theme }: ThemeFunction<{}>) => theme.buttonBorderRadius,

    textTransform: 'uppercase',
    fontSize: rem(11, baseFontSize),
    fontWeight: 'bold',
    lineHeight: 15 / 13,
    WebkitFontSmoothing: 'antialiased',
    alignSelf: 'center',
  },
  postfix: {
    textTransform: 'none',
    fontWeight: '400',
    marginLeft: '0.3em',
  },
};
