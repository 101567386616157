/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { lap } from '@braindate/ui/lib/token/mediaQuery';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import {
  resetMarketQuery,
  setMarketQuery,
  setMarketQueryContext,
} from 'src/shared/app/market/action/marketActions';
import { keywordContext } from 'src/shared/app/market/constant/marketConstants';
import messages from 'src/shared/app/market/l10n/marketL10n';
import { getMarketQuery } from 'src/shared/app/market/selector/marketSearchQuerySelectors';
import { trackGAEvent } from 'src/shared/core/service/tracking/gaService';
import useTopicsForMarket from 'src/shared/domain/topic/topic/useTopicsForMarket';
import TextButton from 'src/shared/ui/component/button/TextButton';
import SearchInput from 'src/shared/ui/component/form/SearchInput';

import styles from './MarketQueryForm.style';

export const marketQueryFormId = 'market-query-form-id';

const useStyles = createUseThemeStyles(styles);

type Props = {
  id: string,
  isOpened?: boolean,
  onClose?: () => void,
};

const MarketQueryForm = ({ id, isOpened = false, onClose }: Props): Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const query = useSelector(getMarketQuery);
  const { data, isFetching } = useTopicsForMarket();
  const topicsCount = data?.count || 0;

  const isDesktop = useMediaQuery(lap);
  const classes = useStyles({ isOpened });

  const handleSubmit = (newQuery) => {
    trackGAEvent('Market search', 'Submit search input', newQuery);

    dispatch(setMarketQuery(newQuery.trim(), location, navigate));
    dispatch(setMarketQueryContext(keywordContext));
  };

  const handleDismiss = () => {
    dispatch(resetMarketQuery(location, navigate));
  };

  const tabIndex = isDesktop || isOpened ? 0 : -1;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <SearchInput
          id={`query-${id}`}
          name={`query-${id}`}
          tabIndex={tabIndex}
          placeholder={intl.formatMessage(messages.queryPlaceholder)}
          defaultValue={query}
          disableTour={!topicsCount && !isFetching}
          onDismiss={handleDismiss}
          onSubmit={handleSubmit}
        />
      </div>

      {onClose ? (
        <TextButton
          className={classes.cancel}
          tabIndex={tabIndex}
          onClick={onClose}
        >
          {intl.formatMessage(messages.cancel)}
        </TextButton>
      ) : null}
    </div>
  );
};

export default memo<Props>(MarketQueryForm);
