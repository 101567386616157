/* @flow */
import { em } from 'polished';

export default {
  root: {
    width: em(16),
    height: em(16),
    fontSize: '1.3em',
  },
};
