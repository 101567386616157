/* @flow */

import { em } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    paddingTop: 35,
    paddingBottom: 35,
    transition: '250ms ease-in-out',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  details: {
    width: 295,
  },
  title: {
    fontSize: em(16, baseFontSize),
    fontWeight: 600,
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
  },
  button: {
    width: 'max-content',
    gap: '5px',
  },
};
