/* @flow */

export const timeBeforeAnimationStart = 800;

export const CAN_CREATE_ENABLED = 'enabled';
export const CAN_CREATE_HIDDEN = 'hidden';

export const newTopicCreatedModal = 'newTopicCreatedModal';

export const ERROR_PAGE_CODES = {
  rangesNeverCreated: 'all_ranges_never_created',
  rangesPast: 'all_ranges_are_past',
  topicInterval: 'user_has_to_wait_interval_before_new_topic',
};

export const NEW_TOPICS_MODAL = {
  AFTER_3_DAYS: 'topic_creation_after_3_days',
  TOPIC_ENGAGEMENT: 'topic_engagement_ongoing_events',
};
