(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "ramda", "./locationAssertions", "@braindate/util/lib/assert", "../../base/l10n/util", "../../topic/setting"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("ramda"), require("./locationAssertions"), require("@braindate/util/lib/assert"), require("../../base/l10n/util"), require("../../topic/setting"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.ramda, global.locationAssertions, global.assert, global.util, global.setting);
    global.locationGetters = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _ramda, _locationAssertions, _assert, _util, _setting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLocationBraindateMeetingPoint = getLocationBraindateMeetingPoint;
  _exports.getLocationBraindatePods = getLocationBraindatePods;
  _exports.getLocationBraindateRanges = getLocationBraindateRanges;
  _exports.getLocationContext = getLocationContext;
  _exports.getLocationGroupBraindateCapacity = getLocationGroupBraindateCapacity;
  _exports.getLocationGroupBraindatePods = getLocationGroupBraindatePods;
  _exports.getLocationId = getLocationId;
  _exports.getLocationLabel = getLocationLabel;
  _exports.getLocationSuperGroupBraindateCapacity = getLocationSuperGroupBraindateCapacity;
  _exports.getLocationTimezone = getLocationTimezone;
  _exports.getLocationURL = getLocationURL;
  _exports.getLocationURLs = getLocationURLs;
  _exports.getLocationsCompatible = getLocationsCompatible;
  _exports.getLocationsSameGroupCapacity = getLocationsSameGroupCapacity;
  _exports.getLocationsSameSuperGroupCapacity = getLocationsSameSuperGroupCapacity;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
   * Get the id of a location
   * @param   {Location} location - Location to get the id from
   * @returns {number} Id
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getLocationId(location) {
    (0, _locationAssertions.assertLocation)(location);
    return location.id;
  }

  /**
   * Get the URL of a location
   * @param   {Location} location - Location to get the URL from
   * @returns {string} URL
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getLocationURL(location) {
    (0, _locationAssertions.assertLocation)(location);
    return location.url;
  }

  /**
   * Get the location_context of a location
   * @param   {Location} location - Location to get the location_context from
   * @returns {string} location_context
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getLocationContext(location) {
    (0, _locationAssertions.assertLocation)(location);
    return location.location_context;
  }

  /**
   * Get the URLs of a location
   * @param   {Location} location - Location to get the URLs from
   * @returns {LocationURLs} URLs
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an object
   */
  function getLocationURLs(location) {
    (0, _locationAssertions.assertLocation)(location);
    var urls = location.urls;
    (0, _locationAssertions.assertLocationURLs)(urls);
    return urls;
  }

  /**
   * Get the timezone of a location
   * @param   {Location} location - Location to get the timezone from
   * @returns {string} Timezone
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getLocationTimezone(location) {
    (0, _locationAssertions.assertLocation)(location);
    return location.timezone;
  }

  /**
   * Get the ranges of a location
   * @param   {Location} location - Event to get the slots from
   * @returns {Array<BraindateRange>} List of BraindateRange
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an array
   */
  function getLocationBraindateRanges(location) {
    (0, _locationAssertions.assertLocation)(location);
    var ranges = location.braindate_ranges;
    (0, _locationAssertions.assertLocationBraindateRanges)(ranges);
    return ranges.flatMap(function (range) {
      var computedRanges = range.computed_ranges;
      if (!computedRanges) return [range];

      // $FlowIssue Flow can't determine the typing
      return computedRanges.map(function (computedRange) {
        return _objectSpread(_objectSpread(_objectSpread({}, range), computedRange), {}, {
          computed_ranges: null
        });
      });
    });
  }

  /**
   * Get the group braindate capacity of a location
   * @param   {Location} location - Location
   * @returns {number} Capacity
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an array
   */
  function getLocationGroupBraindateCapacity(location) {
    (0, _locationAssertions.assertLocation)(location);
    return location.group_braindate_capacity;
  }

  /**
   * Get the super group braindate capacity of a location
   * @param   {Location} location - Location
   * @returns {number} Capacity
   *
   * @throws Will throw an exception if parameter is not an object or if attribute
   * is not an array
   */
  function getLocationSuperGroupBraindateCapacity(location) {
    (0, _locationAssertions.assertLocation)(location);
    return location.super_group_braindate_capacity;
  }

  /*
   |------------------------------------------------------------------------------
   | Advanced getters
   |------------------------------------------------------------------------------
   */

  /**
   * Get the label of a location
   * @param   {Location} location - Location to get the label from
   * @param   {string} [locale] - Locale in which to get the label
   * @returns {string} Label
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getLocationLabel(location, locale) {
    (0, _locationAssertions.assertLocation)(location);
    return (0, _util.getLocalizedData)(location, locale).label || "";
  }
  function getLocationBraindateMeetingPoint(location, topicKind, locale) {
    (0, _locationAssertions.assertLocation)(location);
    var getMeetingPointKey = function getMeetingPointKey(topicKind) {
      if (topicKind === _setting.topicGroupKind) {
        return "group_braindate_meeting_point";
      }
      if (topicKind === _setting.topicSuperGroupKind) {
        return "super_group_braindate_meeting_point";
      }
      return "braindate_meeting_point";
    };
    return (0, _util.getLocalizedData)(location, locale)[getMeetingPointKey(topicKind)] || "";
  }

  /**
   * Get the Braindate pods of a location
   * @param   {Location} location - Location to get the label from
   * @param   {string} [locale] - Locale in which to get the label
   * @returns {Array<string>} Braindate pods
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getLocationBraindatePods(location, locale) {
    (0, _locationAssertions.assertLocation)(location);
    return (0, _util.getLocalizedData)(location, locale).braindate_pods || [];
  }

  /**
   * Get the Group Braindate pods of a location
   * @param   {Location} location - Location to get the label from
   * @param   {string} [locale] - Locale in which to get the label
   * @returns {Array<string>} Group Braindate pods
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function getLocationGroupBraindatePods(location, locale) {
    (0, _locationAssertions.assertLocation)(location);
    return (0, _util.getLocalizedData)(location, locale).group_braindate_pods || [];
  }

  /**
   * Get all compatible location between two set of locations
   * @param   {Array<Location>} locationsA - Locations A
   * @param   {Array<Location>} locationsB - Locations B
   * @returns {Array<Location>} Compatible Locations
   */
  function getLocationsCompatible(locationsA, locationsB) {
    (0, _assert.assertArray)(locationsA, "locations");
    (0, _assert.assertArray)(locationsB, "locations");
    return locationsA.filter(function (topicLocation) {
      return locationsB.some(function (userLocation) {
        return getLocationId(userLocation) === getLocationId(topicLocation);
      });
    });
  }

  /**
   * Return the Group size capacity if they all have the same value, return null if they don't have the same value.
   * @param   {Array<Location>} locations - List of Locations
   * @returns {number|null} Common Group size capacity
   */
  function getLocationsSameGroupCapacity(locations) {
    (0, _assert.assertArray)(locations, "locations");
    var groupSizeList = locations.map(getLocationGroupBraindateCapacity);
    var uniqGroupSizes = (0, _ramda.uniq)(groupSizeList);
    if (uniqGroupSizes.length === 1) {
      return uniqGroupSizes[0];
    }
    return null;
  }

  /**
   * Return the Super Group size capacity if they all have the same value, return null if they don't have the same value.
   * @param   {Array<Location>} locations - List of Locations
   * @returns {number|null} Common Group size capacity
   */
  function getLocationsSameSuperGroupCapacity(locations) {
    (0, _assert.assertArray)(locations, "locations");
    var groupSizeList = locations.map(getLocationSuperGroupBraindateCapacity);
    var uniqGroupSizes = (0, _ramda.uniq)(groupSizeList);
    if (uniqGroupSizes.length === 1) {
      return uniqGroupSizes[0];
    }
    return null;
  }
});