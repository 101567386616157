/* @flow */

import { rem } from 'polished';

import { overlayColor } from '@braindate/ui/lib/token/color';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { borderRadius } from 'src/shared/app/base/style/token/box';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',
    height: '100%',

    backgroundColor: overlayColor,
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: '80%',
    maxWidth: 450,
    padding: '8px 40px',

    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.colorBase,
    borderRadius,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.33)',

    fontSize: rem(15, baseFontSize),
    fontWeight: 500,
  },
  message: {
    textAlign: 'center',
    marginTop: 0,
    padding: 10,
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',

    marginTop: 8,
  },
  button: {
    margin: 8,
  },
};
