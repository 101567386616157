/* @flow */

export const RESET_MEMBERSHIP = 'RESET_MEMBERSHIP';

export const FETCH_GUIDANCE_MARKET_PROMPT_REQUEST =
  'FETCH_GUIDANCE_MARKET_PROMPT_REQUEST';
export const FETCH_GUIDANCE_MARKET_PROMPT_SUCCESS =
  'FETCH_GUIDANCE_MARKET_PROMPT_SUCCESS';
export const FETCH_GUIDANCE_MARKET_PROMPT_FAILURE =
  'FETCH_GUIDANCE_MARKET_PROMPT_FAILURE';

export const DELETE_GUIDANCE_MARKET_PROMPT_REQUEST =
  'DELETE_GUIDANCE_MARKET_PROMPT_REQUEST';
export const DELETE_GUIDANCE_MARKET_PROMPT_SUCCESS =
  'DELETE_GUIDANCE_MARKET_PROMPT_SUCCESS';
export const DELETE_GUIDANCE_MARKET_PROMPT_FAILURE =
  'DELETE_GUIDANCE_MARKET_PROMPT_FAILURE';
