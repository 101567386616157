/* @flow */

import { useSelector } from 'react-redux';

import { isAppEmbedded } from 'src/shared/app/integration/selector/integrationSelectors';

const useIsAppEmbedded = () => {
  return useSelector(isAppEmbedded);
};

export default useIsAppEmbedded;
