/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import AppHooks from 'src/shared/app/base/component/AppHooks';
import AppThemeProvider from 'src/shared/app/base/component/AppThemeProvider';

const App = (): Node => {
  return (
    <AppThemeProvider>
      <AppHooks />
    </AppThemeProvider>
  );
};

export default memo<{}>(App);
