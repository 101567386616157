(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "../assert", "../type"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("../assert"), require("../type"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.assert, global.type);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _assert, _type) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.domainRegex = void 0;
  _exports.forceHTTPProtocol = forceHTTPProtocol;
  _exports.hasURLProtocol = hasURLProtocol;
  _exports.hasValidDomain = hasValidDomain;
  _exports.isURLAnchor = isURLAnchor;
  _exports.isURLMailto = isURLMailto;
  _exports.isValidLink = isValidLink;
  _exports.usernameHandleRegex = _exports.protocolRegex = _exports.phoneRegex = void 0;
  var protocolRegex = _exports.protocolRegex = /^([a-z]|[0-9]|\.|\+|-)+:/;
  var domainRegex = _exports.domainRegex = /(([a-zA-Z\d.-]+)\.([a-zA-Z]{2,}))/;
  var usernameHandleRegex = _exports.usernameHandleRegex = /^@/;
  var phoneRegex = _exports.phoneRegex = /^\+?[0-9]+$/;

  /**
   * Check if a URL has a protocol
   * @param   {string} url - URL to check
   * @returns {boolean} True if it has
   *
   * @throws Will throw an exception if parameter is not a string
   */
  function hasURLProtocol(url) {
    (0, _assert.assertString)(url, "url");
    return protocolRegex.test(url);
  }

  /**
   * Check if a URL is a valid domain
   * @param   {string} url - URL to check
   * @returns {boolean} True if it has
   *
   * @throws Will throw an exception if parameter is not a string
   */
  function hasValidDomain(url) {
    (0, _assert.assertString)(url, "url");
    return domainRegex.test(url);
  }

  /**
   * Prepend HTTP protocol to a URL
   * @param   {string} url - URL to prepend the protocol to
   * @param   {boolean} [secure=true] - Whether to use HTTPS
   * @returns {string} URL with protocol
   *
   * @throws Will throw an exception if parameter is not a string
   */
  function forceHTTPProtocol(url) {
    var secure = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    (0, _assert.assertString)(url, "url");
    var matches = url.match(/^http(s)?:\/\//);
    if ((0, _type.isArray)(matches)) {
      // $FlowIssue: matches can't be null at that point
      var useHTTPS = !!matches[1];
      if (secure && !useHTTPS) {
        return url.replace("http", "https");
      }
      if (!secure && useHTTPS) {
        return url.replace("https", "http");
      }
      return url;
    }
    return "http".concat(secure ? "s" : "", "://").concat(url);
  }

  /**
   * Check if a URL is an anchor
   * @param   {string} url - URL to check
   * @returns {boolean} True if it is
   *
   * @throws Will throw an exception if parameter is not a string
   */
  function isURLAnchor(url) {
    (0, _assert.assertString)(url, "url");
    return /^#/.test(url);
  }

  /**
   * Check if a URL is a mailto
   * @param   {string} url - URL to check
   * @returns {boolean} True if it is
   *
   * @throws Will throw an exception if parameter is not a string
   */
  function isURLMailto(url) {
    (0, _assert.assertString)(url, "url");
    return /^mailto:/.test(url);
  }

  /**
   * Checks if a string is an URL
   * @param   {string} url - URL to check
   * @returns {boolean} True if it is
   *
   * @throws Will throw an exception if parameter is not a string
   */
  function isValidLink(url) {
    (0, _assert.assertString)(url, "url");
    return hasURLProtocol(url) && hasValidDomain(url);
  }
});