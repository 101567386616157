/* @flow */

import { em } from 'polished';

export default {
  root: {
    width: em(27),
    height: em(14),
  },
};
