/* @flow */
import { memo } from 'react';
import type { Node } from 'react';

import { Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import classNames from 'classnames';
import { Field } from 'formik';
import { useId } from 'react-aria';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import ConditionalWrapper from 'src/shared/ui/component/misc/ConditionalWrapper';

import styles from './RadioList.style';

type Props = {
  name: string,
  label?: string,
  options: {
    label: string,
    value: string,
    icon?: Node,
    disabled?: boolean,
    ariaDescribedBy?: string,
    error?: string,
  }[],
  'aria-labelledby'?: string,
  'aria-disabled'?: boolean,
  onChange?: (string) => void,
  horizontal?: boolean,
  isLarge?: boolean,
};

const useStyles = createUseThemeStyles(styles);

const RadioList = ({
  label,
  name,
  options,
  'aria-labelledby': ariaLabelledBy,
  'aria-disabled': ariaDisabled,
  onChange,
  isLarge,
  horizontal,
}: Props) => {
  const classes = useStyles();

  const labelId = useId();

  const radioStyles = isLarge
    ? {
        '& .MuiSvgIcon-root': {
          fontSize: 26,
        },
      }
    : {};

  return (
    <div className={classes.root}>
      {label && (
        <label
          id={labelId}
          className={classNames(classes.inputLabel)}
          htmlFor={name}
        >
          {label}
        </label>
      )}

      <Field id={name} name={name}>
        {({
          field: { onChange: formikOnChange, onBlur, value },
          form: { touched, errors },
          meta: { initialValue },
        }) => (
          <FormControl error={errors ? errors[0] : null} variant="standard">
            <RadioGroup
              name={name}
              value={value}
              defaultValue={initialValue}
              aria-labelledby={ariaLabelledBy || label ? labelId : undefined}
              row={horizontal}
              onClick={(...props) => {
                const option = options.find(
                  // eslint-disable-next-line react/prop-types
                  (opt) => opt.value === props[0].target.value,
                );
                if (option?.disabled) return;
                formikOnChange(...props);
                if (onChange) {
                  onChange(value);
                }
              }}
              onBlur={onBlur}
              sx={radioStyles}
            >
              {options.map((option, key) => {
                const checked = value === option.value;
                const labelClasses = classNames(classes.label, {
                  [`${classes.disabledCheckbox}`]: option.disabled,
                });
                return (
                  <ConditionalWrapper
                    condition={option.error}
                    wrapper={(child) => (
                      <Tooltip
                        title={
                          <>
                            {option.error?.split('\n').map((msg, msgKey) => (
                              <p key={msgKey}>{msg}</p>
                            ))}
                            <span
                              id={`${name}-tooltip-${key}`}
                              className={classes.optionTooltip}
                              role="status"
                            >
                              {option.error}
                            </span>
                          </>
                        }
                        arrow
                        placement="top"
                      >
                        {child}
                      </Tooltip>
                    )}
                    key={key}
                  >
                    <FormControlLabel
                      name={name}
                      value={option.value}
                      checked={checked}
                      control={
                        <Radio
                          id={`${name}-radio-${key}`}
                          name={name}
                          color="default"
                          classes={{
                            root: classes.checkbox,
                            checked: classes.checkedCheckbox,
                            disabled: labelClasses,
                          }}
                          className={classNames({
                            [classes.disabledCheckbox]: option.disabled,
                          })}
                          aria-describedby={
                            option.disabled
                              ? `${name}-tooltip-${key}`
                              : undefined
                          }
                          aria-disabled={ariaDisabled}
                        />
                      }
                      aria-disabled={option.disabled}
                      label={
                        <span
                          id={`${name}-label-${key}`}
                          name={name}
                          className={classes.labelContent}
                        >
                          {option.icon}
                          {option.label}
                        </span>
                      }
                      aria-labelledby={option.ariaDescribedBy}
                      classes={{
                        root: classNames(classes.label, {
                          [classes.labelChecked]: checked,
                        }),
                      }}
                      htmlFor={`${name}-radio-${key}`}
                    />
                  </ConditionalWrapper>
                );
              })}
            </RadioGroup>

            {!!errors && !!errors[0] && touched && (
              <FormHelperText>{errors[0]}</FormHelperText>
            )}
          </FormControl>
        )}
      </Field>
    </div>
  );
};

export default memo<Props>(RadioList);
