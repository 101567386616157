/* @flow */

import { combineReducers } from 'redux';

import * as namespaces from 'src/shared/app/market/action/marketActionNamespaces';
import { createFilteredReducer } from 'src/shared/core/util/reducerUtils';

import marketAutocompleteTopicReducer from './marketAutocompleteTopicReducer';

// $FlowIssue
export default combineReducers({
  topic: createFilteredReducer(
    marketAutocompleteTopicReducer,
    (action) => action.namespace === namespaces.MARKET_AUTOCOMPLETE_TOPICS,
  ),
});
