/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { Portal as ReactPortal } from 'react-portal';

import useMounted from 'src/shared/app/base/hook/useMounted';
import usePortalRegister from 'src/shared/app/base/portal/notification/hook/usePortalRegister';
import { isClient } from 'src/shared/core/util/ssrUtil';

type Props = {
  id: string,
  children: Node,
};
const Portal = ({ children, id }: Props): Node => {
  usePortalRegister(id);

  // The component must re-render at the end of mounting events
  // because the HTML element of the portal ID might draw after.
  // this current component mount.
  // Adding a state change after mounting will make sure to
  // remount inside the real target HTML
  useMounted();

  return isClient ? (
    <ReactPortal node={document && document.getElementById(id)}>
      {children}
    </ReactPortal>
  ) : (
    children
  );
};

export default memo<Props>(Portal);
