/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';

import messages from 'src/shared/app/base/l10n/errorL10n';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import { homeRoute } from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import Subheading from 'src/shared/ui/component/text/Subheading';

import styles from './ErrorScreen.style';

type Props = {
  title?: string,
  children?: Node,
  actionLabel?: string,
  actionOnClick?: () => void,
  actionLink?: string,
  extraAction?: Node,
  extraContent?: Node,
};

const useStyles = createUseThemeStyles(styles);

const ErrorScreen = ({
  title,
  children,
  actionLabel,
  actionOnClick,
  actionLink,
  extraAction,
  extraContent,
}: Props): Node => {
  const intl = useIntl();

  const [homePath] = useRoutes(homeRoute);

  const classes = useStyles();
  const href = !actionOnClick ? actionLink || homePath : null;

  return (
    <section className={classes.root}>
      <Subheading>
        {title || intl.formatMessage(messages.genericError)}
      </Subheading>
      {children && <div className={classes.content}>{children}</div>}
      <div className={classes.actions}>
        <PrimaryButton
          href={href || undefined}
          layoutClass={classes.retry}
          onClick={actionOnClick}
        >
          {actionLabel || intl.formatMessage(messages.home)}
        </PrimaryButton>
        {extraAction}
      </div>
      {extraContent && <div className={classes.details}>{extraContent}</div>}
    </section>
  );
};

export default memo<Props>(ErrorScreen);
