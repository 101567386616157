/* @flow */

export const listReset = {
  marginTop: 0,
  marginBottom: 0,
  paddingLeft: 0,

  listStyleType: 'none',
};

export const antialiasing = {
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
};

export const defaultFontSmoothing = {
  WebkitFontSmoothing: 'auto',
  MozOsxFontSmoothing: 'auto',
};

export const pushDown = {
  position: 'relative',
  top: 2,
};

export function retina([original, retinaUrl]: Array<string>): Object {
  // `retinaImage` from `polished` doesn't seem adapted for our use case
  return {
    backgroundImage: `url(${original})`,
    [`@media only screen and (-webkit-min-device-pixel-ratio: 1.3),
   only screen and (min--moz-device-pixel-ratio: 1.3),
   only screen and (-o-min-device-pixel-ratio: 1.3/1),
   only screen and (min-resolution: 144dpi),
   only screen and (min-resolution: 1.5dppx)`]: {
      backgroundImage: `url(${retinaUrl || original})`,
    },
  };
}

// Accessibility, keep element invisible in UI but readable by screen readers
// Display: none remove an element from ui AND from screen readers
export const visuallyHidden = {
  position: 'absolute',
  clip: 'rect(1px, 1px, 1px, 1px)',
  padding: '0',
  margin: -1,
  border: 0,
  height: 1,
  width: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};
