/* @flow */

import { em } from 'polished';

export default {
  root: {
    fontSize: em(24),
    width: em(15),
    height: em(14),
  },
};
