/* @flow */

import { em } from 'polished';

export default {
  root: {
    fontSize: '1.3em',
    width: em(16),
    height: em(16),
  },
};
