/* @flow */

import * as types from 'src/shared/app/market/action/marketActionTypes';
import { keywordContext } from 'src/shared/app/market/constant/marketConstants';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  queryValue: '',
  queryContext: keywordContext,
};

const setQuery = (state: Object, { value }: Object) => ({
  ...state,
  queryValue: value,
});

const resetQuery = (state: Object) => ({
  ...state,
  queryValue: '',
});

const setQueryContext = (state: Object, { context }: Object) => ({
  ...state,
  queryContext: context,
});

export default createReducer(initialState, {
  [types.SET_MARKET_QUERY]: setQuery,
  [types.RESET_MARKET_QUERY]: resetQuery,
  [types.SET_MARKET_QUERY_CONTEXT]: setQueryContext,
});
