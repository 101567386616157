/* @flow */

import type { TimeSlotLocation } from '@braindate/domain/lib/base/type';
import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { getBraindateId } from '@braindate/domain/lib/braindate/util';
import type { Conversation } from '@braindate/domain/lib/conversation/type';
import {
  getConversationId,
  getConversationMarkAsReadURL,
  getConversationReplyURL,
  getConversationSendContactCardUrl,
  getConversationTopic,
} from '@braindate/domain/lib/conversation/util';
import { getTopicId } from '@braindate/domain/lib/topic/util';
import type { User } from '@braindate/domain/lib/user/type';

import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';
import { apiRoot } from 'src/shared/app/base/api/apiRoot';
import type {
  UseMutation,
  UseQuery,
} from 'src/shared/app/base/api/type/apiQueryType';
import { parseErrorResponse } from 'src/shared/app/base/api/utils/customQuery';
import { getQueryTag } from 'src/shared/app/base/api/utils/endpointUtils';
import {
  conversationCancelEndpoint,
  conversationEndpoint,
  conversationLeaveEndpoint,
  conversationsEndpoint,
} from 'src/shared/app/base/settings/endpointSettings';

type Api = {
  useGetConversationQuery: UseQuery<Conversation, number>,
  useGetConversationParticipantsQuery: UseQuery<User, { url: string }>,

  useConversationCreateMutation: UseMutation<
    ConversationCreateParams,
    Conversation,
  >,
  useConversationReplyMutation: UseMutation<
    ConversationReplyParams,
    Conversation,
  >,
  useConversationSendContactCardMutation: UseMutation<
    ConversationSendContactCardParams,
    Conversation,
  >,
  useConversationLeaveMutation: UseMutation<
    ConversationLeaveParams,
    Conversation,
  >,
  useConversationCancelMutation: UseMutation<
    ConversationLeaveParams,
    Conversation,
  >,
  useConversationMarkAsReadMutation: UseMutation<
    ConversationMarkAsReadParams,
    Conversation,
  >,
};

const invalidateConversation = {
  invalidatesTags: (
    _result,
    _error,
    {
      braindate,
      conversation,
    }: { braindate: Braindate, conversation: Conversation },
  ) => [
    {
      type: TAG_TYPE.CONVERSATION,
      id: getConversationId(conversation),
    },
    {
      type: TAG_TYPE.TOPIC,
      id: getTopicId(getConversationTopic(conversation)),
    },
    ...(braindate
      ? [
          {
            type: TAG_TYPE.BRAINDATE,
            id: getBraindateId(braindate),
          },
        ]
      : []),
  ],
};

const extendedApi: Api = apiRoot.injectEndpoints({
  endpoints: (builder) => ({
    getConversation: builder.query({
      query: (id: number) => ({
        url: conversationEndpoint(id),
      }),
      providesTags: (result) =>
        getQueryTag(TAG_TYPE.CONVERSATION, getConversationId(result)),
    }),
    getConversationParticipants: builder.query({
      query: ({ url }) => ({
        url,
      }),
    }),

    conversationCreate: builder.mutation({
      query: (params: ConversationCreateParams) => ({
        url: conversationsEndpoint(),
        method: 'POST',
        params,
      }),
      invalidatesTags: (result) => {
        return result
          ? [
              {
                type: TAG_TYPE.TOPIC,
                id: getTopicId(getConversationTopic(result)),
              },
              { type: TAG_TYPE.CONVERSATION, id: getConversationId(result) },
              { type: TAG_TYPE.BRAINDATE, id: 'LIST' },
            ]
          : [];
      },
      transformErrorResponse: (response) => {
        return parseErrorResponse(response);
      },
    }),
    conversationReply: builder.mutation({
      query: ({ conversation, message }: ConversationReplyParams) => ({
        url: getConversationReplyURL(conversation),
        method: 'POST',
        params: message,
      }),
      ...invalidateConversation,
    }),
    conversationSendContactCard: builder.mutation({
      query: ({ conversation, params }: ConversationSendContactCardParams) => ({
        url: getConversationSendContactCardUrl(conversation),
        method: 'POST',
        params,
      }),
      ...invalidateConversation,
    }),
    conversationLeave: builder.mutation({
      query: ({ conversation }: ConversationLeaveParams) => ({
        url: conversationLeaveEndpoint(getConversationId(conversation)),
        method: 'POST',
      }),
      ...invalidateConversation,
    }),
    conversationCancel: builder.mutation({
      query: ({ conversation }: ConversationLeaveParams) => ({
        url: conversationCancelEndpoint(getConversationId(conversation)),
        method: 'POST',
      }),
      ...invalidateConversation,
    }),
    conversationMarkAsRead: builder.mutation({
      query: ({ conversation, messageId }: ConversationMarkAsReadParams) => ({
        url: getConversationMarkAsReadURL(conversation),
        method: 'POST',
        params: {
          message_id: messageId,
        },
      }),
      ...invalidateConversation,
    }),
  }),
});

export const {
  useGetConversationQuery,
  useGetConversationParticipantsQuery,
  useConversationCreateMutation,
  useConversationReplyMutation,
  useConversationSendContactCardMutation,
  useConversationLeaveMutation,
  useConversationCancelMutation,
  useConversationMarkAsReadMutation,
} = extendedApi;

export type ConversationCreateParams = {
  topic?: string,
  dates?: TimeSlotLocation,
  message?: string,
  joined_from?: string,
  target_user?: string,
};

export type ConversationReplyParams = {
  braindate: Braindate,
  conversation: Conversation,
  message: {
    action: string,
    data?: {
      dates?: {
        start_time: string,
        end_time: string,
        location_id: number,
      }[],
      date?: {
        start_time: string,
        end_time: string,
        location_id: number,
      },
    },
    text?: string,
  },
};

export type ConversationSendContactCardParams = {
  conversation: Conversation,
  params: {
    email?: string,
    phone_number?: string,
    linkedin?: string,
    note: string,
  },
};

export type ConversationLeaveParams = {
  conversation: Conversation,
  braindate: Braindate,
};

export type ConversationMarkAsReadParams = {
  conversation: Conversation,
  messageId?: number,
};
