/* @flow */

import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import messages from 'src/shared/app/booking/l10n/bookingL10n';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';

type Props = {
  notification: NotificationType<>,
};

const GenericActionErrorNotification = ({ notification }: Props): Node => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  return (
    <Notification timeout={8000} handleDismiss={handleDismiss} withError>
      {intl.formatMessage(messages.genericActionErrorNotification)}
    </Notification>
  );
};

export default GenericActionErrorNotification;
