/* @flow */

import { memo, useMemo } from 'react';
import type { Node } from 'react';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import ChevronIconDown from 'src/shared/ui/component/icon/ChevronIconDown';

import styles from './Dropdown.style';
import {
  getDropdownOptionLabel,
  getDropdownOptionValue,
} from './util/dropdownUtils';

import type { DropdownOption } from './type/dropdownTypes';

type Props = {
  layoutClass?: string,
  id?: string,
  selectedValue?: string,
  options: Array<DropdownOption>,
  isDisabled?: boolean,
  multiple?: boolean,
  onBlur?: (event: HTMLInputElement | HTMLTextAreaElement) => void,
  onValueChange?: (string) => void,
  onChange?: (SyntheticInputEvent<HTMLSelectElement>) => void,
  extra?: string | Node,
  labelId: string,
};

const useStyles = createUseThemeStyles(styles);

const Dropdown = ({
  layoutClass,
  id,
  options,
  multiple,
  selectedValue,
  onValueChange,
  isDisabled,
  onChange,
  onBlur,
  extra,
  labelId,
  ...props
}: Props): Node => {
  const handleChange = (event: SyntheticInputEvent<HTMLSelectElement>) => {
    const { target } = event;

    if (onChange) onChange(event);
    if (onValueChange) onValueChange(target.value);
  };

  const classes = useStyles();

  const getValue = () => {
    if (selectedValue) return selectedValue;
    if (!multiple) return '';
    return [];
  };
  const inputValue = getValue();

  const optionElts = useMemo(
    () =>
      options.map((optionValue) => {
        const value = getDropdownOptionValue(optionValue);
        const label = getDropdownOptionLabel(optionValue);

        const getIsSelected = () => {
          if (typeof value === 'string') {
            return inputValue === value;
          }
          if (Array.isArray(inputValue)) {
            return inputValue.some((v) => v === value);
          }
          return false;
        };

        return (
          <MenuItem value={value} key={value} aria-selected={getIsSelected()}>
            {label}
          </MenuItem>
        );
      }),
    [options],
  );

  return (
    <div className={classNames(classes.root, layoutClass)}>
      <div className={classes.wrapper}>
        <FormControl fullWidth variant="outlined">
          <Select
            {...props}
            disabled={isDisabled}
            displayEmpty
            id={id}
            onBlur={onBlur}
            value={inputValue}
            onChange={handleChange}
            multiple={multiple}
            IconComponent={ChevronIconDown}
            data-testid="dropdown-component"
            labelId={labelId}
            MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            {optionElts}
          </Select>
        </FormControl>
      </div>
      {extra && <div className={classes.extra}>{extra}</div>}
    </div>
  );
};

export default memo<Props>(Dropdown);
