(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "polished"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("polished"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.polished);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _polished) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.yellow = _exports.white = _exports.twitterBlue = _exports.turquoiseGreen = _exports.teal = _exports.tangerine = _exports.red = _exports.officeRed = _exports.newport = _exports.moonlight = _exports.midnight = _exports.mediumGrey = _exports.mediumBlueGrey = _exports.linkedInBlue = _exports.lightYellow = _exports.lightRed = _exports.lightGrey = _exports.lightGreen = _exports.lightBlueGrey = _exports.lightBlue = _exports.husky = _exports.green = _exports.grappa = _exports.googleBlue = _exports.facebookBlue = _exports.evenLighterGrey = _exports.dune = _exports.darkYellow = _exports.darkTeal = _exports.darkRed = _exports.darkNewport = _exports.darkGrey = _exports.darkGreen = _exports.darkBlue = _exports.clay = _exports.brightRed = _exports.blueGrey = _exports.blue = _exports.black = _exports.avalanche = _exports.aquaGreen = _exports.aaDarkGrey = void 0;
  /*
   |------------------------------------------------------------------------------
   | Primary
   |------------------------------------------------------------------------------
   */
  var newport = _exports.newport = "#48646b";
  var darkNewport = _exports.darkNewport = (0, _polished.darken)(0.15, newport);
  var teal = _exports.teal = "#93ccc0";
  var darkTeal = _exports.darkTeal = (0, _polished.darken)(0.15, teal);

  /*
   |------------------------------------------------------------------------------
   | Secondary
   |------------------------------------------------------------------------------
   */

  var tangerine = _exports.tangerine = "#e7573c";
  var avalanche = _exports.avalanche = "#ffffff";
  var moonlight = _exports.moonlight = "#f7f7f5";
  var dune = _exports.dune = "#f0efd7";
  var grappa = _exports.grappa = "#574a54";
  var husky = _exports.husky = "#cbd6d6";

  /*
   |------------------------------------------------------------------------------
   | Neutrals
   |------------------------------------------------------------------------------
   */

  var white = _exports.white = "#ffffff";
  var black = _exports.black = "#000000";
  var clay = _exports.clay = "#cccccc";
  var midnight = _exports.midnight = "#000000";
  var blueGrey = _exports.blueGrey = "#141414";
  var mediumBlueGrey = _exports.mediumBlueGrey = "#7C8698";
  var lightBlueGrey = _exports.lightBlueGrey = "#EFF0F3";
  // Was "#6e6e6e" but didn't provide enough contrast
  var aaDarkGrey = _exports.aaDarkGrey = "#a0a0a0";
  var darkGrey = _exports.darkGrey = "#6e6e6e";
  var mediumGrey = _exports.mediumGrey = "#bababb";
  var lightGrey = _exports.lightGrey = "#e7e7e7";
  var evenLighterGrey = _exports.evenLighterGrey = "#bababa";

  /*
   |------------------------------------------------------------------------------
   | Helpers
   |------------------------------------------------------------------------------
   */

  var blue = _exports.blue = "#2bb3ee";
  var darkBlue = _exports.darkBlue = (0, _polished.darken)(0.15, blue);
  var lightBlue = _exports.lightBlue = (0, _polished.lighten)(0.15, blue);
  var green = _exports.green = "#159D6C";
  var darkGreen = _exports.darkGreen = (0, _polished.darken)(0.15, green);
  var lightGreen = _exports.lightGreen = (0, _polished.lighten)(0.15, green);
  var aquaGreen = _exports.aquaGreen = "#9fd6c4";
  var turquoiseGreen = _exports.turquoiseGreen = "#17C289";
  var red = _exports.red = "#ff7977";
  var darkRed = _exports.darkRed = (0, _polished.darken)(0.15, red);
  var lightRed = _exports.lightRed = (0, _polished.lighten)(0.15, red);
  var brightRed = _exports.brightRed = "#eA4335";
  var yellow = _exports.yellow = "#f9b131";
  var darkYellow = _exports.darkYellow = (0, _polished.darken)(0.15, yellow);
  var lightYellow = _exports.lightYellow = (0, _polished.lighten)(0.15, yellow);

  /*
   |------------------------------------------------------------------------------
   | Third Party
   |------------------------------------------------------------------------------
   */

  var facebookBlue = _exports.facebookBlue = "#3b5998";
  var twitterBlue = _exports.twitterBlue = "#1da1f2";
  var linkedInBlue = _exports.linkedInBlue = "#0077b5";
  var googleBlue = _exports.googleBlue = "#4285f4";
  var officeRed = _exports.officeRed = "#c93e2b";
});