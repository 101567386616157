/* @flow */

import { useEffect, useState } from 'react';

import useErrorModal from 'src/shared/app/base/modal/hook/useErrorModal';

let timerId: TimeoutID | void;

const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);

  const triggerErrorModal = useErrorModal();

  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, []);

  const copyToClipboard = async (text: string) => {
    if (!navigator.clipboard) return;
    try {
      await navigator.clipboard.writeText(text);

      setIsCopied(true);
      clearTimeout(timerId);
      timerId = window.setTimeout(() => setIsCopied(false), 3000);
    } catch (error) {
      triggerErrorModal(error);
    }
  };

  return {
    copyToClipboard,
    isCopied,
    isSupported: typeof navigator !== 'undefined' && navigator.clipboard,
  };
};

export default useCopyToClipboard;
