/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import BaseIcon from 'src/shared/ui/component/icon/BaseIcon';

import svg from './group-braindate-decoration.svg';
import styles from './GroupBraindateDecorationIcon.style';

type Props = {
  className?: string,
  title?: string,
  isSmall?: boolean,
  isExtraSmall?: boolean,
  color?: string,
  // Self passed
};

const useStyles = createUseThemeStyles(styles);

const GroupBraindateDecorationIcon = ({
  className,
  title,
  isSmall,
  isExtraSmall,
  color,
}: Props): Node => {
  const classes = useStyles({ color });

  const rootClasses = classNames(classes.root, className, {
    [classes['root-isSmall']]: isSmall,
    [classes['root-isExtraSmall']]: isExtraSmall,
  });

  return <BaseIcon className={rootClasses} svg={svg} title={title} />;
};

export default memo<Props>(GroupBraindateDecorationIcon);
