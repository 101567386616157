/* @flow */

import type { Location } from '@braindate/domain/lib/location/type';
import { getLocationId } from '@braindate/domain/lib/location/util';

import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';
import { apiRoot } from 'src/shared/app/base/api/apiRoot';
import type {
  EndpointSelector,
  UseQuery,
} from 'src/shared/app/base/api/type/apiQueryType';
import { listLoadingSettings } from 'src/shared/app/base/api/utils/endpointUtils';
import { locationsEndpoint } from 'src/shared/app/base/settings/endpointSettings';

type Api = {
  endpoints: {
    getLocations: EndpointSelector<void, Location[]>,
  },
  useGetLocationsQuery: UseQuery<Location[]>,
};

const extendedApi: Api = apiRoot.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query({
      query: () => ({
        url: locationsEndpoint(),
      }),
      ...listLoadingSettings<Location>({
        tagType: TAG_TYPE.LOCATION,
        idGetter: getLocationId,
      }),
    }),
  }),
});

export const { endpoints, useGetLocationsQuery } = extendedApi;

export const { getLocations } = endpoints;
