/* @flow */
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import { TransitionStatus } from 'react-transition-group';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import CheerBanner from 'src/shared/app/cheer/component/card/CheerBanner';
import CheerModalWrapper from 'src/shared/app/cheer/component/modal/CheerModalWrapper';
import messages from 'src/shared/app/cheer/l10n/cheerl10n';
import defaultCheersMessages from 'src/shared/app/cheer/l10n/defaultCheersl10n';
import {
  cheerCategoryDelightful,
  cheerCategoryFearless,
  cheerCategoryGenerous,
  cheerCategoryInsightful,
} from 'src/shared/app/cheer/util/cheerUtils';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';

import styles from './CheerSuggestions.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  transitionState: TransitionStatus,
  onNext: () => void,
};

const CheerSuggestions = ({ transitionState, onNext }: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const [, , { setValue: setCategory }] = useField('category');
  const [, , { setValue: setMessage }] = useField('message');

  const handleSelectMessage = (category: string, message: string) => {
    setCategory(category);
    setMessage(message);
    onNext();
  };

  const defaultCheers = [
    {
      category: cheerCategoryInsightful,
      message: defaultCheersMessages.insightfulMessage,
    },
    {
      category: cheerCategoryGenerous,
      message: defaultCheersMessages.generousMessage,
    },
    {
      category: cheerCategoryFearless,
      message: defaultCheersMessages.fearlessMessage,
    },
    {
      category: cheerCategoryDelightful,
      message: defaultCheersMessages.delightfulMessage,
    },
  ].map(({ category, message }) => ({
    category,
    message: intl.formatMessage(message),
  }));

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <h3 className={classes.title}>
          {intl.formatMessage(messages.sendAny)}
        </h3>

        <CheerModalWrapper transitionState={transitionState}>
          <div className={classes.grid}>
            {defaultCheers.map(({ category, message }, key: number) => (
              <CheerBanner
                className={classes.card}
                category={category}
                onClick={() => handleSelectMessage(category, message)}
                key={key}
              >
                {message}
                <PrimaryButton type="button">
                  {intl.formatMessage(messages.select)}
                </PrimaryButton>
              </CheerBanner>
            ))}
          </div>
        </CheerModalWrapper>
      </div>
    </div>
  );
};

export default CheerSuggestions;
