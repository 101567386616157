/* @flow */

import { createContext } from 'react';
import type { Node } from 'react';

import { portalTakeOverId } from 'src/shared/app/base/constant/domConstants';
import useScrollDirection from 'src/shared/app/base/hook/useIsScrollingDown';
import usePortalRegistrationLength from 'src/shared/app/base/portal/notification/hook/usePortalRegistrationLength';
import type { HideOnScrollContextType } from 'src/shared/ui/context/HideOnScrollContext/type/HideOnScrollContextType';

type Props = {
  children: Node,
};

export const HideOnScrollContext = createContext<HideOnScrollContextType>({
  isPageScrolled: false,
});

export const HideOnScrollContextProvider = ({ children }: Props) => {
  const takeOverLength = usePortalRegistrationLength(portalTakeOverId);

  const scrollingDirection = useScrollDirection();

  const isScrolled = () => {
    if (takeOverLength) return false;
    return scrollingDirection === 'down';
  };

  return (
    <HideOnScrollContext.Provider
      value={{
        isPageScrolled: isScrolled(),
      }}
    >
      {children}
    </HideOnScrollContext.Provider>
  );
};
