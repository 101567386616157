/* @flow */

export default {
  root: { display: 'flex' },
  button: {
    marginRight: '1em',
  },
  joinIcon: {
    position: 'absolute',
    left: '0.8em',
    top: '50%',
    transform: 'translateY(-50%)',
  },
};
