/* @flow */

import {
  getEventColor,
  getEventDarkColor,
} from '@braindate/domain/lib/event/util';

import useEvent from './useEvent';

export default function useEventAccentColor(): Array<string> {
  const event = useEvent();

  return [getEventColor(event), getEventDarkColor(event) || ''];
}
