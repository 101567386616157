/* @flow */

import { rem } from 'polished';

import { fadeDuration } from '@braindate/ui/lib/token/animation';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { fishbowlGreen } from 'src/shared/app/base/style/token/color';
import { antialiasing } from 'src/shared/app/base/style/token/helper';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    width: '100%',
    height: '100%',
    padding: '8px 8px 24px',

    transition: `opacity ${fadeDuration}`,
  },
  'root-isHidden': {
    opacity: 0,
  },
  top: {
    width: '100%',
  },
  heading: {
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,
    maxWidth: 240,
    margin: '8px auto 32px',
  },
  meeting: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 48,
    padding: 8,
  },
  instructions: {
    extend: antialiasing,

    marginBottom: '1em',

    fontSize: rem(20, baseFontSize),
    lineHeight: 26 / 18,
    textAlign: 'center',
  },
  icon: {
    textAlign: 'center',
    marginBottom: '2em',
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
  },
  action: {
    marginTop: '2em',
  },
  join: {
    display: 'flex',
    alignItems: 'center',
    background: fishbowlGreen,
    border: 0,
  },
  joinIcon: {
    fontSize: '1.4em',
    position: 'relative',
    left: '-0.3em',
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
  },
};
