/* @flow */

export const gutter = 8;

export const BOTTOM_VISIBLE_PADDING = 90;

export const MAX_MOBILE_GRID_WIDTH = 'calc(100% - 10px)';
export const MAX_TABLET_GRID_WIDTH = 950;
export const MAX_NO_SIDEBAR_GRID_WIDTH = 1000;
export const MAX_LAPTOP_GRID_WIDTH = 1200;
