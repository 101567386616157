/* @flow */

import { combineReducers } from 'redux';

import marketAutocompleteReducer from './autocomplete/marketAutocompleteReducer';
import marketFilterReducer from './filter/marketFilterReducer';
import marketSearchReducer from './search/marketSearchReducer';
import marketSortReducer from './sort/marketSortReducer';

// $FlowIssue
export default combineReducers({
  autocomplete: marketAutocompleteReducer,
  filter: marketFilterReducer,
  search: marketSearchReducer,
  sort: marketSortReducer,
});
