/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import FormikSelectChoice from 'src/shared/app/base/component/data-entry/form/FormikSelectChoice';
import Dropdown from 'src/shared/ui/component/form/dropdown/Dropdown';

type Props = {
  className: string,
  id?: string,
  field: Object,
  inputProps: Object,
  options: Object,
  required?: boolean,
  handleFocus: () => void,
  handleBlur: () => void,
  extra?: string | Node,
  renderSelected: (value: Object) => Node,
  onChange: (value: Object) => void,
  form: Object,
  id: string,
  labelId: string,
  'aria-label'?: string,
};

const FormikSelect = ({
  className,
  field,
  inputProps,
  options,
  extra,
  required,
  renderSelected,
  onChange,
  form,
  id,
  labelId,
}: Props): Node =>
  field.value && renderSelected ? (
    <FormikSelectChoice field={field} onChange={onChange} form={form}>
      {renderSelected}
    </FormikSelectChoice>
  ) : (
    <Dropdown
      {...field}
      {...inputProps}
      id={id}
      extra={extra}
      layoutClass={className}
      options={options}
      selectedValue={field ? field.value : null}
      required={required}
      labelId={labelId}
    />
  );

export default memo<Props>(FormikSelect);
