/* @flow */

import { rem } from 'polished';

import { fadeDuration } from '@braindate/ui/lib/token/animation';
import { reducedMotion } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    width: '100%',
    height: '100%',
    padding: '8px 8px 24px',

    transition: `opacity ${fadeDuration}`,
  },
  'root-isHidden': {
    opacity: 0,
  },
  img: {
    width: '80%',
    objectFit: 'contain',
    maxHeight: 310,
    backgroundImage: ({
      backgroundGif,
    }: ThemeFunction<{ backgroundGif: string }>) => `url(${backgroundGif})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: ({ backgroundGif }: ThemeFunction<{ backgroundGif: string }>) =>
      backgroundGif ? '20px 20px 0 20px' : 0,

    [reducedMotion]: {
      backgroundImage: 'none !important',
    },
  },
  heading: {
    fontSize: rem(16, baseFontSize),
    margin: '30px auto 0 auto',
  },
  title: {
    fontWeight: 600,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: rem(14, baseFontSize),
    textAlign: 'center',
    margin: '30px auto',
    color: ({ theme }: ThemeFunction<{}>) => theme.textColorTertiary,
  },
  actions: {
    marginTop: '2em',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
};
