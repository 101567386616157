/* @flow */

import * as types from 'src/shared/app/permission/constant/permissionConstants';
import type { CustomOptin } from 'src/shared/app/permission/type/permissionType';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  isFetched: false,
  isFetching: false,
  results: [],
};

const _setRequest = (state: Object): Object => ({
  ...state,
  isFetching: true,
});

const _setSuccess = (
  state: Object,
  { results }: { results: Array<CustomOptin> },
): Object => ({
  ...state,
  results: Array.isArray(results) ? results : [],
  isFetching: false,
  isFetched: true,
});

const _setFailure = (state: Object): Object => ({
  ...state,
  results: [],
  isFetching: false,
});

export default createReducer(initialState, {
  [types.FETCH_CUSTOMOPTIN_REQUEST]: _setRequest,
  [types.FETCH_CUSTOMOPTIN_SUCCESS]: _setSuccess,
  [types.FETCH_CUSTOMOPTIN_FAILURE]: _setFailure,
});
