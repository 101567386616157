/* @flow */

import { white } from '@braindate/ui/lib/token/palette';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'block',

    width: 15,
    height: 15,

    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    borderRadius: '50%',
  },
  'root-large': {
    width: 15,
    height: 15,
  },
  'root-darkBg': {
    borderColor: ({ theme }: ThemeFunction<{}>) => theme.colorBase,
  },
  badgeIconWrapper: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  houseIcon: {
    display: 'block',
    margin: 'auto',
    height: '100%',
    width: 9,
    color: white,
  },
};
