/* @flow */

import * as types from 'src/shared/app/interest/action/uiInterestActionTypes';
import type { State } from 'src/shared/core/type/reduxTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

/*
 |------------------------------------------------------------------------------
 | Initial state
 |------------------------------------------------------------------------------
 */

const initialState = {
  isInterestGuidanceShown: false,
  isInterestSelectionShown: false,
};

/*
 |------------------------------------------------------------------------------
 | Reducers
 |------------------------------------------------------------------------------
 */

const showInterestGuidance = (state: State) => ({
  ...state,
  isInterestGuidanceShown: true,
});

const hideInterestGuidance = (state: State) => ({
  ...state,
  isInterestGuidanceShown: false,
});

const showInterestSelection = (state: State) => ({
  ...state,
  isInterestSelectionShown: true,
});

const hideInterestSelection = (state: State) => ({
  ...state,
  isInterestSelectionShown: false,
});

export default createReducer(initialState, {
  [types.SHOW_INTEREST_GUIDANCE]: showInterestGuidance,
  [types.HIDE_INTEREST_GUIDANCE]: hideInterestGuidance,
  [types.SHOW_INTEREST_SELECTION]: showInterestSelection,
  [types.HIDE_INTEREST_SELECTION]: hideInterestSelection,
});
