/* @flow */

import { DateTime } from 'luxon';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateCheckInURL,
  getBraindateId,
} from '@braindate/domain/lib/braindate/util';
import { STATUS } from '@braindate/domain/lib/braindate/util/braindateConstant';

import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';
import { apiRoot } from 'src/shared/app/base/api/apiRoot';
import { defaultLimitPages } from 'src/shared/app/base/api/constant/apiConstant';
import type {
  ApiListResult,
  EndpointSelector,
  UseMutation,
  UseQuery,
} from 'src/shared/app/base/api/type/apiQueryType';
import {
  getQueryTag,
  infiniteLoadingSettings,
  listLoadingSettings,
} from 'src/shared/app/base/api/utils/endpointUtils';
import {
  braindateDiscussionSuggestionsEndpoint,
  braindateEndpoint,
  braindatesCountEndpoint,
  braindatesEndpoint,
  topicBraindateEndpoint,
} from 'src/shared/app/base/settings/endpointSettings';

type Api = {
  endpoints: {
    getPendingBraindates: EndpointSelector<Object, ApiListResult<Braindate>>,
  },
  useGetBraindateQuery: UseQuery<Braindate, number>,
  useGetBraindateByTopicIdQuery: UseQuery<Braindate, number>,
  useGetBraindateDiscussionSuggestionsQuery: UseQuery<string[], number>,

  useGetAllBraindatesQuery: UseQuery<Braindate>,
  useGetInvitesTabBraindatesQuery: UseQuery<ApiListResult<Braindate>>,
  useGetAllBraindatesQuery: UseQuery<Braindate[]>,
  useGetPendingBraindatesQuery: UseQuery<ApiListResult<Braindate>>,
  useCountPendingBraindatesQuery: UseQuery<{ count: number }>,
  useGetPastBraindatesQuery: UseQuery<ApiListResult<Braindate>>,
  useGetCancelledBraindatesQuery: UseQuery<ApiListResult<Braindate>>,
  useGetConfirmedBraindatesQuery: UseQuery<ApiListResult<Braindate>>,
  useCountConfirmedBraindatesQuery: UseQuery<{ count: number }>,
  useGetDeclinedBraindatesQuery: UseQuery<ApiListResult<Braindate>>,

  useBraindateCheckInMutation: UseMutation<Braindate, Braindate>,
};

const braindatesInfiniteLoadingSettings = infiniteLoadingSettings<Braindate>({
  tagType: TAG_TYPE.BRAINDATE,
  idGetter: getBraindateId,
});

// TODO: Could be reusable and filled with what's really possible with the endpoint
type BraindatesEndpointParams = Object & {
  user?: string | string[],
};

const parseParams = (
  params: BraindatesEndpointParams,
): BraindatesEndpointParams => {
  const userParam = ['me'];

  // make sure that user 'me' is in the user parameter
  if ('user' in params) {
    if (Array.isArray(params.user)) {
      params.user.forEach((u: string) => {
        if (u === 'me') return;
        userParam.push(u);
      });
    } else if (params.user !== 'me') {
      userParam.push(params.user);
    }
  }

  return { ...params, user: userParam };
};

const extendedApi: Api = apiRoot.injectEndpoints({
  endpoints: (builder) => ({
    getBraindate: builder.query({
      query: (id: number) => ({
        url: braindateEndpoint(id),
      }),
      providesTags: (result) =>
        result ? getQueryTag(TAG_TYPE.BRAINDATE, getBraindateId(result)) : [],
    }),
    getBraindateDiscussionSuggestions: builder.query({
      query: (id: number) => ({
        url: braindateDiscussionSuggestionsEndpoint(id),
      }),
    }),
    getBraindateByTopicId: builder.query({
      query: (id: number) => ({
        url: topicBraindateEndpoint(id),
      }),
      providesTags: (result) =>
        result ? getQueryTag(TAG_TYPE.BRAINDATE, getBraindateId(result)) : [],
    }),

    getAllBraindates: builder.query({
      query: (options: { optimized?: boolean }) => ({
        url: braindatesEndpoint(false),
        params: {
          user: 'me',
          limit: -1,
          for_braindate_screen: options?.optimized,
        },
      }),
      ...listLoadingSettings({
        tagType: TAG_TYPE.BRAINDATE,
        idGetter: getBraindateId,
      }),
    }),
    getInvitesTabBraindates: builder.query({
      query: ({ page = 1, limit = defaultLimitPages } = {}) => ({
        url: braindatesEndpoint(true),
        params: {
          user: 'me',
          limit,
          ordering: 'pending_tab',
          status: [STATUS.PENDING, STATUS.DECLINED, STATUS.CANCELLED],
          offset: (page - 1) * defaultLimitPages,
        },
      }),
      ...braindatesInfiniteLoadingSettings,
    }),
    getPendingBraindates: builder.query({
      query: ({ page = 1, limit = defaultLimitPages, ...params } = {}) => ({
        url: braindatesEndpoint(true),
        params: {
          ...parseParams(params),
          limit,
          ordering: '-last_message',
          status: [STATUS.PENDING],
          offset: (page - 1) * defaultLimitPages,
        },
      }),
      ...braindatesInfiniteLoadingSettings,
    }),
    countPendingBraindates: builder.query({
      query: ({ ...params } = {}) => ({
        url: braindatesCountEndpoint(),
        params: {
          ...parseParams(params),
          status: [STATUS.PENDING],
        },
      }),
      ...braindatesInfiniteLoadingSettings,
    }),
    getCancelledBraindates: builder.query({
      query: ({ page = 1, limit = defaultLimitPages, ...params } = {}) => ({
        url: braindatesEndpoint(true),
        params: {
          ...parseParams(params),
          limit,
          ordering: '-start_time',
          status: STATUS.CANCELLED,
          offset: (page - 1) * defaultLimitPages,
        },
      }),
      ...braindatesInfiniteLoadingSettings,
    }),
    getPastBraindates: builder.query({
      query: ({ page = 1, limit = defaultLimitPages, ...params } = {}) => ({
        url: braindatesEndpoint(true),
        params: {
          ...parseParams(params),
          limit,
          ordering: '-start_time',
          status: 'confirmed',
          end_time_before: DateTime.now().toISO(),
          offset: (page - 1) * limit,
        },
      }),
      ...braindatesInfiniteLoadingSettings,
    }),
    getConfirmedBraindates: builder.query({
      query: ({ page = 1, limit = defaultLimitPages, ...params } = {}) => ({
        url: braindatesEndpoint(true),
        params: {
          ...parseParams(params),
          limit,
          ordering: 'start_time',
          status: STATUS.CONFIRMED,
          end_time_after: DateTime.now().toISO(),
          offset: (page - 1) * defaultLimitPages,
        },
      }),
      ...braindatesInfiniteLoadingSettings,
    }),
    countConfirmedBraindates: builder.query({
      query: ({ ...params } = {}) => ({
        url: braindatesCountEndpoint(),
        params: {
          ...parseParams(params),
          status: STATUS.CONFIRMED,
          end_time_after: DateTime.now().toISO(),
        },
      }),
      ...braindatesInfiniteLoadingSettings,
    }),
    getDeclinedBraindates: builder.query({
      query: ({ page = 1, limit = defaultLimitPages, ...params } = {}) => ({
        url: braindatesEndpoint(true),
        params: {
          ...parseParams(params),
          limit,
          ordering: '-last_message',
          status: STATUS.DECLINED,
          offset: (page - 1) * defaultLimitPages,
        },
      }),
      ...braindatesInfiniteLoadingSettings,
    }),

    braindateCheckIn: builder.mutation({
      query: (braindate: Braindate) => ({
        url: getBraindateCheckInURL(braindate),
        method: 'POST',
      }),
      invalidatesTags: (result) => [
        getQueryTag(TAG_TYPE.BRAINDATE, getBraindateId(result)),
      ],
    }),
  }),
});

export const {
  useGetBraindateQuery,
  useGetBraindateByTopicIdQuery,
  useGetBraindateDiscussionSuggestionsQuery,

  useGetAllBraindatesQuery,
  useGetInvitesTabBraindatesQuery,
  useGetPendingBraindatesQuery,
  useCountPendingBraindatesQuery,
  useGetPastBraindatesQuery,
  useGetCancelledBraindatesQuery,
  useGetConfirmedBraindatesQuery,
  useCountConfirmedBraindatesQuery,
  useGetDeclinedBraindatesQuery,

  useBraindateCheckInMutation,

  endpoints,
} = extendedApi;

export const { getPendingBraindates } = endpoints;
