/* @flow */

import * as types from 'src/shared/app/account/availability/action/uiAvailabilityActionTypes';
import {
  availabilityManualSection,
  availabilityManualTakeover,
} from 'src/shared/app/account/availability/constant/availabilityConstants';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  section: null,
  takeover: null,
  showSyncConfirmation: false,
};

const _showManualSection = (state: Object) => ({
  ...state,
  section: availabilityManualSection,
});

const _resetSection = (state: Object) => ({
  ...state,
  section: null,
});

const _showManualTakeover = (state: Object) => ({
  ...state,
  takeover: availabilityManualTakeover,
});

const _resetTakeover = (state: Object) => ({
  ...state,
  takeover: null,
});

const _showSyncConfirmation = (state: Object) => ({
  ...state,
  showSyncConfirmation: true,
});

const _resetSyncConfirmation = (state: Object) => ({
  ...state,
  showSyncConfirmation: false,
});

export default createReducer(initialState, {
  [types.SHOW_MANUAL_AVAILABILITY_SECTION]: _showManualSection,
  [types.RESET_AVAILABILITY_SECTION]: _resetSection,
  [types.SHOW_MANUAL_AVAILABILITY_TAKEOVER]: _showManualTakeover,
  [types.RESET_AVAILABILITY_TAKEOVER]: _resetTakeover,
  [types.SHOW_SYNC_CONFIRMATION_MODAL]: _showSyncConfirmation,
  [types.RESET_SYNC_CONFIRMATION_MODAL]: _resetSyncConfirmation,
});
