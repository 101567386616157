/* @flow */

import { useIntl } from 'react-intl';

import { getLocalizedData } from '@braindate/domain/lib/base/l10n/util';

import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import type { DrawerMenuItemType } from 'src/shared/app/nav/component/drawermenu/DrawerMenu';
import useEvent from 'src/shared/domain/event/hook/useEvent';

import { getCustomMenuLinks } from 'plugin/custom-menu/util/customMenuUtils';

const useCustomMenuItems = (): DrawerMenuItemType[] => {
  const event = useEvent();
  const intl = useIntl();
  const { isCustomMenuEnabled } = useFeatureDecisions();
  if (!isCustomMenuEnabled) return [];

  const externalItems = getCustomMenuLinks(event) || [];

  const getExternalLinkLabel = (link: Object) =>
    getLocalizedData(link, intl.locale).label;
  const getExternalLinkHref = (link: Object) => link.href;
  const getExternalLinkIcon = (link: Object) => link.icon;
  const getExternalLinkTarget = (link: Object) => link.target;

  return externalItems.map((link) => ({
    // $FlowIssue
    icon: { src: getExternalLinkIcon(link), alt: getExternalLinkLabel(link) },
    title: getExternalLinkLabel(link),
    to: getExternalLinkHref(link),
    target: getExternalLinkTarget(link),
    bottom: false,
    mobileOnly: true,
  }));
};

export default useCustomMenuItems;
