/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { getEventPlugins } from '@braindate/domain/lib/event/util';

import FormBuilder from 'src/shared/app/base/component/data-entry/form-builder/FormBuilder';
import Modal from 'src/shared/app/base/modal/component/Modal';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import { showPostBraindateCheerModalBundle } from 'src/shared/app/cheer/action/cheerAction';
import { customImpactSurvey } from 'src/shared/app/feature/settings/featureSettings';
import {
  dismissNotification,
  dismissNotificationsBundle,
} from 'src/shared/app/notification/action/uiNotificationActions';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import { getBrowserInfo } from 'src/shared/core/util/browserUtil';
import { sendFeedback } from 'src/shared/domain/braindate/action/braindateActions';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import BaseButton from 'src/shared/ui/component/button/BaseButton';
import ChatIcon from 'src/shared/ui/component/icon/ChatIcon';

import messages from 'plugin/virtual-braindate/l10n/virtualBraindateL10n';

import styles from './BraindateFeedbackModal.style';
import defaultFieldsData from './BraindateFeedbackModalDefaultFields';

const useStyles = createUseThemeStyles(styles);

type FieldsDataType = 'checkbox_list' | 'text' | 'checkbox';

type FieldsData = {
  key: string,
  body: Object,
  choices?: Array<FieldsData>,
  field_type: FieldsDataType,
  more?: Array<FieldsData>,
};

type Props = {
  notification: Notification<Braindate>,
  bundleNotifications?: Array<Notification<Braindate>>,
};

const BraindateFeedbackModal = ({
  notification,
  bundleNotifications,
}: Props): Node => {
  const { data: braindate } = notification;
  const intl = useIntl();
  const event = useEvent();
  const classes = useStyles();
  const dispatch = useDispatch();

  const customImpactPlugin = getEventPlugins(event)[customImpactSurvey];
  const fieldsData: Array<FieldsData> =
    customImpactPlugin &&
    customImpactPlugin.survey &&
    customImpactPlugin.survey.questions
      ? customImpactPlugin.survey.questions
      : defaultFieldsData;

  const dispatchExpressionBundle = () => {
    if (!bundleNotifications) return;

    dispatch(
      showPostBraindateCheerModalBundle(
        bundleNotifications
          .map(({ data: bundleBraindate }) => bundleBraindate)
          .filter(Boolean),
      ),
    );
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (braindate) {
      const { rating, ...answers } = values;
      dispatch(sendFeedback(braindate, getBrowserInfo(), answers, rating))
        .finally(() => {
          setSubmitting(false);
          if (bundleNotifications) {
            dispatch(dismissNotificationsBundle(bundleNotifications));
          }
          dispatchExpressionBundle();
        })
        .catch((e) => {
          throw e;
        });
    }
  };
  const handleClose = () => {
    if (bundleNotifications) {
      dispatch(dismissNotificationsBundle(bundleNotifications));
    }
    dispatch(dismissNotification(notification));
    dispatchExpressionBundle();
  };

  return (
    <Modal
      width="fit-content"
      title={intl.formatMessage(messages.feedbackHeader)}
      icon={<ChatIcon />}
      isOpen={notification.active}
      content={
        <div>
          <FormBuilder fieldsData={fieldsData} onSubmit={handleSubmit} />
          <p className={classes.actions}>
            <FormattedMessage
              {...messages.feedbackSkip}
              values={{
                a(text) {
                  return (
                    <BaseButton
                      className={classes.btnSkip}
                      onClick={() => handleClose()}
                    >
                      {text}
                    </BaseButton>
                  );
                },
              }}
            />
          </p>
        </div>
      }
      handleClose={handleClose}
      hideActions
      shouldCloseOnOverlayClick={false}
    />
  );
};

export default memo<Props>(BraindateFeedbackModal);
