/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { Route, Routes } from 'react-router-dom';

import useMobileKeyboardVisibility from 'src/shared/app/base/hook/useMobileKeyboardVisibility';
import useMounted from 'src/shared/app/base/hook/useMounted';
import useReduxState from 'src/shared/app/base/hook/useReduxState';
import { getRoutes } from 'src/shared/app/base/route/registry/routeRegistry';
import {
  getRouteHelp,
  getRoutePath,
} from 'src/shared/app/base/route/util/routeUtils';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import HelpButton from 'src/shared/app/help/component/HelpButton';

const HelpSwitch = (): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */
  const { isHelpEnabled } = useFeatureDecisions();
  const isMounted = useMounted();
  const isMobileKeyboardVisible = useMobileKeyboardVisibility();

  const state = useReduxState();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  if (!(isHelpEnabled && isMounted && !isMobileKeyboardVisible)) return null;
  return (
    <Routes>
      {getRoutes().map((route) => {
        const showHelp = getRouteHelp(route, state);
        const path = getRoutePath(route);

        return (
          <Route
            path={path}
            element={showHelp ? <HelpButton /> : null}
            // Use the same key to prevent re-renders when routes share the
            // same main component
            key={1}
          />
        );
      })}
    </Routes>
  );
};

export default memo<{}>(HelpSwitch);
