/* @flow */

import { memo, useMemo } from 'react';

import { Chip } from '@mui/material';
import { useIntl } from 'react-intl';
import { TransitionStatus } from 'react-transition-group';

import type { Cheer } from '@braindate/domain/lib/cheer/type';
import {
  getCheerCategory,
  getCheerDateSent,
  getCheerMessage,
  getCheerSender,
  getCheerUnwrapitUrl,
} from '@braindate/domain/lib/cheer/util/cheerGetters';
import { getUserFullName } from '@braindate/domain/lib/user/util';

import { formatDateLong } from 'src/shared/app/base/date/util/dateFormatters';
import { convertToDateTime } from 'src/shared/app/base/date/util/dateUtils';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import CheerModalSponsors from 'src/shared/app/cheer/component/modal/CheerModalSponsors';
import CheerModalWrapper from 'src/shared/app/cheer/component/modal/CheerModalWrapper';
import messages from 'src/shared/app/cheer/l10n/cheerl10n';
import {
  cheerCategoryDelightful,
  cheerCategoryFearless,
  cheerCategoryGenerous,
  cheerCategoryInsightful,
} from 'src/shared/app/cheer/util/cheerUtils';
import UserAvatar, {
  small,
} from 'src/shared/components/domain/user/avatar/UserAvatar';
import useCheerPlugin from 'src/shared/domain/event/hook/useCheerPlugin';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import CheerDelightfulIcon from 'src/shared/ui/component/icon/CheerDelightfulIcon';
import CheerFearlessIcon from 'src/shared/ui/component/icon/CheerFearlessIcon';
import CheerGenerousIcon from 'src/shared/ui/component/icon/CheerGenerousIcon';
import CheerIcon from 'src/shared/ui/component/icon/CheerIcon';
import CheerInsightfulIcon from 'src/shared/ui/component/icon/CheerInsightfulIcon';
import UnwrapitGiftIcon from 'src/shared/ui/component/icon/UnwrapitGiftIcon';
import LinkButton from 'src/shared/ui/component/link/LinkButton';

import styles from './CheerRecipientModalContent.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  cheer: Cheer,
  transitionState: TransitionStatus,
  onNextClick?: () => void,
};

const CheerRecipientModalContent = ({
  cheer,
  transitionState,
  onNextClick,
}: Props) => {
  const intl = useIntl();
  const { gradient_received } = useCheerPlugin();
  const classes = useStyles({ gradient: gradient_received });

  const category = getCheerCategory(cheer);
  const author = getCheerSender(cheer);
  const unwrapitGiftUrl = getCheerUnwrapitUrl(cheer);

  const actionLabel = useMemo(() => {
    if (unwrapitGiftUrl) {
      if (onNextClick) {
        return intl.formatMessage(messages.openGiftAndNext);
      }
      return intl.formatMessage(messages.openGift);
    }

    return intl.formatMessage(messages.nextCheer);
  }, [cheer, onNextClick]);

  const ActionComponent = unwrapitGiftUrl ? LinkButton : PrimaryButton;

  const Icon = {
    [cheerCategoryInsightful]: CheerInsightfulIcon,
    [cheerCategoryGenerous]: CheerGenerousIcon,
    [cheerCategoryFearless]: CheerFearlessIcon,
    [cheerCategoryDelightful]: CheerDelightfulIcon,
  }[category];

  return (
    <div className={classes.root}>
      <div className={classes.banner}>
        <Chip
          label={getCheerCategory(cheer)}
          classes={{ root: classes.category }}
          color="info"
        />
        <Icon />
        <div className={classes.messageWrapper}>
          <CheerModalWrapper transitionState={transitionState}>
            <h2 className={classes.message}>{getCheerMessage(cheer)}</h2>
          </CheerModalWrapper>
        </div>
      </div>

      <div className={classes.wrapper}>
        <CheerModalWrapper transitionState={transitionState}>
          <div className={classes.content}>
            <div className={classes.from}>
              <UserAvatar user={author} variant={small} />

              <div className={classes.sender}>
                <span className={classes.senderName}>
                  {intl.formatMessage(messages.from, {
                    name: getUserFullName(author),
                  })}
                </span>
                <span>
                  {intl.formatMessage(messages.receivedOn, {
                    date: formatDateLong(
                      convertToDateTime(getCheerDateSent(cheer)),
                      intl.locale,
                    ),
                  })}
                </span>
              </div>
            </div>

            {unwrapitGiftUrl ? <CheerModalSponsors /> : null}

            {onNextClick || unwrapitGiftUrl ? (
              <ActionComponent
                layoutClass={classes.button}
                to={unwrapitGiftUrl}
                isExternal
                onClick={onNextClick}
              >
                {unwrapitGiftUrl ? <UnwrapitGiftIcon /> : <CheerIcon />}
                {actionLabel}
              </ActionComponent>
            ) : null}
          </div>
        </CheerModalWrapper>
      </div>
    </div>
  );
};

export default memo<Props>(CheerRecipientModalContent);
