/* @flow */

import { rem } from 'polished';

import { darkTextColor } from '@braindate/ui/lib/token/color';
import { white } from '@braindate/ui/lib/token/palette';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

export default {
  root: {
    position: 'fixed',
    top: 0,
    left: '-100%',

    padding: [rem(8, baseFontSize), rem(16, baseFontSize)],

    backgroundColor: white,
    color: darkTextColor,

    transition: ['left', '0.1s', 'ease-in-out'],
    zIndex: 99,
    '&:focus': {
      left: 0,
    },
  },
};
