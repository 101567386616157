/* @flow */

import { createElement, memo, useEffect } from 'react';
import type { Node } from 'react';

import ReactModal from 'react-modal';

import { containerId } from 'src/shared/app/base/constant/domConstants';
import useModalComponent from 'src/shared/app/base/modal/hook/useModalComponent';

const ModalCenter = (): Node | null => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */

  const [component, data] = useModalComponent();

  /*
   |----------------------------------------------------------------------------
   | Effects
   |----------------------------------------------------------------------------
   */

  useEffect(() => {
    ReactModal.setAppElement(document.getElementById(containerId));
  }, []);

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  if (component) {
    return createElement(component, data);
  }
  return null;
};

export default memo<{}>(ModalCenter);
