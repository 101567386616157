(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eventOccurenceOngoing = _exports.eventOccurenceLimited = _exports.eventEntityKey = _exports.eventContextVirtual = _exports.eventContextOnSite = _exports.eventContextHybrid = void 0;
  /**
   * Entity key used by the event schema
   * @type {string}
   */
  var eventEntityKey = _exports.eventEntityKey = "events";

  /**
   * String for event context : Hybrid
   * @type {string}
   */
  var eventContextHybrid = _exports.eventContextHybrid = "hybrid";

  /**
   * String for event context : OnSite
   * @type {string}
   */
  var eventContextOnSite = _exports.eventContextOnSite = "live";

  /**
   * String for event context : Virtual
   * @type {string}
   */
  var eventContextVirtual = _exports.eventContextVirtual = "virtual";

  /**
   * String for event occurrence : Limited
   * @type {string}
   */
  var eventOccurenceLimited = _exports.eventOccurenceLimited = "limited";

  /**
   * String for event occurrence : Ongoing
   * @type {string}
   */
  var eventOccurenceOngoing = _exports.eventOccurenceOngoing = "ongoing";
});