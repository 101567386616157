/* @flow */

import { em } from 'polished';

export default {
  root: {
    width: em(26),
    height: em(29),
  },
};
