(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "normalizr", "@braindate/util/lib/assert", "./eventAssertions", "../schema"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("normalizr"), require("@braindate/util/lib/assert"), require("./eventAssertions"), require("../schema"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.normalizr, global.assert, global.eventAssertions, global.schema);
    global.eventNormalization = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _normalizr, _assert, _eventAssertions, _schema) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hydrateEvent = hydrateEvent;
  _exports.hydrateEvents = hydrateEvents;
  _exports.normalizeEvent = normalizeEvent;
  _exports.normalizeEvents = normalizeEvents;
  /**
   * Normalize an event
   * @param   {BraindateEvent} event - Event to normalize
   * @returns {number} data.result - Id of the event
   * @returns {NormalizedData<number>} data.entities - Normalized data entities
   *
   * @throws Will throw an exception if parameter is not an object
   */
  function normalizeEvent(event) {
    (0, _eventAssertions.assertEvent)(event);
    return (0, _normalizr.normalize)(event, _schema.eventSchema);
  }

  /**
   * Normalize events
   * @param   {Array<BraindateEvent>} events - Events to normalize
   * @returns {Array<number>} data.result - Ids of the events
   * @returns {NormalizedData<Array<number>>} data.entities - Normalized data
   * entities
   *
   * @throws Will throw an exception if parameter is not an array
   */
  function normalizeEvents(events) {
    (0, _assert.assertArray)(events, "events");
    return (0, _normalizr.normalize)(events, _schema.eventsSchema);
  }

  /**
   * Denormalize an event
   * @param   {number} id - Id of the event
   * @param   {Entities} entities - Normalized data entities
   * @returns {BraindateEvent} Denormalized event
   *
   * @throws Will throw an exception if parameters are invalid
   */
  function hydrateEvent(id, entities) {
    (0, _assert.assertNumber)(id, "id");
    (0, _assert.assertObject)(entities, "entities");
    return (0, _normalizr.denormalize)(id, _schema.eventSchema, entities);
  }

  /**
   * Denormalize events
   * @param   {Array<number>} ids - Ids of the events
   * @param   {Entities} entities - Normalized data entities
   * @returns {Array<BraindateEvent>} Denormalized events
   *
   * @throws Will throw an exception if parameters are invalid
   */
  function hydrateEvents(ids, entities) {
    (0, _assert.assertArray)(ids, "ids");
    (0, _assert.assertObject)(entities, "entities");
    return (0, _normalizr.denormalize)(ids, _schema.eventsSchema, entities);
  }
});