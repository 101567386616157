/* @flow */

import { tablet, tabletPx } from '@braindate/ui/lib/token/mediaQuery';

export default {
  root: {
    [`@media only screen and (max-width: ${tabletPx}px)`]: {
      left: '16px !important',
      right: '16px !important',
    },
  },
  button: {
    cursor: 'pointer',
  },
  notificationsMenu: {
    width: '100%',
    maxWidth: 408,

    [tablet]: {
      width: '100vw',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: [15, 17, 6, 17],
  },
  title: {
    margin: 0,
  },
  actionButton: {
    padding: [0, 16],
    height: 32,
  },
  feed: {
    paddingBottom: 16,
    maxHeight: 490,
    overflowY: 'auto',
  },
  list: {
    '& > li': {
      marginBottom: '16px',
    },
  },
};
