/* @flow */

import { useIntl } from 'react-intl';

import { getLocalizedData } from '@braindate/domain/lib/base/l10n/util';
import type {
  BraindateEventCustomizations,
  LandingPageCustomizationBodyType,
  LandingPageCustomizationType,
} from '@braindate/domain/lib/event/type';
import {
  getEventCustomizations,
  getEventName,
} from '@braindate/domain/lib/event/util';

import useEvent from 'src/shared/domain/event/hook/useEvent';

const useEventLandingCustomization = () => {
  const event = useEvent();
  const { locale } = useIntl();

  const eventCustomizations: BraindateEventCustomizations =
    getEventCustomizations(event);

  const eventCustomizationLandingPage: LandingPageCustomizationType =
    eventCustomizations?.landing_page || {};

  const eventCustomizationLandingPageBody = (getLocalizedData(
    eventCustomizationLandingPage?.body || {},
    locale,
  ): LandingPageCustomizationBodyType);

  return {
    eventName:
      eventCustomizationLandingPageBody?.platform_name ||
      getEventName(event, locale),
    customTitle: eventCustomizationLandingPageBody?.title,
    customSubtitle: eventCustomizationLandingPageBody?.subtitle,
    customTextColor: eventCustomizationLandingPage?.text_color,
    bannerOverlayColor: eventCustomizationLandingPage?.banner_overlay_color,
    customBackgroundImage: eventCustomizationLandingPage?.background_image,
  };
};

export default useEventLandingCustomization;
