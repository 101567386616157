/* @flow */
import { memo } from 'react';
import type { Node } from 'react';

import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Popover, SwipeableDrawer, useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';

import { getUserFirstName } from '@braindate/domain/lib/user/util';
import { phablet } from '@braindate/ui/lib/token/mediaQuery';

import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import {
  contactRoute,
  faqRoute,
  supportRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/help/l10n/helpL10n';
import useSelf from 'src/shared/domain/user/hook/useSelf';
import PopoverButton from 'src/shared/ui/component/button/PopoverButton';
import Heading from 'src/shared/ui/component/text/Heading';

import useLiveChat from 'plugin/live-chat/hook/useLiveChat';
import useLiveChatToggle from 'plugin/live-chat/hook/useLiveChatToggle';

import styles from './HelpPopover.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  open: boolean,
  onClose: () => void,
  anchorEl?: HTMLElement | null,
  ariaLabelledby?: string,
};

const HelpPopover = ({ open, onClose, anchorEl, ariaLabelledby }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const self = useSelf();
  const isDesktop = useMediaQuery(phablet);

  const hasLiveChat = useLiveChat();
  const toggleLiveChat = useLiveChatToggle();

  const [faqPath, supportPath, contactPath] = useRoutes(
    faqRoute,
    supportRoute,
    contactRoute,
  );

  const baseProps = {
    open,
    onClose,
    'aria-labelledby': ariaLabelledby,
  };

  const sections: {
    heading: string,
    to?: string,
    action?: () => void,
    buttonIcon: Node,
    buttonTitle: String,
    buttonSubtitle?: string,
  }[] = [
    ...(hasLiveChat
      ? [
          {
            heading: intl.formatMessage(messages.conciergeStart),
            action: toggleLiveChat,
            buttonIcon: <ChatBubbleOutlineIcon />,
            buttonTitle: intl.formatMessage(messages.concierge),
            buttonSubtitle: intl.formatMessage(messages.conciergeAvailibility),
          },
        ]
      : []),
    ...(self
      ? [
          {
            heading: intl.formatMessage(messages.visitFaq),
            to: faqPath,
            buttonIcon: <HelpOutlineIcon />,
            buttonTitle: intl.formatMessage(messages.faq),
          },
        ]
      : []),
    {
      heading: intl.formatMessage(messages.contactSupportAssistance),
      to: self ? supportPath : contactPath,
      buttonIcon: <MailOutlineIcon />,
      buttonTitle: intl.formatMessage(messages.contactSupport),
    },
  ];

  const children = (
    <div className={classes.root}>
      {self && (
        <div className={classes.head}>
          <Heading level={3} layoutClass={classes.heading}>
            {intl.formatMessage(messages.howCanWeHelp, {
              first_name: getUserFirstName(self),
            })}
          </Heading>

          <ChevronLeftIcon className={classes.closeButton} onClick={onClose} />
        </div>
      )}

      {sections.map(
        (
          { heading, to, action, buttonIcon, buttonTitle, buttonSubtitle },
          key,
        ) => (
          <div className={classes.section} key={`help-section-${key}`}>
            <Heading level={4} layoutClass={classes.sectionHeading}>
              {heading}
            </Heading>

            <PopoverButton
              icon={buttonIcon}
              subtitle={buttonSubtitle}
              onClick={() => {
                onClose();
                if (action) {
                  action();
                }
              }}
              to={to}
            >
              {buttonTitle}
            </PopoverButton>
          </div>
        ),
      )}
    </div>
  );

  return isDesktop && anchorEl ? (
    <Popover
      {...baseProps}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </Popover>
  ) : (
    <SwipeableDrawer
      {...baseProps}
      onOpen={() => {}}
      keepMounted={false}
      disableDiscovery
      disableSwipeToOpen
      anchor="bottom"
    >
      {children}
    </SwipeableDrawer>
  );
};

export default memo<Props>(HelpPopover);
