/* @flow */
import { lap } from '@braindate/ui/lib/token/mediaQuery';

import { popOverElevation } from 'src/shared/app/base/style/token/elevation';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';
import { mobileBarHeight } from 'src/shared/app/nav/style/nav.style';

export default {
  root: {
    position: 'fixed !important',
    width: 72,
    height: 72,
    right: 15,
    bottom: ({
      isDrawerMenuEnabled,
    }: ThemeFunction<{ isDrawerMenuEnabled: boolean }>) =>
      26 + (isDrawerMenuEnabled ? mobileBarHeight : 9),
    zIndex: popOverElevation - 1,

    [lap]: {
      right: 42,
      bottom: '40px !important',
    },
  },
  icon: {
    width: '36px !important',
    height: '36px !important',
  },
};
