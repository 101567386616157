/* @flow */

import { useDispatch } from 'react-redux';

import {
  OAUTH_MESSAGE_ACTION_SLACK,
  OAUTH_MESSAGE_TYPE,
} from 'src/shared/app/account/oauth/constant/oauthConstant';
import { invalidateTags } from 'src/shared/app/base/api/actions/apiActions';
import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';
import useEventListener from 'src/shared/app/base/component/hook/useEventListener';
import useMembership from 'src/shared/domain/membership/hook/useMembership';

import { fetchSlackPluginOptions } from 'plugin/slack/reducer/slackPluginReducer';

const debug = require('debug')('useOauthMessages');

export default function useOauthMessages() {
  const membership = useMembership();
  const dispatch = useDispatch();

  const handleMessageWindow = ({ data, source }) => {
    debug('Received message: ', data);
    if (data.type === OAUTH_MESSAGE_TYPE) {
      if (data.action === OAUTH_MESSAGE_ACTION_SLACK) {
        dispatch(fetchSlackPluginOptions())
          .then(() => {
            if (source) {
              source.close();
            }
          })
          .catch((e) => {
            throw e;
          });
      } else if (membership) {
        dispatch(invalidateTags(TAG_TYPE.MEMBERSHIP_OAUTH_STATUS))
          .then(() => {
            if (source) {
              source.close();
            }
          })
          .catch((e) => {
            throw e;
          });
      }
    }
  };

  useEventListener('message', handleMessageWindow);
}
