/* @flow */

import type { Node } from 'react';

import classNames from 'classnames';
import { useId } from 'react-aria';
import { useIntl } from 'react-intl';

import messages from 'src/shared/app/base/l10n/uiL10n';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import CloseButton from 'src/shared/ui/component/button/CloseButton';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import SecondaryButton from 'src/shared/ui/component/button/SecondaryButton';

import BaseModal from './BaseModal';
import styles from './Modal.style';

export type Props = {
  title?: string,
  icon?: Node,
  message?: string | Node | [string | React.Node],
  content?: string | Node,
  handleAccept?: () => void,
  handleClose: () => void,
  acceptCTA?: string | Node,
  closeCTA?: string | Node,
  hideActions?: boolean,
  width?: string | number,
  isOpen?: boolean,
  isAnimated?: boolean,
  withLargeTitle?: boolean,
  shouldCloseOnOverlayClick?: boolean,
  onAfterClose?: () => void,
  titleClassName?: string,
  baseClassName?: string,
  contentClassName?: string,
  closeButtonClassName?: string,
};

const useStyles = createUseThemeStyles(styles);

const Modal = ({
  title,
  icon,
  message,
  content,
  handleClose,
  handleAccept,
  acceptCTA,
  closeCTA,
  hideActions = false,
  width,
  withLargeTitle = false,
  isOpen,
  isAnimated = false,
  shouldCloseOnOverlayClick = false,
  titleClassName,
  baseClassName,
  contentClassName,
  closeButtonClassName,
  onAfterClose,
}: Props): Node => {
  const intl = useIntl();
  const classes = useStyles({ withLargeTitle });

  const iconElt = icon && <div className={classes.icon}>{icon}</div>;
  const acceptCtaIsNode = acceptCTA && typeof acceptCTA !== 'string';
  const closeCtaIsNode = closeCTA && typeof closeCTA !== 'string';

  const titleId = useId();
  const titleElt = title && (
    <h2 id={titleId} className={classNames(classes.title, titleClassName)}>
      {title}
    </h2>
  );

  const messageItem = (msg) => <p className={classes.message}>{msg}</p>;

  const messageElt =
    message &&
    (Array.isArray(message) ? (
      <>{message.map(messageItem)}</>
    ) : (
      <>{messageItem(message)}</>
    ));

  const messageId = useId();

  const confirmBtn = handleAccept && (
    <PrimaryButton
      onClick={handleAccept}
      hasFocus
      data-testid="modal-primary-cta"
    >
      {acceptCTA || intl.formatMessage(messages.confirm)}
    </PrimaryButton>
  );

  const contentId = useId();

  const getAriaLabelledBy = () => {
    if (title) return titleId;
    if (message) return messageId;
  };

  const getAriaDescribedBy = () => {
    if (title && message) return messageId;
    if (content) return contentId;
  };

  return (
    <BaseModal
      width={width}
      isOpen={isOpen}
      isAnimated={isAnimated}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      handleClose={handleClose}
      onAfterClose={onAfterClose}
      baseClassName={baseClassName}
      ariaLabelledBy={getAriaLabelledBy()}
      ariaDescribedBy={getAriaDescribedBy()}
    >
      {iconElt}
      {titleElt}

      <CloseButton
        layoutClass={classNames(classes.closeCtn, closeButtonClassName)}
        onClick={handleClose}
        data-testid="modal-close-btn"
      />

      {message && <div id={messageId}>{messageElt}</div>}
      {content && (
        <div
          id={contentId}
          className={classNames(classes.content, contentClassName)}
        >
          {content}
        </div>
      )}
      {!hideActions && (
        <div className={classNames(classes.actions)}>
          {closeCtaIsNode ? (
            closeCTA
          ) : (
            <SecondaryButton
              onClick={handleClose}
              autoFocus={!handleAccept}
              data-testid="modal-secondary-cta"
            >
              {closeCTA || intl.formatMessage(messages.ok)}
            </SecondaryButton>
          )}
          {acceptCtaIsNode ? acceptCTA : confirmBtn}
        </div>
      )}
    </BaseModal>
  );
};

export default Modal;
